import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { useCrumbs } from "../stores/crumbs";

export const WatchCrumbs = () => {
  const location = useLocation();

  useEffect(() => {
    useCrumbs.getState().push(location.pathname);
  }, [location.pathname]);

  return null;
};
