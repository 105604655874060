import { select } from "radash";

import { useProducts } from "data/use-products";

export const ProductsSet = (props) => {
  const { value } = props;

  const products = useProducts();

  return select(
    value,
    (id) => products.byId(id).name,
    (id) => !!products.byId(id)
  ).join(", ");
};
