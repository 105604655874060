import pick from "lodash/pick";

import { activityStepImpacts } from "@evolved/constants";

import { toUnixDate } from "utils/unix-date";

const { COMPLETED, REVERTED } = activityStepImpacts;

const mapAccount = (account) => {
  if (!account) return {};

  return { accountId: account._id };
};

const mapOpportunity = (opportunity) => {
  if (!opportunity) return {};

  return { opportunityId: opportunity._id };
};

const mapVendor = (vendor) => {
  if (!vendor) return {};

  return { vendorId: vendor._id };
};

const mapActivity = ({ account, contact, opportunity, values, vendor }) => {
  return {
    fields: {
      ...pick(values, [
        "funnelProgressAfter",
        "opportunityStateChangeId",
        "summary",
        "typeId",
      ]),
      ...toUnixDate(values, "performedOn"),
      ...mapAccount(account),
      ...mapOpportunity(opportunity),
      ...mapVendor(vendor),
      ...(contact ? { contactId: contact._id } : {}),
    },
  };
};

const mapClosedFollowup = (closedFollowup) => {
  if (!closedFollowup) return {};

  return { closedFollowupId: closedFollowup._id };
};

export const mapImpactedSteps = ({ steps, values }) => {
  if (!values.steps) return {};

  return {
    impactedSteps: values.steps.reduce((accumulator, step, index) => {
      const original = steps[index];

      if (original.isComplete !== step) {
        accumulator.push({
          stepId: original._id,
          impact: step ? COMPLETED : REVERTED,
        });
      }

      return accumulator;
    }, []),
  };
};

export const preparePayload = ({
  account,
  closedFollowup,
  contact,
  opportunity,
  steps,
  values,
  vendor,
}) => {
  return {
    ...mapActivity({ account, contact, opportunity, values, vendor }),
    ...mapClosedFollowup(closedFollowup),
    ...mapImpactedSteps({ steps, values }),
    ...pick(values, ["contactIds", "lossReasonId", "userIds", "vendorIds"]),
  };
};
