import { Tooltip } from "antd";
import { useEffect, useRef, useState } from "react";
import { usePrevious } from "react-use";

import ReactDOM from "react-dom";
import isNil from "lodash/isNil";
import pick from "lodash/pick";

const Z_INDEX = 99999;

const useRefresh = () => {
  const timeoutId = useRef();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const refresh = () => {
    if (!isNil(timeoutId.current)) {
      clearTimeout(timeoutId.current);
    }

    setIsRefreshing(true);

    timeoutId.current = setTimeout(() => {
      setIsRefreshing(false);
    });
  };

  useEffect(() => {
    return () => {
      if (!isNil(timeoutId.current)) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [timeoutId]);

  return [isRefreshing, refresh];
};

const processDimensions = ({
  elementId,
  scrollIntoView = false,
  setDimensions,
}) => {
  const element = document.getElementById(elementId);

  if (!element) return;

  let domRect = element.getBoundingClientRect();

  // If offscreen, then we scroll into view and set the dimensions
  if (
    (domRect.x > window.innerWidth || domRect.y > window.innerHeight) &&
    scrollIntoView
  ) {
    element.scrollIntoView();
    domRect = element.getBoundingClientRect();
  }

  const diagonal = Math.ceil(
    Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2))
  );

  const borderSize = diagonal + domRect.width / 2;

  setDimensions({
    ...pick(domRect, ["x", "y", "width", "height"]),
    borderSize,
  });
};

const useDimensions = ({ elementId }) => {
  const [isRefreshing, refresh] = useRefresh();
  const [dimensions, setDimensions] = useState();

  useEffect(() => {
    if (!elementId) {
      return;
    }

    let timeoutId;

    const resize =
      ({ scrollIntoView } = {}) =>
      () => {
        if (!isNil(timeoutId)) {
          clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
          timeoutId = null;
          // todo: pass in a onCannotFind handler
          // onCannotFind could remove the active guide, show a tooltip saying that the guide got lost,
          // and possibly offer a way to start again, point to the button, or do nothing because the user already knows where the button is

          processDimensions({ elementId, scrollIntoView, setDimensions });
        }, 200);
      };

    const resizeOnResize = resize({ scrollIntoView: true });
    // Don't scrollIntoView on resize because the screen would pop around
    const resizeOnScroll = resize();

    window.addEventListener("resize", resizeOnResize);
    window.addEventListener("scroll", resizeOnScroll);

    refresh();
    processDimensions({ elementId, scrollIntoView: true, setDimensions });

    return () => {
      if (!isNil(timeoutId)) {
        clearTimeout(timeoutId);
      }

      window.removeEventListener("resize", resizeOnResize);
      window.removeEventListener("scroll", resizeOnScroll);
    };
  }, [elementId, setDimensions]);

  return { dimensions, isRefreshing };
};

export const Spotlight = (props) => {
  const { allowClicks = false, elementId, tooltip } = props;

  const previousElementId = usePrevious(elementId);
  const { dimensions, isRefreshing } = useDimensions({ elementId });

  const [isInSpotlight, setIsInSpotlight] = useState(false);

  const isReady = elementId && tooltip && dimensions;
  const shouldPauseSpotlight = elementId !== previousElementId || isRefreshing;

  const container = useRef(document.createElement("div"));

  useEffect(() => {
    document.body.appendChild(container.current);

    return () => {
      document.body.removeChild(container.current);
    };
  }, []);

  useEffect(() => {
    if (!allowClicks || !isReady || shouldPauseSpotlight) {
      return;
    }

    const onMouseEnter = (e) => {
      if (
        dimensions.x <= e.clientX &&
        e.clientX <= dimensions.x + dimensions.width &&
        dimensions.y <= e.clientY &&
        e.clientY <= dimensions.y + dimensions.height
      ) {
        setIsInSpotlight(true);
        return;
      }

      setIsInSpotlight(false);
    };

    document.addEventListener("mousemove", onMouseEnter);

    return () => {
      document.removeEventListener("mousemove", onMouseEnter);
    };
  }, [
    allowClicks,
    isReady,
    isInSpotlight,
    setIsInSpotlight,
    shouldPauseSpotlight,
  ]);

  let children;

  if (!isReady) {
    children = null;
  } else if (shouldPauseSpotlight) {
    // When previous !== current, we hide everything and rely on the effect above
    // to set things up, and defer visibility for 200ms

    children = (
      <div
        style={{
          backgroundColor: "grey",
          bottom: 0,
          left: 0,
          opacity: 0.7,
          position: "fixed",
          right: 0,
          top: 0,
          zIndex: Z_INDEX,
        }}
      />
    );
  } else {
    const spotlight = (
      <div
        style={{
          height: dimensions.height,
          left: dimensions.x,
          pointerEvents: allowClicks && isInSpotlight ? "none" : "auto",
          position: "fixed",
          top: dimensions.y,
          width: dimensions.width,
          zIndex: Z_INDEX,
        }}
      >
        <div
          style={{
            border: `${dimensions.borderSize}px solid grey`,
            boxSizing: "content-box",
            height: dimensions.height + 16,
            left: "50%",
            opacity: 0.7,

            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: dimensions.width + 16,
            zIndex: Z_INDEX,
          }}
        />
      </div>
    );

    children = tooltip.content ? (
      <Tooltip
        color={"black"}
        placement={tooltip.placement}
        title={tooltip.content}
        open
        zIndex={Z_INDEX}
      >
        {spotlight}
      </Tooltip>
    ) : (
      spotlight
    );
  }

  return ReactDOM.createPortal(children, container.current);
};
