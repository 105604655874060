import { Button } from "antd";

export const EditButton = (props) => {
  const { children, ...rest } = props;

  return (
    <Button ghost type="primary" {...rest}>
      {children || "Update Details"}
    </Button>
  );
};
