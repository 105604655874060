import { Select } from "antd";
import React from "react";
import moment from "moment";

const getOptions = () => {
  const options = [];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  for (let year = moment().year(); year < 2030; year++) {
    for (let month of months) {
      if (moment().month(month).year(year).isBefore(moment(), "month")) {
        continue;
      }

      options.push(`FUTUREYM_${year}_${month}`);
    }
  }

  return options;
};

export const Filters = (props) => {
  const {
    customDateFields,
    setFilterKey,
    filterKey,
    setRangeFilter,
    rangeFilter,
  } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "32px",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          fontWeight: 600,
          marginRight: "8px",
        }}
      >
        With
      </div>
      <Select
        size="small"
        style={{ flexGrow: 1 }}
        options={[
          { value: "targetWinDate", label: "Target Win Date" },
          ...customDateFields.map(({ id, name }) => ({
            value: `userDefinedFields.${id}`,
            label: name,
          })),
        ]}
        showSearch
        value={filterKey}
        onChange={setFilterKey}
      />
      <div
        style={{
          fontWeight: 600,
          margin: "0px 8px",
        }}
      >
        During
      </div>
      <Select
        size="small"
        style={{ marginRight: "8px", width: "150px" }}
        options={getOptions().map((option) => {
          const [, year, month] = option.split("_");

          return { value: option, label: `${month}, ${year}` };
        })}
        showSearch
        value={rangeFilter}
        onChange={setRangeFilter}
      />
    </div>
  );
};
