import { isEmpty } from "radash";
import { useEffect, useState } from "react";

import { viewTypes } from "@evolved/constants";
import { filterData, sortData } from "@evolved/views";

import { AddButton } from "../../components/add-button";
import { CreateOpportunityModal } from "../../components/modals/create-opportunity-modal";
import { DownloadCSV } from "../../components/view/download-csv/download-csv";
import { Extras } from "./extras";
import { FieldSelect } from "../../components/view/fields-select";
import { Filters } from "../../components/view/filters/filters";
import { OpenImportModal } from "../../components/modals/import-data";
import { SaveView } from "../../components/view/save-view";
import { SearchInput } from "../../components/view/search";
import { Sorters } from "../../components/view/sorters";
import { Table } from "../../components/view/table";
import { ViewActions } from "../../components/view/actions";
import { ViewSelect } from "../../components/view/view-select";
import {
  filterConfigKeys,
  reduceSearchResults,
  useSearch,
} from "../use-search";
import { useAccounts } from "../../data/use-accounts";
import { useContacts } from "../../data/use-contacts";
import { useDefaultView } from "../use-default-view";
import { useFieldConfigs } from "../../components/view/use-field-configs";
import { useLossReasons } from "../../data/use-loss-reasons";
import { useModal } from "../../hooks/use-modal";
import { useOpportunities } from "../../data/use-opportunities";
import { useOpportunityStates } from "../../data/use-opportunity-states";
import { useNavigate } from "react-router-dom";
import { useProducts } from "../../data/use-products";
import { useSalesProcesses } from "../../data/use-sales-processes";
import { useSearchStore } from "../../stores/search";
import { useTags } from "../../data/use-tags";
import { useUsers } from "../../data/use-users";
import { useVendors } from "../../data/use-vendors";
import { useViewStore } from "../../stores/view";

const CreateOpportunityButton = ({ setCreatedId }) => {
  const { modal, open } = useModal(CreateOpportunityModal);
  const navigate = useNavigate();

  const onCreate = async ({ opportunity }, { mutation }) => {
    // set createdId to be filtered out, so it doesn't pop into the table when
    // the cache is updated
    setCreatedId(opportunity._id);
    // update the cache
    // todo: should rename onSuccess bustCache or something more appropriate
    await mutation.onSuccess.call();
    // now we can navigate to the opportunity as it is in the cache
    navigate(`/opportunities/${opportunity._id}`);
  };

  return (
    <>
      {modal}
      <AddButton
        id="create-opportunity-button"
        onClick={() =>
          open({
            onCreate,
          })
        }
      >
        Opportunity
      </AddButton>
    </>
  );
};

export const OpportunitiesRoute = () => {
  const accounts = useAccounts();
  const contacts = useContacts();
  const lossReasons = useLossReasons();
  const opportunities = useOpportunities();
  const opportunityStates = useOpportunityStates();
  const products = useProducts();
  const salesProcesses = useSalesProcesses();
  const tags = useTags();
  const users = useUsers();
  const vendors = useVendors();

  const includeArchived = useViewStore(
    (state) => state[viewTypes.OPPORTUNITY].includeArchived
  );

  const [createdId, setCreatedId] = useState(null);

  useDefaultView(viewTypes.OPPORTUNITY);
  const fieldConfigs = useFieldConfigs(viewTypes.OPPORTUNITY);

  const search = useSearch(
    {
      primary: {
        items: opportunities.all({ includeArchived }),
        keys: filterConfigKeys(fieldConfigs),
      },
      secondary: [
        { path: "accountId", items: accounts.all(), keys: ["name"] },
        { path: "contacts", items: contacts.all(), keys: ["alias"] },
        { path: "lossReasonId", items: lossReasons.all(), keys: ["label"] },
        { path: "products", items: products.all(), keys: ["name"] },
        { path: "salesProcessId", items: salesProcesses.all(), keys: ["name"] },
        { path: "stateId", items: opportunityStates.all(), keys: ["label"] },
        { path: "tags", items: tags.all(), keys: ["label"] },
        { path: "users", items: users.all(), keys: ["alias"] },
        { path: "vendors", items: vendors.all(), keys: ["name"] },
      ],
    },
    viewTypes.OPPORTUNITY
  );

  useEffect(() => {
    if (useSearchStore.getState()[viewTypes.OPPORTUNITY].query) {
      search(useSearchStore.getState()[viewTypes.OPPORTUNITY].query);
    }
  }, [
    includeArchived,
    accounts,
    contacts,
    lossReasons,
    opportunities,
    products,
    salesProcesses,
    opportunityStates,
    tags,
    users,
    vendors,
  ]);

  const searchResult = useSearchStore(
    (state) => state[viewTypes.OPPORTUNITY].result
  );

  const filters = useViewStore((state) => state[viewTypes.OPPORTUNITY].filters);
  const sortOrder = useViewStore(
    (state) => state[viewTypes.OPPORTUNITY].sortOrder
  );

  let data;

  if (searchResult) {
    data = reduceSearchResults(searchResult)(opportunities);
  } else if (!isEmpty(filters)) {
    data = filterData({
      data: opportunities.all({ includeArchived }),
      fieldConfigs,
      filters,
    });
  } else {
    data = opportunities.all({ includeArchived });
  }

  if (!isEmpty(sortOrder)) {
    data = sortData({
      data,
      cache: {
        accounts: { entities: accounts.entities, key: "name" },
        lossReasons: { entities: lossReasons.entities, key: "label" },
        opportunityStates: {
          entities: opportunityStates.entities,
          key: "label",
        },
        products: {
          entities: products.entities,
          key: "name",
        },
        salesProcesses: { entities: salesProcesses.entities, key: "name" },
      },
      fieldConfigs,
      sortOrder,
    });
  }

  if (createdId) {
    data = data.filter(({ _id }) => _id !== createdId);
  }

  return (
    <div
      className="w-full"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        height: "100%",
      }}
    >
      <div className="mb-2">
        <div className="mb-4 flex items-center">
          <ViewSelect viewType={viewTypes.OPPORTUNITY} />
          <FieldSelect viewType={viewTypes.OPPORTUNITY} />
          <ViewActions viewType={viewTypes.OPPORTUNITY} />
          <SaveView viewType={viewTypes.OPPORTUNITY} />
          <div className="ml-auto flex">
            <Extras />
            <OpenImportModal type={viewTypes.OPPORTUNITY} />
            <div className="ml-2">
              <DownloadCSV data={data} viewType={viewTypes.OPPORTUNITY} />
            </div>
            <div className="ml-2">
              <CreateOpportunityButton setCreatedId={setCreatedId} />
            </div>
          </div>
        </div>
        <div className="flex items-start">
          <div className="flex flex-wrap items-center">
            <div className="mr-4">
              <SearchInput search={search} viewType={viewTypes.OPPORTUNITY} />
            </div>
            <Filters viewType={viewTypes.OPPORTUNITY} />
            <Sorters viewType={viewTypes.OPPORTUNITY} />
          </div>
        </div>
      </div>
      <Table data={data} viewType={viewTypes.OPPORTUNITY} />
    </div>
  );
};
