import { DisplayDate } from "./display-date";
import { UpdateSubscription } from "./subscription";

export const FailedToValidate = ({ organization, users }) => {
  const { trialPeriodEnd } = organization.subscriptionState;

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        We failed to validate your payment details.
      </div>
      <div style={{ marginBottom: "16px" }}>
        Please update your payment details to continue access after your trial
        ends on <DisplayDate date={trialPeriodEnd} />.
      </div>
      <UpdateSubscription organization={organization} users={users} />
    </>
  );
};
