import { useEffect } from "react";

export const useOnClick = (onClick, selector) => {
  useEffect(() => {
    if (!onClick) return;

    const element = document.getElementById(selector);

    if (!element) {
      return;
    }

    element.addEventListener("click", onClick);

    return () => {
      element.removeEventListener("click", onClick);
    };
  }, [onClick, selector]);
};
