import { Select } from "antd";

import groupBy from "lodash/groupBy";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import omit from "lodash/omit";
import sortBy from "lodash/sortBy";

import { colors } from "@evolved/constants";

import { CreateViewModal } from "./modals/create-view";
import { useModal } from "../../hooks/use-modal";
import { useMe } from "../../data/use-me";
import { useUsers } from "../../data/use-users";
import { useViews } from "../../data/use-views";
import { useViewStore } from "../../stores/view";

const { Option, OptGroup } = Select;

const NoViews = ({ viewType }) => {
  const { open: openCreateViewModal, modal: createViewModal } =
    useModal(CreateViewModal);

  return (
    <>
      {createViewModal}
      <div
        onClick={() => openCreateViewModal({ viewType })}
        style={{
          color: colors.PRIMARY,
          cursor: "pointer",
          marginRight: "16px",
          width: "235px",
        }}
      >
        Save Columns & Filters As A View
      </div>
    </>
  );
};

export const ViewSelect = (props) => {
  const { viewType } = props;

  const profile = useMe();
  const users = useUsers();
  const views = useViews({ filter: { type: viewType } });

  const selectedId = useViewStore((state) => state[viewType].selectedId);

  if (isEmpty(views)) return <NoViews viewType={viewType} />;

  const groups = groupBy(views, (view) => view.createdById);

  return (
    <Select
      optionFilterProp="children"
      notFoundContent="No matching views"
      onChange={(_id) =>
        useViewStore.getState().setView(viewType)(
          views.find((view) => view._id === _id)
        )
      }
      placeholder={"Select View"}
      showSearch
      style={{
        marginRight: "8px",
        minWidth: "250px",
      }}
      value={selectedId}
    >
      {groups[profile._id] && (
        <OptGroup label={"Your Views"}>
          {sortBy(
            groups[profile._id],
            ({ name }) => name && name.toUpperCase()
          ).map((view) => (
            <Option key={view._id} value={view._id}>
              {view.name}
            </Option>
          ))}
        </OptGroup>
      )}
      {map(omit(groups, [profile._id]), (views, userId) => {
        const { alias } = users.byId(userId);

        return (
          <OptGroup key={userId} label={alias}>
            {sortBy(views, ({ name }) => name && name.toUpperCase()).map(
              (view) => (
                <Option key={view._id} value={view._id}>
                  {view.name}
                </Option>
              )
            )}
          </OptGroup>
        );
      })}
    </Select>
  );
};
