import { useEffect, useRef } from "react";

export const useEffectOnce = (cb) => {
  const fired = useRef(false);

  useEffect(() => {
    if (fired.current) {
      return;
    }

    fired.current = true;
    return cb();
  }, []);
};
