export const UnknownState = () => {
  return (
    <div style={{ marginBottom: "16px" }}>
      We are not able to determine your subscription status. Please e-mail us at
      <> </>
      <strong>
        <a
          href="mailto:hello@evolvedmetrics.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          hello@evolvedmetrics.com
        </a>
      </strong>
      <> </>
      so we can make this right.
    </div>
  );
};
