import { buildEntityCache } from "./build-entity-cache";
import { useAccounts } from "data/use-accounts";
import { useContacts } from "data/use-contacts";
import { useOpportunityStates } from "data/use-opportunity-states";
import { useProducts } from "data/use-products";
import { useSalesProcesses } from "data/use-sales-processes";
import { useVendors } from "data/use-vendors";

export const useEntityCache = () => {
  const accounts = useAccounts().all();
  const contacts = useContacts().all();
  const opportunityStates = useOpportunityStates().all();
  const products = useProducts().all();
  const salesProcesses = useSalesProcesses().all();
  const vendors = useVendors().all();

  return buildEntityCache({
    accounts,
    contacts,
    opportunityStates,
    products,
    salesProcesses,
    vendors,
  });
};
