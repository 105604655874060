import { isEmpty } from "radash";

import { useQuery } from "@tanstack/react-query";

import { fetcher } from "./fetcher";

const root = "/reporting";

// todo: turn off cache for these queries
export const useActivityCounts = (range) => {
  const { data } = useQuery({
    keepPreviousData: true,
    queryKey: [root, "/activity-counts", range],
    queryFn: fetcher.get(
      `${root}/activity-counts`,
      !isEmpty(range) ? { query: range } : {}
    ),
  });

  return data;
};

export const useFunnelOpportunityCounts = () => {
  const { data } = useQuery({
    keepPreviousData: true,
    queryKey: [root, "/funnel-opportunity-counts"],
    queryFn: fetcher.get(`${root}/funnel-opportunity-counts`),
  });

  return data;
};

export const useNetworkingCounts = (range) => {
  const { data } = useQuery({
    keepPreviousData: true,
    queryKey: [root, "/networking-counts", range],
    queryFn: fetcher.get(
      `${root}/networking-counts`,
      !isEmpty(range) ? { query: range } : {}
    ),
  });

  return data;
};

export const useOpportunityCountsBy = (groupBy, range) => {
  const { data } = useQuery({
    keepPreviousData: true,
    queryKey: [root, "/opportunity-counts-by", groupBy, range],
    queryFn: fetcher.get(
      `${root}/opportunity-counts-by`,
      !isEmpty(groupBy) || !isEmpty(range)
        ? {
            query: {
              ...(!isEmpty(groupBy) ? { groupBy } : {}),
              ...(!isEmpty(range) ? { ...range } : {}),
            },
          }
        : {}
    ),
  });

  return data;
};

export const useProductOpportunityCountsBy = (groupBy, range) => {
  const { data } = useQuery({
    keepPreviousData: true,
    enabled: !!groupBy,
    queryKey: [
      root,
      "/product-opportunity-counts-by",
      groupBy,
      ...(range ? [range.from, range.to] : []),
    ],
    queryFn: fetcher.get(
      `${root}/product-opportunity-counts-by`,
      !isEmpty(groupBy) ? { query: { groupBy, ...range } } : {}
    ),
  });

  return data;
};

export const useUserActivityCounts = (range) => {
  const { data } = useQuery({
    keepPreviousData: true,
    queryKey: [root, "/user-activity-counts", range],
    queryFn: fetcher.get(
      `${root}/user-activity-counts`,
      !isEmpty(range) ? { query: range } : {}
    ),
  });

  return data;
};

export const useUserOpportunityCountsBy = (groupBy, range) => {
  const { data } = useQuery({
    keepPreviousData: true,
    enabled: !!groupBy,
    queryKey: [
      root,
      "/user-opportunity-counts-by",
      groupBy,
      ...(range ? [range.from, range.to] : []),
    ],
    queryFn: fetcher.get(
      `${root}/user-opportunity-counts-by`,
      !isEmpty(groupBy) ? { query: { groupBy, ...range } } : {}
    ),
  });

  return data;
};
