import React from "react";

const isViewer = (user) => {
  return user.role > 2;
};

const renderViewerLine = (users) => {
  return `$5.00 x ${users.length} viewer licenses`;
};

const renderStandardLine = (users) => {
  return `$25.00 x ${users.length} standard licenses`;
};

export const Price = (props) => {
  const { users } = props;

  const viewerUsers = users.filter(isViewer);
  const standardUsers = users.filter((user) => !isViewer(user));

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ fontSize: "32px", fontWeight: "bold" }}>
          CA${viewerUsers.length * 5 + standardUsers.length * 25}.00
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "8px",
          }}
        >
          <div style={{ lineHeight: "8px", marginTop: "6px" }}>per</div>
          <div>month (plus applicable taxes)</div>
        </div>
      </div>
      <div style={{ height: "32px", marginBottom: "32px", marginTop: "-4px" }}>
        <div>{renderStandardLine(standardUsers)}</div>
        <div>{renderViewerLine(viewerUsers)}</div>
      </div>
    </>
  );
};
