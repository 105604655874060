import filter from "lodash/filter";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Popover, Select, Tag } from "antd";
import { useState } from "react";

import { fieldTypes } from "@evolved/constants";

import { filterOption } from "components/selects/filter-option";
import { useViewStore } from "../../../stores/view";
import { getFieldList } from "../get-field-list";
import { useFieldConfigs } from "../use-field-configs";
import { AccountStateFilter } from "./account-state-filter";
import { DateFilter } from "./date-filter";
import { PastDateFilter } from "./past-date-filter";
import { RangeFilter } from "./range-filter";
import { SelectFilter } from "./select-filter";
import { SetFilter } from "./set-filter";
import { TextFilter } from "./text-filter";
import { renderView } from "./view/render-view";
import { useSearchStore } from "../../../stores/search";

const {
  ACCOUNT_STATE,
  ACTIVITY_DATE,
  CALCULATED,
  DATE,
  DOLLAR,
  FOLLOWUP_DATE,
  NUMBER,
  PERCENT,
  SELECT,
  SET,
  TEXT,
} = fieldTypes;

const FILTER_INPUTS = {
  [ACCOUNT_STATE]: AccountStateFilter,
  [ACTIVITY_DATE]: PastDateFilter,
  [CALCULATED]: RangeFilter,
  [DATE]: DateFilter,
  [DOLLAR]: RangeFilter,
  [FOLLOWUP_DATE]: DateFilter,
  [NUMBER]: RangeFilter,
  [PERCENT]: RangeFilter,
  [SELECT]: SelectFilter,
  [SET]: SetFilter,
  [TEXT]: TextFilter,
};

const filterBlacklisted = (blacklist) => (filters) => {
  return filter(filters, ({ dataIndex }) => !blacklist.includes(dataIndex));
};

const Filter = (props) => {
  const { canRemove, disabled, filter, viewType } = props;

  const fieldConfigs = useFieldConfigs(viewType);

  const [visible, setVisible] = useState(false);

  const config = fieldConfigs[filter.dataIndex] || {};
  const { title, type } = config;

  const FilterInput = FILTER_INPUTS[type];

  if (!FilterInput) return null;

  return (
    <Popover
      content={
        <>
          {visible && FilterInput && (
            <FilterInput
              config={config}
              defaultValue={filter.value}
              hide={() => setVisible(false)}
              onFilter={(filter) => {
                useViewStore.getState().setFilter(viewType)(filter);
                setVisible(false);
              }}
              type={type}
            />
          )}
        </>
      }
      destroyTooltipOnHide
      onOpenChange={disabled ? null : setVisible}
      open={disabled ? false : visible}
      overlayStyle={{ maxWidth: "250px", width: "250px" }}
      placement="bottom"
      trigger="click"
    >
      <Tag
        onClick={disabled ? null : () => setVisible(!visible)}
        style={{
          ...(disabled
            ? {
                cursor: "not-allowed",
              }
            : {
                cursor: "pointer",
              }),
          margin: "4px 4px 4px 0px",
        }}
      >
        <div
          className={`border-none ${disabled ? "text-gray-400" : ""}`}
          style={{ alignItems: "center", display: "flex" }}
        >
          <div>
            {title}: {renderView({ filter, config })}
          </div>
          {canRemove && (
            <CloseOutlined
              onClick={
                disabled
                  ? null
                  : (e) => {
                      useViewStore.getState().removeFilter(viewType)(
                        filter.dataIndex
                      );
                      e.stopPropagation();
                    }
              }
              style={{ marginLeft: "8px" }}
            />
          )}
        </div>
      </Tag>
    </Popover>
  );
};

const getAvailableFilters = ({ blacklist = [], filters, fieldConfigs }) => {
  return getFieldList(fieldConfigs).filter(
    (f) =>
      !filters.some(({ dataIndex }) => dataIndex === f.dataIndex) &&
      !blacklist.includes(f.dataIndex)
  );
};

const FilterSelect = (props) => {
  const { blacklist = [], disabled, viewType } = props;

  const filters = useViewStore((state) => state[viewType].filters);
  const fieldConfigs = useFieldConfigs(viewType);

  const options = getAvailableFilters({
    blacklist,
    fieldConfigs,
    filters,
  });

  const [value, setValue] = useState();
  const [visible, setVisible] = useState(false);

  const config = fieldConfigs[value] || {};
  const { type } = config;

  const FilterInput = FILTER_INPUTS[type];

  return (
    !isEmpty(options) && (
      <Popover
        content={
          <>
            {visible && (
              <Select
                onChange={setValue}
                options={options.map(({ dataIndex, title }) => ({
                  value: dataIndex,
                  label: title,
                }))}
                filterOption={filterOption}
                optionFilterProp="children"
                showSearch
                size="small"
                style={{ marginBottom: "8px", width: "100%" }}
                value={value}
              />
            )}
            {visible && FilterInput && (
              <FilterInput
                config={config}
                hide={() => setVisible(false)}
                onFilter={(filter) => {
                  useViewStore.getState().setFilter(viewType)(filter);
                  setVisible(false);
                }}
                type={type}
              />
            )}
          </>
        }
        destroyTooltipOnHide
        onOpenChange={
          disabled
            ? null
            : (visible) => {
                if (visible) {
                  setValue(first(options).dataIndex);
                }

                setVisible(visible);
              }
        }
        open={disabled ? false : visible}
        overlayStyle={{ maxWidth: "250px", width: "250px" }}
        placement="bottom"
        trigger="click"
      >
        <Button
          disabled={disabled}
          icon={<PlusOutlined />}
          size="small"
          style={{ marginRight: "8px" }}
        >
          Filter
        </Button>
      </Popover>
    )
  );
};

export const Filters = ({ blacklist = [], permanent = [], viewType }) => {
  const filters = useViewStore((state) => state[viewType].filters);
  const disabled = useSearchStore((state) => !!state[viewType].query);

  return (
    <>
      <div
        style={{
          color: "grey",
          marginRight: "8px",
        }}
      >
        Filter By:
      </div>
      {map(filterBlacklisted(blacklist)(filters), (filter) => (
        <Filter
          canRemove={!permanent.includes(filter.dataIndex)}
          disabled={disabled}
          filter={filter}
          key={filter.dataIndex}
          viewType={viewType}
        />
      ))}
      <FilterSelect
        blacklist={blacklist}
        disabled={disabled}
        viewType={viewType}
      />
    </>
  );
};
