import filter from "lodash/filter";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";

import {
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloseSquareOutlined,
  CoffeeOutlined,
  GlobalOutlined,
  IdcardOutlined,
  RadarChartOutlined,
  RocketOutlined,
  StarFilled,
  StarOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Divider,
  Empty,
  Pagination,
  Tag,
  Tooltip,
} from "antd";
import React, { useRef, useState } from "react";

import { activityStepImpacts, colors, styles } from "@evolved/constants";

import { Link } from "components/link";
import { OpportunityState } from "components/opportunity-state";
import { useAccounts } from "data/use-accounts";
import {
  useHighlightActivity,
  useUnhighlightActivity,
} from "data/use-activities";
import { useActivityTypes } from "data/use-activity-types";
import { useContacts } from "data/use-contacts";
import { useMe } from "data/use-me";
import { useOpportunities } from "data/use-opportunities";
import { useOrganization } from "data/use-organization";
import { useVendors } from "data/use-vendors";
import { useModal } from "hooks/use-modal";
import { longWithTime } from "utils/format-date";
import { selectEntities } from "utils/select-entities";
import { useUsers } from "../../../data/use-users";
import { Metadata } from "../metadata";
import { UpdateActivityModal } from "./update-activity";
import { HTML } from "../../html";

const { PRIMARY, INCOMPLETE, SUCCESS } = colors;
const { CLICKABLE_STYLE, PRIMARY_TEXT } = styles;
const { IMPACTS } = activityStepImpacts;

const sortActivities = (activities) => {
  return sortBy(activities, (a) => -a.performedOn);
};

const Header = ({
  activities,
  filterHighlighted,
  showPerformActivity,
  toggleHighlighted,
}) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        width: "100%",
        padding: "24px 16px",
        gridRowStart: 1,
      }}
    >
      <Badge
        count={activities.length}
        showZero
        style={{ backgroundColor: PRIMARY, marginRight: "16px" }}
      />
      <h3 style={{ margin: "0px" }}>Activities</h3>
      <div
        onClick={toggleHighlighted}
        style={{ ...CLICKABLE_STYLE, marginLeft: "auto" }}
      >
        {filterHighlighted ? "Show All" : "Show Highlighted"}
      </div>
      {showPerformActivity && (
        <Button
          ghost
          onClick={showPerformActivity}
          style={{ marginLeft: "16px" }}
          type="primary"
        >
          Log Activity
        </Button>
      )}
    </div>
  );
};

export const StepImpacts = (props) => {
  const { type } = props;

  const impacted = filter(props.impacted, { impact: type });

  if (impacted.length === 0) {
    return null;
  }

  const icon =
    type === IMPACTS.COMPLETED ? (
      <CheckSquareOutlined style={{ color: SUCCESS, marginRight: "8px" }} />
    ) : (
      <CloseSquareOutlined style={{ color: INCOMPLETE, marginRight: "8px" }} />
    );

  if (impacted.length === 1) {
    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
          fontSize: "1em",
          marginBottom: "8px",
        }}
      >
        {icon}
        <div>{impacted[0].step.question}</div>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", fontSize: "1em", marginBottom: "8px" }}>
      <Tooltip
        title={impacted.map(({ step }, index) => {
          return (
            <React.Fragment key={step.question}>
              <div>- {step.question}</div>
              {index < impacted.length - 1 ? <br /> : null}
            </React.Fragment>
          );
        })}
      >
        {icon}
        <span
          style={{ color: IMPACTS.COMPLETED === type ? SUCCESS : INCOMPLETE }}
        >
          {impacted.length}{" "}
          {type === IMPACTS.COMPLETED ? "Completed" : "Reverted"} Steps
        </span>
      </Tooltip>
    </div>
  );
};

export const Activity = (props) => {
  const { activity } = props;

  const contactsStore = useContacts();
  const accountsStore = useAccounts();
  const opportunitiesStore = useOpportunities();
  const vendorsStore = useVendors();
  const activityTypesStore = useActivityTypes();
  const usersStore = useUsers();

  const account = accountsStore.byId(activity.accountId);
  const activityType = activityTypesStore.byId(activity.typeId);
  const contacts = selectEntities(contactsStore, activity.contacts);
  const opportunity = opportunitiesStore.byId(activity.opportunityId);
  const users = selectEntities(usersStore, activity.users);
  const vendors = selectEntities(vendorsStore, activity.vendors);

  const profile = useMe();

  const highlightActivity = useHighlightActivity();
  const unhighlightActivity = useUnhighlightActivity();

  const { modal, open } = useModal(UpdateActivityModal);

  if (!profile) {
    return null;
  }

  const hasActivity = activity.users.some((user) => user === profile._id);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }}>
        {hasActivity && modal}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "8px",
          }}
        >
          <Tag style={{ marginRight: "0px" }}>
            <ClockCircleOutlined style={{ marginRight: "8px" }} />
            {longWithTime(activity.performedOn)}
          </Tag>
          {activity.opportunityStateChangeId && (
            <OpportunityState
              opportunity={{ stateId: activity.opportunityStateChangeId }}
            />
          )}
          {hasActivity && (
            <span
              onClick={() => open({ activity })}
              style={{
                ...CLICKABLE_STYLE,
                marginLeft: "auto",
                marginRight: "8px",
              }}
            >
              Edit
            </span>
          )}
          {props.activity.isHighlighted ? (
            <StarFilled
              onClick={() =>
                unhighlightActivity.mutate({
                  id: props.activity._id,
                })
              }
              style={{
                ...CLICKABLE_STYLE,
                ...(!hasActivity ? { marginLeft: "auto" } : {}),
              }}
            />
          ) : (
            <StarOutlined
              onClick={() =>
                highlightActivity.mutate({
                  id: props.activity._id,
                })
              }
              style={{
                ...CLICKABLE_STYLE,
                ...(!hasActivity ? { marginLeft: "auto" } : {}),
              }}
            />
          )}
        </div>
        <StepImpacts impacted={activity.impacted} type={IMPACTS.COMPLETED} />
        <StepImpacts impacted={activity.impacted} type={IMPACTS.REVERTED} />
        <div style={{ marginBottom: "8px" }}>
          <span style={{ ...PRIMARY_TEXT }}>
            <HTML content={activity.summary} />
          </span>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {activityType && (
            <Metadata
              Icon={CoffeeOutlined}
              value={get(activityType, "label")}
            />
          )}
          {!isEmpty(users) && (
            <Metadata
              Icon={UserOutlined}
              value={users.map((user) => user.alias).join(",")}
            />
          )}
          {!isEmpty(contacts) && (
            <Metadata
              Icon={IdcardOutlined}
              value={contacts.map((contact, i) => (
                <React.Fragment key={contact._id}>
                  <Link
                    link={`/contacts/${contact._id}`}
                    text={contact.alias}
                  />
                  <span>{i < contacts.length - 1 ? ", " : ""}</span>
                </React.Fragment>
              ))}
            />
          )}
          {!isEmpty(vendors) && props.organization?.settings?.showPartners && (
            <Metadata
              Icon={GlobalOutlined}
              value={vendors.map((vendor, i) => (
                <React.Fragment key={vendor._id}>
                  <Link link={`/partners/${vendor._id}`} text={vendor.name} />
                  <span>{i < vendors.length - 1 ? ", " : ""}</span>
                </React.Fragment>
              ))}
            />
          )}
          {opportunity && (
            <Metadata
              Icon={RocketOutlined}
              value={
                <Link
                  link={`/opportunities/${activity.opportunityId}`}
                  text={opportunity.alias}
                />
              }
            />
          )}
          {account && (
            <Metadata
              Icon={RadarChartOutlined}
              value={
                <Link
                  link={`/accounts/${activity.accountId}`}
                  text={account.name}
                />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const ActivitiesList = (props) => {
  const { activities, pageSize = 3, showPerformActivity } = props;

  const organization = useOrganization();

  const [filterHighlighted, setFilterHighlighted] = useState(false);
  const [current, setCurrent] = useState(1);

  const firstIndex = (current - 1) * pageSize;
  const filtered = filterHighlighted
    ? activities.filter((activity) => activity.isHighlighted)
    : activities;

  const slice = sortActivities(filtered).slice(
    firstIndex,
    firstIndex + pageSize
  );

  const listRef = useRef();

  const setCurrentPage = (page) => {
    setCurrent(page);
    if (listRef.current) listRef.current.scrollTop = 0;
  };

  const toggleHighlighted = () => {
    setFilterHighlighted(!filterHighlighted);
    setCurrentPage(1);
  };

  return (
    <Card
      bordered={false}
      bodyStyle={{
        paddingTop: "0px",
        display: "grid",
        height: "100%",
        gridTemplateRows: "64px 1fr 48px",
      }}
      style={{ overflow: "hidden" }}
    >
      <Header
        {...{
          activities,
          filterHighlighted,
          showPerformActivity,
          toggleHighlighted,
        }}
      />
      {isEmpty(slice) ? (
        <div
          className="flex items-center justify-center"
          style={{ gridRowStart: 2 }}
        >
          {isEmpty(activities) ? (
            <Empty description={"No activities, yet."} />
          ) : (
            <Empty
              description={
                <div>
                  No highlighted activities, yet.{" "}
                  <div
                    onClick={toggleHighlighted}
                    style={{ ...CLICKABLE_STYLE }}
                  >
                    Show All
                  </div>
                </div>
              }
            />
          )}
        </div>
      ) : (
        <div ref={listRef} style={{ gridRowStart: 2, overflowY: "scroll" }}>
          {slice.map((activity, index) => (
            <div key={activity._id} className="px-4">
              {index === 0 && <Divider style={{ marginTop: "0px" }} />}
              <Activity activity={activity} organization={organization} />
              {index !== slice.length - 1 && <Divider />}
            </div>
          ))}
        </div>
      )}
      <Pagination
        current={current}
        onChange={setCurrentPage}
        pageSize={pageSize}
        showSizeChanger={false}
        total={filtered.length}
      />
    </Card>
  );
};
