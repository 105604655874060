import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import startsWith from "lodash/startsWith";

export const isValidUrl = (url) => {
  return startsWith(url, "https://") || startsWith(url, "http://");
};

export const ValidUrl = ({ url }) => {
  if (!isValidUrl(url)) {
    return (
      <div>
        {url}
        <Tooltip title="A website URL must start with https:// or http:// for us to navigate to it (https if you want to be secure!). Please update this to the complete URL and this link will work.">
          <QuestionCircleOutlined style={{ marginLeft: "4px" }} />
        </Tooltip>
      </div>
    );
  }

  return (
    <a href={url} rel="noopener noreferrer" target="_blank">
      {url}
    </a>
  );
};
