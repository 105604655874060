import { Badge, Button, Card, List, Popconfirm } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import sortBy from "lodash/sortBy";

import { colors } from "@evolved/constants";

import { LossReasonModal } from "./modals/loss-reason";
import { useModal } from "hooks/use-modal";
import { useArchiveLossReason, useLossReasons } from "data/use-loss-reasons";

const { PRIMARY } = colors;

const sortLossReasons = (lossReasons) => {
  return sortBy(lossReasons, "label");
};

const Header = ({ lossReasons, openLossReasonModal }) => {
  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={lossReasons.length}
        showZero
        style={{ backgroundColor: PRIMARY }}
      />
      <span style={{ marginLeft: "16px" }}>Reasons for Loss</span>
      <Button
        ghost
        onClick={() => openLossReasonModal()}
        style={{ marginLeft: "auto" }}
        type="primary"
      >
        <PlusCircleOutlined style={{ margin: "0px" }} />
      </Button>
    </div>
  );
};

export const LossReasons = () => {
  const lossReasons = useLossReasons().all({ includeArchived: false });
  const archiveLossReason = useArchiveLossReason();

  const { open: openLossReasonModal, modal: lossReasonModal } =
    useModal(LossReasonModal);

  return (
    <>
      {lossReasonModal}
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
        <List
          dataSource={sortLossReasons(lossReasons)}
          header={<Header {...{ lossReasons, openLossReasonModal }} />}
          itemLayout={"vertical"}
          locale={{ emptyText: "You do not have any reasons for loss" }}
          pagination={{
            pageSize: 5,
          }}
          renderItem={(lossReason) => {
            const { _id, description, label } = lossReason;

            return (
              <List.Item key={_id}>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div>
                    <div>{label}</div>
                    {description && (
                      <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {description}
                      </div>
                    )}
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <span
                      className="primary-clickable"
                      onClick={() => openLossReasonModal({ lossReason })}
                    >
                      Update
                    </span>
                    <span style={{ margin: "0px 8px" }}>|</span>
                    <Popconfirm
                      okText="Delete"
                      onConfirm={() =>
                        archiveLossReason.mutateAsync({ id: _id })
                      }
                      title="Loss reasons will remain on opportunities where they currently exist"
                    >
                      <span className="primary-clickable">Delete</span>
                    </Popconfirm>
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};
