import { Button, DatePicker, Popover } from "antd";
import { CalendarOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useState } from "react";
import day from "dayjs";

import {
  THIS_MONTH,
  ONE_MONTH,
  TWO_MONTHS,
  SIX_MONTHS,
  CALENDAR_YEAR,
  getTimeRange,
} from "./use-dashboard-filters";

import { dateFilters } from "@evolved/constants";

const {
  FUTURE_15_DAYS,
  FUTURE_1_MONTH,
  FUTURE_3_MONTHS,
  FUTURE_6_MONTHS,
  FUTURE_1_YEAR,
} = dateFilters;

const { RangePicker } = DatePicker;

const selectRangeValue =
  (setWidgetFilter, setVisible) => (rangeValue) => () => {
    setVisible(false);
    setWidgetFilter(rangeValue);
  };

// TODO: Replace this with components/view/filters/range-filter?
export const RangeSelect = (props) => {
  const {
    back,
    forward,
    supportsOffset,
    widgetFilter,
    widgetFilterLabel,
    setWidgetFilter,
    tenses = [],
  } = props;

  const [visible, setVisible] = useState(false);

  const onSelect = selectRangeValue(setWidgetFilter, setVisible);

  const content = (
    <>
      <div style={{ display: "flex" }}>
        {tenses.includes("past") && (
          <>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(THIS_MONTH)}
            >
              This Month
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(ONE_MONTH)}
            >
              Last Month
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(TWO_MONTHS)}
            >
              Last 2 Months
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(SIX_MONTHS)}
            >
              Last 6 Months
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(CALENDAR_YEAR)}
            >
              Calendar Year
            </Button>
          </>
        )}
        {tenses.includes("future") && (
          <>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(FUTURE_15_DAYS)}
            >
              Next 15 Days
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(FUTURE_1_MONTH)}
            >
              Next Month
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(FUTURE_3_MONTHS)}
            >
              Next 3 Months
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(FUTURE_6_MONTHS)}
            >
              Next 6 Months
            </Button>
            <Button
              size={"small"}
              style={{ marginRight: "8px" }}
              onClick={onSelect(FUTURE_1_YEAR)}
            >
              Next Year
            </Button>
          </>
        )}
        {tenses.includes("all-time") && (
          <Button
            size={"small"}
            style={{ marginRight: "8px" }}
            onClick={onSelect([])}
          >
            All Time
          </Button>
        )}
      </div>
      <RangePicker
        {...(tenses.length === 1 && tenses[0] === "future"
          ? {
            disabledDate: (current) =>
              current && current < day().endOf("day"),
          }
          : {})}
        onChange={(value) => onSelect(value)()}
        size={"small"}
        style={{ marginTop: "8px" }}
        value={getTimeRange(widgetFilter)}
      />
    </>
  );

  return (
    <>
      {supportsOffset && (
        <Button
          icon={<LeftOutlined />}
          onClick={back}
          style={{ marginRight: "8px" }}
        />
      )}
      <h4
        style={{ marginBottom: "0px", marginRight: "8px", fontSize: "18px", textAlign: "center", ...widgetFilterLabel !== "All Time" ? { width: "250px", } : {} }}
      >
        {widgetFilterLabel}
      </h4>
      {supportsOffset && (
        <Button
          icon={<RightOutlined />}
          onClick={forward}
          disabled={!forward}
          style={{ marginRight: "8px" }}
        />
      )}
      <Popover
        content={content}
        onOpenChange={setVisible}
        placement={"bottom"}
        trigger={"click"}
        open={visible}
      >
        <CalendarOutlined style={{ fontSize: "18px" }} />
      </Popover>
    </>
  );
};
