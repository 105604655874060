import {
  CheckCircleOutlined,
  DollarOutlined,
  GlobalOutlined,
  GoldOutlined,
  HourglassOutlined,
  InfoCircleOutlined,
  PlayCircleOutlined,
  RadarChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";

import { viewTypes } from "@evolved/constants";
import { getPartnersLabel } from "@evolved/labels";
import { ScrollableContent } from "../../components/scrollable-content";

import { SET_OPPORTUNITY_PRODUCTS_MODAL, useLayoutStore } from "stores/layout";

import { useModal } from "../../hooks/use-modal";
import { InfoCard } from "components/info-card";
import { UserDefinedFields } from "components/user-defined-fields";
import { long } from "utils/format-date";
import { formatDollars } from "utils/format-dollars";
import { renderList } from "utils/render-list";
import { SetUsers } from "./modals/set-users";
import { SetVendors } from "./modals/set-vendors";
import { useAccounts } from "data/use-accounts";
import { useProducts } from "data/use-products";
import { useUsers } from "data/use-users";
import { useVendors } from "data/use-vendors";

const Product = ({ opportunity }) => {
  const byId = useProducts().byId;

  const products = opportunity.products
    .map(({ _id, quantity }) => byId(_id) && { quantity, ...byId(_id) })
    .filter((product) => product);

  return (
    <InfoCard
      content={
        !isEmpty(products)
          ? products
            .map(
              (product) =>
                `${product.quantity && product.quantity > 1
                  ? `${product.quantity}x `
                  : ""
                }${product.name}`
            )
            .join(", ")
          : "No products assigned, go ahead and assign one now"
      }
      Icon={GoldOutlined}
      label="Products"
      onClick={() =>
        useLayoutStore.getState().open(SET_OPPORTUNITY_PRODUCTS_MODAL, {
          opportunity,
        })
      }
    />
  );
};

export const Details = (props) => {
  const { opportunity, organization } = props;

  const startedOn = get(opportunity, "startedOn");
  const targetWinDate = get(opportunity, "targetWinDate");
  const value = get(opportunity, "value");
  const weightedRevenue = get(opportunity, "weightedRevenue");

  const { modal: usersModal, open: openUsersModal } = useModal(SetUsers);
  const { modal: vendorsModal, open: openVendorsModal } = useModal(SetVendors);

  const account = useAccounts().byId(opportunity.accountId);
  const users = useUsers();
  const vendors = useVendors();

  const navigate = useNavigate();

  return (
    <>
      {usersModal}
      {vendorsModal}
      <ScrollableContent>
        <Row gutter={16} type="flex">
          <InfoCard
            content={`${isNil(value) ? "--" : formatDollars(value)}`}
            Icon={DollarOutlined}
            label="Value"
          />
          {!isNil(weightedRevenue) && (
            <InfoCard
              content={formatDollars(weightedRevenue)}
              Icon={DollarOutlined}
              label="Weighted Revenue"
            />
          )}
          <InfoCard
            content={startedOn ? long(startedOn) : "--"}
            Icon={PlayCircleOutlined}
            label="Start Date"
          />
          <InfoCard
            content={targetWinDate ? long(targetWinDate) : "--"}
            Icon={HourglassOutlined}
            label="Target Win Date"
          />
          {get(opportunity, "wonDate") && (
            <InfoCard
              content={long(get(opportunity, "wonDate"))}
              Icon={CheckCircleOutlined}
              label="Won Date"
            />
          )}
          {account && (
            <InfoCard
              content={account.name}
              Icon={RadarChartOutlined}
              label="Account"
              onClick={() => navigate(`/accounts/${account._id}`)}
            />
          )}
          <Product opportunity={opportunity} />
          <InfoCard
            content={renderList(
              opportunity.users
                .map((_id) => users.byId(_id))
                .filter((user) => user),
              "Assign sales reps"
            )}
            Icon={UserOutlined}
            label="Sales Reps"
            onClick={() => openUsersModal({ opportunity })}
          />
          {organization?.settings?.showPartners && (
            <InfoCard
              content={renderList(
                opportunity.vendors
                  .map((_id) => vendors.byId(_id))
                  .filter((vendor) => vendor),
                `Assign ${getPartnersLabel(organization)}`
              )}
              Icon={GlobalOutlined}
              label={getPartnersLabel(organization)}
              onClick={() => openVendorsModal({ opportunity, organization })}
            />
          )}
          {get(opportunity, "summary") && (
            <InfoCard
              content={get(opportunity, "summary")}
              Icon={InfoCircleOutlined}
              label="Summary"
              style={{
                content: { fontSize: "1em" },
              }}
            />
          )}
          <UserDefinedFields
            data={opportunity}
            organization={organization}
            type={viewTypes.OPPORTUNITY}
          />
        </Row>
      </ScrollableContent>
    </>
  );
};
