import { useQuery, useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { root as accountsRoot } from "./use-accounts.js";
import { root as activitiesRoot } from "./use-activities.js";
import { root as contactsRoot } from "./use-contacts.js";
import { root as filesRoot } from "./use-files.js";
import { root as followupsRoot } from "./use-followups.js";
import { root as productsRoot } from "./use-products.js";
import { root as vendorsRoot } from "./use-vendors.js";
import { useMutation } from "./use-mutation.js";

export const root = "/opportunity";

export const useOpportunities = buildUseEntities(root);

export const useCreateOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (patch) => {
        return fetcher.post(root)(patch);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
      },
    },
    overrides
  );
};

export const useCreateOpportunityBatch = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/import`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

// todo: test how many of these get re-fetched when root is invalidated
export const useOpportunitySteps = (_id) => {
  const { data } = useQuery({
    enabled: !!_id,
    queryKey: [root, _id, "steps"],
    queryFn: fetcher.get(`${root}/${_id}/steps`),
  });

  return data;
};

export const useUpdateOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useMergeOpportunities = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/merge`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [activitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
        await queryClient.invalidateQueries({ queryKey: [productsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

export const usePatchOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, ...data }) => {
        return fetcher.patch(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOpportunityContacts = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, contactIds }) => {
        return fetcher.put(`${root}/${id}/contacts`)({ contactIds });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOpportunityProducts = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/products`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOpportunityTags = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/tags`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOpportunityVendors = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, vendorIds }) => {
        return fetcher.put(`${root}/${id}/vendors`)({ vendorIds });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOpportunityUsers = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, userIds }) => {
        return fetcher.put(`${root}/${id}/users`)({ userIds });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUntagOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, tagId }) => {
        return fetcher.post(`${root}/${id}/untag`)({ tagId });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useTagOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, tagId }) => {
        return fetcher.post(`${root}/${id}/tag`)({ tagId });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useOverrideSalesProcess = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, opportunityStateChangeId, impacted }) => {
        return fetcher.post(`${root}/${id}/override-sales-process`)({
          opportunityStateChangeId,
          impacted,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchiveOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id }) => {
        return fetcher.post(`${root}/${id}/archive`)({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUnarchiveOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/unarchive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useDeleteOpportunity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id }) => {
        return fetcher.del(`${root}/${id}`)();
      },
      onSuccess: async (data, { variables }) => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [activitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
        await queryClient.invalidateQueries({
          queryKey: [filesRoot, "OPPORTUNITY", variables.id],
        });
      },
    },
    overrides
  );
};
