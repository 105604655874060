import { Form } from "antd";

import { getPartnersLabel } from "@evolved/labels";

import { useSetOpportunityVendors } from "data/use-opportunities";
import { VendorSelect } from "components/selects/vendor-select";
import { useOnChange } from "hooks/use-on-change";
import { Modal } from "components/modal";

export const SetVendors = ({ close, isOpen, opportunity, organization }) => {
  const setOpportunityVendors = useSetOpportunityVendors({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setOpportunityVendors.mutate({
      id: opportunity._id,
      vendorIds: values.vendorIds,
    });
  };

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        vendorIds: opportunity.vendors,
      });
    },
    [isOpen, form, opportunity]
  );

  return (
    <Modal
      confirmLoading={setOpportunityVendors.isLoading}
      okText={`Set ${getPartnersLabel(organization)}`}
      onCancel={close}
      onOk={form.submit}
      title={getPartnersLabel(organization)}
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <VendorSelect
          label={getPartnersLabel(organization)}
          mode="multiple"
          name="vendorIds"
        />
      </Form>
    </Modal>
  );
};
