import map from "lodash/map";
import { Select } from "antd";

import { pickSelectProps } from "./pick-props";
import { filterOption } from "./filter-option";

export const GroupedSearchSelect = ({ groups, ...props }) => {
  const selectGroups = map(groups, (group) => {
    return (
      <Select.OptGroup key={group.name} label={group.name}>
        {map(group.options, (option) => {
          const { label, value } = option;

          return (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          );
        })}
      </Select.OptGroup>
    );
  });

  return (
    <Select
      showSearch
      filterOption={filterOption}
      optionFilterProp="children"
      {...pickSelectProps(props)}
    >
      {selectGroups}
    </Select>
  );
};
