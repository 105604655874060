import create from "zustand";
import produce from "immer";

export const useCrumbs = create((set) => ({
  crumbs: [],
  push: (route) =>
    set(
      produce((state) => {
        state.crumbs.push(route);
        if (state.crumbs.length > 5) state.crumbs.shift();
      })
    ),
}));

export const getLastCrumb = () => {
  const crumbs = useCrumbs.getState().crumbs;

  return crumbs[crumbs.length - 2];
};
