import { useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";
import { root as followupRoot } from "./use-followups.js";
import { root as accountRoot } from "./use-accounts.js";
import { root as contactRoot } from "./use-contacts.js";
import { root as opportunityRoot } from "./use-opportunities.js";
import { root as organizationRoot } from "./use-organization.js";
import { root as meRoot } from "./use-me.js";

export const root = "/user";

export const useUsers = buildUseEntities(root);

export const useCreateUser = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(root)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [organizationRoot] });
      },
    },
    overrides
  );
};

export const useUpdateUser = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [meRoot] });
      },
    },
    overrides
  );
};

export const useArchiveUser = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (id) => {
        return fetcher.post(`${root}/${id}/archive`)({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useMergeUsers = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ childIds, parentId }) => {
        return fetcher.post(`${root}/merge`)({ childIds, parentId });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [followupRoot] });
        await queryClient.invalidateQueries({ queryKey: [accountRoot] });
        await queryClient.invalidateQueries({ queryKey: [contactRoot] });
        await queryClient.invalidateQueries({ queryKey: [opportunityRoot] });
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useReassignOwner = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (userId) => {
        return fetcher.post(`${root}/owner/reassign`)({ userId });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
