import { ArchiveSalesProcess } from "./archive";
import { BackButton } from "components/back-button";
import { DisplayArchived } from "components/display-archived";
import { EditButton } from "components/edit-button";
import { UnarchiveSalesProcess } from "./unarchive";
import { UpdateSalesProcess } from "../modals/update-sales-process";
import { useModal } from "hooks/use-modal";
import { withManagerRole } from "components/with-role";

const style = {
  container: { alignItems: "center", display: "flex" },
  button: { marginLeft: "auto" },
  header: { margin: "0px" },
};

const UpdateDetails = withManagerRole((props) => {
  const { open } = props;

  return (
    <EditButton
      onClick={open}
      style={{ marginLeft: "auto", marginRight: "8px" }}
    />
  );
});

export const Header = (props) => {
  const { salesProcess } = props;

  const { modal, open } = useModal(UpdateSalesProcess);

  return (
    <>
      {modal}
      <div style={style.container}>
        <BackButton default={"/sales-processes"} />
        <h2 style={style.header}>{salesProcess.name}</h2>
        <DisplayArchived className={"ml-4"} entity={salesProcess} />
        <UpdateDetails
          open={() => open({ salesProcess })}
          style={{ marginLeft: "auto", marginRight: "8px" }}
        />
        {!salesProcess.isArchived ? (
          <ArchiveSalesProcess salesProcess={salesProcess} />
        ) : (
          <UnarchiveSalesProcess salesProcess={salesProcess} />
        )}
      </div>
    </>
  );
};
