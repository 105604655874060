import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useState } from "react";

const INITIAL_INDEX = -1;

export const useGuideState = ({ hideSetupWizard, showSetupWizard }) => {
  const [activeType, setActiveType] = useState();
  const [index, setIndex] = useState(INITIAL_INDEX);

  const back = () => setIndex(index - 1);
  const forward = () => setIndex(index + 1);

  const go = (nextIndex) => setIndex(nextIndex);

  const start = (step) => {
    const { getStartIndex = () => 0, type } = step;

    hideSetupWizard();
    setActiveType(type);
    setIndex(getStartIndex());
  };

  const stop = () => {
    setActiveType(null);
    setIndex(INITIAL_INDEX);
  };

  const complete = () => {
    setActiveType(null);
    setIndex(INITIAL_INDEX);
    showSetupWizard();
  };

  return {
    back,
    complete,
    forward,
    go,
    start,
    stop,

    activeType,
    index,
  };
};

export const Step = (props) => {
  const { children, onBack, onClose, onNext, nextText = "Next" } = props;

  return (
    <div style={{ position: "relative" }}>
      {onClose && (
        <CloseOutlined
          onClick={onClose}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "2px",
            top: "2px",
          }}
        />
      )}
      <div
        style={{
          padding: onClose ? "24px 12px 0px 12px" : "12px 12px 0px 12px",
        }}
      >
        {children}
      </div>
      {onBack || onNext ? (
        <div style={{ display: "flex", margin: "16px 12px 8px 12px" }}>
          {onBack && (
            <Button
              ghost
              onClick={onBack}
              size="small"
              style={{ marginRight: "auto" }}
              type="primary"
            >
              Back
            </Button>
          )}
          {onNext && (
            <Button
              ghost
              onClick={onNext}
              size="small"
              style={{ marginLeft: "auto" }}
              type="primary"
            >
              {nextText}
            </Button>
          )}
        </div>
      ) : (
        <div style={{ padding: "8px" }} />
      )}
    </div>
  );
};
