import day from "dayjs";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Modal, Tag } from "antd";

import { styles } from "@evolved/constants";
import { detailed } from "../../utils/format-date";
import { Metadata } from "../lists/metadata";
import { Persons } from "./emails";

const { PRIMARY_TEXT } = styles;

const Email = (props) => {
  const { email, onClose, onLogActivity } = props;
  const { bcc, body, cc, created, from, subject, to, user } = email;

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "8px",
          }}
        >
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginBottom: "8px",
            }}
          >
            <Tag>
              <ClockCircleOutlined style={{ marginRight: "8px" }} />
              {detailed(day(created).unix())}
            </Tag>
            <Metadata
              Icon={UserOutlined}
              style={{ marginLeft: "8px", marginRight: "0px" }}
              value={user.alias}
            />
            <Button
              ghost
              onClick={() => {
                if (onLogActivity) {
                  onClose();
                  onLogActivity(email);
                }
              }}
              type="primary"
              style={{ marginLeft: "auto", marginRight: "32px" }}
            >
              Log Activity
            </Button>
          </div>
          <div>
            <strong>From</strong> {from}
          </div>
          <Persons label="To" persons={to} />
          <Persons label="cc" persons={cc} />
          <Persons label="bcc" persons={bcc} />
          <div>
            <strong>Subject</strong> {subject}
          </div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{ __html: body }}
        style={{ overflowY: "scroll", ...PRIMARY_TEXT }}
      />
    </>
  );
};

export const FullEmails = ({
  email,
  onClose,
  onLoadMore,
  onLogActivity,
  onNext,
  onPrevious,
}) => {
  return (
    <Modal
      styles={{
        body: {
          display: "flex",
          flexDirection: "column",
          height: "50vh",
          minHeight: "500px",
          padding: "24px",
        },
      }}
      footer={
        <div>
          <Button disabled={!onPrevious} onClick={onPrevious}>
            Previous
          </Button>
          <Button
            disabled={!onNext && !onLoadMore}
            onClick={onNext || onLoadMore}
          >
            {!onNext && onLoadMore ? "Load" : "Next"}
          </Button>
        </div>
      }
      onCancel={onClose}
      open={!!email}
    >
      {email && (
        <Email email={email} onClose={onClose} onLogActivity={onLogActivity} />
      )}
    </Modal>
  );
};
