export const formatDollars = (value, options = {}) => {
  if (!value) return options.noSign ? "0" : "$ 0";

  const formatted = value.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  if (options.noSign) return formatted;

  return `$ ${formatted}`;
};
