import sortBy from "lodash/sortBy";
import { App, Select } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useState } from "react";

import { useMe } from "../../../data/use-me";
import { useOnChange } from "../../../hooks/use-on-change";
import { useUsers, useReassignOwner } from "../../../data/use-users";
import { Modal } from "components/modal";

const onSubmit = ({ modal, reassignOwner, userId }) => {
  return () => {
    modal.confirm({
      title: "Are you sure?",
      icon: <ExclamationCircleOutlined />,
      content: "Only the new owner user can revert this change.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => reassignOwner.mutate(userId),
    });
  };
};

export const ReassignOwnershipModal = (props) => {
  const { close, isOpen } = props;

  const { modal } = App.useApp();

  const profile = useMe();
  const users = useUsers().all();

  const reassignOwner = useReassignOwner({ onSuccess: close });

  let filteredUsers = users.filter((user) => +user._id !== +profile._id);
  filteredUsers = filteredUsers.map(({ _id, alias }) => ({
    value: _id,
    label: alias,
  }));
  filteredUsers = sortBy(
    filteredUsers,
    ({ label }) => label && label.toUpperCase()
  );

  const [userId, setUserId] = useState(filteredUsers[0]?.value);

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      setUserId(filteredUsers[0]?.value);
    },
    [isOpen, filteredUsers]
  );

  return (
    <Modal
      confirmLoading={reassignOwner.isLoading}
      maskClosable={false}
      okText="Reassign"
      onCancel={close}
      onOk={onSubmit({
        modal,
        reassignOwner,
        userId,
      })}
      title={"Reassign Ownership"}
      open={isOpen}
    >
      <strong>New Owner</strong>
      <Select
        style={{ marginLeft: "16px", width: "50%" }}
        options={filteredUsers}
        onChange={setUserId}
        required
        value={userId}
      />
    </Modal>
  );
};
