import { useEffect } from "react";

import { SET_OPPORTUNITY_PRODUCTS_MODAL, useLayoutStore } from "stores/layout";

export const EditableProductsSelect = (props) => {
  const { isEditing, setIsEditing, children, opportunity } = props;

  useEffect(() => {
    if (isEditing) {
      useLayoutStore.getState().open(SET_OPPORTUNITY_PRODUCTS_MODAL, {
        opportunity,
      });
      setIsEditing(false);
    }
  }, [isEditing]);

  return children;
};
