import { useQueryClient } from "@tanstack/react-query";

import { CreateOpportunityModal } from "components/modals/create-opportunity-modal";
import { OpportunitiesList } from "components/lists/opportunities";
import { root as contactsRoot } from "data/use-contacts";
import { useModal } from "hooks/use-modal";
import { useOpportunities } from "data/use-opportunities";

export const Opportunities = (props) => {
  const { contact } = props;

  const queryClient = useQueryClient();
  const opportunities = useOpportunities();

  const { modal, open } = useModal(CreateOpportunityModal);

  return (
    <>
      {modal}
      <OpportunitiesList
        opportunities={opportunities
          .all()
          .filter(({ contacts }) => contacts.includes(contact._id))}
        openCreateModal={() =>
          open({
            onCreate: async () => {
              await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
            },
            ...(contact.accountId
              ? { preselectedAccountId: contact.accountId }
              : {}),
            preselectedContactId: contact._id,
          })
        }
      />
    </>
  );
};
