import { useScroll } from "react-use";
import { useCallback, useEffect, useRef } from "react";
import { debounce } from "radash";
import { useTableStore } from "../../../stores/table";

export const useScrollState = (tableRef, viewType) => {
  const initialized = useRef(false);

  useEffect(() => {
    if (!tableRef.current || initialized.current) return;

    tableRef.current.scrollTo({ top: useTableStore.getState()[viewType].top });
    initialized.current = true;
  });

  const { y } = useScroll(tableRef);

  const updateTop = useCallback(
    debounce({ delay: 500 }, (y) => {
      useTableStore.getState().setTop(viewType)(y);
    }),
    [viewType]
  );

  useEffect(() => {
    updateTop(y);
  }, [y]);
};
