export const style = {
  container: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
  },
  dropBox: {
    alignItems: "center",
    border: "black dashed 1px",
    display: "flex",
    justifyContent: "center",
    height: "100px",
    width: "100px",
  },
};
