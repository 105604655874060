import { select } from "radash";

import { useUsers } from "data/use-users";

export const UsersSet = (props) => {
  const { value } = props;

  const users = useUsers();

  return select(
    value,
    (id) => users.byId(id).alias,
    (id) => !!users.byId(id)
  ).join(", ");
};
