import { FilterOutlined } from "@ant-design/icons";
import { Button, InputNumber, message } from "antd";
import { useState } from "react";

import { useFocus } from "./use-focus";
import { validateFilter } from "./validate-filter";

export const RangeFilter = ({
  config,
  defaultValue = { max: null, min: null },
  hide,
  onFilter,
  type,
}) => {
  const ref = useFocus(config.dataIndex);

  const [value, setValue] = useState(defaultValue);

  const { max, min } = value;

  const onSubmit = () =>
    validateFilter(type, value)
      ? onFilter({ dataIndex: config.dataIndex, value })
      : message.warning("Filter must have at least a min or a max.");

  const onEnter = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <InputNumber
          onChange={(min) =>
            setValue({
              max,
              min,
            })
          }
          onKeyDown={onEnter}
          placeholder="Min"
          ref={ref}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}
          value={min}
        />
        <InputNumber
          onChange={(max) =>
            setValue({
              max,
              min,
            })
          }
          onKeyDown={onEnter}
          placeholder="Max"
          size="small"
          style={{
            flexBasis: 0,
            flexGrow: 1,
          }}
          value={max}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Button
          icon={<FilterOutlined />}
          onClick={onSubmit}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}
          type="primary"
        >
          Filter
        </Button>
        <Button
          onClick={hide}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
