import sortBy from "lodash/sortBy";
import { Badge, Button, Card, Empty, List } from "antd";
import { useState } from "react";

import { colors, styles } from "@evolved/constants";

import { useMe } from "data/use-me";

const { CLICKABLE_STYLE } = styles;
const { PRIMARY } = colors;

import { Followup } from "./followup";

const style = {
  badge: { backgroundColor: PRIMARY },
  container: { alignItems: "center", display: "flex", width: "100%" },
  title: { margin: "0px 16px" },
  toggle: { ...CLICKABLE_STYLE, marginLeft: "auto", marginRight: "15px" },
};

const renderTitle = (props) => {
  const {
    displayPersonalFollowups,
    followups,
    showCreateFollowup,
    togglePersonalFollowups,
  } = props;

  return (
    <div style={style.container}>
      <Badge count={followups.length} showZero style={style.badge} />
      <h3 style={style.title}>Followups</h3>
      <span onClick={() => togglePersonalFollowups()} style={style.toggle}>
        {displayPersonalFollowups ? "Showing Assigned" : "Showing All"}
      </span>
      <Button
        ghost
        onClick={showCreateFollowup}
        style={style.button}
        type="primary"
      >
        Follow Up
      </Button>
    </div>
  );
};

export const FollowupsList = (props) => {
  const { followups, hideAccount, hideContact, hideOpportunity, hideVendor } =
    props;

  const profile = useMe();
  const [displayPersonalFollowups, setDisplayPersonalFollowups] =
    useState(false);

  const filtered = displayPersonalFollowups
    ? followups.filter(({ assigneeId }) => assigneeId === profile._id)
    : followups;

  return (
    <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
      <List
        dataSource={sortBy(filtered, "dueOn")}
        header={renderTitle({
          ...props,
          displayPersonalFollowups,
          togglePersonalFollowups: () =>
            setDisplayPersonalFollowups(!displayPersonalFollowups),
        })}
        itemLayout={"vertical"}
        locale={{ emptyText: <Empty description={"What's next?"} /> }}
        pagination={{ pageSize: 3 }}
        renderItem={(followup) => {
          return (
            <List.Item key={followup._id} style={{ padding: "16px 8px" }}>
              <Followup
                followup={followup}
                hideAccount={hideAccount}
                hideContact={hideContact}
                hideOpportunity={hideOpportunity}
                hideVendor={hideVendor}
              />
            </List.Item>
          );
        }}
      />
    </Card>
  );
};
