import find from "lodash/find";

import { userRoles } from "@evolved/constants";

const { ROLES } = userRoles;

export const getDefaultActiveUsers = ({ organization, users }) => {
  const owner = find(users, { role: ROLES.OWNER });

  const activeUsers = organization?.subscriptionState?.activeUsers
    ? organization.subscriptionState.activeUsers.filter((_id) =>
        find(users, (user) => Number(user._id) === _id)
      )
    : users.map((user) => Number(user._id));

  return activeUsers.includes(Number(owner._id))
    ? activeUsers
    : [...activeUsers, Number(owner._id)];
};
