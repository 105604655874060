import { Button } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useUnarchiveContact } from "data/use-contacts";

export const UnarchiveContact = (props) => {
  const { contact } = props;

  const unarchiveContact = useUnarchiveContact();

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      disabled={unarchiveContact.isLoading}
      onClick={() => unarchiveContact.mutate({ id: contact._id })}
    >
      Unarchive
    </Button>
  );
};
