import { forwardRef } from "react";

export const Box = forwardRef((props, ref) => {
  const { children, isComplete, isCurrent, onClick } = props;

  let style = {
    border: "2px dashed rgba(220, 220, 220, 0.25)",
  };

  if (isComplete) {
    style = {
      border: "2px solid rgba(143, 75, 230, 0.25)",
    };
  }

  if (isCurrent) {
    style = {
      border: "2px dashed rgba(143, 75, 230, 1)",
    };
  }

  return (
    <div
      className={"sm-larger-on-hover transition"}
      onClick={onClick}
      ref={ref}
      style={{
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.12)",
        cursor: "pointer",
        height: "325px",
        width: "325px",
        marginRight: "24px",
        borderRadius: "10px",
        flexShrink: 0,
        padding: "24px",
        ...style,
      }}
    >
      {children}
    </div>
  );
});
