import isEmpty from "lodash/isEmpty";
import { useEffect } from "react";

import { filterData, sortData } from "@evolved/views";
import { userRoles, viewTypes } from "@evolved/constants";

import { ActivitiesList } from "components/lists/activities";
import { DownloadCSV } from "../../components/view/download-csv/download-csv";
import { Filters } from "components/view/filters";
import { SaveView } from "../../components/view/save-view";
import { SearchInput } from "../../components/view/search";
import { ViewActions } from "../../components/view/actions";
import { ViewSelect } from "../../components/view/view-select";
import {
  filterConfigKeys,
  reduceSearchResults,
  useSearch,
} from "../use-search";
import { useAccounts } from "../../data/use-accounts";
import { useActivityTypes } from "../../data/use-activity-types";
import { useAllActivities } from "../../data/use-activities";
import { useContacts } from "../../data/use-contacts";
import { useDefaultView } from "../use-default-view";
import { useFieldConfigs } from "../../components/view/use-field-configs";
import { useMe } from "../../data/use-me";
import { useOpportunities } from "../../data/use-opportunities";
import { useOpportunityStates } from "../../data/use-opportunity-states";
import { useSearchStore } from "../../stores/search";
import { useUsers } from "../../data/use-users";
import { useVendors } from "../../data/use-vendors";
import { useViewStore } from "../../stores/view";

const { ROLES } = userRoles;

export const ActivityFeedRoute = () => {
  const accounts = useAccounts();
  const activities = useAllActivities();
  const contacts = useContacts();
  const opportunities = useOpportunities();
  const opportunityStates = useOpportunityStates();
  const profile = useMe();
  const types = useActivityTypes();
  const users = useUsers();
  const vendors = useVendors();

  useDefaultView(viewTypes.ACTIVITY);

  const fieldConfigs = useFieldConfigs(viewTypes.ACTIVITY);

  const search = useSearch(
    {
      primary: {
        items: activities.all(),
        keys: filterConfigKeys(fieldConfigs),
      },
      secondary: [
        {
          path: "opportunityStateChangeId",
          items: opportunityStates.all(),
          keys: ["label"],
        },
        { path: "accountId", items: accounts.all(), keys: ["name"] },
        { path: "opportunityId", items: opportunities.all(), keys: ["alias"] },
        { path: "typeId", items: types.all(), keys: ["label"] },
        { path: "contacts", items: contacts.all(), keys: ["alias"] },
        { path: "users", items: users.all(), keys: ["alias"] },
        { path: "vendors", items: vendors.all(), keys: ["name"] },
      ],
    },
    viewTypes.ACTIVITY
  );

  useEffect(() => {
    if (useSearchStore.getState()[viewTypes.ACTIVITY].query) {
      search(useSearchStore.getState()[viewTypes.ACTIVITY].query);
    }
  }, [
    accounts,
    activities,
    contacts,
    opportunities,
    opportunityStates,
    types,
    users,
    vendors,
  ]);

  const searchResult = useSearchStore(
    (state) => state[viewTypes.ACTIVITY].result
  );

  const filters = useViewStore((state) => state[viewTypes.ACTIVITY].filters);
  const sortOrder = useViewStore(
    (state) => state[viewTypes.ACTIVITY].sortOrder
  );

  let data;

  if (searchResult) {
    data = reduceSearchResults(searchResult)(activities);
  } else if (!isEmpty(filters)) {
    data = filterData({ data: activities.all(), fieldConfigs, filters });
  } else {
    data = activities.all();
  }

  if (!isEmpty(sortOrder)) {
    data = sortData({
      data,
      cache: {
        accounts: { entities: accounts.entities, key: "name" },
        activityTypes: { entities: types.entities, key: "label" },
      },
      fieldConfigs,
      sortOrder,
    });
  }

  return (
    <div
      className="lg:w-[1080px]"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        height: "100%",
      }}
    >
      <div className="mb-4">
        <div className="mb-4 flex items-center">
          <ViewSelect viewType={viewTypes.ACTIVITY} />
          <ViewActions viewType={viewTypes.ACTIVITY} />
          <SaveView viewType={viewTypes.ACTIVITY} />
          <div className="ml-auto flex">
            <DownloadCSV data={data} viewType={viewTypes.ACTIVITY} />
          </div>
        </div>
        <div className="flex items-center">
          <div className="mr-4">
            <SearchInput search={search} viewType={viewTypes.ACTIVITY} />
          </div>
          {searchResult ? (
            <a onClick={useSearchStore.getState().reset(viewTypes.ACTIVITY)}>
              Clear global search
            </a>
          ) : (
            <>
              <Filters
                blacklist={
                  (profile?.role ?? ROLES.VIEWER) > ROLES.MANAGER
                    ? ["users"]
                    : []
                }
                permanent={["performedOn"]}
                viewType={viewTypes.ACTIVITY}
              />
            </>
          )}
        </div>
      </div>

      <ActivitiesList activities={data} pageSize={10} />
    </div>
  );
};
