import { withPermission } from "components/with-permission";
import { states } from "./states";
import { UnknownState } from "./unknown-state";
import { useUsers } from "data/use-users";

const getState = ({ organization }) => {
  const { subscriptionState } = organization;

  return states[subscriptionState.status] || UnknownState;
};

export const ApplicationAccess = withPermission("organization:billing")(
  (props) => {
    const { organization } = props;

    const State = getState({ organization });

    const users = useUsers();

    return (
      <div
        style={{
          background: "#fff",
          borderRadius: "0.75em",
          maxWidth: "500px",
          padding: "12px",
        }}
      >
        <div style={{ marginBottom: "8px" }}>
          <strong>Application Access</strong>
        </div>
        <State users={users.all({ includeArchived: false })} {...props} />
        <div style={{ marginTop: "16px" }}>
          Have questions about your subscription? Contact us at{" "}
          <strong>
            <a
              href="mailto:hello@evolvedmetrics.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              hello@evolvedmetrics.com
            </a>
          </strong>
        </div>
      </div>
    );
  }
);
