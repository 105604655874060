import sortBy from "lodash/sortBy";

import { GroupedSearchSelect } from "components/selects/grouped-search-select";
import { useOrganization } from "data/use-organization";
import { useSalesProcesses } from "data/use-sales-processes";

const involved = {
  _id: crypto.randomUUID(),
  group: "Best Practices",
  name: "Detailed",
  steps: [
    { funnelTrigger: 5, question: "Did you attempt to make customer contact?" },
    {
      funnelTrigger: 10,
      question: "Did you have an initial customer conversation?",
    },
    {
      funnelTrigger: 15,
      question: "Were pre-qualification questions answered?",
    },
    {
      funnelTrigger: 20,
      question: "Did you identify the key Decision Maker(s)?",
    },
    {
      funnelTrigger: 30,
      question:
        "Did you have a meeting where customer requirements were discussed?",
    },
    {
      funnelTrigger: 40,
      question:
        "Did you discuss the product(s) with the customer in light of their requirements?",
    },
    { funnelTrigger: 50, question: "Did you discuss pricing?" },
    { funnelTrigger: 60, question: "Was a proposal submitted?" },
    {
      funnelTrigger: 80,
      question:
        "Were follow up questions generated from the proposal and answered?",
    },
    { funnelTrigger: 100, question: "Was the proposal accepted?" },
  ],
};

const balanced = {
  _id: crypto.randomUUID(),
  group: "Best Practices",
  name: "Balanced",
  steps: [
    { funnelTrigger: 10, question: "Prospecting" },
    { funnelTrigger: 20, question: "Research" },
    { funnelTrigger: 35, question: "Contact/Approach Prospect" },
    { funnelTrigger: 50, question: "Present Offer" },
    { funnelTrigger: 70, question: "Overcome Objections" },
    { funnelTrigger: 90, question: "Close the Sale" },
    { funnelTrigger: 100, question: "Follow-up & Ask for Referrals" },
  ],
};

const simple = {
  _id: crypto.randomUUID(),
  group: "Best Practices",
  name: "Simple",
  steps: [
    { funnelTrigger: 25, question: "Intro" },
    { funnelTrigger: 50, question: "Meeting" },
    { funnelTrigger: 75, question: "Proposed" },
    { funnelTrigger: 100, question: "Signed Deal" },
  ],
};

const bestPractices = [simple, balanced, involved];

export const TemplateSelect = ({ form }) => {
  const organization = useOrganization();

  let salesProcesses = useSalesProcesses().all();
  salesProcesses = [...bestPractices, ...salesProcesses];

  let groups = salesProcesses.reduce((accumulator, salesProcess) => {
    const name = salesProcess.group || organization.name;

    if (!accumulator[name]) {
      accumulator[name] = { name, options: [] };
    }

    accumulator[name].options.push({
      label: salesProcess.name,
      value: salesProcess._id,
    });

    return accumulator;
  }, {});
  groups = Object.values(groups);
  groups = groups.map((group) => ({
    ...group,
    options: sortBy(group.options, "label"),
  }));

  return (
    <div style={{ marginBottom: "24px" }}>
      <GroupedSearchSelect
        onChange={(value) => {
          const salesProcess = salesProcesses.find(({ _id }) => _id === value);

          form.setFieldValue(
            "steps",
            salesProcess.steps.map(({ funnelTrigger, question }) => ({
              funnelTrigger,
              question,
            }))
          );
        }}
        groups={groups}
        placeholder="You can start with a template..."
        style={{ width: "100%" }}
      />
    </div>
  );
};
