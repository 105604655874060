import { Tag } from "antd";
import { select } from "radash";

import { useProducts } from "../../../../../data/use-products";
import { Link } from "../../../../link";

export const ProductsSet = (props) => {
  const { value } = props;

  const products = useProducts();

  const selected = select(
    value,
    ({ _id, quantity }) => ({
      ...products.byId(_id),
      quantity,
    }),
    ({ _id }) => !!products.byId(_id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((product) => {
    return (
      <Link link={`/products/${product._id}`} key={product._id}>
        <Tag style={product.isArchived ? { opacity: 0.5 } : {}}>
          {product.quantity && product.quantity > 1
            ? `${product.quantity}x `
            : ""}
          {product.name}
        </Tag>
      </Link>
    );
  });
};
