export const ROLES = {
  OWNER: 0,
  MANAGER: 1,
  SALES: 2,
  VIEWER: 3,
};

export const SUPER = -1;

export const display = (role) => ["Owner", "Manager", "Sales", "Viewer"][role];
