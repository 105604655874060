import { select } from "radash";

import { useActivityTypes } from "data/use-activity-types";

export const ActivityTypesSet = (props) => {
  const { value } = props;

  const activityTypes = useActivityTypes();

  return select(
    value,
    (id) => activityTypes.byId(id).label,
    (id) => !!activityTypes.byId(id)
  ).join(", ");
};
