import { Modal as AntdModal } from "antd";

import { useWindowSize } from "react-use";

import { ScrollableContent } from "./scrollable-content";

export const Modal = (props) => {
  const { children, ...rest } = props;
  const { height } = useWindowSize();

  return (
    <AntdModal
      destroyOnClose
      style={{ top: 20 }}
      styles={{ body: { paddingTop: "16px" } }}
      {...rest}
    >
      <ScrollableContent height={`${height - 188}px`}>
        {children}
      </ScrollableContent>
    </AntdModal>
  );
};
