import { MoreOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Empty, List, Popover } from "antd";

import { reduceFieldConfigs } from "@evolved/views";
import { colors } from "@evolved/constants";

import { Contact } from "./contact";
import { DisplayPreviewModal } from "./display-preview-modal";
import { getDisplaySettings } from "./get-display-settings";
import { useMe } from "data/use-me";
import { useModal } from "hooks/use-modal";
import { useOrganization } from "data/use-organization";

const { PRIMARY } = colors;

const Header = ({ contacts, showDisplaySettings, onUpdate }) => {
  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={contacts.length}
        showZero
        style={{ backgroundColor: PRIMARY, marginRight: "16px" }}
      />
      <h3 style={{ margin: "0px" }}>Contacts</h3>
      {onUpdate && (
        <Button
          ghost
          onClick={onUpdate}
          style={{ marginLeft: "auto" }}
          type="primary"
        >
          Add / Remove
        </Button>
      )}
      <Popover
        content={
          <Button size="small" onClick={showDisplaySettings}>
            Display Settings
          </Button>
        }
        trigger="hover"
        placement="bottom"
      >
        <MoreOutlined style={{ fontSize: "18px", marginLeft: "8px" }} />
      </Popover>
    </div>
  );
};

export const ContactsList = (props) => {
  const organization = useOrganization();
  const profile = useMe();

  const { contacts = [], onUpdate } = props;

  const { modal, open } = useModal(DisplayPreviewModal);

  const fieldMap = reduceFieldConfigs({
    organization,
    viewType: "CONTACT",
  });
  const displaySettings = getDisplaySettings(profile, fieldMap);

  return (
    <>
      {modal}
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
        <List
          dataSource={contacts}
          header={
            <Header
              contacts={contacts}
              showDisplaySettings={open}
              onUpdate={onUpdate}
            />
          }
          itemLayout={"vertical"}
          locale={{ emptyText: <Empty description={"No contacts yet..."} /> }}
          pagination={{ pageSize: 5 }}
          renderItem={(contact) => (
            <Contact
              key={contact._id}
              contact={contact}
              displaySettings={displaySettings}
              fieldMap={fieldMap}
            />
          )}
        />
      </Card>
    </>
  );
};
