const item = {
  margin: "16px 24px 0px 0px",
};

export const styles = {
  buttonCard: {
    container: {
      alignItems: "flex-start",
      borderColor: "rgba(0, 0, 0, 0.12)",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.12)",
      display: "flex",
      justifyContent: "flex-start",
      marginRight: "16px",
      width: "238px",
      ...item,
    },
    svg: {
      container: {
        padding: "0px",
      },
      body: {
        width: 100,
        height: 80,
      },
    },
  },
};
