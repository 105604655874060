import { Alert } from "antd";
import {
  createBrowserRouter,
  RouterProvider,
  useRouteError,
} from "react-router-dom";

import { AccountRoute } from "./account";
import { AccountsRoute } from "./accounts";
import { ActivityFeedRoute } from "./activity-feed";
import { ContactRoute } from "./contact";
import { ContactsRoute } from "./contacts";
import { DashboardRoute } from "./dashboard";
import { FullScreenCenter } from "components/full-screen-center.jsx";
import { InternalToolsRoute } from "./internal-tools";
import { LogOutButton } from "components/log-out-button";
import { OpportunitiesRoute } from "./opportunities";
import { OpportunityRoute } from "./opportunity";
import { OrganizationRoute } from "./organization";
import { ProductRoute } from "./product";
import { ProductsRoute } from "./products";
import { ProfileRoute } from "./profile";
import { RedirectToDashboard } from "./redirect-to-dashboard";
import { RootRoute } from "./root-route";
import { SalesProcessRoute } from "./sales-process";
import { SalesProcessesRoute } from "./sales-processes";
import { SupportMessage } from "components/support-message";
import { VendorRoute } from "./vendor";
import { VendorsRoute } from "./vendors";

const ErrorBoundary = () => {
  const error = useRouteError();

  return (
    <>
      <div className="absolute right-8 top-8">
        <LogOutButton />
      </div>
      <FullScreenCenter>
        <div className="w-[512px]">
          <Alert
            message="Something unexpected has happened."
            description={
              <SupportMessage error={error} retry={() => location.reload()} />
            }
            type="error"
          />
        </div>
      </FullScreenCenter>
    </>
  );
};

const router = () =>
  createBrowserRouter([
    {
      path: "/",
      element: <RootRoute />,
      children: [
        {
          path: "accounts",
          element: <AccountsRoute />,
        },
        {
          path: "accounts/:id",
          element: <AccountRoute />,
        },
        {
          path: "activity-feed",
          element: <ActivityFeedRoute />,
        },
        {
          path: "contacts",
          element: <ContactsRoute />,
        },
        {
          path: "contacts/:id",
          element: <ContactRoute />,
        },
        {
          path: "dashboard",
          element: <DashboardRoute />,
        },
        {
          path: "internal-tools",
          element: <InternalToolsRoute />,
        },
        {
          path: "opportunities",
          element: <OpportunitiesRoute />,
        },
        {
          path: "opportunities/:id",
          element: <OpportunityRoute />,
        },
        {
          path: "organization",
          element: <OrganizationRoute />,
        },
        {
          path: "profile",
          element: <ProfileRoute />,
        },
        {
          path: "partners",
          element: <VendorsRoute />,
        },
        {
          path: "partners/:id",
          element: <VendorRoute />,
        },
        {
          path: "products",
          element: <ProductsRoute />,
        },
        {
          path: "products/:id",
          element: <ProductRoute />,
        },
        {
          path: "sales-processes",
          element: <SalesProcessesRoute />,
        },
        {
          path: "sales-processes/:id",
          element: <SalesProcessRoute />,
        },
      ],
      errorElement: <ErrorBoundary />,
    },
    {
      path: "/*",
      element: <RedirectToDashboard />,
      errorElement: <ErrorBoundary />,
    },
  ]);

export const PrivateRoutes = () => {
  return <RouterProvider router={router()} />;
};
