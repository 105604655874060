import { memo } from "react";
import { isNumber } from "radash";

import { styles } from "./styles";
import { renderView } from "./cell/view/render-view";

export const Aggregations = memo((props) => {
  const { columns } = props;

  let fixedLeft = 48;

  return (
    <>
      <tr style={{ position: "sticky", bottom: 0, zIndex: 2 }}>
        <td
          style={{
            ...styles.cell,
            bottom: 0,
            height: 39,
            left: 0,
            maxWidth: 48,
            minWidth: 48,
            position: "sticky",
            zIndex: 2,
          }}
        >
          {null}
        </td>
        {columns.fixed.map((column) => {
          const left = fixedLeft;
          fixedLeft = fixedLeft + column.width;

          return (
            <td
              key={column.dataIndex}
              style={{
                ...styles.cell,
                bottom: 0,
                left,
                maxWidth: column.width,
                minWidth: column.width,
                position: "sticky",
                zIndex: 2,
              }}
            >
              {isNumber(column.aggregation) ? column.aggregation : null}
            </td>
          );
        })}
        {columns.scrollable.map((column) => {
          return (
            <td
              key={column.dataIndex}
              style={{
                ...styles.cell,
                maxWidth: column.width,
                minWidth: column.width,
                position: "relative",
                zIndex: 2,
              }}
            >
              {isNumber(column.aggregation)
                ? renderView({
                    column,
                    row: { [column.dataIndex]: column.aggregation },
                  })
                : null}
            </td>
          );
        })}
        <td
          style={{
            ...styles.cell,
            position: "relative",
          }}
        />
      </tr>
    </>
  );
});
