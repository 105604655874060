import produce from "immer";
import clone from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { UploadOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useState } from "react";

import { viewTypes } from "@evolved/constants";
import { getPartnersLabel } from "@evolved/labels";

import getErrorMessage from "utils/get-error-message";

import { ErrorMessage } from "components/error-message";
import { GetTemplate } from "./get-template";
import { handleFileUpload } from "./handle-file-upload";
import { normalizeText } from "./handle-file-upload/normalize-text";
import { style } from "./style";
import { useAccounts, useCreateAccountBatch } from "data/use-accounts";
import { useCreateContactBatch } from "data/use-contacts";
import { useCreateOpportunityBatch } from "data/use-opportunities";
import { useMe } from "data/use-me";
import { useOpportunityStates } from "data/use-opportunity-states";
import { useOrganization } from "data/use-organization";
import { useProducts, useCreateProductBatch } from "data/use-products";
import { useSalesProcesses } from "data/use-sales-processes";
import { useVendors, useCreateVendorBatch } from "data/use-vendors";
import { useSetUserDefinedFields } from "data/use-organization";
import { useViewStore } from "../../../stores/view";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;
const { Dragger } = Upload;

const getTitle = (organization) => (type) =>
  ({
    [ACCOUNT]: "Import Accounts",
    [CONTACT]: "Import Contacts",
    [OPPORTUNITY]: "Import Opportunities",
    [VENDOR]: `Import ${getPartnersLabel(organization)}`,
  }[type]);

const useFileListState = (initialState) => {
  const [value, set] = useState(initialState);

  const setFileList = (name, status) =>
    set([{ name, status: status || "done", uid: "-1" }]);
  const clearFileList = () => set([]);

  return [value, setFileList, clearFileList];
};

export const ImportDataModal = (props) => {
  const { isOpen, type } = props;

  const accounts = useAccounts().all();
  const organization = useOrganization();
  const opportunityStates = useOpportunityStates().all();
  const products = useProducts().all();
  const profile = useMe();
  const salesProcesses = useSalesProcesses().all();
  const vendors = useVendors().all();

  const close = () => {
    props.close();

    clearFileList();
    setPayload();
    setError();
  };

  const setUserDefinedFields = useSetUserDefinedFields({
    onSuccess: (data, { mutation }) => mutation.onFinal.skip(),
  });

  const createAccountBatch = useCreateAccountBatch();
  const createContactBatch = useCreateContactBatch();
  const createOpportunityBatch = useCreateOpportunityBatch();
  const createProductBatch = useCreateProductBatch();
  const createVendorBatch = useCreateVendorBatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [fileList, setFileList, clearFileList] = useFileListState([]);
  const [payload, setPayload] = useState();

  const IMPORTS = {
    [ACCOUNT]: createAccountBatch.mutateAsync,
    [CONTACT]: createContactBatch.mutateAsync,
    [OPPORTUNITY]: createOpportunityBatch.mutateAsync,
    [VENDOR]: createVendorBatch.mutateAsync,
  };

  const onOk = async () => {
    const temporaryIdIndexMap = {
      accounts: "accountId",
      products: "productId",
      salesProcesses: "salesProcessId",
      vendors: "vendorId",
    };

    setLoading(true);

    try {
      if (!isEmpty(payload.udfs)) {
        const udfPayload = payload.udfs.reduce((acc, udf) => {
          const existingIndex = acc.findIndex(({ id }) => id === udf.id);

          if (existingIndex > -1) {
            acc[existingIndex] = udf;
          } else {
            acc.push(udf);
          }

          return acc;
        }, clone(organization.userDefinedFields || []));

        await setUserDefinedFields.mutateAsync(udfPayload);

        payload.udfs.forEach((u) =>
          useViewStore.getState().addField(type)(`userDefinedFields.${u.id}`)
        );
      }

      let entitiesPayload = payload.entities;

      if (!isEmpty(payload.relationships)) {
        for (const relationship of payload.relationships) {
          const { type, entities } = relationship;

          const importType = {
            accounts: createAccountBatch.mutateAsync,
            products: createProductBatch.mutateAsync,
            vendors: createVendorBatch.mutateAsync,
          }[type];

          const result = await importType(map(entities, ({ value }) => value));

          entitiesPayload = produce(payload.entities, (draft) => {
            for (const r of result) {
              const relationship = entities[normalizeText(r.name)];

              if (relationship) {
                for (const i of relationship.indexes) {
                  draft[i][temporaryIdIndexMap[type]] = r._id;
                }
              }
            }
          });
        }
      }

      await IMPORTS[type](entitiesPayload);
      setLoading(false);

      close();
    } catch (err) {
      clearFileList();
      setError(err);
      setLoading(false);
    }
  };

  const userDefinedFields = get(organization, "userDefinedFields", []).filter(
    (field) => field.type === type
  );

  return (
    <Modal
      confirmLoading={loading}
      destroyOnClose
      maskClosable={false}
      okButtonProps={{ disabled: isEmpty(fileList) || error }}
      okText={"Finally, Import!"}
      onCancel={close}
      onOk={onOk}
      title={getTitle(organization)(type)}
      open={isOpen}
      width={700}
    >
      {isOpen && (
        <div style={style.container}>
          {!loading ? (
            <>
              <GetTemplate
                profile={profile}
                userDefinedFields={userDefinedFields}
                setError={setError}
                type={type}
              />
              <Dragger
                accept=".csv"
                customRequest={handleFileUpload({
                  accounts,
                  clearFileList,
                  organization,
                  opportunityStates,
                  products,
                  salesProcesses,
                  vendors,
                  setError,
                  setFileList,
                  setPayload,
                  type,
                })}
                fileList={fileList}
                onRemove={clearFileList}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text" style={{ margin: "4px 24px" }}>
                  Drag the completed .csv file here
                </p>
              </Dragger>
            </>
          ) : (
            <div
              style={{
                alignItems: "center",
                flexDirection: "column",
                display: "flex",
                height: "352px",
                justifyContent: "center",
              }}
            >
              <h2>We are working hard to load your data!</h2>
              <h3>
                {payload?.entities?.length} {type.toLowerCase()}s total...
              </h3>
            </div>
          )}
          {error && (
            <ErrorMessage
              error={`${getErrorMessage(
                error
              )}. Take any corrective actions and re-upload the file to try again.`}
              style={{ marginTop: "24px" }}
              support={false}
            />
          )}
        </div>
      )}
    </Modal>
  );
};
