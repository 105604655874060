import day from "dayjs";

import { Checkbox, Form, Input } from "antd";

import {
  CREATE_VENDOR_FOLLOWUP_MODAL,
  PERFORM_VENDOR_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { usePerformVendorActivity } from "../../../data/use-activities";
import { useMe } from "../../../data/use-me";
import { useVendors } from "../../../data/use-vendors";
import { useOnChange } from "../../../hooks/use-on-change";
import { ActivityTypeSelect } from "../../selects/activity-type-select";
import { ContactSelect } from "../../selects/contact-select";
import { UserSelect } from "../../selects/user-select";
import { onSubmit } from "./on-submit";
import { roundPerformedOn } from "./round-performed-on";
import { DatePicker } from "../../date-picker";
import { Modal } from "components/modal";

export const PerformVendorActivityModal = () => {
  const layoutState = useLayoutStore(
    (state) => state[PERFORM_VENDOR_ACTIVITY_MODAL]
  );
  const close = (afterClose) =>
    useLayoutStore.getState().close(PERFORM_VENDOR_ACTIVITY_MODAL, afterClose);

  const { closedFollowup, vendorId, defaults } = layoutState.payload;

  const vendor = useVendors().byId(vendorId);
  const profile = useMe();

  const performActivity = usePerformVendorActivity();

  const [form] = Form.useForm();

  useOnChange(
    layoutState.isOpen,
    () => {
      if (!layoutState.isOpen) {
        return;
      }

      form.setFieldsValue({
        performedOn: roundPerformedOn(
          closedFollowup && day.unix(closedFollowup.dueOn).isBefore(day())
            ? day.unix(closedFollowup.dueOn)
            : day()
        ),
        userIds: [profile._id],
        typeId: null,
        contactIds: [],
        summary: "",
        shouldFollowUp: true,
        ...defaults,
      });
    },
    [layoutState.isOpen, form, closedFollowup, defaults, profile]
  );

  return (
    <Modal
      confirmLoading={performActivity.isLoading}
      okText="Log"
      onCancel={close}
      onOk={form.submit}
      title={
        <div style={{ display: "flex" }}>
          <div>
            <div>Log Activity</div>
            {closedFollowup && (
              <div style={{ fontSize: "12px" }}>
                <span style={{ fontSize: "10px" }}>Follow up on: </span>
                {closedFollowup.task}
              </div>
            )}
          </div>
        </div>
      }
      open={layoutState.isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          vendor,
          close,
          closedFollowup,
          performActivity: performActivity.mutate,
          showCreateFollowup: ({ openingActivityId }) =>
            useLayoutStore.getState().open(CREATE_VENDOR_FOLLOWUP_MODAL, {
              vendorId,
              openingActivityId,
            }),
        })}
      >
        <Form.Item
          label="Performed On"
          name="performedOn"
          rules={[{ required: true }]}
        >
          <DatePicker />
        </Form.Item>
        <UserSelect
          label="Performed By"
          mode="multiple"
          name="userIds"
          rules={[{ required: true }]}
          required
        />
        <ActivityTypeSelect
          label="Type"
          name="typeId"
          rules={[{ required: true }]}
        />
        <ContactSelect
          filter={(contact) => vendor.contacts.includes(contact._id)}
          label="Contacts"
          mode="multiple"
          name="contactIds"
          notFoundContent={<div>No associated contacts</div>}
        />
        <Form.Item label="Summary" name="summary" rules={[{ required: true }]}>
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          name="shouldFollowUp"
          wrapperCol={{ offset: 6, span: 18 }}
          valuePropName="checked"
        >
          <Checkbox size="small">Follow up on this activity</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
