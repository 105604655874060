import { Input } from "antd";

import { useState } from "react";

import { useRequestChangeEmail } from "../../data/use-me";
import { Modal } from "components/modal";

export const RequestChangeEmailModal = (props) => {
  const { close, isOpen, refetch } = props;

  const requestChangeEmail = useRequestChangeEmail({
    onSuccess: () => {
      close();
      setEmail("");
    },
  });

  const [email, setEmail] = useState("");

  return (
    <Modal
      afterClose={() => setEmail("")}
      confirmLoading={requestChangeEmail.isLoading}
      maskClosable={false}
      okText="Change"
      onCancel={close}
      onOk={() => requestChangeEmail.mutate({ email })}
      title={"Change Email"}
      open={isOpen}
    >
      <p>
        This request will send an Email to your new address. Click the received
        link to confirm the change.
      </p>
      <strong>New Email*</strong>
      <Input
        style={{ marginLeft: "16px", width: "50%" }}
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
    </Modal>
  );
};
