import { PreviewImage } from "./preview-image";
import { PreviewPdf } from "./preview-pdf";

export const Preview = ({ file }) => {
  if (file.fileType.startsWith("image/")) {
    return <PreviewImage file={file} />;
  }

  if (file.fileType === "application/pdf") {
    return <PreviewPdf file={file} />;
  }

  return null;
};
