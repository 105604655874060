import isArray from "lodash/isArray.js";
import isEmpty from "lodash/isEmpty.js";
import isNil from "lodash/isNil.js";
import isObject from "lodash/isPlainObject.js";

export const filter = (value, filter) => {
  if (isEmpty(filter)) return true;

  if (isNil(value)) {
    return false;
  }

  value = isArray(value) ? value : [value];

  // todo: having to check if this is an object is a leaky abstraction
  // do better
  return value.some((_id) => filter.includes(isObject(_id) ? _id._id : _id));
};

export const validate = (filter) => isArray(filter) && !isEmpty(filter);
