import { select } from "radash";

import { useTags } from "data/use-tags";

export const TagsSet = (props) => {
  const { value } = props;

  const tags = useTags();

  return select(
    value,
    (id) => tags.byId(id).label,
    (id) => !!tags.byId(id)
  ).join(", ");
};
