import { ArchiveOpportunity } from "./archive";
import { BackButton } from "components/back-button";
import { DeleteOpportunity } from "./delete";
import { DisplayArchived } from "components/display-archived";
import { EditButton } from "components/edit-button";
import { OpportunityState } from "components/opportunity-state";
import { UnarchiveOpportunity } from "./unarchive";

const style = {
  container: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "16px",
  },
  button: { marginLeft: "auto", marginRight: "8px" },
  header: { margin: "0px 16px 0px 0px" },
};

export const Header = (props) => {
  const { openUpdateDetails, opportunity = {} } = props;

  return (
    <div className="space-y-2" style={style.container}>
      <div className="mr-auto flex flex-wrap items-center">
        <BackButton default={"/opportunities"} />
        <h2 style={style.header}>{opportunity.alias || "--"}</h2>
        <OpportunityState
          opportunity={opportunity}
          style={{ marginLeft: "0px", marginRight: "8px" }}
        />
        <DisplayArchived entity={opportunity} />
      </div>
      <div className="flex flex-wrap items-center">
        <EditButton
          onClick={() => openUpdateDetails({ opportunity })}
          style={{ marginRight: "8px" }}
        />
        {!opportunity.isArchived ? (
          <ArchiveOpportunity opportunity={opportunity} />
        ) : (
          <>
            <div style={{ marginRight: "8px" }}>
              <UnarchiveOpportunity opportunity={opportunity} />
            </div>
            <DeleteOpportunity opportunity={opportunity} />
          </>
        )}
      </div>
    </div>
  );
};
