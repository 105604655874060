import { NOTIFICATIONS_SIDER, useLayoutStore } from "../../stores/layout";

import { Followups } from "./followups";

import { createBreakpoint } from "react-use";

const useBreakpoint = createBreakpoint({ sm: 0, md: 860 });

const NotificationsSider = () => {
  const state = useLayoutStore((state) => state[NOTIFICATIONS_SIDER]);

  const breakpoint = useBreakpoint();

  return (
    <div
      className="transition-width ease-in-out"
      style={{
        ...(breakpoint === "sm"
          ? {
              position: "fixed",
              right: 0,
            }
          : {}),
        transitionDuration: "300ms",
        backgroundColor: "rgb(255, 255, 255)",
        width: state.isOpen ? "275px" : "0px",
        gridColumnStart: 3,
        gridRowStart: 1,
        gridRowEnd: -1,
      }}
    >
      <Followups
        isOpen={state.isOpen}
        onClose={() => useLayoutStore.getState().toggle(NOTIFICATIONS_SIDER)}
      />
    </div>
  );
};

export default NotificationsSider;
