import { RightOutlined } from "@ant-design/icons";
import { Button, Statistic } from "antd";
import { useNavigate } from "react-router-dom";

import first from "lodash/first";
import last from "lodash/last";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";

import { ActivityCountPie } from "components/activity-count-pie";
import { InitialActivity } from "./initial-activity";
import { LatestActivity } from "./latest-activity";
import { NextFollowup } from "./next-followup";
import { ScheduleNextFollowup } from "./schedule-next-followup";

import { useActivityTypes } from "../../../data/use-activity-types";
import { useVendorActivities } from "../../../data/use-activities";
import { useVendorFollowups } from "../../../data/use-followups";
import {
  CREATE_VENDOR_FOLLOWUP_MODAL,
  PERFORM_VENDOR_ACTIVITY_MODAL,
  useLayoutStore,
} from "../../../stores/layout";

export const VendorSummary = (props) => {
  const { _id } = props;

  const navigate = useNavigate();

  const activities = useVendorActivities({ _id });
  const activityTypes = useActivityTypes();
  const followups = useVendorFollowups({ _id });
  // todo: add counts, similar to accounts summary

  const activityTypeGroups = reduce(
    activities,
    (accumulator, activity) => {
      if (!activity?.typeId) {
        return accumulator;
      }

      if (!accumulator[activity.typeId]) {
        const type = activityTypes.byId(activity.typeId);

        if (!type) {
          return accumulator;
        }

        accumulator[activity.typeId] = {
          _id: type._id,
          name: type.label,
          count: 0,
        };
      }

      accumulator[activity.typeId].count =
        accumulator[activity.typeId].count + 1;

      return accumulator;
    },
    {}
  );

  const activity = last(sortBy(activities, "performedOn"));
  const followup = first(sortBy(followups, "dueOn"));

  const showPerformActivity = () => {
    useLayoutStore.getState().open(PERFORM_VENDOR_ACTIVITY_MODAL, {
      vendorId: _id,
    });
  };

  const showCreateFollowup = () => {
    useLayoutStore.getState().open(CREATE_VENDOR_FOLLOWUP_MODAL, {
      vendorId: _id,
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 16px",
          marginRight: "24px",
        }}
      >
        {activities.length === 0 && (
          <Button
            onClick={() => navigate(`/partners/${_id}`)}
            style={{ marginBottom: "16px", width: "100%", height: "36px" }}
          >
            Details
            <RightOutlined />
          </Button>
        )}
        <div style={{ marginBottom: "24px" }}>
          {!followup && (
            <ScheduleNextFollowup showCreateFollowup={showCreateFollowup} />
          )}
          {followup && <NextFollowup followup={followup} />}
          <Button
            onClick={showCreateFollowup}
            ghost
            type="primary"
            style={{ width: "100%", height: "36px", marginTop: "16px" }}
          >
            Schedule Followup
          </Button>
        </div>
        <div>
          {activity && <LatestActivity activity={activity} />}
          {!activity && (
            <InitialActivity showPerformActivity={showPerformActivity} />
          )}
          <Button
            ghost
            onClick={showPerformActivity}
            style={{ width: "100%", height: "36px", marginTop: "16px" }}
            type="primary"
          >
            Log Activity
          </Button>
        </div>
      </div>
      {activities.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "8px 18px 18px 18px",
          }}
        >
          <Button
            onClick={() => navigate(`/partners/${_id}`)}
            style={{ marginBottom: "16px", width: "100%", height: "36px" }}
          >
            Details
            <RightOutlined />
          </Button>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Statistic
              title="Logged Activities"
              value={activities.length}
              style={{ marginBottom: "16px" }}
            />
          </div>
          <div style={{ padding: "18px" }}>
            <ActivityCountPie counts={Object.values(activityTypeGroups)} />
          </div>
        </div>
      )}
    </div>
  );
};
