import { userRoles } from "@evolved/constants";

import { useMe } from "../data/use-me";

export const useHasRole = (role) => () => {
  const me = useMe();

  return me.role <= role;
};

export const useHasSalesRole = useHasRole(userRoles.ROLES.SALES);
export const useHasManagerRole = useHasRole(userRoles.ROLES.MANAGER);
export const useHasOwnerRole = useHasRole(userRoles.ROLES.OWNER);
