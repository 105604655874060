import filter from "lodash/filter";
import map from "lodash/map";

import { viewTypes } from "@evolved/constants";

import { CalculatedValue } from "../calculated-value";
import { FieldList } from "./field-list";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

const renderType = (organization) => (type) => {
  return (
    <div key={type} style={{ marginBottom: "24px" }}>
      <FieldList
        fields={filter(organization.userDefinedFields, { type })}
        organization={organization}
        type={type}
      />
    </div>
  );
};

export const UserDefinedFields = (props) => {
  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <CalculatedValue />
      </div>
      {map(
        [OPPORTUNITY, ACCOUNT, CONTACT, VENDOR],
        renderType(props.organization)
      )}
    </>
  );
};
