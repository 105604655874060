import { useRef, useState } from "react";

import { useEffectOnce } from "./use-effect-once";

export const buildTimer = (minimumTime) => {
  let startTimestamp = null;

  const start = () => (startTimestamp = performance.now());
  const stop = () => (startTimestamp = null);

  const wait = async () => {
    if (!startTimestamp) {
      return;
    }

    const difference = performance.now() - startTimestamp;

    if (difference < minimumTime) {
      await new Promise((resolve) =>
        setTimeout(resolve, minimumTime - difference)
      );
    }
  };

  return {
    start,
    stop,
    wait,
  };
};

export const useLoading = (minimumTime = 500) => {
  const [state, setState] = useState(false);

  const timer = useRef();

  useEffectOnce(() => {
    timer.current = buildTimer(minimumTime);
  });

  const start = () => {
    timer.current.start();
    setState(true);
  };

  const stop = () => {
    timer.current.stop();
    setState(false);
  };

  const wait = async () => {
    await timer.current.wait();
  };

  return { state, start, stop, wait };
};
