import sortBy from "lodash/sortBy";
import { diff, unique } from "radash";

import { Form, Select } from "antd";

import { useModal } from "../../../hooks/use-modal";
import { filterOption } from "../../selects/filter-option";
import { ContactDetailsModal } from "../contact-details-modal";
import { filterAccountContacts } from "./filter-account-contacts";

export const ContactsSelect = (props) => {
  const { contacts, form } = props;

  const accountId = Form.useWatch("accountId", form);
  const contactIds = Form.useWatch("contactIds", form);
  const accountContacts =
    filterAccountContacts({ accountId, contacts }).map(({ _id }) => _id) || [];

  const { modal, open } = useModal(ContactDetailsModal);

  return (
    <>
      {modal}
      <Form.Item
        label="Contacts"
        name="contactIds"
        extra={
          <div style={{ marginTop: "8px", display: "flex", flexWrap: "wrap" }}>
            <div>
              <a
                onClick={() =>
                  open({
                    disableRelationshipSelect: true,
                    onCreate: (contact) => {
                      const current = form.getFieldValue("contactIds");

                      form.setFieldValue("contactIds", [
                        ...current,
                        contact._id,
                      ]);
                    },
                  })
                }
              >
                Create
              </a>{" "}
              contact
            </div>
            {accountId && diff(accountContacts, contactIds).length > 0 && (
              <>
                <div style={{ margin: "0px 4px" }}>|</div>
                <div>
                  <a
                    onClick={() => {
                      const current = form.getFieldValue("contactIds");

                      form.setFieldValue(
                        "contactIds",
                        unique([...current, ...accountContacts])
                      );
                    }}
                  >
                    Select all
                  </a>{" "}
                  from account
                </div>
              </>
            )}
            {contactIds?.length > 0 && (
              <>
                <div style={{ margin: "0px 4px" }}>|</div>
                <div>
                  <a
                    onClick={() => {
                      form.setFieldValue("contactIds", []);
                    }}
                  >
                    Clear
                  </a>
                </div>
              </>
            )}
          </div>
        }
      >
        <Select
          filterOption={filterOption}
          mode="multiple"
          notFoundContent="Search returned no contacts"
          optionFilterProp="children"
          options={sortBy(
            contacts.map(({ _id, alias }) => ({
              label: alias || "Unnamed Contact",
              value: _id,
            })),
            ({ label }) => label && label.toUpperCase()
          )}
          placeholder="Select contacts"
          showSearch
        />
      </Form.Item>
    </>
  );
};
