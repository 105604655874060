import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { alphabetical, sort, title } from "radash";
import { Fade } from "react-awesome-reveal";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import { useLocalStorage } from "react-use";
import { useNavigate } from "react-router-dom";

import { styles } from "@evolved/constants";

import { formatDollars } from "../../utils/format-dollars";

import { Void } from "components/image-placeholders";
import { useOpportunityStates } from "../../data/use-opportunity-states";
import {
  useProductOpportunityCountsBy,
  useUserOpportunityCountsBy,
} from "../../data/use-reports";
import { RangeSelect } from "./range-select";
import { WidgetHeader } from "./widget-header";
import { withSalesRole } from "../../components/with-role";
import { WidgetContainer } from "./widget-container";
import { useOrganization } from "../../data/use-organization";
import { convertRange } from "./convert-range";

const { CLICKABLE_STYLE } = styles;

const AccountsLink = ({ navigate }) => (
  <span onClick={() => navigate("/accounts")} style={CLICKABLE_STYLE}>
    accounts
  </span>
);

const OpportunitiesLink = ({ navigate }) => (
  <span onClick={() => navigate("/opportunities")} style={CLICKABLE_STYLE}>
    opportunities
  </span>
);

const ProductsLink = ({ navigate }) => (
  <span onClick={() => navigate("/products")} style={CLICKABLE_STYLE}>
    products
  </span>
);

export const useProductOpportunitiesState = (organization) => {
  const [isUser, setIsUser] = useLocalStorage(
    `${organization._id}.DASHBOARD.OPPORTUNITY_WIN_RATE.IS_USER`,
    true
  );

  const [sortOrder, setSortOrder] = useLocalStorage(
    `${organization._id}.DASHBOARD.OPPORTUNITY_WIN_RATE.SORT_ORDER`,
    "desc",
  );

  const sortTypes = ["closedRate", "wonValue", "name"];

  const [sortType, setSortType] = useLocalStorage(
    `${organization._id}.DASHBOARD.OPPORTUNITY_WIN_RATE.SORT_TYPE`,
    sortTypes[0],
  );

  const toggleSortType = () => {
    const currentIndex = sortTypes.indexOf(sortType);

    setSortType(sortTypes[(currentIndex + 1) % sortTypes.length]);
  };

  return {
    isUser,
    setIsUser,
    setSortOrder,
    sortOrder,
    sortType,
    toggleSortType,
  }
};

const Component = (props) => {
  const {
    isUser,
    setIsUser,
    setSortOrder,
    sortOrder,
    sortType,
    toggleSortType,
  } = props;

  const { widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets } =
    props.dateRange;

  const opportunityStates = useOpportunityStates().all();

  const productOpportunityCounts = useProductOpportunityCountsBy("stateId", convertRange(widgetFilter));
  const userOpportunityCounts = useUserOpportunityCountsBy("stateId", convertRange(widgetFilter));

  const navigate = useNavigate();
  const organization = useOrganization();

  const counts = isUser ? userOpportunityCounts : productOpportunityCounts;

  const wonState = opportunityStates.find(
    ({ systemKey }) => systemKey === "WON"
  );


  const reducedCounts = (counts ?? []).map(({ _id, name, counts }) => {
    const {
      count: wonCount,
      value: wonValue,
    } = (
        find(
          counts,
          ({ stateId }) => Number(stateId) === Number(wonState._id)
        ) || { count: 0, value: 0 }
      );

    const { count, value } = counts.reduce((acc, { stateId, count, value }) => {
      if (organization.settings?.winRateOpportunityStateIds) {
        const opportunityState = opportunityStates.find(({ _id }) => _id === stateId);

        if (["WON", "LOST"].includes(opportunityState?.systemKey)) {
          return {
            count: acc.count + count,
            value: acc.value + value,
          };
        }

        if (organization.settings?.winRateOpportunityStateIds.includes(stateId)) {
          return {
            count: acc.count + count,
            value: acc.value + value,
          };
        }

        return acc;
      }

      return {
        count: acc.count + count,
        value: acc.value + value,
      };
    }, { value: 0, count: 0 });

    const closedRate =
      count === 0 ? 0 : Math.round((wonCount / count) * 100);

    return {
      _id,
      closedRate,
      count,
      name,
      value,
      wonCount,
      wonValue,
    };
  }, []);

  let sortedCounts;

  if (["name"].includes(sortType)) {
    sortedCounts = alphabetical(reducedCounts, (count) => {
      return count[sortType];
    }, sortOrder);
  } else {
    sortedCounts = sort(reducedCounts, (count) => {
      return count[sortType];
    }, sortOrder === "desc");
  }

  return (
    <div>
      <div
        style={{ alignItems: "center", display: "flex", marginBottom: "24px" }}
      >
        <div class="mr-auto">
          <WidgetHeader label="Opportunity Win Rate" />
          <div class="flex space-x-1">
            <div>Sort</div>
            {
              <div onClick={toggleSortType} style={styles.CLICKABLE_STYLE}>{title(sortType)}</div>
            }
            {sortOrder === "desc" ?
              <div onClick={() => setSortOrder("asc")} style={styles.CLICKABLE_STYLE}><DownOutlined /></div> :
              <div onClick={() => setSortOrder("desc")} style={styles.CLICKABLE_STYLE}><UpOutlined /></div>
            }
          </div>
        </div>
        <RangeSelect
          tenses={["past", "all-time"]}
          {...{ widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets }}
        />
        <h4
          style={{
            ...CLICKABLE_STYLE,
            fontSize: "18px",
            marginBottom: "0px",
            marginLeft: "24px",
          }}
          onClick={() => setIsUser(!isUser)}
        >
          {isUser ? "By Sales Rep" : "By Product"}
        </h4>
      </div>

      {isEmpty(counts) ? (
        <Fade>
          <Void
            primary={"No opportunities yet."}
            secondary={
              <>
                Go ahead and create some <ProductsLink navigate={navigate} />,{" "}
                <AccountsLink navigate={navigate} /> and{" "}
                <OpportunitiesLink navigate={navigate} />
              </>
            }
          />
        </Fade>
      ) : (
        <Fade>
          <div
            style={{ alignItems: "center", display: "flex", flexWrap: "wrap" }}
          >
            {map(sortedCounts, ({
              _id,
              closedRate,
              count,
              name,
              value,
              wonCount,
              wonValue,
            }, i) => {
              return (
                <div
                  key={_id}
                  style={{
                    ...(i >= 2 ? { marginTop: "16px" } : {}),
                    width: "50%",
                  }}
                >
                  <div
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "black",
                    }}
                  >
                    {name}
                  </div>
                  <div >
                    <div style={{ display: "flex", alignItems: "baseline" }}>
                      <div
                        style={{
                          fontSize: "32px",
                          fontWeight: "600",
                          color: "#7F5196",
                          lineHeight: "40px",
                        }}
                      >
                        {closedRate}
                        <span
                          style={{
                            fontSize: "18px",
                            lineHeight: "32px",
                          }}
                        >
                          %
                        </span>
                      </div>
                      <div
                        className="text-sm font-light"
                        style={{
                          fontWeight: "600",
                          lineHeight: 1,
                          marginLeft: "12px"
                        }}
                      >
                        {wonCount} / {count}
                      </div>
                    </div>
                    <div
                      className="text-lg mt-1 text-grey-500"
                      style={{
                        lineHeight: 1,
                      }}
                    >
                      {formatDollars(wonValue)} / {formatDollars(value)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Fade>
      )}
    </div>
  );
};

export const ProductOpportunities = withSalesRole((props) => {
  return (
    <WidgetContainer>
      <Component {...props} />
    </WidgetContainer>
  );
});
