import Parse from "papaparse";

import { fields as generalFields } from "../fields";
import { relationships } from "../relationships";
import { normalizeText } from "./normalize-text";
import { parseFile } from "./parse-file.js";

export const parseGeneralFields = (type) =>
  generalFields[type].map((field) => ({
    ...field,
    fieldType: "GENERAL",
  }));

export const parseOrganizationUserDefinedFields = (organization, type) =>
  (organization.userDefinedFields || [])
    .filter((field) => field.type === type)
    .map(({ id, name, options, dataType }) => ({
      dataIndex: `userDefinedFields.${id}`,
      fieldType: "UDF",
      title: name,
      type: dataType,
      ...(options ? { options: options.filter((o) => !o.isArchived) } : {}),
    }));

export const parseRelationshipsFields = (type) =>
  relationships[type].map((relationship) => ({
    ...relationship,
    fieldType: "RELATIONSHIP",
  }));

export const parseFields = (organization, type) => [
  ...parseGeneralFields(type),
  ...parseRelationshipsFields(type),
  ...parseOrganizationUserDefinedFields(organization, type),
];

export const buildCache = ({
  accounts,
  opportunityStates,
  products,
  salesProcesses,
  vendors,
}) => ({
  accounts: accounts.reduce((acc, account) => {
    acc[normalizeText(account.name)] = account;

    return acc;
  }, {}),
  opportunityStates: opportunityStates.reduce((acc, opportunityState) => {
    acc[normalizeText(opportunityState.label)] = opportunityState;

    return acc;
  }, {}),
  products: products.reduce((acc, product) => {
    acc[normalizeText(product.name)] = product;

    return acc;
  }, {}),
  salesProcesses: salesProcesses.reduce((acc, salesProcess) => {
    acc[normalizeText(salesProcess.name)] = salesProcess;

    return acc;
  }, {}),
  vendors: vendors.reduce((acc, vendor) => {
    acc[normalizeText(vendor.name)] = vendor;

    return acc;
  }, {}),
});

export const handleFileUpload = ({
  accounts,
  clearFileList,
  opportunityStates,
  organization,
  products,
  salesProcesses,
  setError,
  setFileList,
  setPayload,
  type,
  vendors,
}) => {
  const fields = parseFields(organization, type);

  const cache = buildCache({
    accounts,
    opportunityStates,
    products,
    salesProcesses,
    vendors,
  });

  return async ({ file, onSuccess }) => {
    setFileList(file.name, "uploading");
    setError();

    try {
      let parsed = await file.text();
      parsed = Parse.parse(parsed, { header: true, skipEmptyLines: true });

      const headers = parsed.meta.fields;
      const rows = parsed.data;

      setPayload(parseFile(type, { rows, headers }, fields, cache));
    } catch (error) {
      setError(error);
      clearFileList();

      return onSuccess("ok");
    }

    setFileList(file.name);
    onSuccess("ok");
  };
};
