import { getPartnersLabel } from "@evolved/labels";

import { useModal } from "../../hooks/use-modal";
import { CreateVendorModal } from "../modals/create-vendor-modal";
import { VendorSelect as VendorSelectBase } from "../selects/vendor-select";
import { useOrganization } from "../../data/use-organization";

export const CreateVendorSelect = (props) => {
  const { disableVendorSelect, form, multi } = props;

  const { modal, open } = useModal(CreateVendorModal);
  const organization = useOrganization();

  if (disableVendorSelect) {
    return null;
  }

  return (
    <>
      {modal}
      <VendorSelectBase
        {...(multi ? { mode: "multiple" } : {})}
        allowClear
        extra={
          <div style={{ marginTop: "4px" }}>
            <a
              onClick={() =>
                open({
                  onCreate: (vendor) => {
                    if (multi) {
                      const current = props.form.getFieldValue("vendorIds");

                      props.form.setFieldValue("vendorIds", [
                        ...current,
                        vendor._id,
                      ]);
                    } else {
                      form.setFieldValue("vendorId", vendor._id);
                    }
                  },
                })
              }
            >
              Create
            </a>{" "}
            a new partner
          </div>
        }
        label={getPartnersLabel(organization, multi ? "plural" : "singular")}
        name={multi ? "vendorIds" : "vendorId"}
      />
    </>
  );
};
