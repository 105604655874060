import { SaveOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { CreateViewModal } from "./modals/create-view";
import { useModal } from "hooks/use-modal";
import { useUpdateView } from "../../data/use-views";
import { useViewStore } from "../../stores/view";

export const SaveView = (props) => {
  const selectedId = useViewStore((state) => state[props.viewType].selectedId);
  const saveView = useUpdateView();

  const { open: openCreateViewModal, modal: createViewModal } =
    useModal(CreateViewModal);

  return (
    <>
      {createViewModal}
      <Button
        icon={<SaveOutlined />}
        loading={saveView.isLoading}
        onClick={() => {
          if (!selectedId) {
            openCreateViewModal({ viewType: props.viewType });
            return;
          }

          const state = useViewStore.getState()[props.viewType];

          saveView.mutate({
            id: state.selectedId,
            fields: state.fields,
            filters: state.filters,
            includeArchived: state.includeArchived,
            sortOrder: state.sortOrder,
            pageSize: state.pageSize,
            widths: state.widths,
          });
        }}
        type="primary"
        shape="circle"
        style={{ marginRight: "8px" }}
        ghost
      />
    </>
  );
};
