import day from "dayjs";
import { Button, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useState } from "react";

const TWO_WEEKS = "TWO_WEEKS";
const ONE_MONTH = "ONE_MONTH";
const TWO_MONTHS = "TWO_MONTHS";
const SIX_MONTHS = "SIX_MONTHS";
const CALENDAR_YEAR = "CALENDAR_YEAR";

const ON_SELECT = {
  [TWO_WEEKS]: () => [day().subtract(2, "weeks"), day()],
  [ONE_MONTH]: () => [day().subtract(1, "month"), day()],
  [TWO_MONTHS]: () => [day().subtract(2, "month"), day()],
  [SIX_MONTHS]: () => [day().subtract(6, "month"), day()],
  [CALENDAR_YEAR]: () => [
    day().dayOfYear(1).hour(0).minute(0).second(0).millisecond(0),
    day(),
  ],
};

const selectRangeValue = (setFilter, setVisible) => (rangeValue) => () => {
  setVisible(false);
  setFilter(ON_SELECT[rangeValue]);
};

export const RangeFilter = (props) => {
  const { setRange } = props;

  const [visible, setVisible] = useState(false);
  const onSelect = selectRangeValue(setRange, setVisible);

  const content = (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Button
          size={"small"}
          style={{ marginRight: "8px", marginBottom: "4px" }}
          onClick={onSelect(TWO_WEEKS)}
        >
          Last Two Weeks
        </Button>
        <Button
          size={"small"}
          style={{ marginRight: "8px", marginBottom: "4px" }}
          onClick={onSelect(ONE_MONTH)}
        >
          Last Month
        </Button>
        <Button
          size={"small"}
          style={{ marginRight: "8px", marginBottom: "4px" }}
          onClick={onSelect(TWO_MONTHS)}
        >
          Last Two Months
        </Button>
        <Button
          size={"small"}
          style={{ marginRight: "8px", marginBottom: "4px" }}
          onClick={onSelect(SIX_MONTHS)}
        >
          Last Six Months
        </Button>
        <Button
          size={"small"}
          style={{ marginRight: "8px" }}
          onClick={onSelect(CALENDAR_YEAR)}
        >
          Calendar Year
        </Button>
      </div>
    </>
  );

  return (
    <Popover
      content={content}
      onOpenChange={setVisible}
      placement={"bottom"}
      trigger={"click"}
      open={visible}
    >
      <MoreOutlined style={{ fontSize: "18px" }} />
    </Popover>
  );
};
