import { useEffect, useState } from "react";

import { fetchSignedUrl } from "./fetch-signed-url";

export const useSignedUrl = (file) => {
  const [signedUrl, setSignedUrl] = useState();

  const fetchUrl = async () => {
    const url = await fetchSignedUrl(file);

    setSignedUrl(url);
  };

  useEffect(() => {
    if (file) {
      fetchUrl();
    }
  }, [file]);

  return signedUrl;
};
