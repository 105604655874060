import filter from "lodash/filter";
import find from "lodash/find";

const DEFAULT_VALUES = {
  SELECT: null,
  TEXT: "",
};

const filterUserDefinedFields = (entityFields = {}, organizationFields) => {
  return organizationFields.reduce((acc, field) => {
    const entityField = entityFields[field.id];

    if (entityField) {
      if (
        field.dataType === "SELECT" &&
        !find(field.options, (o) => o.id === entityField && !o.isArchived)
      ) {
        return acc;
      }

      acc[`${field.id}`] = entityField;
    } else {
      acc[`${field.id}`] = DEFAULT_VALUES[field.dataType];
    }

    return acc;
  }, {});
};

export const mapInitialUserDefinedFieldsState = (
  entity = {},
  { organization = {}, type }
) => {
  return {
    userDefinedFields: filterUserDefinedFields(
      entity.userDefinedFields,
      filter(organization.userDefinedFields, { type })
    ),
  };
};
