export const LEAD = 0;
export const PROSPECT = 1;
export const CLIENT = 2;

export const STATES = {
  LEAD,
  PROSPECT,
  CLIENT,
};

export const display = (state) => ["Lead", "Prospect", "Client"][state];
export const isValid = (state) => [LEAD, PROSPECT, CLIENT].includes(state);
