import filter from "lodash/filter";

import { FilterOutlined } from "@ant-design/icons";
import { Button, message, Select } from "antd";
import { useState } from "react";

import { filterOption } from "../../selects/filter-option";
import { useFocus } from "./use-focus";
import { useVisible } from "./use-visible";
import { validateFilter } from "./validate-filter";

export const SelectFilter = ({
  config,
  defaultValue = [],
  hide,
  onFilter,
  type,
}) => {
  const { options = [] } = config;

  const ref = useFocus(config.dataIndex);
  const [visible, setVisible] = useVisible(config.dataIndex);

  const [value, setValue] = useState(defaultValue);

  const filteredOptions = options.filter((o) => !o.isArchived);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <Select
          filterOption={filterOption}
          mode="multiple"
          notFoundContent={"No match"}
          optionFilterProp="children"
          showSearch
          options={filteredOptions.map((option) => ({
            value: option.id,
            label: option.label,
          }))}
          value={filter(value, (id) =>
            filteredOptions.find((o) => o.id === id)
          )}
          onChange={setValue}
          onDropdownVisibleChange={setVisible}
          open={visible}
          ref={ref}
          size="small"
          style={{ width: "100%" }}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Button
          icon={<FilterOutlined />}
          onClick={() => {
            return validateFilter(type, value)
              ? onFilter({ dataIndex: config.dataIndex, value })
              : message.warning("Must have at least one selection to filter");
          }}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}
          type="primary"
        >
          Filter
        </Button>
        <Button
          onClick={hide}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
