import { Button, Checkbox, Divider, Row } from "antd";
import { useState } from "react";
import get from "lodash/get";

import { fetcher } from "../../data/fetcher";
import { useNotification } from "contexts/notification/use-notification";
import { InfoCard } from "../../components/info-card";
import {
  usePatchIsNylasEmailPublic,
  useNylasLogout,
} from "../../data/use-integrations";

export const NylasConfig = ({ profile }) => {
  const notification = useNotification();
  const { email, isConfigured, settings } =
    get(profile, "integrations.nylas") || {};

  const [isLoading, setIsLoading] = useState();

  const authenticate = async () => {
    setIsLoading(true);

    try {
      const { url } = await fetcher.get("/integrations/nylas/oauth")();

      window.location.href = url;
    } catch (err) {
      notification.support(err);
    }

    setIsLoading(false);
  };

  const patchIsEmailPublic = usePatchIsNylasEmailPublic();
  const logout = useNylasLogout();

  return (
    <Row gutter={16} id="nylas-config-card" type="flex">
      <InfoCard
        content={
          <>
            <div style={{ margin: "8px 0px" }}>
              Integrating your email allows CRM read-only access to link your
              email interactions to your contacts, accounts, and opportunities.
              This makes analyzing and acting on your opportunities easier.
            </div>
            <div style={{ margin: "16px 0px" }}>
              Please note, we use a third-party integrator called{" "}
              <a href="https://www.nylas.com/">Nylas</a> that allows easy and
              secure email integration. You will be prompted to give access to{" "}
              <a href="https://www.nylas.com/">Nylas</a>. You can be confident
              that the granting of this access has been rigorously tested and
              the system is secure.
            </div>

            <Divider />
            {isConfigured ? (
              <>
                <div style={{ margin: "16px 0px" }}>
                  Your email integration has been successfully configured with{" "}
                  <strong>{email}</strong>
                </div>
                <div style={{ marginBottom: "24px" }}>
                  <Checkbox
                    checked={!!settings?.isEmailPublic}
                    disabled={patchIsEmailPublic.isLoading}
                    onChange={(e) =>
                      patchIsEmailPublic.mutate({
                        isEmailPublic: e.target.checked,
                      })
                    }
                  >
                    Expose my emails to my team.
                  </Checkbox>
                </div>
                <Button
                  id="nylas-disconnect-button"
                  loading={logout.isLoading}
                  onClick={() => logout.mutate()}
                >
                  Disconnect
                </Button>
              </>
            ) : (
              <>
                <div style={{ margin: "16px 0px" }}>
                  Sign in to integrate your email with CRM.
                </div>
                <Button
                  id="nylas-config-connect-button"
                  loading={isLoading}
                  onClick={authenticate}
                  type="primary"
                >
                  Connect
                </Button>
              </>
            )}
          </>
        }
        label={
          <div style={{ marginBottom: "16px" }}>
            <span style={{ fontSize: "16px" }}>Email Integration</span>
          </div>
        }
      />
    </Row>
  );
};
