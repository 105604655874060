import { MoreOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Popover } from "antd";
import { useState } from "react";

import { useModal } from "hooks/use-modal";
import { useMe, useUpdateDefaultView } from "../../data/use-me";
import { useArchive, useTogglePublic, useViews } from "../../data/use-views";
import { useViewStore } from "../../stores/view";
import { swallow } from "../../utils/swallow";
import { CreateViewModal } from "./modals/create-view";
import { UpdateViewNameModal } from "./modals/update-view-name";
import { useResetView } from "../../stores/view";

export const ViewActions = ({ viewType }) => {
  const [showActions, setShowActions] = useState(false);

  const archiveView = useArchive();
  const togglePublic = useTogglePublic();
  const updateDefaultView = useUpdateDefaultView();

  const selectedId = useViewStore((state) => state[viewType].selectedId);
  const includeArchived = useViewStore(
    (state) => state[viewType].includeArchived
  );

  const views = useViews();
  const selectedView = views.find((view) => view._id === selectedId);

  const profile = useMe();

  const { open: openCreateViewModal, modal: createViewModal } =
    useModal(CreateViewModal);
  const { open: openUpdateViewNameModal, modal: updateViewNameModal } =
    useModal(UpdateViewNameModal);

  const resetView = useResetView(viewType);

  return (
    <>
      {createViewModal}
      {updateViewNameModal}
      <Popover
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              size="small"
              onClick={() => {
                openCreateViewModal({ viewType });
                setShowActions(false);
              }}
              style={{ marginBottom: "8px" }}
            >
              Save As
            </Button>
            <Button
              size="small"
              style={{ marginBottom: "8px" }}
              onClick={() => {
                resetView();
                setShowActions(false);
              }}
            >
              Reset View Filters
            </Button>
            {selectedView && (
              <Button
                loading={updateDefaultView.isLoading}
                size="small"
                style={{ marginBottom: "8px" }}
                onClick={() =>
                  swallow(
                    (async () => {
                      await updateDefaultView.mutateAsync({
                        _id: selectedId,
                        viewType,
                      });

                      setShowActions(false);
                    })()
                  )
                }
              >
                Set As Default
              </Button>
            )}
            {selectedView?.createdById === profile?._id && (
              <>
                <Button
                  onClick={() => {
                    openUpdateViewNameModal({ viewType });
                    setShowActions(false);
                  }}
                  size="small"
                  style={{ marginBottom: "8px" }}
                >
                  Update Name
                </Button>
                <Button
                  loading={togglePublic.isLoading}
                  size="small"
                  style={{ marginBottom: "8px" }}
                  onClick={() =>
                    swallow(
                      (async () => {
                        await togglePublic.mutateAsync({ id: selectedId });

                        setShowActions(false);
                      })()
                    )
                  }
                >
                  {selectedView?.isPublic
                    ? "Hide From Team"
                    : "Share With Team"}
                </Button>
              </>
            )}

            {selectedView?.createdById === profile?._id && (
              <Popconfirm
                okText="Delete"
                onConfirm={async () => {
                  await archiveView.mutateAsync({ id: selectedId });

                  useViewStore.getState().clearSelectedId(viewType)();
                  setShowActions(false);
                }}
                title="Are you sure?"
              >
                <Button
                  disabled={archiveView.isLoading}
                  size="small"
                  style={{ marginBottom: "8px", width: "100%" }}
                >
                  Delete View
                </Button>
              </Popconfirm>
            )}
            <Button
              size="small"
              onClick={() => {
                useViewStore.getState().setIncludeArchived(viewType)(
                  !includeArchived
                );
                setShowActions(false);
              }}
            >
              {includeArchived ? "Hide Archived" : "Show Archived"}
            </Button>
          </div>
        }
        onOpenChange={setShowActions}
        placement="bottom"
        trigger="hover"
        open={showActions}
      >
        <Button
          ghost
          icon={<MoreOutlined />}
          shape="circle"
          style={{ marginRight: "8px" }}
          type="primary"
        />
      </Popover>
    </>
  );
};
