import { Form } from "antd";

import { useSetContactUsers } from "data/use-contacts";
import { UserSelect } from "components/selects/user-select";
import { useOnChange } from "hooks/use-on-change";
import { Modal } from "components/modal";

export const SetUsers = ({ close, isOpen, contact }) => {
  const setContactUsers = useSetContactUsers({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setContactUsers.mutate({
      id: contact._id,
      userIds: values.userIds,
    });
  };

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        userIds: contact.users,
      });
    },
    [isOpen, form, contact]
  );

  return (
    <Modal
      confirmLoading={setContactUsers.isLoading}
      okText="Set Sales Reps"
      onCancel={close}
      onOk={form.submit}
      title="Sales Reps"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <UserSelect
          label="Sales Reps"
          mode="multiple"
          name="userIds"
          placeholder="Select sales reps"
        />
      </Form>
    </Modal>
  );
};
