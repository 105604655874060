import reject from "lodash/reject";
import { Badge, Card, List, Popconfirm, Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { colors, styles, viewTypes } from "@evolved/constants";

import { FieldOrder } from "./field-order";
import { UserDefinedFieldModal } from "../modals/user-defined-field";
import { useModal } from "hooks/use-modal";

import { useSetUserDefinedFields } from "data/use-organization";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;
const { CLICKABLE_STYLE } = styles;
const { PRIMARY } = colors;

const labels = {
  [ACCOUNT]: "Account",
  [CONTACT]: "Contact",
  [OPPORTUNITY]: "Opportunity",
  [VENDOR]: "Partner",
};

const Header = (props) => {
  const { fields, open, organization, type } = props;

  return (
    <>
      <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
        <Badge
          count={fields.length}
          showZero
          style={{ backgroundColor: PRIMARY }}
        />
        <span style={{ marginLeft: "16px" }}>Custom {labels[type]} Fields</span>
        <div style={{ marginLeft: "auto" }}>
          <FieldOrder fields={fields} organization={organization} type={type} />
        </div>
        <Button
          ghost
          onClick={() => open({ type })}
          style={{ marginLeft: "16px" }}
          type="primary"
        >
          <PlusCircleOutlined style={{ margin: "0px" }} />
        </Button>
      </div>
    </>
  );
};

export const FieldList = (props) => {
  const { fields, organization, type } = props;

  const setUserDefinedFields = useSetUserDefinedFields();

  const { modal, open } = useModal(UserDefinedFieldModal);

  return (
    <>
      {modal}
      <Card
        bordered={false}
        bodyStyle={{
          paddingTop: "0px",
          maxHeight: "500px",
          overflowY: "scroll",
        }}
      >
        <List
          dataSource={fields}
          header={<Header {...{ fields, open, organization, type }} />}
          itemLayout={"horizontal"}
          locale={{ emptyText: "No fields have been created" }}
          renderItem={(item) => {
            return (
              <List.Item
                actions={[
                  <div
                    key="user-defined-fields-edit"
                    style={CLICKABLE_STYLE}
                    onClick={() => open({ type, userDefinedField: item })}
                  >
                    edit
                  </div>,
                  <Popconfirm
                    key="user-defined-fields-delete"
                    title="Are you sure you would like to delete this custom field?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => {
                      setUserDefinedFields.mutateAsync(
                        reject(organization.userDefinedFields, { id: item.id })
                      );
                    }}
                  >
                    <div style={CLICKABLE_STYLE}>delete</div>
                  </Popconfirm>,
                ]}
                key={item.id}
              >
                <List.Item.Meta
                  description={item.description}
                  title={`${item.name} (${item.dataType.toLowerCase()})`}
                />
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};
