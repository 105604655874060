import copy from "copy-to-clipboard";

import { Button, message } from "antd";
import { useAuth } from "@clerk/clerk-react";

export const DevTools = () => {
  const { getToken } = useAuth();

  return (
    <>
      <div className="mb-2">
        <Button
          onClick={async () => {
            const token = await window.Clerk.session.getToken({
              template: "testing",
            });

            copy(token);

            message.success("Token copied to clipboard.");
          }}
        >
          Get UI Access Token
        </Button>
      </div>
      <div>
        <Button
          onClick={async () => {
            const response = await fetch(
              `${import.meta.env.VITE_API_URL}/integrations/procore/token`,
              {
                headers: {
                  Authorization: `Bearer ${await getToken()}`,
                  "Content-Type": "application/json",
                },
                method: "GET",
              }
            );

            if (response.status < 300) {
              const { token } = await response.json();

              copy(token);
            } else {
              message.warn(await response.text());
            }

            message.success("Token copied to clipboard.");
          }}
        >
          Get Procore token
        </Button>
      </div>
      <div>
        <Button
          onClick={async () => {
            const response = await fetch(
              `${import.meta.env.VITE_API_URL}/integrations/hubspot/token`,
              {
                headers: {
                  Authorization: `Bearer ${await getToken()}`,
                  "Content-Type": "application/json",
                },
                method: "GET",
              }
            );

            if (response.status < 300) {
              const { token } = await response.json();

              copy(token);
            } else {
              message.warn(await response.text());
            }

            message.success("Token copied to clipboard.");
          }}
        >
          Get HubSpot token
        </Button>
      </div>
    </>
  );
};
