import { Link as RouterLink } from "react-router-dom";

import { styles } from "@evolved/constants";

const { CLICKABLE_STYLE } = styles;

export const Link = (props) => {
  const { children, text, link } = props;

  return (
    <RouterLink to={link} style={CLICKABLE_STYLE}>
      {children || text}
    </RouterLink>
  );
};
