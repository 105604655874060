import { select } from "radash";

import { useSalesProcesses } from "data/use-sales-processes";

export const SalesProcessesSet = (props) => {
  const { value } = props;

  const salesProcesses = useSalesProcesses();

  return select(
    value,
    (id) => salesProcesses.byId(id).name,
    (id) => !!salesProcesses.byId(id)
  ).join(", ");
};
