import { useEffect, useState } from "react";

export const useVisible = (trigger) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 100);
  }, [trigger]);

  return [visible, setVisible];
};
