import { smartTrim } from "./handle-file-upload/normalize-text";

export const getUnmatchedRelationships = ({
  entityCache,
  fieldConfigs,
  headers,
  rows,
}) => {
  const relationshipConfigs = fieldConfigs.filter(
    ({ fieldType, title }) =>
      fieldType === "RELATIONSHIP" && headers.includes(title)
  );

  let result;

  const addResult = (collection) => (value) => {
    if (!result) {
      result = {};
    }

    if (!result[collection]) {
      result[collection] = [];
    }

    result[collection].push(value);
  };

  console.log(rows);
  for (const row of rows) {
    for (const relationshipConfig of relationshipConfigs) {
      const { title } = relationshipConfig;

      const values = row[title]?.split(",");

      for (const value of values) {
        if (value === "") {
          continue;
        }

        const matches = entityCache[relationshipConfig.collection]?.find(value);

        // if there are no matches, then add
        // if there are multiple matches, then... ?? ask the user?? will do so later
        // if there is 1 match, ignore
        if (matches.length === 0) {
          addResult(relationshipConfig.collection)(smartTrim(value));
        }
      }
    }
  }

  return result;
};
