import { CloudOutlined, PhoneOutlined, UserOutlined } from "@ant-design/icons";
import { Row } from "antd";

import { viewTypes } from "@evolved/constants";

import { InfoCard } from "components/info-card";
import { SetUsers } from "./modals/set-users";
import { UserDefinedFields } from "components/user-defined-fields";
import { ValidUrl } from "components/valid-url";
import { renderList } from "utils/render-list";
import { useModal } from "hooks/use-modal";
import { useUsers } from "data/use-users";
import { ScrollableContent } from "../../components/scrollable-content";

export const Details = (props) => {
  const { account, organization } = props;

  const users = useUsers();

  const { modal: usersModal, open: openUsersModal } = useModal(SetUsers);

  return (
    <>
      {usersModal}
      <ScrollableContent>
        <Row gutter={16} type="flex">
          <InfoCard
            content={account.phone || "--"}
            Icon={PhoneOutlined}
            label="Phone"
          />
          <InfoCard content={account.address || "--"} label="Address" />
          <InfoCard
            content={account.website ? <ValidUrl url={account.website} /> : "--"}
            Icon={CloudOutlined}
            label="Website"
          />
          <InfoCard
            content={renderList(users.byIds(account.users), "Assign sales reps")}
            Icon={UserOutlined}
            label="Sales Reps"
            onClick={() => openUsersModal({ account })}
          />
          <UserDefinedFields
            data={account}
            organization={organization}
            type={viewTypes.ACCOUNT}
          />
        </Row>
      </ScrollableContent>
    </>
  );
};
