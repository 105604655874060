import isNil from "lodash/isNil";

import {
  ClusterOutlined,
  FilterOutlined,
  GlobalOutlined,
  GoldOutlined,
  LeftOutlined,
  IdcardOutlined,
  RadarChartOutlined,
  RightOutlined,
  RocketOutlined,
} from "@ant-design/icons";
import { Menu as AntdMenu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { createBreakpoint, useLocalStorage } from "react-use";

import { userRoles } from "@evolved/constants";
import { getPartnersLabel } from "@evolved/labels";

import { useMe } from "../../data/use-me";
import { useOrganization } from "../../data/use-organization";

const { ROLES } = userRoles;

const getItem =
  ({ profile }) =>
  ({ label, Icon, key, to, role }) => {
    if (!isNil(role) && (!profile || profile.role > role)) {
      return null;
    }

    return {
      icon: <Icon />,
      id: `${key}-menu-item`,
      key,
      label: (
        <Link to={to}>
          <span style={{ color: "white" }}>{label}</span>
        </Link>
      ),
    };
  };

const items = ({ organization }) => [
  {
    key: "opportunities",
    Icon: RocketOutlined,
    label: "Opportunities",
    to: "/opportunities",
  },
  {
    key: "accounts",
    Icon: RadarChartOutlined,
    label: "Accounts",
    to: "/accounts",
  },
  {
    key: "contacts",
    Icon: IdcardOutlined,
    label: "Contacts",
    to: "/contacts",
  },
  ...(organization?.settings?.showPartners
    ? [
        {
          key: "partners",
          Icon: GlobalOutlined,
          label: getPartnersLabel(organization),
          to: "/partners",
        },
      ]
    : []),
  {
    key: "products",
    Icon: GoldOutlined,
    label: "Products",
    to: "/products",
  },
  {
    key: "sales-processes",
    Icon: FilterOutlined,
    label: "Sales Processes",
    to: "/sales-processes",
  },
  {
    key: "organization",
    Icon: ClusterOutlined,
    label: "Organization",
    to: "/organization",
    role: ROLES.MANAGER,
  },
];

const useBreakpoint = createBreakpoint({ sm: 0, md: 612 });

const NavigationMenu = () => {
  const [isCollapsedSetting, setIsCollapsed] = useLocalStorage(
    "LAYOUT_STATE.NAVIGATION_MENU.IS_COLLAPSED",
    true
  );
  const breakpoint = useBreakpoint();

  const isCollapsed = isCollapsedSetting || breakpoint === "sm";

  const location = useLocation();

  const organization = useOrganization();
  const profile = useMe();

  return (
    <div
      className="transition-width ease-in-out"
      style={{
        backgroundColor: "rgb(0, 21, 41)",
        display: "flex",
        flexDirection: "column",
        gridColumn: 1,
        transitionDuration: "300ms",
        width: !isCollapsed ? "215px" : "80px",
      }}
    >
      <AntdMenu
        items={items({ organization }).map(getItem({ profile }))}
        mode="inline"
        inlineCollapsed={isCollapsed}
        selectedKeys={[location.pathname.split("/")[1]]}
        theme={"dark"}
        style={{ flexGrow: 1 }}
      />
      {breakpoint === "md" && (
        <div
          onClick={() => setIsCollapsed(!isCollapsedSetting)}
          style={{
            height: "50px",
            backgroundColor: "#002140",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          {isCollapsed ? <RightOutlined /> : <LeftOutlined />}
        </div>
      )}
    </div>
  );
};

export default NavigationMenu;
