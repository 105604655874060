import { Tag } from "antd";
import { select } from "radash";

import { useUsers } from "../../../../../data/use-users";

export const UsersSet = (props) => {
  const { value } = props;

  const users = useUsers();

  const selected = select(
    value,
    (id) => users.byId(id),
    (id) => !!users.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((user) => {
    return (
      <Tag key={user._id} style={user.isArchived ? { opacity: 0.5 } : {}}>
        {user.alias}
      </Tag>
    );
  });
};
