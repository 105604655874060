import { Tag } from "antd";
import { select } from "radash";

import { useVendors } from "../../../../../data/use-vendors";
import { Link } from "../../../../link";

export const VendorsSet = (props) => {
  const { value } = props;

  const vendors = useVendors();

  const selected = select(
    value,
    (id) => vendors.byId(id),
    (id) => !!vendors.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((vendor) => {
    return (
      <Link link={`/partners/${vendor._id}`} key={vendor._id}>
        <Tag style={vendor.isArchived ? { opacity: 0.5 } : {}}>
          {vendor.name}
        </Tag>
      </Link>
    );
  });
};
