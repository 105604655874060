import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";
import set from "lodash/set";
import trim from "lodash/trim";
import moment from "moment";

import { v4 as uuid } from "uuid";

import { accountStates, fieldTypes } from "@evolved/constants";

import { normalizeText } from "./normalize-text";

const {
  display: displayAccountState,
  isValid: isAccountStateValid,
  STATES: ACCOUNT_STATES,
} = accountStates;

const { ACCOUNT_STATE, DATE, DOLLAR, PERCENT } = fieldTypes;

const conversions = {
  [ACCOUNT_STATE]: (value, row) => {
    if (!isAccountStateValid(ACCOUNT_STATES[value.toUpperCase()])) {
      throw new Error(
        `Account State on row ${row} must be one of ${Object.values(
          ACCOUNT_STATES
        )
          .map(displayAccountState)
          .join(", ")}`
      );
    }

    return ACCOUNT_STATES[value.toUpperCase()];
  },
  [DOLLAR]: (value, row) => {
    const parsed = Number(value);

    if (parsed != value) {
      throw new Error(
        `We cannot parse the dollar value on row ${row}. Dollar values for import must be whole (no decimals), represented without commas or dollar signs`
      );
    }

    return parsed;
  },
  [DATE]: (value, row) => {
    const parsed = moment(value);

    if (!parsed.isValid()) {
      throw new Error(
        `We cannot parse the date on row ${row}. Try a format such as 1/30/2021 13:00:00`
      );
    }

    return parsed.unix();
  },
  [PERCENT]: (value, row, header) => {
    let parsed = Number(value.replace("%", ""));

    if (!isFinite(parsed)) {
      throw new Error(
        `${header.title} on row ${row} must be a percentage between 0 and 100`
      );
    }

    if (parsed < 1) {
      parsed = parsed * 100;
    }

    if (parsed < 0 || parsed > 100) {
      throw new Error(
        `${header.title} on row ${row} must be a percentage between 0 and 100`
      );
    }

    return parsed;
  },
};

export const parseFile = (entityType, data, fields, cache) => {
  const headers = data.headers.reduce((accumulator, header) => {
    const field = fields.find(
      ({ title }) => normalizeText(header) === normalizeText(title)
    );

    const dataIndex = field ? field.dataIndex : `userDefinedFields.${uuid()}`;

    accumulator[normalizeText(header)] = field || {
      dataIndex,
      fieldType: "UDF",
      isNew: true,
      // options: [],
      title: header,
      type: "TEXT",
    };

    return accumulator;
  }, {});

  const newRelationships = {};

  let mapped = data.rows.map((row, index) => {
    return reduce(
      data.headers,
      (acc, key) => {
        let value = row[key];

        if (isNil(value) || isEmpty(value)) {
          return acc;
        }

        value = trim(value);

        const header = headers[normalizeText(key)];

        if (isNil(header)) {
          return acc;
        }

        if (header.fieldType === "RELATIONSHIP" && cache[header.collection]) {
          if (cache[header.collection][normalizeText(value)]) {
            set(
              acc,
              header.idIndex,
              cache[header.collection][normalizeText(value)]._id
            );
          } else {
            if (!header.canCreate) {
              throw new Error(
                `${header.title} "${value}" as well as any others must be created prior to import`
              );
            }

            set(acc, header.idIndex, value);

            newRelationships[header.collection] =
              newRelationships[header.collection] || {};

            const newRelationship =
              newRelationships[header.collection][normalizeText(value)];

            if (!newRelationship) {
              newRelationships[header.collection][normalizeText(value)] = {
                value: {
                  name: value,
                },
                indexes: [index],
              };
            } else {
              newRelationship.indexes.push(index);
            }
          }
        } else if (header.fieldType === "UDF") {
          if (header.isNew) {
            set(acc, header.dataIndex, trim(value));

            // const option = header.options.find(
            //   (label) => normalizeText(label) === normalizeText(value)
            // );

            // if (!option) {
            //   header.options.push(trim(value));
            // } else {
            //   header.hasRepeatOption = true;
            // }

            // if (header.options.length > 50) {
            //   header.options = false;
            // }
          } else {
            if (header.type === "SELECT") {
              let option = header.options.find(
                ({ label }) => normalizeText(label) === normalizeText(value)
              );
              if (!option) {
                option = { id: uuid(), label: trim(value) };

                header.options.push(option);
                header.isUpdated = true;
              }

              set(acc, header.dataIndex, option.id);
            } else {
              set(acc, header.dataIndex, value);
            }
          }
        } else {
          if (conversions[header.type]) {
            set(
              acc,
              header.dataIndex,
              conversions[header.type](value, index + 1, header)
            );
          } else {
            set(acc, header.dataIndex, value);
          }
        }

        return acc;
      },
      {}
    );
  });

  const newUdfs = Object.values(headers)
    .filter((header) => header.fieldType === "UDF" && header.isNew)
    .map((udf) => {
      return {
        id: udf.dataIndex.split(".")[1],
        name: udf.title,
        type: entityType,
        ...(udf.options && udf.hasRepeatOption && udf.options.length > 1
          ? {
              dataType: "SELECT",
              options: udf.options.map((label) => ({
                id: uuid(),
                label,
              })),
            }
          : { dataType: "TEXT" }),
      };
    });

  const newSelectUdfs = newUdfs.filter((d) => d.dataType === "SELECT");

  if (!isEmpty(newSelectUdfs)) {
    mapped = mapped.map((m) => {
      return reduce(
        newSelectUdfs,
        (acc, u) => {
          let value = get(acc, `userDefinedFields.${u.id}`);

          if (!isNil(value) && !isEmpty(value)) {
            set(
              acc,
              `userDefinedFields.${u.id}`,
              u.options.find(
                (o) => normalizeText(o.label) === normalizeText(value)
              ).id
            );
          }

          return acc;
        },
        m
      );
    });
  }

  const updatedUdfs = Object.values(headers)
    .filter((header) => header.fieldType === "UDF" && header.isUpdated)
    .map((udf) => {
      return {
        id: udf.dataIndex.split(".")[1],
        name: udf.title,
        type: entityType,
        ...(udf.options
          ? {
              dataType: "SELECT",
              options: udf.options,
            }
          : { dataType: "TEXT" }),
      };
    });

  return {
    entities: mapped,
    relationships: Object.keys(newRelationships).map((type) => ({
      entities: newRelationships[type],
      type,
    })),
    udfs: [...newUdfs, ...updatedUdfs],
  };
};
