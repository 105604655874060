import get from "lodash/get";
import GoldOutlined from "@ant-design/icons/GoldOutlined";
import isEmpty from "lodash/isEmpty";
import { forwardRef } from "react";

import { Box } from "../box";
import { BodyHeader } from "../body-header";
import { BodyIcon } from "../body-icon";
import { BodyText } from "../body-text";
import { Spotlight } from "../../spotlight";
import { Step } from "../guide";
import { useOnClick } from "../use-on-click";
import { useOnExists } from "../use-on-exists";
import { useOnItemsCreated } from "../use-on-items-created";

const Guide = (props) => {
  const { guide, isComplete, products = [], patchSetupState } = props;

  const steps = [
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onClose={guide.stop}>
            This is how you get to your products, click here to go there now.
          </Step>
        ),
        placement: "right",
      },
      elementId: "products-menu-item",
    },
    {
      tooltip: {
        content: (
          <Step onNext={guide.forward} onClose={guide.stop}>
            Here is where your products will show up after you create them.
          </Step>
        ),
        placement: "top",
      },
      elementId: "PRODUCT-view-table",
    },
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step
            onBack={guide.back}
            onNext={async () => {
              if (!isComplete) {
                patchSetupState.mutate({
                  isProductsStepComplete: true,
                });
              }

              guide.go(steps.length);
            }}
            nextText={"Got it"}
            onClose={guide.stop}
          >
            This is the primary way to create products, click to add one now.
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "create-product-button",
    },
  ];

  useOnExists(guide.forward, guide.index === 0 ? "PRODUCT-view-table" : null);

  useOnClick(
    () => guide.go(steps.length),
    guide.index === 2 ? "create-product-button" : null
  );

  useOnItemsCreated(guide.complete, products);

  const step = steps[guide.index];

  if (!step) {
    return null;
  }

  return (
    <Spotlight
      allowClicks={step.allowClicks}
      elementId={step.elementId}
      tooltip={step.tooltip}
    />
  );
};

const HeroButton = (props, ref) => {
  const { isComplete, isCurrent, onClick } = props;

  const bodyText = (
    <>
      Products and services are the bread and butter of your business, the value
      you provide.
    </>
  );

  return (
    <Box
      isComplete={isComplete}
      isCurrent={isCurrent}
      onClick={onClick}
      ref={ref}
    >
      <BodyIcon
        Icon={GoldOutlined}
        isComplete={isComplete}
        isCurrent={isCurrent}
      />
      <BodyHeader
        isComplete={isComplete}
        isCurrent={isCurrent}
        title={"Products"}
      />
      <BodyText isComplete={isComplete} isCurrent={isCurrent}>
        {bodyText}
      </BodyText>
    </Box>
  );
};

const getStartIndex = () => {
  if ((window.location.href || "").endsWith("/products")) {
    return 1;
  }

  return 0;
};

export const products = {
  Guide,
  HeroButton: forwardRef(HeroButton),

  getStartIndex,
  isComplete: ({ organization, products }) =>
    !isEmpty(products) ||
    get(organization, "setupState.isProductsStepComplete"),
  mapData: ({ products }) => ({ products }),
  type: "product",
};
