import { MoreOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";

import { withManagerRole } from "../../components/with-role";
import { useModal } from "../../hooks/use-modal";
import { MergeContactsModal } from "./merge-contacts-modal";

const Component = () => {
  const { modal: mergeContactModal, open: openMergeContactModal } =
    useModal(MergeContactsModal);

  return (
    <>
      {mergeContactModal}
      <Popover
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button size="small" onClick={openMergeContactModal}>
              Merge Contacts
            </Button>
          </div>
        }
        placement={"bottom"}
      >
        <Button
          ghost
          icon={<MoreOutlined />}
          shape="circle"
          style={{ marginRight: "8px" }}
          type="primary"
        />
      </Popover>
    </>
  );
};

export const Extras = withManagerRole(Component);
