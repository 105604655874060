import CountUp from "react-countup";
import D3Funnel from "d3-funnel";
import equal from "deep-equal";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import { alphabetical, debounce } from "radash";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Select, Statistic, Tooltip } from "antd";
import { useLocalStorage } from "react-use";

import { styles, viewTypes } from "@evolved/constants";
import { opportunityFields } from "@evolved/views";

import { getFunnelProgressColor } from "utils/color/get-funnel-progress-color";
import { formatDollars } from "utils/format-dollars";
import { WidgetHeader } from "./widget-header";

import { useFunnelOpportunityCounts } from "data/use-reports.js";
import { useOpportunityStates } from "data/use-opportunity-states.js";
import { useOrganization } from "data/use-organization.js";
import { useSalesProcesses } from "data/use-sales-processes.js";
import { WidgetContainer } from "./widget-container";
import { useViewStore } from "../../stores/view";

const { CLICKABLE_STYLE } = styles;
const { OPPORTUNITY } = viewTypes;

const dollarFormatter = (value) => (
  <CountUp end={value} separator="," prefix="$" />
);

export const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

const prepareData = (data, salesProcesses, stateIds) => {
  let total = 0;
  let weightedRevenue = 0;
  let prepared;

  if (isEmpty(salesProcesses)) {
    prepared = reduce(
      data,
      (accumulator, { funnelProgress, stateId, value }) => {
        if (!stateIds.includes(stateId)) {
          return accumulator;
        }

        total = total + value;
        weightedRevenue = weightedRevenue + (value * funnelProgress) / 100;

        let bucket;

        if (funnelProgress === 0) {
          bucket = 0;
        } else if (funnelProgress <= 15) {
          bucket = 1;
        } else {
          bucket = Math.ceil(funnelProgress / 20);
        }

        accumulator[bucket].value = accumulator[bucket].value + value;

        return accumulator;
      },
      [
        { label: { label: "0%" }, min: 0, max: 0, value: 0 },
        { label: { label: "1 - 20%" }, min: 1, max: 20, value: 0 },
        { label: { label: "21% - 40%" }, min: 21, max: 40, value: 0 },
        { label: { label: "41% - 60%" }, min: 41, max: 60, value: 0 },
        { label: { label: "61% - 80%" }, min: 61, max: 80, value: 0 },
        { label: { label: "81% - 100%" }, min: 81, max: 100, value: 0 },
      ]
    );
  } else {
    let stepGroups = reduce(
      salesProcesses,
      (accumulator, { name, steps }) => {
        for (const step of steps) {
          if (!accumulator[step.funnelTrigger]) {
            accumulator[step.funnelTrigger] = {
              funnelTrigger: step.funnelTrigger,
              questions: [],
            };
          }

          accumulator[step.funnelTrigger].questions.push(
            salesProcesses.length > 1
              ? `${name}: ${step.question}`
              : step.question
          );
        }

        return accumulator;
      },
      {}
    );

    // todo: should test this case
    // can just fake some data
    if (!stepGroups[0]) {
      stepGroups[0] = {
        funnelTrigger: 0,
        questions: ["No steps completed, yet"],
      };
    } else if (stepGroups[0].questions.length !== salesProcesses.length) {
      stepGroups[0].questions.push("Or, no steps completed yet.");
    }

    stepGroups = Object.values(stepGroups);
    stepGroups = sortBy(stepGroups, "funnelTrigger");

    prepared = stepGroups.map(({ funnelTrigger, questions }) => {
      const { count, value } = reduce(
        data,
        (
          accumulator,
          { funnelProgress, salesProcessId, stateId, count, value }
        ) => {
          if (
            !stateIds.includes(stateId) ||
            funnelProgress !== funnelTrigger ||
            !salesProcesses.find(({ _id }) => Number(_id) === salesProcessId)
          ) {
            return accumulator;
          }

          accumulator.count = accumulator.count + count;
          accumulator.value = accumulator.value + value;

          return accumulator;
        },
        { count: 0, value: 0 }
      );

      total = total + value;
      weightedRevenue = weightedRevenue + (value * funnelTrigger) / 100;

      return {
        count,
        label: { label: `${funnelTrigger}%`, count, questions },
        min: funnelTrigger,
        max: funnelTrigger,
        questions,
        value,
      };
    });
  }

  return {
    prepared,
    weightedRevenue,
    total,
  };
};

const parseString = (string) => {
  if (string.length > 28) {
    return `${string.substring(0, 28)}...`;
  }

  return string;
};

const Component = () => {
  const navigate = useNavigate();

  const viewOpportunities = (min, max, salesProcessIds, statesIds) => {
    useViewStore.getState().setView(OPPORTUNITY)({
      fields: useViewStore.getState()[OPPORTUNITY].fields,
      filters: [
        ...(isEmpty(salesProcessIds)
          ? []
          : [
            {
              dataIndex: opportunityFields.salesProcess.dataIndex,
              value: salesProcessIds,
            },
          ]),
        ...(isEmpty(statesIds)
          ? []
          : [
            {
              dataIndex: opportunityFields.state.dataIndex,
              value: statesIds,
            },
          ]),
        {
          dataIndex: opportunityFields.funnelProgress.dataIndex,
          value: { min, max },
        },
      ],
      preset: true,
    });

    navigate("/opportunities");
  };

  const opportunityStates = useOpportunityStates();
  const organization = useOrganization();
  const salesProcessesStore = useSalesProcesses();
  const opportunityCounts = useFunnelOpportunityCounts();

  const salesProcesses = salesProcessesStore.all({ includeArchived: false });

  const funnelRef = useRef(null);

  const [legacySelectedSalesProcessIds, setSelectedSalesProcessIds] =
    useLocalStorage(
      `${organization._id}.DASHBOARD.SALES_FUNNEL.SELECTED_SALES_PROCESS_IDS`,
      []
    );

  const selectedSalesProcessIds = legacySelectedSalesProcessIds
    .filter((id) => !!salesProcessesStore.byId(id, { includeArchived: false }))
    .map((id) => Number(id));
  const selectedSalesProcesses = selectedSalesProcessIds.map((id) =>
    salesProcessesStore.byId(id)
  );

  const potentialOpenStates = opportunityStates
    .all({ includeArchived: false })
    .filter(({ systemKey }) => systemKey !== "WON" && systemKey !== "LOST");

  const [legacySelectedStateIds, setSelectedStateIds] = useLocalStorage(
    `${organization._id}.DASHBOARD.SALES_FUNNEL.SELECTED_STATES`,
    potentialOpenStates.map(({ _id }) => _id)
  );

  const selectedStateIds = legacySelectedStateIds
    .filter((id) => !!opportunityStates.byId(id, { includeArchived: false }))
    .map((id) => Number(id));

  const { prepared, total, weightedRevenue } = prepareData(
    opportunityCounts,
    selectedSalesProcesses,
    selectedStateIds
  );

  const funnelColors = prepared.map(({ max }) => getFunnelProgressColor(max));

  useEffect(() => {
    if (salesProcesses?.length === 1 && isEmpty(selectedSalesProcesses)) {
      setSelectedSalesProcessIds([salesProcesses[0]._id]);
    }
  }, [salesProcesses, selectedSalesProcesses]);

  const height =
    reduce(
      prepared,
      (sum, { questions }) => {
        return (questions?.length || 0) + sum;
      },
      prepared.length
    ) * 30;

  const chartRenderProps = useRef();

  if (
    !equal(
      {
        prepared,
        funnelColors,
        selectedSalesProcessIds,
        selectedStateIds,
      },
      chartRenderProps.current
    )
  ) {
    chartRenderProps.current = {
      prepared,
      funnelColors,
      selectedSalesProcessIds,
      selectedStateIds,
    };
  }

  const funnel = useRef();
  const renderChart = useRef(
    debounce({ delay: 250 }, () => {
      const {
        prepared,
        funnelColors,
        selectedSalesProcessIds,
        selectedStateIds,
      } = chartRenderProps.current;

      funnel.current = new D3Funnel(funnelRef.current);
      funnel.current.draw(prepared, {
        chart: {
          bottomWidth: 0.6,
          curve: {
            enabled: true,
          },
          animate: 50,
          width: 560,
        },
        block: {
          fill: {
            scale: funnelColors,
          },
          minHeight: 24,
        },
        label: {
          format: ({ label, questions = [] }, value) => {
            return `${label} | ${formatDollars(value)}\n${questions
              .map(parseString)
              .join("\n")}`;
          },
        },
        tooltip: {
          enabled: !isEmpty(selectedSalesProcessIds),
          format: ({ questions = [] }) => questions.join("\n"),
        },
        events: {
          click: {
            block: (e, { index }) => {
              viewOpportunities(
                prepared[index].min,
                prepared[index].max,
                selectedSalesProcessIds,
                selectedStateIds
              );
            },
          },
        },
      });
    })
  );

  useEffect(() => {
    const { prepared } = chartRenderProps.current;

    if (isEmpty(prepared)) return;

    renderChart.current();

    return () => {
      if (funnel.current) {
        funnel.current.destroy();
      }
    };
  }, [chartRenderProps.current]);

  const title = get(organization, "labels.salesFunnel") || "Sales Funnel";

  const canClearSelection =
    !isEmpty(selectedSalesProcesses) && salesProcesses?.length > 1;

  return (
    <div>
      <div
        style={{ alignItems: "center", display: "flex", marginBottom: "24px" }}
      >
        <WidgetHeader
          label={
            <>
              {title}
              <Tooltip
                title={
                  <span>
                    The sales funnel includes all <strong>Open</strong>{" "}
                    opportunities. <strong>Lost</strong> and{" "}
                    <strong>Won</strong> are not included.
                  </span>
                }
              >
                <InfoCircleOutlined style={{ marginLeft: "8px" }} />
              </Tooltip>
            </>
          }
        />
        <h4
          style={{ fontSize: "18px", marginBottom: "0px", marginLeft: "auto" }}
        >
          As of Today
        </h4>
      </div>
      <div style={{ display: "flex", marginBottom: "12px" }}>
        <div
          style={{
            fontWeight: 600,
            width: "100px",
            marginRight: "8px",
            textAlign: "right",
          }}
        >
          At Stage
        </div>
        <div style={{ flexGrow: 1 }}>
          <Select
            filterOption={filterOption}
            mode="multiple"
            notFoundContent={"No match"}
            placeholder="Select at least one stage"
            optionFilterProp="children"
            showSearch
            options={potentialOpenStates.map((option) => ({
              value: option._id,
              label: option.label,
            }))}
            value={selectedStateIds}
            onChange={setSelectedStateIds}
            size="small"
            style={{ width: "100%" }}
          />
        </div>
      </div>
      {salesProcesses?.length > 0 && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              fontWeight: 600,
              width: "100px",
              marginRight: "8px",
              textAlign: "right",
            }}
          >
            With Process
          </div>
          <Select
            mode="multiple"
            size="small"
            allowClear={salesProcesses?.length > 1}
            // todo: is this even necessary, in particular when you select all,
            // should that just be the default now?
            onClear={() => setSelectedSalesProcessIds([])}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onChange={setSelectedSalesProcessIds}
            options={alphabetical(
              salesProcesses,
              (salesProcess) => salesProcess.name
            ).map(({ _id, name }) => ({
              label: name,
              value: _id,
            }))}
            placeholder="Filter by specific sales process"
            style={{ flexGrow: 1 }}
            value={selectedSalesProcessIds}
          />
        </div>
      )}
      <div
        onClick={
          canClearSelection ? () => setSelectedSalesProcessIds([]) : noop
        }
        style={{
          ...(canClearSelection ? CLICKABLE_STYLE : {}),
          marginTop: "4px",
          marginBottom: "12px",
          textAlign: "right",
        }}
      >
        {canClearSelection ? `Clear Process Selection` : ""}
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          marginBottom: "24px",
        }}
      >
        <Fade>
          <Tooltip title="value * (funnel progress / 100)">
            <Statistic
              formatter={dollarFormatter}
              title={"Weighted"}
              value={weightedRevenue}
            />
          </Tooltip>
        </Fade>
        <Fade>
          <Tooltip title="Total value of all opportunities in the funnel">
            <Statistic
              formatter={dollarFormatter}
              title={"Total"}
              value={total}
            />
          </Tooltip>
        </Fade>
      </div>
      {!isEmpty(prepared) && (
        <Fade>
          <div
            style={height > 675 ? { maxHeight: 675, overflowY: "scroll" } : {}}
          >
            <div
              id="opportunity-funnel"
              ref={funnelRef}
              style={{
                borderRadius: "12px",
                height: height > 675 ? height : 675,
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </div>
        </Fade>
      )}
    </div>
  );
};

export const OpportunityFunnel = () => {
  return (
    <WidgetContainer>
      <Component />
    </WidgetContainer>
  );
};
