import { useEffect } from "react";

import { useCommandPalette as useCommandPaletteState } from "../../stores/command-palette";

import { useFlag } from "hooks/use-flag";

export const useCommandPaletteListener = () => {
  const isOn = useFlag("command-palette");

  useEffect(() => {
    if (!isOn) return;

    function handleKeyDown(e) {
      if (useCommandPaletteState.getState().isOpen && e.key === "Escape") {
        useCommandPaletteState.getState().setIsOpen(false);
      }

      if ((e.metaKey && e.key === "k") || e.key === "/") {
        e.preventDefault();
        e.stopPropagation();

        useCommandPaletteState.getState().toggle();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
};
