import {
  PERFORM_ACCOUNT_ACTIVITY_MODAL,
  PERFORM_CONTACT_ACTIVITY_MODAL,
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  PERFORM_VENDOR_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";

export const performActivityOnFollowup = (followup) => {
  const { accountId, contactId, opportunityId, vendorId } = followup;

  if (opportunityId) {
    return useLayoutStore.getState().open(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, {
      closedFollowup: followup,
      opportunityId,
    });
  }

  if (vendorId) {
    return useLayoutStore.getState().open(PERFORM_VENDOR_ACTIVITY_MODAL, {
      closedFollowup: followup,
      vendorId,
    });
  }

  if (contactId) {
    return useLayoutStore.getState().open(PERFORM_CONTACT_ACTIVITY_MODAL, {
      closedFollowup: followup,
      contactId,
    });
  }

  useLayoutStore.getState().open(PERFORM_ACCOUNT_ACTIVITY_MODAL, {
    closedFollowup: followup,
    accountId,
  });
};
