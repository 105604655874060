import isNil from "lodash/isNil";
import { List } from "antd";
import { useNavigate } from "react-router-dom";

import { styles } from "@evolved/constants";

import { OpportunityState } from "components/opportunity-state";
import { formatDollars } from "utils/format-dollars";

const { CLICKABLE_STYLE, PRIMARY_TEXT } = styles;

const WeightedRevenue = ({ weightedRevenue }) => {
  if (isNil(weightedRevenue)) return null;

  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div style={{ marginRight: "8px" }}>Weighted Revenue</div>
      <span style={{ ...PRIMARY_TEXT }}>{formatDollars(weightedRevenue)}</span>
    </div>
  );
};

export const Opportunity = ({ opportunity, showPerformActivity }) => {
  const navigate = useNavigate();

  const { weightedRevenue } = opportunity;

  return (
    <List.Item style={{ padding: "8px" }}>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            onClick={() => navigate(`/opportunities/${opportunity._id}`)}
            style={{ ...PRIMARY_TEXT, ...CLICKABLE_STYLE }}
          >
            {opportunity.alias}
          </div>
          <WeightedRevenue weightedRevenue={weightedRevenue} />
          <div
            onClick={() => showPerformActivity({ opportunity })}
            style={{ ...CLICKABLE_STYLE }}
          >
            Act
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <OpportunityState opportunity={opportunity} lossReasonLeft />
        </div>
      </div>
    </List.Item>
  );
};
