import map from "lodash/map";
import { useLayoutEffect } from "react";

import { Tabs } from "antd";

import { DevTools } from "./dev-tools";
// import { EmailDigests } from "./email-digests";
// import { WelcomeEmail } from "./welcome-email";
import { SetupWizard } from "./setup-wizard";
import { useInternal } from "hooks/use-internal";

const { TabPane } = Tabs;

const tabs = [
  { label: "Setup Wizard", Component: SetupWizard },
  // { label: "Email Digests", Component: EmailDigests },
  // { label: "Welcome Email", Component: WelcomeEmail },
  { label: "Dev Tools", Component: DevTools },
  {
    label: "Throw Error",
    Component: () => {
      throw new Error("This is a test error");
    },
  },
];

export const InternalToolsRoute = () => {
  const isInternal = useInternal();

  useLayoutEffect(() => {
    if (!isInternal) {
      window.location.hash = "/dashboard";
    }
  }, [isInternal]);

  return (
    <div
      className="w-full"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        height: "100%",
      }}
    >
      <h2>Internal Tools</h2>
      <Tabs defaultActiveKey="0" tabPosition="left">
        {map(tabs, ({ label, Component: Tab }, key) => {
          return (
            <TabPane key={key} tab={label}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ width: "500px" }}>
                  <Tab />
                </div>
              </div>
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};
