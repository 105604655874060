import { isNumber } from "radash";

export const aggregate = (data, aggregations) => {
  let aggregated = {};

  for (const key of Object.keys(aggregations)) {
    aggregated[key] = 0;
  }

  for (const datum of data) {
    for (const [dataIndex, aggregation] of Object.entries(aggregations)) {
      if (!isNumber(datum[dataIndex])) {
        continue;
      }

      if (["sum", "avg"].includes(aggregation)) {
        if (!isNumber(aggregated[dataIndex])) {
          aggregated[dataIndex] = 0;
        }

        aggregated[dataIndex] = aggregated[dataIndex] + datum[dataIndex];

        continue;
      }

      if (
        aggregation === "min" &&
        (!isNumber(aggregated[dataIndex]) ||
          datum[dataIndex] < aggregated[dataIndex])
      ) {
        aggregated[dataIndex] = datum[dataIndex];
      }

      if (
        aggregation === "max" &&
        (!isNumber(aggregated[dataIndex]) ||
          datum[dataIndex] > aggregated[dataIndex])
      ) {
        aggregated[dataIndex] = datum[dataIndex];
      }
    }
  }

  for (const [dataIndex, aggregation] of Object.entries(aggregations)) {
    if (aggregation === "avg" && isNumber(aggregated[dataIndex])) {
      aggregated[dataIndex] = aggregated[dataIndex] / data.length;
    }
  }

  return aggregated;
};
