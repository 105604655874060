import day from "dayjs";

import { InfoCircleOutlined } from "@ant-design/icons";
import { Checkbox, Form, Tooltip } from "antd";

import { detailed } from "utils/format-date";

const styles = {
  container: { alignItems: "center", display: "flex", marginBottom: "8px" },
  icon: { marginLeft: "auto" },
};

export const isBeforePerformedOn = (performedOn) => (lastImpactedOn) =>
  !lastImpactedOn || day.unix(lastImpactedOn).isBefore(performedOn);

export const Steps = (props) => {
  const { form, steps } = props;

  const performedOn = Form.useWatch("performedOn", form);

  const isBefore = isBeforePerformedOn(performedOn);

  return (
    <Form.List name="steps">
      {(fields) => {
        return fields.map((field) => {
          const { lastImpactedOn, question } = steps[field.key];

          const canImpact = isBefore(lastImpactedOn);

          return (
            <div key={field.key} style={{ display: "flex" }}>
              <Form.Item
                {...field}
                valuePropName="checked"
                wrapperCol={{ span: 24 }}
                style={{ marginBottom: 0 }}
              >
                <Checkbox disabled={!canImpact} style={{ marginRight: "5px" }}>
                  {question}
                </Checkbox>
              </Form.Item>

              {!canImpact && (
                <Tooltip
                  title={`Step was impacted on ${detailed(
                    lastImpactedOn
                  )}. This activity would have no affect.`}
                >
                  <InfoCircleOutlined style={{ ...styles.icon }} />
                </Tooltip>
              )}
            </div>
          );
        });
      }}
    </Form.List>
  );
};
