import { SaveOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";

import noop from "lodash/noop";

export const EditableTextCell = (props) => {
  const {
    isEditing,
    setIsEditing,
    children,
    onUpdate,
    validate = noop,
    value,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    if (isEditing && inputRef.current) {
      if (inputRef?.current?.resizableTextArea?.textArea?.value) {
        const end = inputRef.current.resizableTextArea.textArea.value.length;

        inputRef.current.resizableTextArea.textArea.setSelectionRange(end, end);
      }

      inputRef.current.focus();
    }
  }, [isEditing]);

  const save = async () => {
    const error = validate(inputRef.current.resizableTextArea.textArea.value);

    if (error) {
      message.warning(error);
      return;
    }

    setIsLoading(true);

    const isSuccess = await onUpdate(
      inputRef.current.resizableTextArea.textArea.value
    );

    if (isSuccess) {
      setIsEditing(false);
    }

    setIsLoading(false);
  };

  return isEditing ? (
    <>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <Button
          icon={<SaveOutlined />}
          loading={isLoading}
          onClick={save}
          size="small"
          style={{ flexBasis: 0, marginRight: 8 }}
          type="primary"
        >
          Save
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => setIsEditing(false)}
          size="small"
          style={{ flexBasis: 0 }}
        >
          Cancel
        </Button>
      </div>
      <Input.TextArea
        autoSize
        defaultValue={value}
        disabled={isLoading}
        ref={inputRef}
      />
    </>
  ) : (
    children
  );
};
