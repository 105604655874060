import {
  GlobalOutlined,
  IdcardOutlined,
  RadarChartOutlined,
  RocketOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { styles } from "@evolved/constants";

import { FollowupDate } from "components/followup-date";
import { Link } from "components/link";
import { useAccounts } from "data/use-accounts";
import { useContacts } from "data/use-contacts";
import { useOpportunities } from "data/use-opportunities";
import { useVendors } from "data/use-vendors";
import { useUsers } from "../../../../data/use-users";
import { Metadata } from "../../metadata";
import { FollowupActions } from "./followup-actions";
import { HTML } from "../../../html";

const { PRIMARY_TEXT } = styles;

export const Followup = (props) => {
  const {
    followup,
    followUpOn = false,
    hideAssignee = false,
    hideContact = false,
    hideOpportunity = false,
    hideAccount = false,
    hideVendor = false,
  } = props;

  const accounts = useAccounts();
  const contacts = useContacts();
  const opportunities = useOpportunities();
  const users = useUsers();
  const vendors = useVendors();

  const account = accounts.byId(followup.accountId);
  const assignee = users.byId(followup.assigneeId);
  const contact = contacts.byId(followup.contactId);
  const opportunity = opportunities.byId(followup.opportunityId);
  const vendor = vendors.byId(followup.vendorId);

  return (
    <div
      style={{
        alignItems: "flex-start",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", marginBottom: "16px", width: "100%" }}>
        <div>
          {followUpOn && (
            <strong style={{ marginRight: "16px" }}>Follow up on</strong>
          )}
          <FollowupDate date={followup.dueOn} />
        </div>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <span style={{ ...PRIMARY_TEXT }}>
          <HTML content={followup.task} />
        </span>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "8px",
        }}
      >
        {assignee && !hideAssignee && (
          <Metadata Icon={UserOutlined} value={assignee.alias} />
        )}
        {opportunity && !hideOpportunity && (
          <Metadata
            Icon={RocketOutlined}
            value={
              <Link
                link={`/opportunities/${opportunity._id}`}
                text={opportunity.alias}
              />
            }
          />
        )}
        {account && !hideAccount && (
          <Metadata
            Icon={RadarChartOutlined}
            value={
              <Link link={`/accounts/${account._id}`} text={account.name} />
            }
          />
        )}
        {vendor && !hideVendor && (
          <Metadata
            Icon={GlobalOutlined}
            value={<Link link={`/partners/${vendor._id}`} text={vendor.name} />}
          />
        )}
        {contact && !hideContact && (
          <Metadata
            Icon={IdcardOutlined}
            value={
              <Link link={`/contacts/${contact._id}`} text={contact.alias} />
            }
          />
        )}
      </div>
      <FollowupActions followup={followup} />
    </div>
  );
};
