import { Form, Input } from "antd";

import { useOnChange } from "hooks/use-on-change";

import { useCreateActivityType } from "data/use-activity-types";
import { useUpdateActivityType } from "data/use-activity-types";
import { Modal } from "components/modal";

const onSubmit =
  ({ mutation, activityType }) =>
  (values) => {
    if (activityType) {
      mutation.mutate({
        id: activityType._id,
        ...values,
      });
    }

    if (!activityType) {
      mutation.mutate(values);
    }
  };

export const ActivityTypeModal = ({ activityType, close, isOpen }) => {
  const createActivityType = useCreateActivityType({ onSuccess: close });
  const updateActivityType = useUpdateActivityType({ onSuccess: close });

  const mutation = activityType ? updateActivityType : createActivityType;

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      if (activityType) {
        form.setFieldsValue({
          label: activityType.label,
          description: activityType.description,
        });
      } else {
        form.setFieldsValue({
          label: "",
          description: "",
        });
      }
    },
    [isOpen, form, activityType]
  );

  return (
    <Modal
      confirmLoading={mutation.isLoading}
      okText={activityType ? "Update" : "Create"}
      onCancel={close}
      onOk={form.submit}
      title={`${activityType ? "Update" : "Create"} Activity Type`}
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({ mutation, activityType })}
      >
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
