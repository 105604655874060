import { isArray, select } from "radash";

export const selectEntities = (store, ids) => {
  ids = isArray(ids) ? ids : [ids];

  return select(
    ids,
    (id) => store.byId(id),
    (id) => !!store.byId(id)
  );
};
