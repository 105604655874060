import equals from "lodash/isEqual";
import { Button, Spin } from "antd";
import { useState } from "react";

import { styles } from "@evolved/constants";

import { fetcher } from "data/fetcher";
import { getDefaultActiveUsers } from "./get-default-active-users";
import { Price } from "./price";
import { SelectUsers } from "./select-users";
import { useNotification } from "contexts/notification/use-notification";
import { useUpdateSubscriptionActiveUsers } from "../../../../data/use-organization";

const { CLICKABLE_STYLE } = styles;

const onCustomerPortalClick =
  ({ notification, setLoading }) =>
  async () => {
    setLoading(true);

    try {
      const { url } = await fetcher.post("/integrations/stripe/portal-session")(
        {}
      );

      window.location.href = url;
    } catch (err) {
      notification.support(err);
    }

    setLoading(false);
  };

export const UpdateSubscription = (props) => {
  const { organization, users } = props;

  const [activeUsers, setActiveUsers] = useState(
    getDefaultActiveUsers({ organization, users })
  );
  const updateSubscriptionActiveUsers = useUpdateSubscriptionActiveUsers();

  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const onUpdateLicenseQuantityClick = () => {
    updateSubscriptionActiveUsers.mutate({ activeUsers });
  };

  return (
    <>
      <SelectUsers checked={activeUsers} onChange={setActiveUsers} />
      <Price
        users={users.filter(({ _id }) => activeUsers.includes(Number(_id)))}
      />
      <div
        style={{ alignItems: "center", display: "flex", flexDirection: "row" }}
      >
        <Button
          ghost
          onClick={onUpdateLicenseQuantityClick}
          style={{ marginRight: "auto" }}
          type="primary"
          disabled={equals(
            organization?.subscriptionState?.activeUsers,
            activeUsers
          )}
        >
          Update License Quantity
        </Button>
        <Spin spinning={loading}>
          <span
            onClick={onCustomerPortalClick({
              notification,
              setLoading,
            })}
            style={CLICKABLE_STYLE}
          >
            {organization?.subscriptionState?.cancelAtPeriodEnd
              ? "Update and Renew Subscription"
              : "Update or Cancel Subscription"}
          </span>
        </Spin>
      </div>
    </>
  );
};
