import { fieldTypes, viewTypes } from "@evolved/constants";
import {
  accountFields,
  contactFields,
  opportunityFields,
  vendorFields,
} from "@evolved/views";

const systemFields = {
  [viewTypes.ACCOUNT]: accountFields.COLLECTION,
  [viewTypes.CONTACT]: contactFields.COLLECTION,
  [viewTypes.OPPORTUNITY]: opportunityFields.COLLECTION,
  [viewTypes.VENDOR]: vendorFields.COLLECTION,
};

export const getCalculatedFieldOptions = ({
  userDefinedFields,
  type,
  excludeSystemFields,
}) => {
  return [
    ...userDefinedFields
      .filter((udf) => {
        return udf.dataType === fieldTypes.NUMBER && type === udf.type;
      })
      .map(({ name, id }) => ({
        label: name,
        value: `userDefinedFields.${id}`,
      })),
    ...((!excludeSystemFields && systemFields[type]) || [])
      .filter(({ type }) => type === fieldTypes.DOLLAR)
      .map(({ dataIndex, title }) => ({ label: title, value: dataIndex })),
  ];
};
