import { ArchiveProduct } from "./archive";
import { BackButton } from "components/back-button";
import { DisplayArchived } from "components/display-archived";
import { EditButton } from "components/edit-button";
import { ProductDetailsModal } from "components/modals/product-details-modal";
import { UnarchiveProduct } from "./unarchive";
import { useModal } from "hooks/use-modal";
import { withManagerRole } from "components/with-role";

const style = {
  container: { alignItems: "center", display: "flex" },
  button: { marginLeft: "auto" },
  header: { margin: "0px" },
};

const EditProduct = withManagerRole((props) => {
  const { product } = props;
  const { modal, open } = useModal(ProductDetailsModal);

  return (
    <>
      {modal}
      <EditButton
        onClick={() => open({ product })}
        style={{ marginLeft: "auto", marginRight: "8px" }}
      />
    </>
  );
});

export const Header = (props) => {
  const { product } = props;

  return (
    <>
      <div style={style.container}>
        <BackButton default={"/products"} />
        <h2 style={style.header}>{product.name}</h2>
        <DisplayArchived className={"ml-4"} entity={product} />
        <EditProduct product={product} />
        {!product.isArchived ? (
          <ArchiveProduct product={product} />
        ) : (
          <UnarchiveProduct product={product} />
        )}
      </div>
    </>
  );
};
