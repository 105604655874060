import { CheckOutlined } from "@ant-design/icons";

export const BodyHeader = (props) => {
  const { isComplete, isCurrent, title } = props;

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        marginBottom: "12px",
      }}
    >
      <h2
        style={{
          ...(!isCurrent ? { color: "rgb(192,192,192)" } : {}),
          margin: "0px",
        }}
      >
        {title}
      </h2>
      {isComplete ? (
        <CheckOutlined
          style={{
            color: "#3ad48c",
            fontSize: "18px",
            marginLeft: "4px",
            opacity: "0.75",
          }}
        />
      ) : null}
    </div>
  );
};
