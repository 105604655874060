// todo: update to dayjs, pay attention, the format is different
// https://day.js.org/docs/en/display/format#docsNav
import moment from "moment";

const DETAILED_FORMAT = "MMM DD Y, h:mm A";
const LONG_FORMAT = "MMM DD Y";
const LONG_FORMAT_WITH_TIME = "MMM DD Y, h:mm A";
const SHORT_FORMAT = "MMM DD";
const SHORT_WITH_TIME_FORMAT = "MMM DD, h:mm";
const WEEKDAY = "dddd, MMM Do, YYYY";

export const detailed = (timestamp) =>
  moment.unix(timestamp).format(DETAILED_FORMAT);
export const long = (timestamp) => moment.unix(timestamp).format(LONG_FORMAT);
export const longWithTime = (timestamp) =>
  moment.unix(timestamp).format(LONG_FORMAT_WITH_TIME);
export const weekday = (timestamp) => moment.unix(timestamp).format(WEEKDAY);
export const short = (timestamp) => moment.unix(timestamp).format(SHORT_FORMAT);
export const shortWithTime = (timestamp) =>
  moment.unix(timestamp).format(SHORT_WITH_TIME_FORMAT);
