import has from "lodash/has";
import isPlainObject from "lodash/isPlainObject";

import { useState } from "react";

export const useModal = (BaseModal, validate = () => true) => {
  const [state, setState] = useState({
    isOpen: false,
    payload: {},
  });

  const close = () => {
    setState({
      isOpen: false,
      payload: state.payload,
    });

    setTimeout(() => {
      setState({ payload: {} });
    }, 500);
  };

  const open = (payload) => {
    const isOpen = true;

    if (!isPlainObject(payload) || has(payload, "nativeEvent")) {
      payload = {};
    }

    setState({
      payload: {
        ...payload,
      },
      isOpen,
    });
  };

  const modal = validate(state.payload) ? (
    <BaseModal close={close} isOpen={state.isOpen} {...state.payload} />
  ) : null;

  return {
    close,
    isOpen: state.isOpen,
    modal,
    open,
  };
};
