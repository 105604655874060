import ClusterOutlined from "@ant-design/icons/ClusterOutlined";
import get from "lodash/get";
import { forwardRef } from "react";

import { getPartnersLabel } from "@evolved/labels";

import { Spotlight } from "../../spotlight";
import { BodyHeader } from "../body-header";
import { BodyIcon } from "../body-icon";
import { BodyText } from "../body-text";
import { Box } from "../box";
import { Step } from "../guide";
import { useFlag } from "hooks/use-flag";
import { useOnExists } from "../use-on-exists";

const Guide = (props) => {
  const { guide, isComplete, organization, patchSetupState } = props;

  const hasProcore = useFlag("integrations:procore");

  const steps = [
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onClose={guide.stop}>
            Click here to see your organization settings.
          </Step>
        ),
        placement: "right",
      },
      elementId: "organization-menu-item",
    },
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onNext={guide.forward}>
            Here is where all of your general organization settings can be
            found.
          </Step>
        ),
        placement: "right",
      },
      elementId: "general-settings-tab",
    },
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onBack={guide.back} onNext={guide.forward}>
            Here you can add custom text or select fields for Accounts,
            Contacts, Opportunities, or {getPartnersLabel(organization)}.
          </Step>
        ),
        placement: "right",
      },
      elementId: "custom-fields-settings-tab",
    },
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onBack={guide.back} onNext={guide.forward}>
            Your activity stream tracks your progress along your sales process
            towards winning opportunities. Customize it here.
          </Step>
        ),
        placement: "right",
      },
      elementId: "activity-stream-settings-tab",
    },
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onBack={guide.back} onNext={guide.forward}>
            Customize labels throughout the application, to tailor your
            experience.
          </Step>
        ),
        placement: "right",
      },
      elementId: "labels-settings-tab",
    },
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onBack={guide.back} onNext={guide.forward}>
            Manage your tags here to help organize your data.
          </Step>
        ),
        placement: "right",
      },
      elementId: "tags-settings-tab",
    },
    ...(hasProcore
      ? [
          {
            tooltip: {
              content: (
                <Step
                  onClose={guide.stop}
                  onBack={guide.back}
                  onNext={guide.forward}
                >
                  Manage your organization wide integrations here.
                </Step>
              ),
              placement: "right",
            },
            elementId: "integrations-settings-tab",
          },
        ]
      : []),
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onBack={guide.back} onNext={guide.forward}>
            Customize what sales reps with different roles can access.
          </Step>
        ),
        placement: "right",
      },
      elementId: "permissions-settings-tab",
    },
    {
      tooltip: {
        content: (
          <Step onClose={guide.stop} onBack={guide.back} onNext={guide.forward}>
            Here you can manage the users allowed access to your organization.
          </Step>
        ),
        placement: "right",
      },
      elementId: "users-settings-tab",
    },
    {
      tooltip: {
        content: (
          <Step
            onClose={guide.stop}
            onBack={guide.back}
            onNext={() => {
              if (!isComplete) {
                patchSetupState.mutate({
                  isOrganizationStepComplete: true,
                });
              }

              guide.complete();
            }}
            nextText={"Got it"}
          >
            Manage your billing settings here to make sure your service is not
            interrupted.
          </Step>
        ),
        placement: "right",
      },
      elementId: "billing-settings-tab",
    },
  ];

  useOnExists(guide.forward, guide.index === 0 ? "general-settings-tab" : null);

  const step = steps[guide.index];

  if (!step) {
    return null;
  }

  return (
    <Spotlight
      allowClicks={step.allowClicks}
      elementId={step.elementId}
      tooltip={step.tooltip}
    />
  );
};

const HeroButton = (props, ref) => {
  const { isComplete, isCurrent, onClick } = props;

  const bodyText = (
    <>
      Learn how to configure your organization settings to customize your
      team&apos;s experience.
    </>
  );

  return (
    <Box
      isComplete={isComplete}
      isCurrent={isCurrent}
      onClick={onClick}
      ref={ref}
    >
      <BodyIcon
        Icon={ClusterOutlined}
        isComplete={isComplete}
        isCurrent={isCurrent}
      />
      <BodyHeader
        isComplete={isComplete}
        isCurrent={isCurrent}
        title={"Organization"}
      />
      <BodyText isComplete={isComplete} isCurrent={isCurrent}>
        {bodyText}
      </BodyText>
    </Box>
  );
};

const getStartIndex = () => {
  if ((window.location.href || "").includes("/organization")) {
    return 1;
  }

  return 0;
};

export const organization = {
  Guide,
  HeroButton: forwardRef(HeroButton),

  getStartIndex,
  isComplete: ({ organization }) =>
    get(organization, "setupState.isOrganizationStepComplete"),
  type: "organization",
};
