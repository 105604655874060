import { Active } from "./active";
import { Canceled } from "./canceled";
import { Incomplete } from "./incomplete";
import { IncompleteExpired } from "./incomplete_expired";
import { None } from "./none";
import { PastDue } from "./past-due";
import { Trialing } from "./trialing";
import { Unpaid } from "./unpaid";

export const states = {
  active: Active,
  canceled: Canceled,
  incomplete: Incomplete,
  incomplete_expired: IncompleteExpired,
  none: None,
  past_due: PastDue,
  trialing: Trialing,
  unpaid: Unpaid,
};
