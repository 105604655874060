import { useAuth } from "@clerk/clerk-react";
import { useQueryClient } from "@tanstack/react-query";
import produce from "immer";
import create from "zustand";

import { useLayoutStore } from "./layout";

export const useAuthenticationStore = create((set) => ({
  inProgress: false,
  redirect: null,
  session: null,
  clearRedirect: () =>
    set(
      produce((state) => {
        state.redirect = null;
      })
    ),
  setRedirect: (redirect) =>
    set(
      produce((state) => {
        if (redirect && redirect !== "/") {
          state.redirect = redirect;
        }
      })
    ),
  setSession: (session) =>
    set(
      produce((state) => {
        state.session = session;
      })
    ),
  start: () =>
    set(
      produce((state) => {
        state.inProgress = true;
      })
    ),
  stop: () =>
    set(
      produce((state) => {
        state.inProgress = false;
      })
    ),
}));

export const useLogOut = () => {
  const { signOut } = useAuth();
  const client = useQueryClient();

  return async () => {
    const { inProgress, start, stop } = useAuthenticationStore.getState();

    if (inProgress) {
      return;
    }

    start();

    await signOut();

    useLayoutStore.getState().reset();
    client.clear();

    stop();
  };
};
