import { Button, Drawer, Popconfirm, Progress } from "antd";
import { createRef, useEffect, useRef, useState } from "react";
import {
  CompassOutlined,
  LeftCircleFilled,
  RightCircleFilled,
} from "@ant-design/icons";

import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import times from "lodash/times";

import { styles } from "@evolved/constants";

import { FinalStep } from "./final-step";
import { ShouldGuideQuestion } from "./should-guide-question";
import { steps as allSteps } from "./steps";
import { useAccounts } from "../../data/use-accounts";
import { useContacts } from "../../data/use-contacts";
import { useGuideState } from "./guide";
import { useOpportunities } from "../../data/use-opportunities";
import { useProducts } from "../../data/use-products";
import { useSalesProcesses } from "../../data/use-sales-processes";
import { useVendors } from "../../data/use-vendors";
import { withManagerRole } from "../with-role";
import {
  useOrganization,
  usePatchOrganizationSetupState,
} from "../../data/use-organization";

const { CLICKABLE_STYLE } = styles;

const parseSetupCompletedRatio = (steps) => (props) => {
  const total = steps.length;

  const completedCount = steps.reduce((accumulator, step) => {
    if (step.isComplete(props)) {
      return accumulator + 1;
    }

    return accumulator;
  }, 0);

  return [total, (completedCount / total) * 100];
};

const useAutoOpen = ({ completedPercent, isComplete, setIsVisible }) => {
  const opened = useRef(false);

  useEffect(() => {
    if (!isComplete && completedPercent === 0 && !opened.current) {
      opened.current = true;
      setIsVisible(true);
    }
  }, [isComplete, opened, setIsVisible]);
};

export const SetupWizard = withManagerRole(() => {
  const [isVisible, setIsVisible] = useState(false);

  const showSetupWizard = () => setIsVisible(true);
  const hideSetupWizard = () => setIsVisible(false);

  const organization = useOrganization();

  const accounts = useAccounts().all();
  const contacts = useContacts().all();
  const opportunities = useOpportunities().all();
  const products = useProducts().all();
  const salesProcesses = useSalesProcesses().all();
  const vendors = useVendors().all();

  const data = {
    accounts,
    contacts,
    opportunities,
    organization,
    products,
    salesProcesses,
    vendors,
  };

  const patchSetupState = usePatchOrganizationSetupState();

  const { setupState = {} } = organization;

  const steps = organization?.settings?.showPartners
    ? allSteps
    : allSteps.filter(({ type }) => type !== "vendor");

  const containerRef = useRef();
  const heroButtonRefs = useRef(times(steps.length, createRef));
  const finalStepRef = useRef();

  const guide = useGuideState({
    showSetupWizard,
    hideSetupWizard,
  });

  const currentStepIndex = findIndex(steps, (step) => {
    return !step.isComplete(data);
  });

  const [totalStepCount, completedPercent] =
    parseSetupCompletedRatio(steps)(data);

  useAutoOpen({
    completedPercent,
    isComplete: setupState.isComplete,
    setIsVisible,
  });

  const isGuideQuestionStep = !setupState.isGreeted;
  const isFinalStep = completedPercent === 100;

  useEffect(() => {
    const stepRef = isFinalStep
      ? finalStepRef
      : heroButtonRefs.current[currentStepIndex];

    if (
      containerRef &&
      containerRef.current &&
      stepRef &&
      stepRef.current &&
      isVisible
    ) {
      // scroll into view once the step is fully in the container, after the slide up animation
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0]?.isIntersecting) {
            stepRef.current.scrollIntoView({
              inline: "center",
              behavior: "smooth",
            });

            observer.disconnect();
          }
        },
        { threshold: 1.0 }
      );

      observer.observe(containerRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [currentStepIndex, isFinalStep, isVisible]);

  let contentComponent = null;
  let guideComponent = null;

  const onComplete = () => {
    setIsVisible(false);

    setTimeout(() => {
      patchSetupState.mutate({
        isComplete: true,
        isGreeted: true,
      });
    }, 300);
  };

  if (get(organization, "setupState.isComplete")) {
    return null;
  }

  if (isGuideQuestionStep) {
    contentComponent = (
      <ShouldGuideQuestion
        onNo={onComplete}
        onYes={() => {
          patchSetupState.mutate({
            isComplete: false,
            isGreeted: true,
          });
        }}
      />
    );
  } else {
    const activeGuide =
      guide.activeType && find(steps, { type: guide.activeType });

    guideComponent = activeGuide && (
      <activeGuide.Guide
        guide={guide}
        hideSetupWizard={hideSetupWizard}
        isComplete={activeGuide.isComplete(data)}
        organization={organization}
        patchSetupState={patchSetupState}
        showSetupWizard={showSetupWizard}
        {...(activeGuide.mapData && activeGuide.mapData(data))}
      />
    );

    contentComponent = (
      <>
        <LeftCircleFilled
          className="md-larger-on-hover transition"
          onClick={() =>
            containerRef.current.scrollBy({
              left: -425,
              behavior: "smooth",
            })
          }
          style={{
            cursor: "pointer",
            fontSize: "42px",
            left: "4px",
            opacity: "0.6",
            padding: "0px, 8px",
            position: "absolute",
            top: "50%",
            zIndex: 9999,
          }}
        />
        <div
          ref={containerRef}
          style={{
            display: "flex",
            flexDirection: "row",
            overflowX: "scroll",
            padding: "24px",
          }}
        >
          {steps.map((step, index) => (
            <step.HeroButton
              isComplete={step.isComplete(data)}
              isCurrent={currentStepIndex === index}
              key={step.type}
              onClick={() => {
                guide.start(step);
              }}
              organization={organization}
              ref={heroButtonRefs.current[index]}
              {...(step.mapData && step.mapData(data))}
            />
          ))}
          {isFinalStep && <FinalStep onOk={onComplete} ref={finalStepRef} />}
          <div style={{ padding: "0.5px" }} />
        </div>
        <RightCircleFilled
          className="md-larger-on-hover transition"
          onClick={() =>
            containerRef.current.scrollBy({
              left: 425,
              behavior: "smooth",
            })
          }
          style={{
            cursor: "pointer",
            fontSize: "42px",
            right: "4px",
            opacity: "0.6",
            padding: "0px, 8px",
            position: "absolute",
            top: "50%",
            zIndex: 9999,
          }}
        />
      </>
    );
  }

  return (
    <>
      <Button
        id="setup-wizard-button"
        className="mr-2"
        onClick={() => setIsVisible(!isVisible)}
        type="default"
        ghost
        icon={<CompassOutlined />}
      >
        Setup Guide
      </Button>
      <Drawer
        styles={{
          body: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: "0px",
          },
        }}
        height={450}
        onClose={() => setIsVisible(false)}
        mask={false}
        placement="bottom"
        title={
          <div style={{ alignItems: "center", display: "flex" }}>
            <div style={{ marginRight: "16px" }}>Setup Guide</div>
            {!isGuideQuestionStep && (
              <>
                <Progress
                  percent={Math.round(completedPercent)}
                  steps={totalStepCount}
                  strokeColor={"rgba(143, 75, 230, 0.75"}
                  strokeWidth={16}
                />
                <Popconfirm
                  onConfirm={onComplete}
                  overlayStyle={{ maxWidth: "450px" }}
                  title={
                    "Are you sure? The guide will not be accessible again."
                  }
                >
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "32px",
                      ...CLICKABLE_STYLE,
                    }}
                  >
                    I&apos;m good to go, please hide the guide
                  </div>
                </Popconfirm>
              </>
            )}
          </div>
        }
        open={isVisible}
      >
        {contentComponent}
      </Drawer>
      {guideComponent}
    </>
  );
});
