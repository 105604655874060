import get from "lodash/get";
import { useEffect, useRef } from "react";

export const useFocus = (trigger) => {
  const ref = useRef(null);

  useEffect(() => {
    if (get(ref, "current.focus")) ref.current.focus();
    if (get(ref, "current.input.focus")) ref.current.input.focus();
  }, [trigger]);

  return ref;
};
