import { useQuery, useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";
import { root as activitiesRoot } from "./use-activities.js";
import { root as contactsRoot } from "./use-contacts.js";
import { root as filesRoot } from "./use-files.js";
import { root as followupsRoot } from "./use-followups.js";
import { root as opportunitiesRoot } from "./use-opportunities";
import { root as vendorsRoot } from "./use-vendors.js";

export const root = "/account";

export const useAccounts = buildUseEntities(root);

export const useCreateAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(root)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
      },
    },
    overrides
  );
};

export const useCreateAccountBatch = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/import`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
      },
    },
    overrides
  );
};

export const useAccount = ({ _id }) => {
  const { data } = useQuery({
    queryFn: fetcher.get(`/account/${_id}`),
    queryKey: [root, _id],
  });

  return data || [];
};

export const useAccountCounts = ({ _id }) => {
  const { data } = useQuery({
    queryFn: fetcher.get(`/account/${_id}/counts`),
    queryKey: [root, _id, "counts"],
  });

  return data;
};

export const useMergeAccounts = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/merge`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
      },
    },
    overrides
  );
};

export const usePatchAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.patch(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetAccountContacts = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/contacts`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
      },
    },
    overrides
  );
};

export const useSetAccountTags = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/tags`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetAccountUsers = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}/users`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useTagAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/tag`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUntagAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/untag`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchiveAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/archive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [activitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
        await queryClient.invalidateQueries({ queryKey: [opportunitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUnarchiveAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/unarchive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useDeleteAccount = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id }) => {
        return fetcher.del(`${root}/${id}`)();
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [activitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
        await queryClient.invalidateQueries({ queryKey: [opportunitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
        await queryClient.invalidateQueries({ queryKey: [filesRoot] });
      },
    },
    overrides
  );
};
