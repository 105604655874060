import get from "lodash/get";
import pick from "lodash/pick";

import { DatePicker, Form, Input } from "antd";

import { fromUnixDate, toUnixDate } from "utils/unix-date";
import { useUpdateFollowup } from "../../data/use-followups";
import { useOnChange } from "../../hooks/use-on-change";
import { UPDATE_FOLLOWUP_MODAL, useLayoutStore } from "../../stores/layout";
import { UserSelect } from "../selects/user-select";
import { Modal } from "components/modal";

const getTypes = (followup) => {
  const types = [];

  if (followup.accountId) types.push("ACCOUNT");
  if (followup.contactId) types.push("CONTACT");
  if (followup.opportunityId) types.push("OPPORTUNITY");
  if (followup.vendorId) types.push("VENDOR");

  return types;
};

export const UpdateFollowupModal = () => {
  const layoutState = useLayoutStore((state) => state[UPDATE_FOLLOWUP_MODAL]);
  const close = () => useLayoutStore.getState().close(UPDATE_FOLLOWUP_MODAL);

  const { followup } = layoutState.payload;

  const updateFollowup = useUpdateFollowup();

  const [form] = Form.useForm();

  useOnChange(
    layoutState.isOpen,
    () => {
      if (!layoutState.isOpen) {
        return;
      }

      form.setFieldsValue({
        ...pick(followup, ["task"]),
        ...fromUnixDate(followup, "dueOn"),
        assigneeId: followup.assigneeId || get(followup, "assignee._id"),
      });
    },
    [layoutState.isOpen, form, followup]
  );

  const onFinish = (values) => {
    updateFollowup.mutate(
      {
        id: followup._id,
        ...values,
        ...toUnixDate(values, "dueOn"),
        types: getTypes(followup),
      },
      {
        onSuccess: close,
      }
    );
  };

  return (
    <Modal
      confirmLoading={updateFollowup.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      open={layoutState.isOpen}
      title={"Update Followup"}
    >
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
      >
        <Form.Item label="Due On" name="dueOn" rules={[{ required: true }]}>
          <DatePicker allowClear={false} format={"YYYY-MM-DD HH:mm"} showTime />
        </Form.Item>
        <UserSelect
          label="Assign To"
          name="assigneeId"
          rules={[{ required: true }]}
        />
        <Form.Item label="Task" name="task" rules={[{ required: true }]}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
