import { Button, Row } from "antd";
import { useState } from "react";
import get from "lodash/get";

import { InfoCard } from "components/info-card";
import { fetcher } from "data/fetcher";
import { useNotification } from "contexts/notification/use-notification";
import { useOrganization } from "data/use-organization";
import { useHubSpotLogout } from "data/use-integrations";

export const HubspotConfig = () => {
  const notification = useNotification();
  const organization = useOrganization();

  const { isConfigured, isExpired } =
    get(organization, "integrations.hubspot") || {};

  const [isLoading, setIsLoading] = useState();

  const authenticate = async () => {
    setIsLoading(true);

    try {
      const { url } = await fetcher.get("/integrations/hubspot/oauth")();
      window.location.href = url;
    } catch (err) {
      notification.support(err);
    }

    setIsLoading(false);
  };

  const logout = useHubSpotLogout();

  return (
    <Row gutter={16} id="nylas-config-card" type="flex">
      <InfoCard
        content={
          <>
            {isExpired ? (
              <>
                <div style={{ margin: "16px 0px" }}>
                  Your HubSpot integration authentication has expired. Please
                  log in again to reconnect and reach out to support if this
                  persists.
                </div>
                <Button
                  id="procore-config-connect-button"
                  loading={isLoading}
                  onClick={authenticate}
                  type="primary"
                >
                  Reconnect
                </Button>
              </>
            ) : isConfigured ? (
              <>
                <div style={{ margin: "16px 0px" }}>
                  HubSpot has been successfully configured.
                </div>
                <Button
                  id="hubspot-disconnect-button"
                  loading={logout.isLoading}
                  onClick={() => logout.mutate()}
                >
                  Disconnect
                </Button>
              </>
            ) : (
              <>
                <div style={{ margin: "16px 0px" }}>
                  Sign in to integrate your HubSpot account with CRM.
                </div>
                <Button
                  id="procore-config-connect-button"
                  loading={isLoading}
                  onClick={authenticate}
                  type="primary"
                >
                  Connect
                </Button>
              </>
            )}
          </>
        }
        label={
          <div>
            <span style={{ fontSize: "16px" }}>HubSpot Integration</span>
          </div>
        }
      />
    </Row>
  );
};
