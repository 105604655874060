import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, Tag } from "antd";
import { useEffect, useRef, useState } from "react";

export const EditableTag = (props) => {
  const {
    closeOnConfirm,
    closeOnEmpty,
    color,
    isDashed,
    label,
    onDelete,
    style,
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(props.value || "");

  const inputRef = useRef(null);
  const previousIncomingValue = useRef(null);
  const wasEditing = useRef(null);

  useEffect(() => {
    if (!wasEditing.current && isEditing) {
      inputRef.current.focus();
    }

    wasEditing.current = isEditing;
  });

  useEffect(() => {
    if (!isNil(props.value) && previousIncomingValue.current !== props.value) {
      previousIncomingValue.current = props.value;
      setValue(props.value);
    }
  }, [props.value]);

  const onConfirm = async () => {
    if (!isEmpty(value) && props.onConfirm) {
      await props.onConfirm(value, { reset: () => setValue(props.value) });
    }

    if (isEmpty(value) && closeOnEmpty) {
      await props.onConfirm(value, { reset: () => setValue(props.value) });
    }

    if (!props.value) {
      setValue("");
    }

    if (closeOnConfirm) {
      setIsEditing(false);
    }
  };

  if (isEditing) {
    return (
      <Input
        ref={inputRef}
        size="small"
        style={{ marginRight: "8px", width: 78 }}
        type="text"
        onBlur={() => {
          setIsEditing(false);
          setValue(props.value || "");
        }}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={onConfirm}
        value={value}
      />
    );
  }

  return (
    <Tag
      {...(color ? { color } : {})}
      {...(isDashed
        ? {
            className: "primary-hoverable-text-and-border",
            style: { background: "#fff", borderStyle: "dashed", ...style },
          }
        : { style: { cursor: "pointer", ...style } })}
      onClick={() => setIsEditing(true)}
    >
      {value ? (
        <>
          {value}
          {onDelete && (
            <CloseOutlined
              onClick={onDelete}
              style={{ fontSize: "10px", marginLeft: "4px", cursor: "pointer" }}
            />
          )}
        </>
      ) : (
        <>
          <PlusOutlined /> {label || "Tag"}
        </>
      )}
    </Tag>
  );
};
