import { isFunction } from "radash";

import { hasAccess } from "utils/has-access";
import { useMe } from "data/use-me";
import { useOrganization } from "data/use-organization";

export const withPermission = (permission) => (Component) => (props) => {
  const profile = useMe();
  const organization = useOrganization();

  if (!profile || !organization) {
    return null;
  }

  if (
    !hasAccess(profile)(organization)(
      isFunction(permission) ? permission(props) : permission
    )()
  ) {
    return null;
  }

  return <Component {...props} />;
};
