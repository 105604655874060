import { Switch } from "antd";

import {
  useOrganization,
  useSetSalesProcessEnforcement,
} from "data/use-organization";

export const ToggleProductSalesProcessEnforcement = () => {
  const organization = useOrganization();
  const setSalesProcessEnforcement = useSetSalesProcessEnforcement();

  return (
    <>
      <div style={{ display: "flex" }}>
        <strong>Enforce product sales process</strong>
        <Switch
          checked={organization.shouldEnforceProductSalesProcess}
          onChange={(shouldEnforce) => {
            setSalesProcessEnforcement.mutate({ shouldEnforce });
          }}
          style={{ marginLeft: "16px" }}
        />
      </div>
      <div style={{ fontSize: "12px", marginTop: "8px" }}>
        Enabling this will enforce the sales process for a given product for all
        opportunities on that product. To set a sales process, navigate to the
        product page and edit its details.
      </div>
    </>
  );
};
