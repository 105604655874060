import noop from "lodash/noop";
import { forwardRef } from "react";

import { Celebration } from "../image-placeholders";

import { Box } from "./box";

export const FinalStep = forwardRef((props, ref) => {
  const { onOk = noop } = props;

  return (
    <Box isComplete onClick={onOk} ref={ref}>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2 style={{ marginBottom: "24px" }}>You are all done!</h2>
        <h3 style={{ marginBottom: "16px" }}>
          Please reach out if you have any questions or if you think we can
          improve your daily workflow.
        </h3>
        <Celebration
          containerStyle={{
            fontSize: "1.1em",
            padding: "0px",
          }}
          secondary={"Let's go"}
        />
      </div>
    </Box>
  );
});
