import moment from "moment";

// example data:
//
// const scheduled = [
//   {
//     report: {
//       type: "canned | view",
//       id: "", // either the canned view key, or the id of the
//     },
//     period: "weekly",
//     every: 1, // 1 = every week, 2 = every second week
//     day: 1,
//     lastRanOn: "",
//     startOn: "",
//   },
//   {
//     period: "monthly",
//     lastRanOn: "",
//     startOn: "",
//   },
// ];

export const getNextRunOn = (scheduled) => {
  if (!scheduled.lastRanOn && !scheduled.startOn) {
    return null;
  }

  if (!scheduled.lastRanOn) {
    return moment.utc(scheduled.startOn).toDate();
  }

  const lastRanOn = moment.utc(scheduled.lastRanOn);

  if (scheduled.period === "weekly") {
    return lastRanOn
      .clone()
      .day(scheduled.day + scheduled.every * 7)
      .toDate();
  }

  return lastRanOn
    .clone()
    .add(1, "day")
    .endOf("month")
    .hour(lastRanOn.hour())
    .minute(lastRanOn.minute())
    .second(lastRanOn.second())
    .millisecond(lastRanOn.millisecond())
    .toDate();
};
