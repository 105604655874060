import { Select } from "antd";

import { dateFilters } from "@evolved/constants";

import { useFocus } from "./use-focus";
import { useVisible } from "./use-visible";

export const DateFilter = ({ config, defaultValue = null, onFilter }) => {
  const ref = useFocus(config.dataIndex);
  const [visible, setVisible] = useVisible(config.dataIndex);

  return (
    <Select
      onChange={(value) => onFilter({ dataIndex: config.dataIndex, value })}
      onDropdownVisibleChange={setVisible}
      open={visible}
      options={Object.keys(dateFilters.OPTIONS).map((value) => ({
        value,
        label: dateFilters.OPTIONS[value],
      }))}
      placeholder="Within..."
      ref={ref}
      size="small"
      style={{ width: "100%" }}
      value={defaultValue}
    />
  );
};
