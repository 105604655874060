import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import reduce from "lodash/reduce";

import { fieldTypes } from "@evolved/constants";
import { calculateRow } from "@evolved/views";

import { long } from "utils/format-date";
import { InfoCard } from "./info-card";

const { CALCULATED, DATE, NUMBER, SELECT, TEXT } = fieldTypes;

const FIELD_TYPE_MAP = {
  [CALCULATED]: (props) => {
    const { row, field, ...rest } = props;

    const result = calculateRow(row, field);
    return <InfoCard {...rest} content={result === null ? "--" : result} />;
  },
  [SELECT]: (props) => {
    const { field, value, ...rest } = props;

    if (value === "--") {
      return <InfoCard {...rest} content="--" />;
    }

    const option = find(field.options, (o) => o.id === value && !o.isArchived);

    return <InfoCard {...rest} content={option ? option.label : "--"} />;
  },
  [DATE]: (props) => {
    const { value, ...rest } = props;

    if (value === "--") {
      return <InfoCard {...rest} content="--" />;
    }

    return <InfoCard {...rest} content={value ? long(value) : "--"} />;
  },
  [NUMBER]: (props) => {
    const { value, ...rest } = props;

    return <InfoCard {...rest} content={isFinite(value) ? value : "--"} />
  },
  [TEXT]: (props) => {
    const { value, ...rest } = props;

    return <InfoCard {...rest} content={value} />;
  },
};

export const UserDefinedFields = (props) => {
  const { data, organization, type } = props;

  return reduce(
    get(organization, "userDefinedFields"),
    (accumulator, field) => {
      const { dataType, id, name, hideFromDetailsPage } = field;

      if (hideFromDetailsPage) return accumulator;
      if (type !== field.type) return accumulator;

      const value = get(data, ["userDefinedFields", id]);

      if (isEmpty(field)) return accumulator;

      const Field = FIELD_TYPE_MAP[dataType];

      if (!Field) return accumulator;

      accumulator.push(
        <Field
          field={field}
          key={id}
          label={name}
          row={data}
          value={isNil(value) || value === "" ? "--" : value}
        />
      );

      return accumulator;
    },
    []
  );
};
