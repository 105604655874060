import { Popconfirm } from "antd";

import { styles } from "@evolved/constants";

import { useArchiveFollowup } from "../../../../data/use-followups";
import { performActivityOnFollowup } from "../../../modals/perform-activity/perform-activity-on-followup";

import {
  UPDATE_FOLLOWUP_MODAL,
  useLayoutStore,
} from "../../../../stores/layout";

const { CLICKABLE_STYLE } = styles;

const getTypes = (followup) => {
  const types = [];

  if (followup.accountId) types.push("ACCOUNT");
  if (followup.contactId) types.push("CONTACT");
  if (followup.opportunityId) types.push("OPPORTUNITY");
  if (followup.vendorId) types.push("VENDOR");

  return types;
};

export const FollowupActions = (props) => {
  const { followup } = props;

  const archiveFollowup = useArchiveFollowup();

  return (
    <div style={{ display: "flex" }}>
      <div
        onClick={() => performActivityOnFollowup(followup)}
        style={CLICKABLE_STYLE}
      >
        Act
      </div>
      <div style={{ margin: "0px 8px" }}>|</div>
      <div
        onClick={() =>
          useLayoutStore.getState().open(UPDATE_FOLLOWUP_MODAL, { followup })
        }
        style={CLICKABLE_STYLE}
      >
        Edit
      </div>
      <div style={{ margin: "0px 8px" }}>|</div>
      <Popconfirm
        cancelText="Cancel"
        okText="Yes"
        onConfirm={() =>
          archiveFollowup.mutateAsync({
            id: followup._id,
            types: getTypes(followup),
          })
        }
        title="Are you sure?"
      >
        <div style={CLICKABLE_STYLE}>Clear</div>
      </Popconfirm>
    </div>
  );
};
