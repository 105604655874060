import { select } from "radash";

import { useLossReasons } from "data/use-loss-reasons";

export const LossReasonsSet = (props) => {
  const { value } = props;

  const lossReasons = useLossReasons();

  return select(
    value,
    (id) => lossReasons.byId(id).label,
    (id) => !!lossReasons.byId(id)
  ).join(", ");
};
