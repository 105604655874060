import { Tag } from "antd";
import { select } from "radash";

import { useOpportunities } from "../../../../../data/use-opportunities";
import { Link } from "../../../../link";

export const OpportunitiesSet = (props) => {
  const { value } = props;

  const opportunities = useOpportunities();

  const selected = select(
    value,
    (id) => opportunities.byId(id),
    (id) => !!opportunities.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((opportunity) => {
    return (
      <Link link={`/opportunities/${opportunity._id}`} key={opportunity._id}>
        <Tag style={opportunity.isArchived ? { opacity: 0.5 } : {}}>
          {opportunity.alias}
        </Tag>
      </Link>
    );
  });
};
