import { DisplayDate } from "../display-date";
import { UpdateSubscription } from "../subscription";

export const Active = ({ organization, users }) => {
  const { cancelAtPeriodEnd, subscribedPeriodEnd } =
    organization.subscriptionState;

  const summary = cancelAtPeriodEnd ? (
    <>
      Your subscription has been canceled but will remain active until{" "}
      <DisplayDate date={subscribedPeriodEnd} />.
    </>
  ) : (
    <>
      Your subscription is active and will renew on{" "}
      <DisplayDate date={subscribedPeriodEnd} />.
    </>
  );
  return (
    <>
      <div style={{ marginBottom: "16px" }}>{summary}</div>
      <UpdateSubscription organization={organization} users={users} />
    </>
  );
};
