import { CoffeeOutlined, RocketOutlined } from "@ant-design/icons";
import { Statistic } from "antd";
import { Fade } from "react-awesome-reveal";
import CountUp from "react-countup";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import sumBy from "lodash/sumBy";

import { opportunityStates } from "@evolved/constants";

import { ActivityCountPie } from "./activity-count-pie";
import { OpportunitiesBarChart } from "./opportunities-bar-chart";
import { WidgetHeader } from "./widget-header";
import {
  useActivityCounts,
  useOpportunityCountsBy,
} from "../../data/use-reports";
import { useOpportunityStates } from "../../data/use-opportunity-states";
import { useOrganization } from "../../data/use-organization";
import { RangeSelect } from "./range-select";
import { WidgetContainer } from "./widget-container";
import { convertRange } from "./convert-range";

const { WON } = opportunityStates;

const countFormatter = (value) => <CountUp end={value} separator="," />;

const Component = (props) => {
  const organization = useOrganization();
  const { widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets } =
    props.dateRange;

  const activityCounts = useActivityCounts(convertRange(widgetFilter));
  const opportunityCounts = useOpportunityCountsBy(
    "stateId",
    convertRange(widgetFilter)
  );

  const opportunityStates = useOpportunityStates().all();

  const totalActivities = sumBy(activityCounts, "count");

  const totalOpportunities = (opportunityCounts ?? []).reduce((acc, { stateId, count }) => {
    if (organization.settings?.winRateOpportunityStateIds) {
      const opportunityState = opportunityStates.find(({ _id }) => _id === stateId);

      if (["WON", "LOST"].includes(opportunityState?.systemKey)) {
        return acc + count;
      }

      if (organization.settings?.winRateOpportunityStateIds.includes(stateId)) {
        return acc + count;
      }

      return acc;
    }

    return acc + count;
  }, 0);

  const wonState = find(opportunityStates, { systemKey: WON });

  const won = (
    find(opportunityCounts, { stateId: Number(wonState._id) }) || { count: 0 }
  ).count;

  const closedRate =
    totalOpportunities === 0 ? 0 : Math.round((won / totalOpportunities) * 100);

  return (
    <Fade>
      <div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "24px",
          }}
        >
          <WidgetHeader label="Company Totals" />
          <RangeSelect
            tenses={["past", "all-time"]}
            {...{ widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets }}
          />
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <Statistic
            formatter={countFormatter}
            prefix={<CoffeeOutlined style={{ marginRight: "4px" }} />}
            title={"Activities"}
            value={totalActivities}
          />
          <Statistic
            formatter={countFormatter}
            prefix={<RocketOutlined style={{ marginRight: "4px" }} />}
            title={"Opportunities"}
            value={totalOpportunities}
          />
          <Statistic
            formatter={countFormatter}
            suffix={"%"}
            title={"Close Rate"}
            value={closedRate}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {!isEmpty(activityCounts) && (
            <ActivityCountPie
              counts={activityCounts}
              name={"Activities by Type"}
            />
          )}
          {!isEmpty(opportunityCounts) && (
            <OpportunitiesBarChart
              name={"Opportunities by Stage"}
              opportunityCounts={opportunityCounts}
              opportunityStates={opportunityStates}
            />
          )}
        </div>
      </div>
    </Fade>
  );
};

const Container = (props) => {
  return (
    <WidgetContainer>
      <Component {...props} />
    </WidgetContainer>
  );
};

export const Totals = Container;
