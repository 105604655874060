import produce from "immer";
import create from "zustand";

const getDefaults = () => {
  return {
    indexes: [],
    top: 0,
  };
};

export const useTableStore = create((set) => ({
  ACCOUNT: getDefaults(),
  CONTACT: getDefaults(),
  OPPORTUNITY: getDefaults(),
  VENDOR: getDefaults(),

  setIndexes: (viewType) => (indexes) =>
    set(
      produce((state) => {
        state[viewType].indexes = indexes;
      })
    ),

  setTop: (viewType) => (top) =>
    set(
      produce((state) => {
        state[viewType].top = top;
      })
    ),

  reset: (viewType) => () =>
    set(
      produce((state) => {
        state[viewType] = getDefaults();
      })
    ),
}));
