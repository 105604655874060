import { Card } from "antd";

import { BuildingBlocks } from "components/image-placeholders";
import { styles } from "./styles";

export const InitialActivity = (props) => {
  const { showPerformActivity } = props;

  return (
    <Card
      bordered={false}
      className={"sm-larger-on-hover transition"}
      hoverable
      onClick={showPerformActivity}
      style={styles.buttonCard.container}
    >
      <BuildingBlocks
        containerStyle={styles.buttonCard.svg.container}
        primary={
          "Or if you have already made process, log your first activity!"
        }
        style={styles.buttonCard.svg.body}
      />
    </Card>
  );
};
