import { Form, Select } from "antd";

import { useSalesProcesses } from "../../data/use-sales-processes";
import { filterOption } from "./filter-option";
import { pickFormItemProps, pickSelectProps } from "./pick-props";
import { getSelectOptions } from "../../utils/get-select-options";

export const SalesProcessSelect = (props) => {
  const salesProcesses = useSalesProcesses();

  const form = Form.useFormInstance();
  const initialValue = form.getFieldValue(props.name);

  return (
    <Form.Item {...pickFormItemProps(props)}>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        options={getSelectOptions({
          store: salesProcesses,
          initialIds: initialValue,
        })}
        {...pickSelectProps(props)}
      />
    </Form.Item>
  );
};
