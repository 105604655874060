import { useUser } from "@clerk/clerk-react";

export const useInternal = () => {
  const { user } = useUser();

  if (!user) return false;

  return (
    (user.emailAddresses || []).some(({ emailAddress }) =>
      emailAddress.endsWith("@evolvedmetrics.com")
    ) || !!user?.publicMetadata?.isInternal
  );
};
