import { SearchSelect } from "components/selects/search-select";
import {
  useOrganization,
  useSetOrganizationTimezone,
} from "data/use-organization";

import { timezones } from "@evolved/constants";

export const SelectTimezone = () => {
  const organization = useOrganization();
  const setOrganizationTimezone = useSetOrganizationTimezone();

  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <strong>Timezone</strong>
      </div>
      <SearchSelect
        disabled={setOrganizationTimezone.isLoading}
        loading={setOrganizationTimezone.isLoading}
        onChange={async (timezone) => {
          await setOrganizationTimezone.mutate({
            timezone,
          });
        }}
        options={timezones.map((timezone) => ({
          label: timezone,
          value: timezone,
        }))}
        placeholder={"Select a default timezone"}
        style={{ display: "block" }}
        value={organization.timezone || "America/Edmonton"}
      />
      <div className="mt-2">
        <strong>Note: </strong>this timezone is only used for generated reports.
        The app will continue to display info in your local timezone.
      </div>
    </>
  );
};
