import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useArchiveAccount } from "data/use-accounts";

export const ArchiveAccount = (props) => {
  const { account } = props;

  const archiveAccount = useArchiveAccount({
    onSuccess: async (data, { mutation }) => {
      await mutation.onSuccess.call();
    },
  });

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() => archiveAccount.mutateAsync({ id: account._id })}
      title="Are you sure?"
    >
      <Button
        icon={<EyeInvisibleOutlined />}
        disabled={archiveAccount.isLoading}
      >
        Archive
      </Button>
    </Popconfirm>
  );
};
