import isPlainObject from "lodash/isPlainObject";
import StackTrace from "stacktrace-js";
import { isArray } from "radash";

import { useEffect } from "react";

import { useLog } from "../data/use-logs";
import { useAuthenticationStore } from "../stores/authentication";

export const parseError = (error) => {
  if (error?.statusCode && error?.error && error?.message) {
    return error.message;
  }

  if (error.errors && error.errors[0] && error.errors[0].message) {
    return error.errors && error.errors[0] && error.errors[0].message;
  }

  if (error instanceof Error) {
    return JSON.stringify(
      {
        cause: error.cause,
        message: error.message,
        stack: error.stack,
      },
      null,
      4
    );
  }

  if (isPlainObject(error)) {
    return JSON.stringify(error, null, 4);
  }

  if (typeof error === "string") {
    return error;
  }
};

export const SupportMessage = ({ error, retry, silenceEmail = false }) => {
  const log = useLog();
  const { session } = useAuthenticationStore();

  useEffect(() => {
    if (!error || !(error instanceof Error) || !session || silenceEmail) {
      return;
    }

    const logIt = async () => {
      if (import.meta.env.DEV) {
        return;
      }

      let stack;

      try {
        stack = await StackTrace.fromError(error);
      } catch (error) {
        stack = `parsing stack failed: ${error.message}`;
      }

      log.mutate([
        {
          message: error.message,
          stack: isArray(stack)
            ? stack.map((sf) => sf.toString()).join("</br>")
            : `${stack} : ${error.stack}`,
        },
      ]);
    };

    logIt();
  }, [error, session, silenceEmail]);

  return (
    <>
      <div className="mb-4 max-h-[256px] overflow-y-scroll">
        {parseError(error)}
      </div>
      <div>
        <span>
          <strong>Support has been notified.</strong>
          {retry && (
            <>
              {" Or "}
              <strong className="cursor-pointer text-blue-500" onClick={retry}>
                Try Again
              </strong>
            </>
          )}
        </span>
      </div>
    </>
  );
};
