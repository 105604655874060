import { FilterOutlined } from "@ant-design/icons";
import { Button, message, Select } from "antd";
import { useState } from "react";

import { useAccounts } from "../../../data/use-accounts";
import { useActivityTypes } from "../../../data/use-activity-types";
import { useContacts } from "../../../data/use-contacts";
import { useLossReasons } from "../../../data/use-loss-reasons";
import { useOpportunities } from "../../../data/use-opportunities";
import { useOpportunityStates } from "../../../data/use-opportunity-states";
import { useProducts } from "../../../data/use-products";
import { useSalesProcesses } from "../../../data/use-sales-processes";
import { useTags } from "../../../data/use-tags";
import { useUsers } from "../../../data/use-users";
import { useVendors } from "../../../data/use-vendors";
import { filterOption } from "../../selects/filter-option";
import { useFocus } from "./use-focus";
import { useVisible } from "./use-visible";
import { validateFilter } from "./validate-filter";

export const SetFilter = ({
  config,
  defaultValue = [],
  hide,
  onFilter,
  type,
}) => {
  const ref = useFocus(config.dataIndex);
  const [visible, setVisible] = useVisible(config.dataIndex);

  const [value, setValue] = useState(defaultValue);

  const accounts = useAccounts();
  const activityTypes = useActivityTypes();
  const contacts = useContacts();
  const lossReasons = useLossReasons();
  const opportunities = useOpportunities();
  const opportnityStates = useOpportunityStates();
  const products = useProducts();
  const salesProcesses = useSalesProcesses();
  const tags = useTags();
  const users = useUsers();
  const vendors = useVendors();

  const data = {
    accounts: { store: accounts, label: "name" },
    activityTypes: { store: activityTypes, label: "label" },
    contacts: { store: contacts, label: "alias" },
    lossReasons: { store: lossReasons, label: "label" },
    opportunities: { store: opportunities, label: "alias" },
    opportunityStates: { store: opportnityStates, label: "label" },
    products: { store: products, label: "name" },
    salesProcesses: { store: salesProcesses, label: "name" },
    tags: { store: tags, label: "label" },
    users: { store: users, label: "alias" },
    vendors: { store: vendors, label: "name" },
  }[config.collection];

  // should get the config from somewhere, maybe it's passed in

  return (
    <>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <Button
          icon={<FilterOutlined />}
          onClick={() => {
            return validateFilter(type, value)
              ? onFilter({ dataIndex: config.dataIndex, value })
              : message.warning("Must have at least one selection to filter.");
          }}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}
          type="primary"
        >
          Filter
        </Button>
        <Button
          onClick={hide}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          Cancel
        </Button>
      </div>
      <div style={{ display: "flex" }}>
        <Select
          filterOption={filterOption}
          mode="multiple"
          notFoundContent={"No match"}
          optionFilterProp="children"
          showSearch
          options={data.store.all({ includeArchived: false }).map((item) => ({
            value: item._id,
            label: item[data.label],
          }))}
          value={data.store.optionsByIds(value)}
          onChange={setValue}
          onDropdownVisibleChange={setVisible}
          open={visible}
          ref={ref}
          size="small"
          style={{ width: "100%" }}
        />
      </div>
    </>
  );
};
