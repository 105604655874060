import { Form } from "antd";

import { Modal } from "components/modal";
import { SalesProcess } from "../../../components/modals/perform-activity/perform-opportunity-activity";
import { StageChange } from "../../../components/modals/perform-activity/perform-opportunity-activity/stage-change";
import { mapImpactedSteps } from "../../../components/modals/perform-activity/prepare-payload";
import { useOpportunitySteps } from "../../../data/use-opportunities";
import { useOnChange } from "hooks/use-on-change";
import { useOverrideSalesProcess } from "data/use-opportunities";

export const OverrideSalesProcess = ({ close, isOpen, opportunity }) => {
  const overrideSalesProcess = useOverrideSalesProcess({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    overrideSalesProcess.mutate({
      id: opportunity._id,
      impacted: mapImpactedSteps({ steps, values }).impactedSteps,
      opportunityStateChangeId: values.opportunityStateChangeId || null,
    });
  };

  const steps = useOpportunitySteps(opportunity._id);

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        opportunityStateChangeId: null,
        steps: (steps || []).map(({ isComplete }) => isComplete),
      });
    },
    [isOpen, form, opportunity]
  );

  return (
    <>
      <Modal
        confirmLoading={overrideSalesProcess.isLoading}
        okText="Override"
        onCancel={close}
        onOk={form.submit}
        title="Override Sales Process"
        open={isOpen}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
        >
          <StageChange form={form} opportunity={opportunity} />
          <div>
            <SalesProcess
              form={form}
              shouldRenderSalesProcess
              steps={steps}
              sm={24}
            />
          </div>
        </Form>
      </Modal>
    </>
  );
};
