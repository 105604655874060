import get from "lodash/get";
import sortBy from "lodash/sortBy";

import { QuestionCircleOutlined } from "@ant-design/icons";
import { Form, Select, Tooltip } from "antd";
import { useEffect } from "react";

import { useProducts } from "../../../data/use-products";
import { useSalesProcesses } from "../../../data/use-sales-processes";
import { filterOption } from "../../selects/filter-option";

const getHint = ({ organization, product }) => {
  if (get(product, "salesProcess")) {
    if (organization.shouldEnforceProductSalesProcess) {
      return (
        <span>
          <strong>{product.salesProcess.name}</strong> is the required sales
          process for <strong>{product.name}</strong> set at the manager level.
        </span>
      );
    }

    return (
      <span>
        <strong>{product.salesProcess.name}</strong> is the default sales
        process for <strong>{product.name}</strong>
      </span>
    );
  }
};

export const SalesProcessSelect = (props) => {
  const { form, organization } = props;

  const products = useProducts().all();
  const salesProcesses = useSalesProcesses().all();

  const productId = Form.useWatch("productId");

  const product = products.find((product) => product._id === productId);

  useEffect(() => {
    const defaultSalesProcess = get(product, "salesProcess");

    if (!defaultSalesProcess) {
      return;
    }

    form.setFieldValue("salesProcessId", defaultSalesProcess._id);
  }, [organization, product]);

  return (
    <Form.Item
      name="salesProcessId"
      label={
        <span>
          {"Sales Process"}
          {/* todo: test this */}
          {getHint({ organization, product }) && (
            <Tooltip title={getHint({ organization, product })}>
              <QuestionCircleOutlined style={{ marginLeft: "5px" }} />
            </Tooltip>
          )}
        </span>
      }
    >
      <Select
        allowClear
        disabled={
          product &&
          product.salesProcess &&
          organization.shouldEnforceProductSalesProcess
        }
        filterOption={filterOption}
        notFoundContent={"Search returned no sales processes"}
        options={sortBy(
          salesProcesses.map((salesProcess) => {
            return {
              label: salesProcess.name,
              value: salesProcess._id,
            };
          }),
          ({ label }) => label && label.toUpperCase()
        )}
        placeholder="Select a sales process to follow"
        showSearch
      />
    </Form.Item>
  );
};
