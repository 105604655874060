import { useModal } from "../../hooks/use-modal";
import { CreateAccountModal } from "../modals/create-account-modal";
import { AccountSelect as AccountSelectBase } from "../selects/account-select";

export const CreateAccountSelect = (props) => {
  const { disableAccountSelect, form } = props;

  const { modal, open } = useModal(CreateAccountModal);

  if (disableAccountSelect) {
    return null;
  }

  return (
    <>
      {modal}
      <AccountSelectBase
        allowClear
        extra={
          <div style={{ marginTop: "4px" }}>
            <a
              onClick={() =>
                open({
                  onCreate: (account) => {
                    form.setFieldValue("accountId", account._id);
                  },
                })
              }
            >
              Create
            </a>{" "}
            a new account
          </div>
        }
        label="Account"
        name="accountId"
      />
    </>
  );
};
