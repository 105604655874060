import { Tag } from "antd";
import { select } from "radash";

import { useLossReasons } from "../../../../../data/use-loss-reasons";

export const LossReasonsSet = (props) => {
  const { value } = props;

  const lossReasons = useLossReasons();

  const selected = select(
    value,
    (id) => lossReasons.byId(id),
    (id) => !!lossReasons.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((lossReason) => {
    return (
      <Tag
        style={lossReason.isArchived ? { opacity: 0.5 } : {}}
        key={lossReason._id}
      >
        {lossReason.label}
      </Tag>
    );
  });
};
