import { CreateSubscription, UpdateSubscription } from "../subscription";
import { DisplayDate } from "../display-date";

export const Canceled = ({ organization, users }) => {
  const { hasSubscription, subscribedPeriodEnd } =
    organization.subscriptionState;

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        Your subscription was canceled and ended on{" "}
        <DisplayDate date={subscribedPeriodEnd} />.
      </div>
      {hasSubscription ? (
        <UpdateSubscription organization={organization} users={users} />
      ) : (
        <CreateSubscription organization={organization} users={users} />
      )}
    </>
  );
};
