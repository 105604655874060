import { isArray } from "radash";

import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { fetcher } from "./fetcher";
import { findAvailableByIds, findByIds, normalize } from "./normalize";

export const buildUseEntities = (key, options) => {
  key = isArray(key) ? key : [key];
  const route = key.join("");

  const queryFn = fetcher.get(route, { map: normalize });

  const hook = () => {
    const { data } = useQuery({
      queryFn,
      queryKey: key,
      ...options,
    });

    return useMemo(() => {
      const { ids, entities, rows } = data || {
        ids: [],
        entities: {},
        rows: [],
      };

      return {
        entities,
        ids,
        all: ({ includeArchived = true } = {}) => {
          if (includeArchived) {
            return rows;
          }

          return rows.filter((entity) => !entity.isArchived);
        },
        optionById: (id) => {
          const entity = entities[id];

          if (!entity) return null;

          return {
            value: entity._id,
            label: entity.name || entity.label || entity.alias,
          };
        },
        optionsByIds: (ids) => {
          return ids
            .filter((id) => !!entities[id])
            .map((id) => ({
              value: id,
              label:
                entities[id].name || entities[id].label || entities[id].alias,
            }));
        },
        byId: (id, { includeArchived = true } = {}) => {
          const entity = entities[id];

          if (!includeArchived && entity?.isArchived) {
            return;
          }

          return entity;
        },
        byIds: (ids, { includeArchived = true } = {}) => {
          if (includeArchived) {
            return findByIds(ids)({ entities });
          }

          return findAvailableByIds(ids)({ entities });
        },
      };
    }, [data]);
  };

  hook.key = key;
  hook.queryFn = queryFn;

  return hook;
};
