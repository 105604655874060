import { Input } from "antd";
import { CloseOutlined, SearchOutlined } from "@ant-design/icons";

import { useSearchStore } from "../../stores/search";

export const SearchInput = (props) => {
  const query = useSearchStore((state) => state[props.viewType].query);

  return (
    <Input
      value={query}
      prefix={<SearchOutlined />}
      onChange={(e) => {
        useSearchStore.getState().setQuery(props.viewType)(e.target.value);
        props.search(e.target.value);
      }}
      placeholder="Search All"
      style={{ width: "250px" }}
      suffix={
        !!query && (
          <CloseOutlined
            className="text-xs"
            onClick={() => useSearchStore.getState().reset(props.viewType)()}
          />
        )
      }
    />
  );
};
