import { Card, Tag } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { title } from "radash";

import { OpportunityState } from "../../components/opportunity-state";
import { StepImpacts } from "../../components/lists/activities/list";
import { activityStepImpacts, colors, styles } from "@evolved/constants";
import { detailed } from "../../utils/format-date";
import { useOpportunityStream } from "../../data/use-stream";
import { useSalesProcesses } from "../../data/use-sales-processes";
import { useUsers } from "../../data/use-users";

const { IMPACTS } = activityStepImpacts;

const renderBadge = (item) => {
  if (item.type === "EVENT" || item.status === "success") {
    return (
      <Tag style={{ ...styles.STATE_TAG_STYLE }} color={colors.SUCCESS}>
        Success
      </Tag>
    );
  }

  if (["retry", "fresh"].includes(item.status)) {
    return (
      <Tag style={{ ...styles.STATE_TAG_STYLE }} color={colors.INCOMPLETE}>
        In Progress
      </Tag>
    );
  }

  return (
    <Tag style={{ ...styles.STATE_TAG_STYLE }} color={colors.EMERGENCY}>
      Failed
    </Tag>
  );
};

const SalesProcessOverride = ({ event }) => {
  const salesProcesses = useSalesProcesses();

  const impacted = event.data.impacted
    .map(({ stepId, impact }) => ({
      step: salesProcesses.entities[event.data.salesProcessId]?.steps.find(
        ({ _id }) => _id === stepId
      ),
      impact,
    }))
    .filter(({ step }) => !!step);

  return (
    <div className="my-4 space-y-2">
      <div className="flex">
        {event.data.opportunityStateChangeId && (
          <OpportunityState
            marginLeft={"0px"}
            opportunity={{ stateId: event.data.opportunityStateChangeId }}
          />
        )}
      </div>
      <StepImpacts impacted={impacted} type={IMPACTS.COMPLETED} />
      <StepImpacts impacted={impacted} type={IMPACTS.REVERTED} />
    </div>
  );
};

const details = {
  salesProcessOverride: SalesProcessOverride,
};

export const Stream = (props) => {
  const stream = useOpportunityStream({ _id: props.opportunity._id });
  const users = useUsers();

  if (!stream.length) return null;

  return (
    <Card
      bordered={false}
      bodyStyle={{
        paddingTop: "0px",
        paddingBottom: "0px",
        display: "grid",
        height: "100%",
      }}
      style={{ overflow: "hidden" }}
      title="Event Stream"
    >
      <div>
        {stream.map((item) => {
          const user = users.entities[item.userId];

          const Details = details[item.name];

          return (
            <div
              className="p-4"
              style={{ borderBlockEnd: "1px solid rgba(5, 5, 5, 0.06)" }}
            >
              <div className="flex" key={item.id}>
                <div>
                  <div className="text-lg text-slate-500">
                    {title(item.name)}
                  </div>
                </div>
                <div className="ml-auto">{renderBadge(item)}</div>
              </div>
              {Details && <Details event={item} />}
              <div>
                <div>{detailed(item.createdOn)}</div>
                {user && (
                  <div>
                    <UserOutlined className="mr-2" />
                    {user.alias}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
