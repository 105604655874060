// set from shake doesn't handle uuids
import { set } from "lodash";
import { shake } from "radash";

const construct = (obj) => {
  if (!obj) return {};

  return Object.keys(obj).reduce((acc, path) => {
    set(acc, path, obj[path]);
    return acc;
  }, {});
};

export const mapOutgoingValues = (values) => {
  return construct(
    shake(values, (v) => v === null || v === undefined || v === "")
  );
};
