import sortBy from "lodash/sortBy";
import { Badge, Button, Card, List, Popconfirm } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { colors } from "@evolved/constants";

import { ActivityTypeModal } from "./modals/activity-type-modal";
import { useModal } from "hooks/use-modal";
import {
  useActivityTypes,
  useArchiveActivityType,
} from "data/use-activity-types";

const { PRIMARY } = colors;

const sortActivityTypes = (activityTypes) => {
  return sortBy(activityTypes, "label");
};

const Header = ({ activityTypes, openActivityTypeModal }) => {
  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={activityTypes.length}
        showZero
        style={{ backgroundColor: PRIMARY }}
      />
      <span style={{ marginLeft: "16px" }}>Activity Types</span>
      <Button
        ghost
        onClick={() => openActivityTypeModal()}
        style={{ marginLeft: "auto" }}
        type="primary"
      >
        <PlusCircleOutlined style={{ margin: "0px" }} />
      </Button>
    </div>
  );
};

export const ActivityTypes = () => {
  const activityTypes = useActivityTypes().all({ includeArchived: false });
  const archiveActivityType = useArchiveActivityType();

  const { modal: activityTypeModal, open: openActivityTypeModal } =
    useModal(ActivityTypeModal);

  return (
    <>
      {activityTypeModal}
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
        <List
          dataSource={sortActivityTypes(activityTypes)}
          header={<Header {...{ activityTypes, openActivityTypeModal }} />}
          itemLayout={"vertical"}
          pagination={{
            pageSize: 5,
          }}
          renderItem={(activityType) => {
            const { _id, description, label } = activityType;

            return (
              <List.Item key={_id}>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div>
                    <div>{label}</div>
                    {description && (
                      <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {description}
                      </div>
                    )}
                  </div>
                  {!activityType.isSystem && (
                    <div style={{ marginLeft: "auto" }}>
                      <span
                        className="primary-clickable"
                        onClick={() => openActivityTypeModal({ activityType })}
                      >
                        Update
                      </span>
                    </div>
                  )}

                  {!activityType.isSystem && (
                    <>
                      <div style={{ margin: "0px 8px" }}>|</div>
                      <Popconfirm
                        okText="Yes"
                        onConfirm={() =>
                          // todo: test with delay
                          archiveActivityType.mutateAsync({
                            id: activityType._id,
                          })
                        }
                        title="Are you sure?"
                      >
                        <span className="primary-clickable">Archive</span>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};
