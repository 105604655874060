import get from "lodash/get";

const userError = (err) => get(err, "graphQLErrors.0.message");
const developerError = (err) =>
  get(err, "networkError.result.errors.0.message") ||
  get(err, "networkError.message") ||
  get(err, "message");

export default (err) =>
  userError(err) ||
  developerError(err) ||
  err ||
  "Something bad happened and it's not your fault :(";
