import { saveAs } from "file-saver";

import { viewTypes } from "@evolved/constants";

import { fields } from "./fields";
import { relationships } from "./relationships";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

const FILE_NAMES = {
  [ACCOUNT]: "accounts-import-template.csv",
  [OPPORTUNITY]: "opportunities-import-template.csv",
  [VENDOR]: "vendors-import-template.csv",
  [CONTACT]: "contacts-import-template.csv",
};

const EXPLAINER_TEXT = {
  [OPPORTUNITY]: (
    <span>
      Opportunity &quot;Progress&quot; and &quot;Stage&quot; are indicators of
      how far along the sales process you are. In general these values are
      determined by completion of sales process steps, however you can set
      initial values here. This is useful if you are migrating from another
      system.
      <br />
      <br />
      <strong>Name</strong> is also a required field. Any opportunities without
      it will be ignored from the import.
    </span>
  ),
};

const onClick =
  ({ userDefinedFields, setError, type }) =>
  async () => {
    try {
      const file = new File(
        [
          [
            ...fields[type].map(({ title }) => title),
            ...relationships[type].map(({ title }) => title),
            ...userDefinedFields.map(({ name }) => name),
          ].join(","),
        ],
        FILE_NAMES[type],
        { type: "text/plain;charset=utf-8" }
      );

      saveAs(file);
    } catch (err) {
      console.error(err);
      setError("Something bad happened and it's not your fault");
    }
  };

export const GetTemplate = ({ userDefinedFields, setError, type }) => {
  return (
    <div>
      Welcome to the import tool!
      <br />
      <br />
      First,
      <span
        className="primary-clickable"
        onClick={onClick({
          setError,
          type,
          userDefinedFields,
        })}
      >
        {" "}
        download{" "}
      </span>
      the import template. Next, paste your existing data into the correct
      columns in the template. Save it and go!
      <br />
      <br />
      If you are just looking to update records, that&apos;ll work too! We match
      the rows to update based on the name, so make sure they are correct! For
      that, we recommend using an export of your data.
      <br />
      {EXPLAINER_TEXT[type] && (
        <>
          <br />
          <div>Note: {EXPLAINER_TEXT[type]}</div>
        </>
      )}
      <br />
      If you have any questions, please check out our{" "}
      <a
        href="https://www.youtube.com/channel/UC7gblyuosT1dJJLU4zl8oUg"
        target="_blank"
        rel="noopener noreferrer"
      >
        YouTube channel
      </a>{" "}
      for help videos.
      <br />
      <br />
    </div>
  );
};
