import { isEmpty } from "radash";
import { useEffect } from "react";

import { filterData, sortData } from "@evolved/views";
import { getPartnersLabel } from "@evolved/labels";
import { viewTypes } from "@evolved/constants";

import { AddButton } from "../../components/add-button";
import { CreateVendorModal } from "../../components/modals/create-vendor-modal";
import { DownloadCSV } from "../../components/view/download-csv/download-csv";
import { FieldSelect } from "../../components/view/fields-select";
import { Filters } from "../../components/view/filters/filters";
import { OpenImportModal } from "../../components/modals/import-data";
import { SaveView } from "../../components/view/save-view";
import { SearchInput } from "../../components/view/search";
import { Sorters } from "../../components/view/sorters";
import { Table } from "../../components/view/table";
import { ViewActions } from "../../components/view/actions";
import { ViewSelect } from "../../components/view/view-select";
import {
  filterConfigKeys,
  reduceSearchResults,
  useSearch,
} from "../use-search";
import { useContacts } from "../../data/use-contacts";
import { useDefaultView } from "../use-default-view";
import { useFieldConfigs } from "../../components/view/use-field-configs";
import { useModal } from "../../hooks/use-modal";
import { useOrganization } from "../../data/use-organization";
import { useSearchStore } from "../../stores/search";
import { useTags } from "../../data/use-tags";
import { useUsers } from "../../data/use-users";
import { useVendors } from "../../data/use-vendors";
import { useViewStore } from "../../stores/view";

const CreateVendorButton = () => {
  const organization = useOrganization();

  const { modal, open } = useModal(CreateVendorModal);

  return (
    <>
      {modal}
      <AddButton id="create-vendor-button" onClick={open}>
        {getPartnersLabel(organization, "singular")}
      </AddButton>
    </>
  );
};

export const VendorsRoute = () => {
  const vendors = useVendors();
  const contacts = useContacts();
  const tags = useTags();
  const users = useUsers();

  const includeArchived = useViewStore(
    (state) => state[viewTypes.VENDOR].includeArchived
  );

  useDefaultView(viewTypes.VENDOR);

  const fieldConfigs = useFieldConfigs(viewTypes.VENDOR);

  const search = useSearch(
    {
      primary: {
        items: vendors.all({ includeArchived }),
        keys: filterConfigKeys(fieldConfigs),
      },
      secondary: [
        { path: "contacts", items: contacts.all(), keys: ["alias"] },
        { path: "tags", items: tags.all(), keys: ["label"] },
        { path: "users", items: users.all(), keys: ["alias"] },
      ],
    },
    viewTypes.VENDOR
  );

  useEffect(() => {
    if (useSearchStore.getState()[viewTypes.VENDOR].query) {
      search(useSearchStore.getState()[viewTypes.VENDOR].query);
    }
  }, [includeArchived, vendors, contacts, tags, users]);

  const searchResult = useSearchStore(
    (state) => state[viewTypes.VENDOR].result
  );

  const filters = useViewStore((state) => state[viewTypes.VENDOR].filters);
  const sortOrder = useViewStore((state) => state[viewTypes.VENDOR].sortOrder);

  let data;

  if (searchResult) {
    data = reduceSearchResults(searchResult)(vendors);
  } else if (!isEmpty(filters)) {
    data = filterData({
      data: vendors.all({ includeArchived }),
      fieldConfigs,
      filters,
    });
  } else {
    data = vendors.all({ includeArchived });
  }

  if (!isEmpty(sortOrder)) {
    data = sortData({
      data,
      fieldConfigs,
      sortOrder,
    });
  }

  return (
    <div
      className="w-full"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "auto 1fr",
        height: "100%",
      }}
    >
      <div className="mb-4">
        <div className="mb-4 flex items-center">
          <ViewSelect viewType={viewTypes.VENDOR} />
          <FieldSelect viewType={viewTypes.VENDOR} />
          <ViewActions viewType={viewTypes.VENDOR} />
          <SaveView viewType={viewTypes.VENDOR} />
          <div className="ml-auto flex">
            <OpenImportModal type={viewTypes.VENDOR} />
            <div className="ml-2">
              <DownloadCSV data={data} viewType={viewTypes.VENDOR} />
            </div>
            <div className="ml-2">
              <CreateVendorButton />
            </div>
          </div>
        </div>
        <div className="flex items-start">
          <div className="flex flex-wrap items-center">
            <div className="mr-4">
              <SearchInput search={search} viewType={viewTypes.VENDOR} />
            </div>
            <Filters viewType={viewTypes.VENDOR} />
            <Sorters viewType={viewTypes.VENDOR} />
          </div>
        </div>
      </div>
      <Table data={data} viewType={viewTypes.VENDOR} />
    </div>
  );
};
