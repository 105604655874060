import { useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";

const root = "/sales-process";

export const useSalesProcesses = buildUseEntities(root);

export const useCreateSalesProcess = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(root)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateSalesProcess = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateSalesProcessStep = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/step/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchiveSalesProcess = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/archive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUnarchiveSalesProcess = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/unarchive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
