import day from "dayjs";

import { Form } from "antd";

import { detailed } from "utils/format-date";
import { OpportunityStateSelect } from "../../../selects/opportunity-state-select";

export const StageChange = (props) => {
  const { form, opportunity } = props;

  const performedOn = Form.useWatch("performedOn", form);

  const canChangeState =
    !opportunity.lastStateChangeOn ||
    day.unix(opportunity.lastStateChangeOn).isBefore(performedOn);

  return (
    <OpportunityStateSelect
      label="Stage Change"
      name="opportunityStateChangeId"
      disabled={!canChangeState}
      extra={
        !canChangeState
          ? `Stage was changed on ${detailed(
              opportunity.lastStateChangeOn
            )}. This change would have no affect.`
          : undefined
      }
    />
  );
};
