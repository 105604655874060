import find from "lodash/find";
import reduce from "lodash/reduce";
import { Bar, BarChart, Tooltip, XAxis } from "recharts";

import { CHART_SIZE } from "./constants";

export const OpportunitiesBarChart = (props) => {
  const { name, opportunityCounts = [], opportunityStates } = props;

  const data = reduce(
    opportunityStates,
    (accumulator, state) => {
      accumulator[state.label] = (
        find(opportunityCounts, { stateId: Number(state._id) }) || { count: 0 }
      ).count;

      return accumulator;
    },
    []
  );

  return (
    <div>
      {name && (
        <div className={"ant-statistic-title"} style={{ marginBottom: "8px" }}>
          {name}
        </div>
      )}
      <BarChart
        style={{ fontSize: "18px" }}
        width={CHART_SIZE}
        height={CHART_SIZE}
        data={[data]}
      >
        <XAxis dataKey={"name"} hide />
        <Tooltip
          formatter={(value, name) => [value, name]}
          labelFormatter={() => ""}
        />
        {opportunityStates.map((state) => (
          <Bar dataKey={state.label} key={state._id} fill={state.color} />
        ))}
      </BarChart>
    </div>
  );
};
