import { viewTypes } from "@evolved/constants";
import {
  accountFields as account,
  contactFields as contact,
  opportunityFields as opportunity,
} from "@evolved/views";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

// NOTE: canCreate false means it will fail if the thing does not exist and prompt the user to go create it first
export const relationships = {
  [ACCOUNT]: [
    { ...account.contacts, canCreate: true },
    { ...account.tags, canCreate: true },
    { ...account.users, canCreate: false },
  ],
  [CONTACT]: [
    { ...contact.account, canCreate: true },
    { ...contact.tags, canCreate: true },
    { ...contact.vendor, canCreate: true },
  ],
  [OPPORTUNITY]: [
    { ...opportunity.account, canCreate: true },
    { ...opportunity.contacts, canCreate: true },
    // TODO: parser is to parse out the field to the relationship, will take in the existing as an input
    { ...opportunity.products, canCreate: true, parser: () => {} },
    { ...opportunity.partners, canCreate: true },
    { ...opportunity.salesProcess, canCreate: false },
    { ...opportunity.initialState, canCreate: false },
    { ...opportunity.lossReason, canCreate: true },
    { ...opportunity.tags, canCreate: true },
    { ...opportunity.users, canCreate: false },
  ],
  [VENDOR]: [],
};
