import { Tag } from "antd";
import { select } from "radash";

import { useAccounts } from "../../../../../data/use-accounts";
import { Link } from "../../../../link";

export const AccountsSet = (props) => {
  const { value } = props;

  const accounts = useAccounts();

  const selected = select(
    value,
    (id) => accounts.byId(id),
    (id) => !!accounts.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((account) => {
    return (
      <Link link={`/accounts/${account._id}`} key={account._id}>
        <Tag style={account.isArchived ? { opacity: 0.5 } : {}}>
          {account.name}
        </Tag>
      </Link>
    );
  });
};
