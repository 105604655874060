import { useCallback } from "react";

import { useTableStore } from "../stores/table";

export const useIndexes = (viewType) => {
  const { indexes } = useTableStore((state) => state[viewType]);

  const isOn = useCallback(
    (index) => {
      return indexes.includes(index);
    },
    [indexes]
  );

  const toggle = useCallback(
    (index) => {
      let next;

      if (indexes.includes(index)) {
        next = indexes.filter((existing) => existing !== index);
      } else {
        next = [...indexes, index];
      }

      useTableStore.getState().setIndexes(viewType)(next);
    },
    [indexes]
  );

  return {
    indexes,
    isOn,
    toggle,
  };
};
