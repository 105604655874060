import { Modal as AntModal, Form, Input } from "antd";

import { UserSelect } from "components/selects/user-select";
import { useScheduleVendorFollowup } from "data/use-followups";
import { CREATE_VENDOR_FOLLOWUP_MODAL, useLayoutStore } from "stores/layout";
import { toUnixDate } from "utils/unix-date";
import { useOnChange } from "../../hooks/use-on-change";
import { DatePicker } from "../date-picker";

export const AssignVendorFollowupModal = () => {
  const layoutState = useLayoutStore(
    (state) => state[CREATE_VENDOR_FOLLOWUP_MODAL]
  );

  const close = (afterClose) =>
    useLayoutStore.getState().close(CREATE_VENDOR_FOLLOWUP_MODAL, afterClose);

  const { vendorId, openingActivityId } = layoutState.payload;

  const assignFollowup = useScheduleVendorFollowup({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  useOnChange(
    layoutState.isOpen,
    () => {
      if (!layoutState.isOpen) {
        return;
      }

      form.setFieldsValue({
        assigneeId: null,
        dueOn: null,
        task: "",
      });
    },
    [layoutState.isOpen, form]
  );

  return (
    <AntModal
      styles={{ body: { paddingTop: "16px" } }}
      confirmLoading={assignFollowup.isLoading}
      destroyOnClose
      okText="Assign"
      onCancel={close}
      onOk={form.submit}
      style={{ top: 20 }}
      title="Schedule Followup"
      open={layoutState.isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={(values) => {
          assignFollowup.mutate({
            ...values,
            vendorId,
            // todo: test that this is working correctly
            ...(openingActivityId ? { openingActivityId } : {}),
            ...toUnixDate(values, "dueOn"),
          });
        }}
      >
        <Form.Item label="Due On" name="dueOn" rules={[{ required: true }]}>
          <DatePicker />
        </Form.Item>
        <UserSelect
          label="Assign To"
          name="assigneeId"
          rules={[{ required: true }]}
        />
        <Form.Item label="Task" name="task" rules={[{ required: true }]}>
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </AntModal>
  );
};
