import { useRef } from "react";

import { Button, Form, InputNumber, Select } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { isInt } from "radash";

import { getSelectOptions } from "../../utils/get-select-options";
import { filterOption } from "./filter-option";
import { useProducts } from "../../data/use-products";

export const OpportunityProductsSelect = (props) => {
  const { isMultiple, setIsMultiple } = props;

  const productsStore = useProducts();

  const form = Form.useFormInstance();

  Form.useWatch("products", form, { preserve: true });

  const selectedProducts = form.getFieldValue("products");
  const selectedProduct = form.getFieldValue("product");

  const initialValue = useRef(
    isMultiple ? (selectedProducts || []).map(({ id }) => id) : selectedProduct
  );

  const options = getSelectOptions({
    store: productsStore,
    initialIds: initialValue.current,
  });

  const remaining = options
    ? options.filter(
        ({ value }) => !selectedProducts.some(({ id }) => id === value)
      )
    : [];

  if (options && options.length < 1) {
    return <div>Create some products to select them here.</div>;
  }

  if (!isMultiple) {
    return (
      <Form.Item
        help={
          <div style={{ margin: "4px 0px 24px 0px" }}>
            <a
              onClick={() => {
                if (form.getFieldValue("product")) {
                  form.setFieldValue(
                    ["products"],
                    [{ id: form.getFieldValue("product"), quantity: 1 }]
                  );
                }

                setIsMultiple(true);
              }}
            >
              Sell (multiple) product quantities
            </a>
          </div>
        }
        label="Product"
        name="product"
      >
        <Select
          allowClear
          filterOption={filterOption}
          notFoundContent={"Search returned no products"}
          optionFilterProp="children"
          options={options}
          placeholder="Select product"
          showSearch
        />
      </Form.Item>
    );
  }

  return (
    <>
      <Form.List name="products">
        {(fields, operations) => {
          return (
            <>
              <Form.Item
                help={
                  <div style={{ marginTop: "4px" }}>
                    <a
                      onClick={() => {
                        form.setFieldValue(["products"], []);
                        setIsMultiple(false);
                      }}
                    >
                      Sell single product
                    </a>
                  </div>
                }
                label="Products"
                style={{ marginBottom: "0px" }}
              >
                <Select
                  disabled={remaining.length === 0}
                  filterOption={filterOption}
                  notFoundContent={"Search returned no products"}
                  onChange={(id) => {
                    operations.add({ id, quantity: 1 });
                  }}
                  options={remaining}
                  placeholder={
                    remaining.length ? "Select to add" : "All products selected"
                  }
                  showSearch
                  style={{ width: "100%" }}
                  value={null}
                />
              </Form.Item>
              <div style={{ margin: "8px 0px 24px 80px" }}>
                {fields.map((field) => {
                  const fieldState = form.getFieldValue([
                    "products",
                    field.name,
                  ]);

                  const product = productsStore.byId(fieldState.id);

                  return (
                    <div
                      key={field.key}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginBottom: "8px",
                      }}
                    >
                      <Button
                        style={{ marginRight: "8px" }}
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          operations.remove(field.name);
                        }}
                      />
                      <Form.Item
                        // to not show the validation message
                        help=""
                        name={[field.name, "quantity"]}
                        rules={[
                          {
                            required: true,
                            validator: async (rule, value) => {
                              if (!isInt(value)) {
                                throw new Error("Must be integer");
                              }
                            },
                          },
                        ]}
                        style={{ marginBottom: "0px" }}
                      >
                        <InputNumber min={1} size="small" />
                      </Form.Item>
                      <div style={{ marginLeft: "8px" }}>x {product.name}</div>
                    </div>
                  );
                })}
              </div>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
