import { Input, Form, Popover, Tag } from "antd";
// todo: replace with antd color picker
import { SwatchesPicker } from "react-color";
import { useState } from "react";

import { useOnChange } from "hooks/use-on-change";

import {
  useCreateOpportunityState,
  useUpdateOpportunityState,
} from "../../../data/use-opportunity-states";
import { Modal } from "components/modal";

const onSubmit =
  ({ form, mutation, opportunityState }) =>
  (values) => {
    let variables;
    const color = form.getFieldValue("color") || null;

    if (opportunityState) {
      variables = {
        id: opportunityState._id,
        color,
        ...values,
      };
    }

    if (!opportunityState) {
      variables = {
        color,
        ...values,
      };
    }

    mutation.mutate(variables);
  };

const SelectColorField = (props) => {
  const { form } = props;

  const [selectColor, setSelectColor] = useState(false);

  return (
    <Form.Item shouldUpdate>
      {() => {
        const value = form.getFieldValue("color");

        return (
          <div style={{ display: "flex" }}>
            <div style={{ width: "100px", textAlign: "right" }}>Color</div>
            <Popover
              content={
                <SwatchesPicker
                  color={value}
                  height={190}
                  colors={[
                    ["#583967", "#874D9A", "#9564AD"],
                    ["#333368", "#45458F", "#5B5BB2"],
                    ["#D54E2C", "#DF755A", "#E99D89"],
                    ["#EFAF18", "#F4C24C", "#F8D481"],
                    ["#2188DF", "#5DA9E9", "#82BDEF"],
                    ["#25A268", "#3AD48C", "#5CDCA0"],
                    ["#878484", "#A9A9A9", "#C0BCBC"],
                  ]}
                  onChange={(color) =>
                    color && form.setFieldValue("color", color.hex)
                  }
                />
              }
              trigger="click"
              open={selectColor}
              onOpenChange={setSelectColor}
            >
              <Tag
                color={value}
                style={{
                  cursor: "pointer",
                  marginLeft: "18px",
                  fontWeight: "700",
                }}
              >
                Click To Select
              </Tag>
            </Popover>
          </div>
        );
      }}
    </Form.Item>
  );
};

export const OpportunityStateModal = ({ close, isOpen, opportunityState }) => {
  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      if (opportunityState) {
        form.setFieldsValue({
          ...(opportunityState.color ? { color: opportunityState.color } : {}),
          label: opportunityState.label,
          description: opportunityState.description,
        });
        if (!opportunityState.color) form.setFieldValue("color");
      } else {
        form.setFieldsValue({
          label: "",
          description: "",
        });
        form.setFieldValue("color");
      }
    },
    [isOpen, form, opportunityState]
  );

  const updateOpportunityState = useUpdateOpportunityState({
    onSuccess: close,
  });

  const createOpportunityState = useCreateOpportunityState({
    onSuccess: close,
  });

  const mutation = opportunityState
    ? updateOpportunityState
    : createOpportunityState;

  return (
    <Modal
      confirmLoading={mutation.isLoading}
      okText={opportunityState ? "Update" : "Create"}
      onCancel={close}
      onOk={form.submit}
      title={`${opportunityState ? "Update" : "Create"} Opportunity State`}
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({ form, mutation, opportunityState })}
      >
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <SelectColorField form={form} />
      </Form>
    </Modal>
  );
};
