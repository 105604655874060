import { Form } from "antd";
import { useState } from "react";

import { SET_OPPORTUNITY_PRODUCTS_MODAL, useLayoutStore } from "stores/layout";
import { useSetOpportunityProducts } from "../../data/use-opportunities";
import { useOnChange } from "../../hooks/use-on-change";
import { OpportunityProductsSelect } from "../selects/opportunity-products-select";
import { Modal } from "components/modal";

export const SetOpportunityProducts = () => {
  const layoutState = useLayoutStore(
    (state) => state[SET_OPPORTUNITY_PRODUCTS_MODAL]
  );

  const close = (afterClose) =>
    useLayoutStore.getState().close(SET_OPPORTUNITY_PRODUCTS_MODAL, afterClose);

  const { opportunity } = layoutState.payload;

  const setOpportunityProducts = useSetOpportunityProducts({
    onSuccess: close,
  });

  const [isMultipleProducts, setIsMultipleProducts] = useState(false);

  const [form] = Form.useForm();

  useOnChange(
    layoutState.isOpen,
    () => {
      if (!layoutState.isOpen) {
        return;
      }

      let isMultipleProducts = false;
      let products = [];
      let product = null;

      if (opportunity.products.length > 0) {
        if (
          opportunity.products.length > 1 ||
          opportunity.products[0].quantity > 1
        ) {
          isMultipleProducts = true;
          products = opportunity.products.map((product) => ({
            id: product._id,
            quantity: product.quantity,
          }));
        } else {
          product = opportunity.products[0]._id;
        }
      }

      setIsMultipleProducts(isMultipleProducts);

      form.setFieldValue("product", product);
      form.setFieldValue("products", products);
    },
    [layoutState.isOpen, form, opportunity?.products, setIsMultipleProducts]
  );

  const onFinish = async (values) => {
    let products = [];

    if (isMultipleProducts) {
      products = values.products || [];
    } else if (values.product) {
      products = [{ id: values.product, quantity: 1 }];
    }

    setOpportunityProducts.mutate({
      id: opportunity._id,
      products,
    });
  };

  return (
    <Modal
      confirmLoading={setOpportunityProducts.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      open={layoutState.isOpen}
      title={"Set Products"}
    >
      <Form
        form={form}
        initialValues={{ products: [], product: null }}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        onFinish={onFinish}
      >
        <OpportunityProductsSelect
          isMultiple={isMultipleProducts}
          setIsMultiple={setIsMultipleProducts}
        />
      </Form>
    </Modal>
  );
};
