import MailOutlined from "@ant-design/icons/MailOutlined";
import { useSignIn, useSignUp } from "@clerk/clerk-react";
import { Button, Form, Input } from "antd";
import { useRef, useState } from "react";
import { Fade } from "react-awesome-reveal";

import { styles } from "@evolved/constants";

import { FullScreenCard } from "components/full-screen-card";
import { LogoLarge } from "components/logo-large";
import { useNotification } from "contexts/notification/use-notification";
import { useAutoFocus } from "hooks/use-auto-focus";
import { useLoading } from "hooks/use-loading";

const { CLICKABLE_STYLE } = styles;

export const LoginRoute = () => {
  const { signIn, setActive: setLoginActive } = useSignIn();
  const { signUp, setActive: setSignUpActive } = useSignUp();
  const notification = useNotification();

  const [type, setType] = useState("login");
  const [form, setForm] = useState("email");

  const loading = useLoading();

  const inputRef = useRef();
  useAutoFocus(inputRef);

  const sendLoginEmailMagicLink = async (values) => {
    loading.start();

    try {
      const { supportedFirstFactors } = await signIn.create({
        identifier: values.email,
      });

      const firstEmailFactor = supportedFirstFactors.find((factor) => {
        return factor.strategy === "email_code";
      });

      const { emailAddressId } = firstEmailFactor;

      await signIn.prepareFirstFactor({
        strategy: "email_code",
        emailAddressId,
      });

      await loading.wait();

      setForm("code");
    } catch (error) {
      // todo: handle errors properly inline vs notifications popup
      // popup can be jarring when it's simply a logged in email
      // popup should only be for something unexpected
      // could define a way to have expected errors that are handled
      // by notifications context on query and those that should be bubbled up to the component
      await loading.wait();

      notification.support(error);
    }

    loading.stop();
  };

  const sendSignUpEmailMagicLink = async (values) => {
    loading.start();

    try {
      await signUp.create({
        emailAddress: values.email,
      });

      await signUp.prepareEmailAddressVerification();
      await loading.wait();

      setForm("code");
    } catch (error) {
      await loading.wait();

      notification.support(error);
    }

    loading.stop();
  };

  const sendLoginCode = async (values) => {
    loading.start();

    try {
      await signIn.attemptFirstFactor({
        strategy: "email_code",
        code: values.code.trim(),
      });

      await setLoginActive({ session: signIn.createdSessionId });
      loading.stop();
    } catch (error) {
      await loading.wait();

      notification.support(error);
      loading.stop();
    }
  };

  const sendSignUpCode = async (values) => {
    loading.start();

    try {
      await signUp.attemptEmailAddressVerification({
        code: values.code,
      });

      await setSignUpActive({ session: signUp.createdSessionId });

      loading.stop();
    } catch (error) {
      await loading.wait();

      notification.support(error);
      loading.stop();
    }
  };

  return (
    <>
      <FullScreenCard cardClass="w-96">
        <Fade>
          <LogoLarge className="mb-8" />
        </Fade>
        {form === "email" && (
          <>
            <Form
              disabled={loading.state}
              initialValues={{ email: "" }}
              onFinish={
                type === "login"
                  ? sendLoginEmailMagicLink
                  : sendSignUpEmailMagicLink
              }
              onFinishFailed={() => {
                inputRef.current.focus();
              }}
              wrapperCol={{ span: 24 }}
            >
              <Fade delay={100}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      type: "email",
                      required: true,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input ref={inputRef} prefix={<MailOutlined />} />
                </Form.Item>
              </Fade>
              <Fade delay={200}>
                <Button
                  className="mb-2 w-full"
                  htmlType="submit"
                  loading={loading.state}
                  type="primary"
                >
                  {type === "login" ? "Login" : "Sign Up"}
                </Button>
              </Fade>
            </Form>
            {type === "login" ? (
              <div
                onClick={() => setType("sign-up")}
                style={{ ...CLICKABLE_STYLE, marginTop: "4px" }}
              >
                Don't have an account? Sign Up.
              </div>
            ) : (
              <div
                onClick={() => setType("login")}
                style={{ ...CLICKABLE_STYLE, marginTop: "4px" }}
              >
                Already have an account? Log In.
              </div>
            )}
          </>
        )}
        {form === "code" && (
          <>
            <p className="mb-1 text-sm font-semibold">Verification Code</p>
            <p className="mb-6 text-sm">
              has been sent to your Email. It may take a couple of minutes.
            </p>
            <Form
              disabled={loading.state}
              initialValues={{ code: "" }}
              onFinish={type === "login" ? sendLoginCode : sendSignUpCode}
              onFinishFailed={() => {
                inputRef.current.focus();
              }}
              wrapperCol={{ span: 24 }}
            >
              <Fade delay={100}>
                <Form.Item
                  name="code"
                  rules={[
                    {
                      required: true,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input ref={inputRef} />
                </Form.Item>
              </Fade>
              <Fade delay={200}>
                <Button
                  className="mb-2 w-full"
                  htmlType="submit"
                  loading={loading.state}
                  type="primary"
                >
                  Submit
                </Button>
              </Fade>
            </Form>
            <div
              onClick={() => setForm("email")}
              style={{ ...CLICKABLE_STYLE, marginTop: "4px" }}
            >
              Experiencing an issue? Try again.
            </div>
          </>
        )}
      </FullScreenCard>
    </>
  );
};
