import { PRIMARY } from "./colors.js";

export const STATE_TAG_STYLE = {
  cursor: "default",
  fontWeight: "700",
  marginLeft: "15px",
  textAlign: "center",
};

export const CLICKABLE_STYLE = {
  color: PRIMARY,
  cursor: "pointer",
};

export const PRIMARY_TEXT = {
  fontSize: "1.1em",
};
