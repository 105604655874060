import { Form } from "antd";

import { ContactSelect } from "components/selects/contact-select";
import { useOnChange } from "hooks/use-on-change";
import { useSetAccountContacts } from "data/use-accounts";
import { ContactDetailsModal } from "../../../components/modals/contact-details-modal";
import { useModal } from "../../../hooks/use-modal";
import { Modal } from "components/modal";

export const SetContacts = ({ close, isOpen, account }) => {
  const setAccountContacts = useSetAccountContacts({
    onSuccess: close,
  });

  const { modal: contactDetailsModal, open: openContactDetailsModal } =
    useModal(ContactDetailsModal);

  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setAccountContacts.mutate({
      id: account._id,
      contactIds: values.contactIds,
    });
  };

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        contactIds: account.contacts,
      });
    },
    [isOpen, form, account]
  );

  return (
    <>
      {contactDetailsModal}
      <Modal
        confirmLoading={setAccountContacts.isLoading}
        okText="Set Contacts"
        onCancel={close}
        onOk={form.submit}
        title="Contacts"
        open={isOpen}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          onFinish={onFinish}
        >
          <ContactSelect
            label="Contacts"
            mode="multiple"
            name="contactIds"
            placeholder="Select contacts"
            extra={
              <div style={{ marginTop: "8px" }}>
                <a
                  onClick={() =>
                    openContactDetailsModal({
                      disableRelationshipSelect: true,
                      onCreate: (contact) => {
                        const current = form.getFieldValue("contactIds");

                        form.setFieldValue("contactIds", [
                          ...current,
                          contact._id,
                        ]);
                      },
                    })
                  }
                >
                  Create
                </a>{" "}
                a new contact
              </div>
            }
          />
        </Form>
      </Modal>
    </>
  );
};
