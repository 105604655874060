import { Tags as TagsList } from "components/lists/tags";

import { useTagContact, useUntagContact } from "data/use-contacts";

export const Tags = (props) => {
  const { contact } = props;
  const { tags } = contact;

  const tagContact = useTagContact();
  const untagContact = useUntagContact();

  const addTag = {
    isLoading: tagContact.isLoading,
    mutate: (tagId) => tagContact.mutate({ id: contact._id, tagId }),
  };

  const removeTag = {
    isLoading: untagContact.isLoading,
    mutate: (tagId) => untagContact.mutate({ id: contact._id, tagId }),
  };

  return (
    <TagsList
      isSelect
      addTag={addTag}
      removeTag={removeTag}
      selectedTags={tags}
    />
  );
};
