const colors = [
  "#EC7252",
  "#ED7C52",
  "#EE8653",
  "#F09053",
  "#F19A54",
  "#D57B62",
  "#B95C70",
  "#9E3E7D",
  "#821F8B",
  "#660099",
  "#6822A6",
  "#6A45B3",
  "#6C67C1",
  "#6E8ACE",
  "#70ACDB",
  "#6FB3CC",
  "#6FBABE",
  "#6EC2AF",
  "#6EC9A1",
  "#6DD092",
  "#6DD092",
];

export const getFunnelProgressColor = (percent) => {
  return colors[Math.floor(percent / 5)];
};
