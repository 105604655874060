import { Button } from "antd";
import { useLogOut } from "../stores/authentication";

export const LogOutButton = () => {
  const logOut = useLogOut();

  return (
    <Button onClick={logOut} type="primary">
      Logout
    </Button>
  );
};
