import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import noop from "lodash/noop";
import create from "zustand";

export const CREATE_ACCOUNT_FOLLOWUP_MODAL = "CREATE_ACCOUNT_FOLLOWUP_MODAL";
export const CREATE_CONTACT_FOLLOWUP_MODAL = "CREATE_CONTACT_FOLLOWUP_MODAL";
export const CREATE_OPPORTUNITY_FOLLOWUP_MODAL =
  "CREATE_OPPORTUNITY_FOLLOWUP_MODAL";
export const CREATE_VENDOR_FOLLOWUP_MODAL = "CREATE_VENDOR_FOLLOWUP_MODAL";
export const NOTIFICATIONS_SIDER = "NOTIFICATIONS_SIDER";
export const PERFORM_ACCOUNT_ACTIVITY_MODAL = "PERFORM_ACCOUNT_ACTIVITY_MODAL";
export const PERFORM_CONTACT_ACTIVITY_MODAL = "PERFORM_CONTACT_ACTIVITY_MODAL";
export const PERFORM_OPPORTUNITY_ACTIVITY_MODAL =
  "PERFORM_OPPORTUNITY_ACTIVITY_MODAL";
export const PERFORM_VENDOR_ACTIVITY_MODAL = "PERFORM_VENDOR_ACTIVITY_MODAL";
export const UPDATE_FOLLOWUP_MODAL = "UPDATE_FOLLOWUP_MODAL";
export const SET_OPPORTUNITY_PRODUCTS_MODAL = "SET_OPPORTUNITY_PRODUCTS_MODAL";

const getLocalStorageState = (key) => {
  const state = localStorage.getItem(`LAYOUT_STATE.${key}`);

  try {
    return JSON.parse(state);
  } catch {
    return {};
  }
};

const setLocalStorageState = (key, state) => {
  localStorage.setItem(`LAYOUT_STATE.${key}`, JSON.stringify(state));
};

export const getDefaultLayoutState = () =>
  [[NOTIFICATIONS_SIDER, { isOpen: true }]].reduce(
    (memo, [constant, defaultState]) => ({
      ...memo,
      [constant]: {
        ...defaultState,
        ...getLocalStorageState(constant),
      },
    }),
    {}
  );

const getDefaultModalState = () =>
  [
    CREATE_ACCOUNT_FOLLOWUP_MODAL,
    CREATE_CONTACT_FOLLOWUP_MODAL,
    CREATE_OPPORTUNITY_FOLLOWUP_MODAL,
    CREATE_VENDOR_FOLLOWUP_MODAL,
    PERFORM_ACCOUNT_ACTIVITY_MODAL,
    PERFORM_CONTACT_ACTIVITY_MODAL,
    PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
    PERFORM_VENDOR_ACTIVITY_MODAL,
    SET_OPPORTUNITY_PRODUCTS_MODAL,
    UPDATE_FOLLOWUP_MODAL,
  ].reduce(
    (memo, constant) => ({
      ...memo,
      [constant]: { isOpen: false, payload: {} },
    }),
    {}
  );

const updateState = (set) => (updateValue) => (key, payload, afterClose) => {
  if (isFunction(payload)) {
    afterClose = payload;
    payload = undefined;
  }

  set((state) => {
    const currentState = state[key];

    if (isNil(currentState)) return {};

    const updatedValue = updateValue(currentState, payload);

    setLocalStorageState(key, updatedValue);

    return { [key]: updatedValue };
  });

  (afterClose || noop)();
};

export const useLayoutStore = create((set) => ({
  ...getDefaultLayoutState(),
  ...getDefaultModalState(),

  open: updateState(set)((currentState, payload = {}) => ({
    ...currentState,
    payload,
    isOpen: true,
  })),

  close: updateState(set)((currentState) => ({
    ...currentState,
    isOpen: false,
    payload: {},
  })),

  reset: () => {
    set({
      ...getDefaultLayoutState(),
      ...getDefaultModalState(),
    });
  },

  toggle: updateState(set)((currentState) => ({
    isOpen: !currentState.isOpen,
  })),
}));
