import { select } from "radash";

import { useContacts } from "data/use-contacts";

export const ContactsSet = (props) => {
  const { value } = props;

  const contacts = useContacts();

  return select(
    value,
    (id) => contacts.byId(id).alias,
    (id) => !!contacts.byId(id)
  ).join(", ");
};
