import find from "lodash/find";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Form,
  InputNumber,
  Input,
  Select,
  Tooltip,
} from "antd";
import { useState } from "react";

import { UserSelect } from "components/selects/user-select";
import { useUpdateSalesProcessStep } from "data/use-sales-processes";
import { useOnChange } from "hooks/use-on-change";
import { useOpportunityStates } from "data/use-opportunity-states";
import { Modal } from "components/modal";

const onSubmit =
  ({ setError, step, updateStep }) =>
  async (values) => {
    setError();

    if (values.followups.some((s) => !s.task || !s.delayInDays)) {
      setError("All followups must have a task and a delay in days.");
      return;
    }

    const triggers = {
      followups: isEmpty(values.followups) ? [] : values.followups,
      ...(values.automaticStateChange
        ? { stateChange: { id: values.automaticStateChange } }
        : {}),
    };

    await updateStep.mutate({
      id: step._id,
      ...pick(values, ["question", "statusTriggerId"]),
      triggers,
    });
  };

export const UpdateSalesProcessStep = (props) => {
  const { close, isOpen, step } = props;

  const [error, setError] = useState();

  const opportunityStates = useOpportunityStates().all();

  const updateStep = useUpdateSalesProcessStep({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      setError();
      form.setFieldsValue({
        ...pick(step, ["question"]),
        automaticStateChange: find(opportunityStates, {
          _id: get(step, "triggers.stateChange.id"),
        })
          ? get(step, "triggers.stateChange.id")
          : undefined,
        followups: get(step, "triggers.followups", []),
      });
    },
    [isOpen, form, step]
  );

  return (
    <Modal
      confirmLoading={updateStep.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      title="Update Step"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          setError,
          step,
          updateStep,
        })}
      >
        <Form.Item
          label="Question"
          name="question"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        <div
          style={{
            alignItems: "center",
            display: "flex",
          }}
        >
          <h3 style={{ margin: "0px 8px 0px 0px" }}>Auto Stage Change</h3>
          <Tooltip title="Automatically update the opportunity stage when this step is completed.">
            <InfoCircleOutlined style={{ fontSize: "16px" }} />
          </Tooltip>{" "}
          <Form.Item name="automaticStateChange" noStyle>
            <Select
              allowClear
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={opportunityStates.map(({ _id, label }) => ({
                label,
                value: _id,
              }))}
              placeholder="--"
              style={{ flexGrow: 1, marginLeft: "16px" }}
            />
          </Form.Item>
        </div>
        <Form.List name="followups">
          {(fields, arrayHelpers) => (
            <div style={{ margin: "16px 0px 8px 0px" }}>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "32px",
                }}
              >
                <h3 style={{ margin: "0px 8px 0px 0px" }}>
                  Scheduled Followups{" "}
                </h3>
                <Tooltip title="Automatically schedule followups based on when this step was performed.">
                  <InfoCircleOutlined style={{ fontSize: "16px" }} />
                </Tooltip>{" "}
                <Button
                  icon={<PlusOutlined />}
                  onClick={() =>
                    arrayHelpers.add({
                      id: crypto.randomUUID(),
                      delayInDays: 1,
                      task: "",
                    })
                  }
                  style={{ marginLeft: "auto" }}
                >
                  Schedule
                </Button>
              </div>
              {fields.map((field, index) => {
                return (
                  <div key={index} style={{ marginBottom: "32px" }}>
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "24px",
                        width: "100%",
                      }}
                    >
                      <Form.Item name={[field.key, "task"]} noStyle>
                        <Input.TextArea
                          style={{ margin: "0px 12px 0px 0px" }}
                        />
                      </Form.Item>
                      <Button
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        marginBottom: "12px",
                        width: "100%",
                      }}
                    >
                      Schedule{" "}
                      <Form.Item name={[field.key, "delayInDays"]} noStyle>
                        <InputNumber
                          min={1}
                          style={{
                            margin: "0px 12px",
                            width: "75px",
                          }}
                        />
                      </Form.Item>{" "}
                      day(s) after activity, and assign it to
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        width: "428px",
                      }}
                    >
                      <UserSelect
                        allowClear
                        name={[field.key, "assignToId"]}
                        noStyle
                        placeholder="Sales rep who logs the triggering activity"
                        style={{
                          margin: "4px 24px 0px 0px",
                          width: "100%",
                        }}
                      />
                      <Tooltip title="If the sales rep cannot be found in the future because they were archived then this will fall back to the rep performing the triggering activity.">
                        <InfoCircleOutlined style={{ fontSize: "16px" }} />
                      </Tooltip>{" "}
                    </div>
                    {index < fields.length - 1 && <Divider />}
                  </div>
                );
              })}
            </div>
          )}
        </Form.List>
      </Form>

      {error && (
        <Alert
          message={error}
          style={{ margin: "-12px 0px 12px 0px" }}
          type="error"
        />
      )}
    </Modal>
  );
};
