import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Input } from "antd";
import { useRef } from "react";
import { Fade } from "react-awesome-reveal";

import { Card } from "components/card";
import { handleError } from "components/handle-error";
import { fetcher } from "data/fetcher";
import { useMe } from "data/use-me";
import { useOrganization } from "data/use-organization";
import { useAutoFocus } from "hooks/use-auto-focus";
import { useLoading } from "hooks/use-loading";

export const CreateOrganizationForm = () => {
  const queryClient = useQueryClient();

  const loading = useLoading();

  const mutation = useMutation({
    mutationFn: async (args) => {
      loading.start();

      return fetcher.post("/organization")(args);
    },
    onSuccess: async () => {
      await loading.wait();

      // todo: test this invalidation
      queryClient.invalidateQueries({ queryKey: useMe.key });
      queryClient.invalidateQueries({ queryKey: useOrganization.key });
    },
    onError: async (error) => {
      await loading.wait();

      handleError(error);

      loading.stop();
    },
  });

  const inputRef = useRef();
  useAutoFocus(inputRef);

  return (
    <Card className="w-96">
      <Form
        disabled={loading.state}
        initialValues={{ name: "" }}
        onFinish={mutation.mutate}
        onFinishFailed={() => {
          inputRef.current.focus();
        }}
        wrapperCol={{ span: 24 }}
      >
        <Fade delay={100}>
          <p className="mb-8 text-left text-2xl dark:text-white">
            What is your organization called?
          </p>
          <div className="mb-8">
            <Form.Item
              name="name"
              rules={[
                {
                  type: "string",
                  max: 84,
                  validateTrigger: "onSubmit",
                  required: true,
                },
              ]}
            >
              <Input ref={inputRef} />
            </Form.Item>
          </div>
        </Fade>
        <Fade delay={200}>
          <Button
            className="w-full"
            htmlType="submit"
            loading={loading.state}
            type="primary"
          >
            Next
          </Button>
        </Fade>
      </Form>
    </Card>
  );
};
