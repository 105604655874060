import { Alert, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import get from "lodash/get";

import { InfoCard } from "components/info-card";
import { useModal } from "hooks/use-modal";
import { RequestChangeEmailModal } from "./request-change-email-modal";

export const Details = (props) => {
  const { profile } = props;

  const [searchParams, setSearchParams] = useSearchParams();

  const { modal: changeEmailModal, open: openChangeEmailModal } = useModal(
    RequestChangeEmailModal
  );

  return (
    <>
      {changeEmailModal}
      {searchParams.get("change_email_request_success") === "true" && (
        <Alert
          afterClose={() => {
            searchParams.delete("change_email_request_success");
            setSearchParams(searchParams);
          }}
          closable
          message="Your email has been successfully changed!"
          style={{ marginBottom: "16px" }}
          type="success"
        />
      )}
      {searchParams.get("change_email_request_expired") === "true" && (
        <Alert
          afterClose={() => {
            searchParams.delete("change_email_request_expired");
            setSearchParams(searchParams);
          }}
          closable
          message="Sorry, but that change link has expired. Go ahead and make a new request."
          style={{ marginBottom: "16px" }}
          type="warning"
        />
      )}
      <Row gutter={16} type="flex">
        <InfoCard
          content={
            <>
              <div>{get(profile, "email", "--")}</div>
              <div>
                <a onClick={openChangeEmailModal}>Change</a>
              </div>
            </>
          }
          label={<span style={{ fontSize: "16px" }}>Email</span>}
        />
      </Row>
    </>
  );
};
