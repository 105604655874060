import { App } from "antd";
import { useEffectOnce } from "react-use";

import { userRoles } from "@evolved/constants";
import { useSearchParams } from "react-router-dom";

import { Details } from "./details";
import { Header } from "./header";
import { NylasConfig } from "./nylas-config";
import { ScheduledEmails } from "./scheduled-emails";
import { SelectTimezone } from "./select-timezone";
import { useMe } from "data/use-me";
import { useOrganization } from "data/use-organization";

const { ROLES } = userRoles;

const hasIntegrationError = (searchParams) => {
  return searchParams.get("integration_error") === "true";
};

const hasIntegrationSuccess = (searchParams) => {
  return searchParams.get("integration_success") === "true";
};

export const ProfileRoute = () => {
  const { message } = App.useApp();
  const organization = useOrganization();
  const profile = useMe();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffectOnce(() => {
    if (hasIntegrationError(searchParams)) {
      message.error(
        "Integration failed, try again or contact customer support"
      );
    }

    if (hasIntegrationSuccess(searchParams)) {
      message.success("Integration Succeeded");
    }

    const next = new URLSearchParams(searchParams);
    next.delete("integration_error");
    next.delete("integration_success");

    setSearchParams(next);
  });

  return (
    <div className="flex w-full justify-center">
      <div style={{ maxWidth: "768px" }}>
        <Header profile={profile} />
        <div style={{ marginBottom: "24px" }}>
          <Details profile={profile} />
        </div>
        {profile.role <= ROLES.SALES && (
          <div style={{ marginBottom: "24px" }}>
            <SelectTimezone organization={organization} profile={profile} />
          </div>
        )}
        {profile.role <= ROLES.SALES && (
          <div style={{ marginBottom: "24px" }}>
            <ScheduledEmails profile={profile} />
          </div>
        )}
        {profile.role <= ROLES.SALES && (
          <div style={{ marginBottom: "24px" }}>
            <NylasConfig profile={profile} />
          </div>
        )}
      </div>
    </div>
  );
};
