import { opportunityFields } from "@evolved/views";

import {
  usePatchOpportunity,
  useSetOpportunityContacts,
  useSetOpportunityTags,
  useSetOpportunityUsers,
  useSetOpportunityVendors,
} from "../../../../../data/use-opportunities";
import { swallow } from "../../../../../utils/swallow";
import { EditableAccountSelect } from "./editable-account-select";
import { EditableContactsSelect } from "./editable-contacts-select";
import { EditableDateCell } from "./editable-date-cell";
import { EditableNumberCell } from "./editable-number-cell";
import { EditablePartnersSelect } from "./editable-partners-select";
import { EditableProductsSelect } from "./editable-products-select";
import { EditableSalesProcessSelect } from "./editable-sales-process-select";
import { EditableTagsSelect } from "./editable-tags-select";
import { EditableTextCell } from "./editable-text-cell";
import { EditableUserDefinedFieldCell } from "./editable-user-defined-field";
import { EditableUsersSelect } from "./editable-users-select";

const {
  account,
  alias,
  contacts,
  partners,
  products,
  salesProcess,
  startedOn,
  summary,
  tags,
  targetWinDate,
  users,
  value,
} = opportunityFields;

const UserDefinedField = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (userDefinedFields) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        userDefinedFields,
      })
    );
  };

  return (
    <EditableUserDefinedFieldCell
      {...rest}
      onUpdate={onUpdate}
      viewType={"OPPORTUNITY"}
    />
  );
};

const Account = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (accountId) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        accountId,
      })
    );
  };

  return <EditableAccountSelect {...rest} onUpdate={onUpdate} />;
};

const Alias = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (alias) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        alias,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Contacts = (props) => {
  const { row, ...rest } = props;

  const setOpportunityContacts = useSetOpportunityContacts();

  const onUpdate = (contactIds) => {
    return swallow(
      setOpportunityContacts.mutateAsync({
        id: row._id,
        contactIds,
      })
    );
  };

  return <EditableContactsSelect onUpdate={onUpdate} {...rest} />;
};

const Partners = (props) => {
  const { row, ...rest } = props;

  const setOpportunityVendors = useSetOpportunityVendors();

  const onUpdate = (vendorIds) => {
    return swallow(
      setOpportunityVendors.mutateAsync({
        id: row._id,
        vendorIds,
      })
    );
  };

  return <EditablePartnersSelect onUpdate={onUpdate} {...rest} />;
};

const Products = (props) => {
  const { row, ...rest } = props;

  return <EditableProductsSelect {...rest} opportunity={row} />;
};

const SalesProcess = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (salesProcessId) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        salesProcessId,
      })
    );
  };

  return <EditableSalesProcessSelect {...rest} onUpdate={onUpdate} />;
};

const StartedOn = (props) => {
  const { row, ...rest } = props;
  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (startedOn) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        startedOn,
      })
    );
  };

  return <EditableDateCell {...rest} onUpdate={onUpdate} />;
};

const Summary = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (summary) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        summary,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Tags = (props) => {
  const { row, ...rest } = props;

  const setOpportunityTags = useSetOpportunityTags();

  const onUpdate = (tagIds) => {
    return swallow(
      setOpportunityTags.mutateAsync({
        id: row._id,
        tagIds,
      })
    );
  };

  return <EditableTagsSelect onUpdate={onUpdate} {...rest} />;
};

const TargetWinDate = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (targetWinDate) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        targetWinDate,
      })
    );
  };

  return <EditableDateCell {...rest} onUpdate={onUpdate} />;
};

const Users = (props) => {
  const { row, ...rest } = props;

  const setOpportunityUsers = useSetOpportunityUsers();

  const onUpdate = (userIds) => {
    return swallow(
      setOpportunityUsers.mutateAsync({
        id: row._id,
        userIds,
      })
    );
  };

  return <EditableUsersSelect onUpdate={onUpdate} {...rest} />;
};

const Value = (props) => {
  const { row, ...rest } = props;

  const patchOpportunity = usePatchOpportunity();

  const onUpdate = (value) => {
    return swallow(
      patchOpportunity.mutateAsync({
        id: row._id,
        value,
      })
    );
  };

  return <EditableNumberCell {...rest} addonBefore="$" onUpdate={onUpdate} />;
};

export const opportunityCells = {
  [account.dataIndex]: Account,
  [alias.dataIndex]: Alias,
  [contacts.dataIndex]: Contacts,
  [partners.dataIndex]: Partners,
  [products.dataIndex]: Products,
  [salesProcess.dataIndex]: SalesProcess,
  [startedOn.dataIndex]: StartedOn,
  [summary.dataIndex]: Summary,
  [tags.dataIndex]: Tags,
  [targetWinDate.dataIndex]: TargetWinDate,
  [users.dataIndex]: Users,
  [value.dataIndex]: Value,
  userDefinedField: UserDefinedField,
};
