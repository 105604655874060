import filter from "lodash/filter";

export const filterAccountContacts = ({ accountId, contacts }) => {
  if (!accountId) {
    return [];
  }

  return filter(contacts, (contact) => {
    return contact.accountId === accountId;
  });
};
