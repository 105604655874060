import { alphabetical, isArray, isEmpty, sift, unique } from "radash";

// initialIds could be archived, so this handles merging them in, uniquly
export const getSelectOptions = ({
  store,
  filter,
  initialIds,
  labelKey = "name",
  sortAlphabetical = true,
} = {}) => {
  initialIds = isArray(initialIds) ? initialIds : [initialIds];
  initialIds = sift(initialIds);

  let options = store.all({ includeArchived: false });

  if (filter) {
    options = options.filter(filter);
  }

  options = options.map((entity) => ({
    value: entity._id,
    label: entity[labelKey],
  }));

  if (!isEmpty(initialIds)) {
    options = unique(
      [...options, ...store.optionsByIds(initialIds)],
      ({ value }) => value
    );
  }

  return sortAlphabetical ? alphabetical(options, (o) => o.label) : options;
};
