import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import React from "react";
import { Avatar, Card, Col, Divider, Row } from "antd";
import { useParams } from "react-router-dom";

import { Header } from "./header";
import { UpdateSalesProcessStep } from "./modals/update-sales-process-step";
import { useModal } from "../../hooks/use-modal";
import { useOpportunityStates } from "../../data/use-opportunity-states";
import { useSalesProcesses } from "data/use-sales-processes";
import { useUsers } from "data/use-users";
import { withManagerRole } from "../../components/with-role";
import { withEntityExists } from "../with-entity-exists";

const UpdateQuestion = withManagerRole((props) => {
  const { step } = props;

  const { modal, open } = useModal(UpdateSalesProcessStep);

  return (
    <>
      {modal}
      <span
        className={"primary-clickable"}
        onClick={() => open({ step })}
        style={{ marginLeft: "auto" }}
      >
        Update Question
      </span>
    </>
  );
});

const Questions = (props) => {
  const { opportunityStates, steps, users } = props;

  return (
    <>
      {map(steps, (step) => {
        const statusTrigger = get(step, "statusTrigger.label");
        const opportunityState = find(opportunityStates, {
          _id: get(step, "triggers.stateChange.id"),
        });

        return (
          <React.Fragment key={step._id}>
            <div style={{ padding: "12px" }}>
              <div style={{ alignItems: "center", display: "flex" }}>
                <div>
                  <Avatar style={{ backgroundColor: "#9d67af", width: "38px" }}>
                    {step.funnelTrigger}%
                  </Avatar>
                </div>
                <div style={{ marginLeft: "16px" }}>
                  <div style={{ fontSize: "1.2em" }}>{step.question}</div>
                  {statusTrigger ? (
                    <div
                      style={{ fontSize: "0.9em" }}
                    >{`Status Trigger: ${statusTrigger}`}</div>
                  ) : (
                    ""
                  )}
                  {opportunityState ? (
                    <div
                      style={{ fontSize: "0.9em" }}
                    >{`Auto Stage Change: ${opportunityState.label}`}</div>
                  ) : (
                    ""
                  )}
                </div>
                <UpdateQuestion key="update" step={step} />
              </div>
              <div style={{ marginTop: "16px" }}>
                {map(step?.triggers?.followups, (followup) => {
                  const user =
                    followup.assignToId &&
                    find(
                      users,
                      (user) => Number(user._id) === Number(followup.assignToId)
                    );

                  return (
                    <div
                      key={followup.id}
                      style={{ alignItems: "center", display: "flex" }}
                    >
                      <div style={{ fontSize: "1.2em" }}>{followup.task}</div>
                      <div style={{ margin: "0px 8px" }}>|</div>
                      <div style={{ fontSize: "0.9em" }}>
                        <strong>{followup.delayInDays}</strong> day(s)
                        {followup.delayInDays > 1 ? "s " : " "}
                        after this step was performed, assign to{" "}
                        {user?.alias || "the sales rep who logged the activity"}
                        .
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <Divider style={{ margin: "0px" }} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export const SalesProcessRoute = withEntityExists(useSalesProcesses)(() => {
  const id = Number(useParams().id);

  const opportunityStates = useOpportunityStates().all();
  const salesProcess = useSalesProcesses().byId(id);
  const users = useUsers().all();

  return (
    <div className="w-full">
      <Row justify={"center"} type={"flex"}>
        <Col xs={24} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <Header salesProcess={salesProcess} />
          </div>
          <Card bodyStyle={{ padding: "0px" }} bordered={false}>
            <Questions
              opportunityStates={opportunityStates}
              steps={salesProcess.steps}
              users={users}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
});
