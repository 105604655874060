import { message } from "antd";

export const handleError = (error) => {
  message.error({
    content: (
      <span>
        Something unexpected has happened and the team has been notified.
        <br />
        {error?.message ?? error}
      </span>
    ),
    duration: 5,
  });
};
