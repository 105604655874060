import { noop } from "lodash";

import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation";

export const useLog = (overrides) => {
  return useMutation(
    {
      // body = [{message, stack}]
      mutationFn: async (body) => {
        return fetcher.post(`/log`)(body);
      },
      onSuccess: noop,
      onFinal: noop,
      silenceEmail: true,
    },
    overrides
  );
};
