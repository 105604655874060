import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useArchiveProduct } from "data/use-products";

export const ArchiveProduct = (props) => {
  const { product } = props;

  const archiveProduct = useArchiveProduct({
    onSuccess: async (data, { mutation }) => {
      await mutation.onSuccess.call();
    },
  });

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() => archiveProduct.mutateAsync({ id: product._id })}
      title="Are you sure?"
    >
      <Button
        icon={<EyeInvisibleOutlined />}
        disabled={archiveProduct.isLoading}
      >
        Archive
      </Button>
    </Popconfirm>
  );
};
