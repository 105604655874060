import { Form } from "antd";
import { useEffect } from "react";

import { isBeforePerformedOn } from "./steps";

export const OnPerformedOn = (props) => {
  const { form, steps } = props;

  const performedOn = Form.useWatch("performedOn", form);

  useEffect(() => {
    const adjustments = form.getFieldValue("steps");

    if (!steps || !adjustments) return;

    const adjustedSteps = steps.map((originalStep, index) => {
      const adjustedStep = adjustments[index];

      if (!isBeforePerformedOn(performedOn)(originalStep.lastImpactedOn))
        return originalStep.isComplete;

      return adjustedStep;
    });

    form.setFieldValue("steps", adjustedSteps);
  }, [performedOn]);

  return null;
};
