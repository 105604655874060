import get from "lodash/get";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import isUndefined from "lodash/isUndefined";

import { permissions } from "@evolved/constants";

const { accessLevels, defaults } = permissions;

// todo: test this
export const hasAccess =
  (user) =>
  (organization) =>
  (permissions) =>
  (options = {}) => {
    permissions = isArray(permissions) ? permissions : [permissions];

    const { isAssigned = () => false, isResponsible = () => false } = options;

    if (!user || !organization) {
      return false;
    }

    for (const permission of permissions) {
      const rbac = get(
        Object.assign({}, defaults, organization.accessControl),
        `${permission}:${user.role}`
      );

      if (isUndefined(rbac)) {
        return false;
      }

      if (isBoolean(rbac) && !rbac) {
        return false;
      }

      if (accessLevels.RESPONSIBLE === rbac && !isResponsible()) {
        return false;
      }

      // A responsible user is assigned by default
      if (accessLevels.ASSIGNED === rbac && !isAssigned() && !isResponsible()) {
        return false;
      }
    }

    return true;
  };
