import { Tags as TagsList } from "components/lists/tags";

import { useTagProduct, useUntagProduct } from "data/use-products";

export const Tags = (props) => {
  const { product } = props;
  const { tags } = product;

  const tagProduct = useTagProduct();
  const untagProduct = useUntagProduct();

  const addTag = {
    isLoading: tagProduct.isLoading,
    mutate: (tagId) => tagProduct.mutate({ id: product._id, tagId }),
  };

  const removeTag = {
    isLoading: untagProduct.isLoading,
    mutate: (tagId) => untagProduct.mutate({ id: product._id, tagId }),
  };

  return (
    <TagsList
      isSelect
      addTag={addTag}
      removeTag={removeTag}
      selectedTags={tags}
    />
  );
};
