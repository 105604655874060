import CountUp from "react-countup";
import day from "dayjs";
import { Fade } from "react-awesome-reveal";
import { RocketOutlined } from "@ant-design/icons";
import { Statistic } from "antd";
import { isEmpty, last, sort } from "radash";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { RangeSelect } from "./range-select";
import { WidgetContainer } from "./widget-container";
import { WidgetHeader } from "./widget-header";
import { colors } from "@evolved/constants";
import { formatDollars } from "../../utils/format-dollars";
import { long } from "../../utils/format-date";
import { useOpportunities } from "../../data/use-opportunities";
import { useOpportunityStates } from "../../data/use-opportunity-states";

const countFormatter = (value) => <CountUp end={value} separator="," />;
const dollarFormatter = (value) => (
  <CountUp end={value} separator="," prefix="$" />
);

export const WonOpportunities = (props) => {
  const { widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets } =
    props.dateRange;

  const opportunities = useOpportunities().all();
  const wonState = useOpportunityStates()
    .all()
    .find(({ systemKey }) => systemKey === "WON");

  const wonOpportunities = opportunities.filter(
    ({ stateId, lastStateChangeOn }) =>
      stateId === wonState._id &&
      (isEmpty(widgetFilter) ||
        (widgetFilter[0].isBefore(day(lastStateChangeOn * 1000)) &&
          widgetFilter[1].isAfter(day(lastStateChangeOn * 1000))))
  );

  const data = sort(wonOpportunities, o => o.lastStateChangeOn).reduce(
    (accumulator, { lastStateChangeOn, value }) => {
      const { total } = last(accumulator) || { total: 0 };

      accumulator.push({ total: total + value, date: long(lastStateChangeOn) });
      return accumulator;
    },
    []
  );

  const value = wonOpportunities.reduce((total, { value }) => total + value, 0);

  return (
    <Fade>
      <WidgetContainer>
        <div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              marginBottom: "24px",
            }}
          >
            <WidgetHeader label="Company Wins" />
            <RangeSelect
              tenses={["past", "all-time"]}
              {...{ widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets }}
            />
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              marginBottom: "24px",
            }}
          >
            <Statistic
              prefix={<RocketOutlined style={{ marginRight: "4px" }} />}
              formatter={countFormatter}
              value={wonOpportunities.length}
            />
            <Statistic formatter={dollarFormatter} value={value} />
          </div>
          {data.length > 1 && (
            <Fade>
              <div style={{ height: "250px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart
                    data={data}
                    margin={{
                      top: 10,
                      right: 30,
                      left: 30,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" hide />
                    <YAxis width={80} tickFormatter={formatDollars} />
                    <Tooltip formatter={formatDollars} />
                    <Area
                      type="monotone"
                      dataKey="total"
                      stroke={colors.PRIMARY}
                      fill={colors.PRIMARY}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>
            </Fade>
          )}
        </div>
      </WidgetContainer>
    </Fade>
  );
};
