import { viewTypes } from "@evolved/constants";

import * as accountFields from "./account-fields.js";
import * as activityFields from "./activity-fields.js";
import * as contactFields from "./contact-fields.js";
import * as opportunityFields from "./opportunity-fields.js";
import * as vendorFields from "./vendor-fields.js";

const { ACCOUNT, ACTIVITY, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

const VIEW_FIELDS = {
  [ACCOUNT]: accountFields,
  [ACTIVITY]: activityFields,
  [CONTACT]: contactFields,
  [OPPORTUNITY]: opportunityFields,
  [VENDOR]: vendorFields,
};

export const getViewFields = (type) => VIEW_FIELDS[type];
