import { Card } from "./card";
import { FullScreenCenter } from "./full-screen-center";

export const FullScreenCard = ({ children, cardClass }) => {
  return (
    <FullScreenCenter>
      <Card className={cardClass}>{children}</Card>
    </FullScreenCenter>
  );
};
