import includes from "lodash/includes.js";
import isArray from "lodash/isArray.js";
import isEmpty from "lodash/isEmpty.js";
import isString from "lodash/isString.js";

export const filter = (value, filter) => {
  return includes(filter, value);
};

export const validate = (filter) => {
  if (!isArray) return false;

  return !isEmpty(filter) && filter.every(isString);
};
