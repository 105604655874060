import { FilterOutlined } from "@ant-design/icons";
import { Button, Checkbox, message } from "antd";
import { useState } from "react";

import { accountStates } from "@evolved/constants";

import { validateFilter } from "./validate-filter";

const { display, STATES } = accountStates;

const CheckboxGroup = Checkbox.Group;

const getOptions = () => {
  return Object.values(STATES).map((state) => ({
    label: display(state),
    value: state,
  }));
};

export const AccountStateFilter = ({
  config,
  defaultValue = [],
  hide,
  onFilter,
  type,
}) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <CheckboxGroup
          onChange={setValue}
          options={getOptions()}
          value={value}
        />
      </div>
      <div style={{ display: "flex" }}>
        <Button
          icon={<FilterOutlined />}
          onClick={() => {
            return validateFilter(type, value)
              ? onFilter({ dataIndex: config.dataIndex, value })
              : message.warning("Filter must have at least one state.");
          }}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}
          type="primary"
        >
          Filter
        </Button>
        <Button
          onClick={hide}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
