import { select } from "radash";

import { useOpportunityStates } from "data/use-opportunity-states";

export const OpportunityStatesSet = (props) => {
  const { value } = props;

  const opportunityStates = useOpportunityStates();

  return select(
    value,
    (id) => opportunityStates.byId(id).label,
    (id) => !!opportunityStates.byId(id)
  ).join(", ");
};
