import { Tag } from "antd";
import { select } from "radash";

import { useSalesProcesses } from "../../../../../data/use-sales-processes";
import { Link } from "../../../../link";

export const SalesProcessesSet = (props) => {
  const { value } = props;

  const salesProcesses = useSalesProcesses();

  const selected = select(
    value,
    (id) => salesProcesses.byId(id),
    (id) => !!salesProcesses.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((salesProcess) => {
    return (
      <Link
        link={`/sales-processes/${salesProcess._id}`}
        key={salesProcess._id}
        style={salesProcess.isArchived ? { opacity: 0.5 } : {}}
      >
        <Tag style={salesProcess.isArchived ? { opacity: 0.5 } : {}}>
          {salesProcess.name}
        </Tag>
      </Link>
    );
  });
};
