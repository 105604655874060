import filter from "lodash/filter";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import take from "lodash/take";
import { Divider, Input } from "antd";
import { useState } from "react";

import { EditableTag } from "./editable-tag";

const LIMIT = 100;

export const ListItemImport = (props) => {
  const {
    items = [],
    label,
    onCreate,
    previewDescription = null,
    title,
  } = props;

  const [search, setSearch] = useState("");

  let filtered = items;

  if (!isEmpty(search)) {
    filtered = filter(filtered, (item) => {
      const { name } = item;

      return name.includes(search);
    });
  }

  const trimmed = take(filtered, LIMIT);

  return (
    <>
      <Divider orientation="left">{title}</Divider>
      {previewDescription}
      <div style={{ padding: "0px 16px", textAlign: "left", width: "400px" }}>
        {items && items.length > LIMIT && (
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder={"search..."}
            size="small"
            style={{ marginBottom: "16px", width: "200px" }}
          />
        )}
        {trimmed.length !== filtered.length ? (
          <div style={{ marginLeft: "4px", marginTop: "8px" }}>
            Showing {trimmed.length} of {filtered.length}
          </div>
        ) : null}
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexWrap: "wrap",
            paddingRight: "16px",
            maxWidth: "100%",
          }}
        >
          {map(trimmed, (item) => {
            const { color, key, onClick, onDelete, name } = item;

            return (
              <EditableTag
                {...(color ? { color } : {})}
                closeOnConfirm
                closeOnEmpty
                key={key}
                onConfirm={onClick}
                onDelete={onDelete}
                style={{ margin: "4px" }}
                value={name}
              />
            );
          })}
          {onCreate && <EditableTag label={label} onConfirm={onCreate} />}
        </div>
      </div>
    </>
  );
};
