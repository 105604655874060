import pick from "lodash/pick";
import { Checkbox, Form, Input } from "antd";

import { SalesProcessSelect } from "components/selects/sales-process-select";
import { useCreateProduct, useUpdateProduct } from "data/use-products";
import { useOnChange } from "hooks/use-on-change";
import { Modal } from "components/modal";

const CREATE = "create";
const UPDATE = "update";

const getType = (product) => (product ? UPDATE : CREATE);

const getInitialValues = (product) => {
  if (product) {
    return {
      ...pick(product, [
        "canCrossSell",
        "canPartialSell",
        "canSellQuantities",
        "canUpsell",
        "description",
        "name",
        "salesProcessId",
      ]),
    };
  }

  return {
    canCrossSell: false,
    canPartialSell: false,
    canSellQuantities: false,
    canUpsell: false,
    description: "",
    name: "",
    salesProcessId: null,
  };
};

const mapValues = (values) => {
  return {
    ...values,
    salesProcessId: values.salesProcessId || null,
  };
};

const MAP_VARIABLES = {
  [CREATE]: ({ values }) => mapValues(values),
  [UPDATE]: ({ product, values }) => ({
    id: product._id,
    ...mapValues(values),
  }),
};

const OK_TEXT = {
  [CREATE]: "Create",
  [UPDATE]: "Update",
};

const TITLES = {
  [CREATE]: "Create Product",
  [UPDATE]: "Details",
};

const onSubmit =
  ({ product, mutation, type }) =>
  (values) => {
    mutation.mutate(MAP_VARIABLES[type]({ product, values }));
  };

export const ProductDetailsModal = (props) => {
  const { close, isOpen, onDone = () => {}, product } = props;

  const type = getType(product);

  const onSuccess = async (result, { mutation }) => {
    await mutation.onSuccess.call();

    await onDone(result);

    close();
  };

  const createProduct = useCreateProduct({ onSuccess });
  const updateProduct = useUpdateProduct({ onSuccess });

  const mutation = type === CREATE ? createProduct : updateProduct;

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue(getInitialValues(product));
    },
    [isOpen, form]
  );

  return (
    <Modal
      confirmLoading={mutation.isLoading}
      maskClosable={false}
      okText={OK_TEXT[type]}
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title={TITLES[type]}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({ mutation, product, type })}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea rows={6} />
        </Form.Item>
        <Form.Item
          extra="You can sell more of this, after you sell it once."
          name="canUpsell"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox size="small">Can up-sell</Checkbox>
        </Form.Item>
        <Form.Item
          extra="You can sell some of this, before you have sold all of it."
          name="canPartialSell"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox size="small">Can partially sell</Checkbox>
        </Form.Item>
        <Form.Item
          extra="You might sell this to a customer who is already buying other, different product from you."
          name="canCrossSell"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox size="small">Can cross sell</Checkbox>
        </Form.Item>
        <Form.Item
          name="canSellQuantities"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox size="small">Can sell in bulk</Checkbox>
        </Form.Item>
        <SalesProcessSelect
          allowClear
          extra="This will be the default sales process for all opportunities on this product"
          label="Sales process"
          name="salesProcessId"
          placeholder="No Default"
        />
      </Form>
    </Modal>
  );
};
