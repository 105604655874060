import noop from "lodash/noop";
import { useState } from "react";

import {
  BuildingBlocks,
  SetupWizard as SetupWizardSvg,
} from "../image-placeholders";
import { Spotlight } from "../spotlight";
import { Step } from "./guide";

export const ShouldGuideQuestion = (props) => {
  const { onYes = noop, onNo = noop } = props;

  const [showSpotlight, setShowSpotlight] = useState(false);

  return (
    <>
      {showSpotlight && (
        <Spotlight
          allowClicks={false}
          elementId={"setup-wizard-button"}
          tooltip={{
            content: (
              <Step onNext={onYes} nextText={"Got it"}>
                The setup guide will always be available by clicking here
              </Step>
            ),
            placement: "bottom",
          }}
        />
      )}
      <div style={{ padding: "0px 8px 8px 8px", width: "375px" }}>
        <h2 style={{ marginBottom: "24px" }}>Welcome to CRM!</h2>
        <h3 style={{ marginBottom: "24px" }}>
          One of our foundational pillars is choice. How would you like to
          start?
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <SetupWizardSvg
            className={"md-larger-on-hover transition"}
            containerStyle={{
              backgroundColor: "rgba(241, 241, 241, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: "6px",
              boxShadow: "rgba(0, 0, 0, 0.2) 2px 6px 12px",
              fontSize: "1.2em",
              padding: "4px",
              width: "175px",
            }}
            onClick={() => setShowSpotlight(true)}
            secondary={"Guide me, I want help getting started"}
            style={{ width: "125px" }}
          />
          <BuildingBlocks
            className={"md-larger-on-hover transition"}
            containerStyle={{
              backgroundColor: "rgba(241, 241, 241, 0.8)",
              borderColor: "rgba(0, 0, 0, 0.12)",
              borderRadius: "6px",
              boxShadow: "rgba(0, 0, 0, 0.2) 2px 6px 12px",
              fontSize: "1.2em",
              marginLeft: "8px",
              padding: "4px",
              width: "175px",
            }}
            onClick={onNo}
            secondary={"I got this, I want to learn by doing"}
            style={{ width: "125px" }}
          />
        </div>
      </div>
    </>
  );
};
