import day from "dayjs";
import find from "lodash/find";
import maxBy from "lodash/maxBy";
import { useEffect } from "react";

import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Progress,
  Row,
} from "antd";

import { colors, opportunityStates } from "@evolved/constants";
import { getPartnersLabel } from "@evolved/labels";

import { DatePicker } from "../../../date-picker";
import { usePerformOpportunityActivity } from "../../../../data/use-activities";
import { useMe } from "../../../../data/use-me";
import {
  useOpportunities,
  useOpportunitySteps,
} from "../../../../data/use-opportunities";
import { useOpportunityStates } from "../../../../data/use-opportunity-states";
import { useOrganization } from "../../../../data/use-organization";
import {
  CREATE_OPPORTUNITY_FOLLOWUP_MODAL,
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  useLayoutStore,
} from "../../../../stores/layout";
import { OpportunityState } from "../../../opportunity-state";
import { ActivityTypeSelect } from "../../../selects/activity-type-select";
import { ContactSelect } from "../../../selects/contact-select";
import { UserSelect } from "../../../selects/user-select";
import { VendorSelect } from "../../../selects/vendor-select";
import { onSubmit } from "../on-submit";
import { LossReasonSelect } from "./loss-reason-select";
import { OnPerformedOn } from "./on-performed-on";
import { StageChange } from "./stage-change";
import { Steps } from "./steps";
import { roundPerformedOn } from "../round-performed-on";

const { isOpen, LOST } = opportunityStates;
const { PRIMARY } = colors;

const computeFunnelProgress = (steps, adjustments) => {
  const completed = steps.filter((step, index) => {
    return adjustments[index];
  });

  return (maxBy(completed, "funnelTrigger") || {}).funnelTrigger;
};

export const SalesProcess = (props) => {
  const {
    form,
    shouldRenderSalesProcess,
    steps,
    sm = { offset: 1, span: 11 },
  } = props;

  if (!shouldRenderSalesProcess) return null;

  const adjustments = Form.useWatch("steps", form);

  const funnelProgress = steps
    ? computeFunnelProgress(steps, adjustments || [])
    : 0;

  return (
    <Col xs={24} sm={sm}>
      <Progress
        percent={funnelProgress}
        strokeColor={PRIMARY}
        {...(funnelProgress < 100 ? { status: "active" } : {})}
      />
      <Steps form={form} steps={steps} />
    </Col>
  );
};

export const PerformOpportunityActivityModal = () => {
  const layoutState = useLayoutStore(
    (state) => state[PERFORM_OPPORTUNITY_ACTIVITY_MODAL]
  );

  const close = (afterClose) =>
    useLayoutStore
      .getState()
      .close(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, afterClose);

  const { closedFollowup, opportunityId, defaults } = layoutState.payload;

  const opportunity = useOpportunities().byId(opportunityId);
  const steps = useOpportunitySteps(opportunityId);

  const profile = useMe();

  const opportunityStates = useOpportunityStates().all();
  const lostOpportunityState = find(opportunityStates, { systemKey: LOST });

  const opportunityState = useOpportunityStates().byId(opportunity?.stateId);

  const organization = useOrganization();

  const performActivity = usePerformOpportunityActivity();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!layoutState.isOpen) {
      return;
    }

    const { completedSteps = [], ...restOfDefaults } = defaults || {};

    form.setFieldsValue({
      contactIds: opportunity.contacts || [],
      opportunityStateChangeId: null,
      performedOn: roundPerformedOn(
        closedFollowup && day.unix(closedFollowup.dueOn).isBefore(day())
          ? day.unix(closedFollowup.dueOn)
          : day()
      ),
      shouldFollowUp: true,
      steps: (steps || []).map(
        ({ _id, isComplete }) => isComplete || completedSteps.includes(_id)
      ),
      summary: "",
      typeId: null,
      userIds: [profile._id],
      vendorIds: [],
      ...restOfDefaults,
    });
  }, [
    form,
    opportunity,
    closedFollowup,
    opportunity,
    profile,
    defaults,
    steps,
  ]);

  const shouldRenderSalesProcess =
    opportunity?.salesProcessId && isOpen(opportunityState);

  return (
    <Modal
      styles={{
        body: {
          minHeight: "500px",
          padding: "0px",
        },
      }}
      closable={false}
      destroyOnClose
      footer={null}
      maskClosable={false}
      title={null}
      open={layoutState.isOpen}
      width={1024}
    >
      {layoutState.isOpen && (
        <>
          <button
            aria-label="Close"
            className="ant-modal-close"
            onClick={close}
            type="button"
          >
            <span className="ant-modal-close-x">
              <i
                aria-label="icon: close"
                className="anticon anticon-close ant-modal-close-icon"
              >
                <svg
                  viewBox="64 64 896 896"
                  focusable="false"
                  className=""
                  data-icon="close"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z" />
                </svg>
              </i>
            </span>
          </button>
          <div className="ant-modal-header">
            <div className="ant-modal-title">
              <div style={{ display: "flex" }}>
                <div>
                  <div>Log Activity</div>
                  {closedFollowup && (
                    <div style={{ fontSize: "12px" }}>
                      <span style={{ fontSize: "10px" }}>Follow up on: </span>
                      {closedFollowup.task}
                    </div>
                  )}
                </div>
                <div style={{ marginLeft: "auto", marginRight: "24px" }}>
                  <OpportunityState
                    opportunity={opportunity}
                    showFunnelProgress
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "24px",
              width: "100%",
            }}
          >
            <Form
              form={form}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={onSubmit({
                close,
                closedFollowup,
                opportunity,
                performActivity: performActivity.mutate,
                showCreateFollowup: ({ openingActivityId }) =>
                  useLayoutStore
                    .getState()
                    .open(CREATE_OPPORTUNITY_FOLLOWUP_MODAL, {
                      opportunityId,
                      openingActivityId,
                    }),
                steps,
              })}
            >
              <Row>
                <Col xs={24} sm={shouldRenderSalesProcess ? 12 : 24}>
                  <div>
                    <Form.Item
                      label="Performed On"
                      name="performedOn"
                      rules={[{ required: true }]}
                    >
                      <DatePicker />
                    </Form.Item>
                    <UserSelect
                      label="Performed By"
                      mode="multiple"
                      name="userIds"
                      rules={[{ required: true }]}
                      required
                    />
                    {isOpen(opportunityState) && (
                      <StageChange form={form} opportunity={opportunity} />
                    )}
                    <LossReasonSelect
                      form={form}
                      lostOpportunityState={lostOpportunityState}
                      organization={organization}
                    />
                    {isOpen(opportunityState) &&
                      !opportunity.salesProcessId && (
                        <Form.Item
                          label="Funnel Progress"
                          name="funnelProgressAfter"
                        >
                          <InputNumber
                            formatter={(value) => value && `${value}%`}
                            min={0}
                            max={100}
                            parser={(value) => value.replace("%", "")}
                            placeholder="Leave as is"
                          />
                        </Form.Item>
                      )}
                    <ActivityTypeSelect
                      label="Type"
                      name="typeId"
                      rules={[{ required: true }]}
                    />
                    <ContactSelect
                      label="Contacts"
                      mode="multiple"
                      name="contactIds"
                      notFoundContent={<div>No associated contacts</div>}
                    />
                    {organization?.settings?.showPartners && (
                      <VendorSelect
                        label={getPartnersLabel(organization)}
                        mode="multiple"
                        name="vendorIds"
                        notFoundContent={
                          <div>
                            No associated {getPartnersLabel(organization)}
                          </div>
                        }
                      />
                    )}
                    <Form.Item
                      label="Summary"
                      name="summary"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={6} />
                    </Form.Item>
                  </div>
                </Col>
                <SalesProcess
                  form={form}
                  shouldRenderSalesProcess={shouldRenderSalesProcess}
                  steps={steps}
                />
              </Row>
              <Row>
                <Col xs={24} sm={shouldRenderSalesProcess ? 11 : 24}>
                  <Form.Item
                    name="shouldFollowUp"
                    wrapperCol={{ offset: 6, span: 18 }}
                    valuePropName="checked"
                  >
                    <Checkbox size="small">Follow up on this activity</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="ant-modal-footer">
            <div>
              <Button disabled={performActivity.isLoading} onClick={close}>
                Cancel
              </Button>
              <Button
                loading={performActivity.isLoading}
                onClick={form.submit}
                type="primary"
              >
                Log
              </Button>
            </div>
          </div>
          <OnPerformedOn form={form} steps={steps} />
        </>
      )}
    </Modal>
  );
};
