import { App, Form } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { UserSelect } from "components/selects/user-select";
import { useMergeUsers } from "data/use-users";
import { useOnChange } from "hooks/use-on-change";
import { Modal } from "components/modal";

const onSubmit = ({ modal, mergeUsers }) => {
  return (values) => {
    modal.confirm({
      title: "Are you sure you want to merge these users?",
      icon: <ExclamationCircleOutlined />,
      content: "This cannot be undone!",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => mergeUsers.mutate(values),
    });
  };
};

export const MergeUsersModal = (props) => {
  const { close, isOpen } = props;

  const { modal } = App.useApp();
  const [form] = Form.useForm();

  const mergeUsers = useMergeUsers({
    onSuccess: close,
  });

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        childIds: [],
        userId: null,
      });
    },
    [isOpen, form]
  );

  return (
    <Modal
      confirmLoading={mergeUsers.isLoading}
      okText="Merge"
      onCancel={close}
      onOk={form.submit}
      title="Merge Users"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          modal,
          mergeUsers,
        })}
      >
        <div style={{ marginBottom: "16px" }}>
          <strong>
            All accounts, open followups, and open opportunities will be
            reassigned from the secondary users to the primary. The secondary
            users will then be archived.
          </strong>
        </div>

        <UserSelect name="parentId" rules={[{ required: true }]} />
        <UserSelect
          name="childIds"
          rules={[{ min: 1, type: "array" }]}
          mode="multiple"
        />
      </Form>
    </Modal>
  );
};
