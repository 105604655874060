import pick from "lodash/pick";

import { Form, Input } from "antd";

import { viewTypes } from "@evolved/constants";

import {
  mapInitialUserDefinedFieldsState,
  mapOutgoingValues,
  UserDefinedFieldInputs,
} from "components/user-defined-field-inputs";
import { useCreateContact, useUpdateContact } from "../../data/use-contacts";
import { useOrganization } from "../../data/use-organization";
import { useOnChange } from "../../hooks/use-on-change";
import { CreateAccountSelect } from "../selects/create-account-select";
import { CreateVendorSelect } from "../selects/create-partner-select";
import { Modal } from "components/modal";
import { UserSelect } from "../selects/user-select";

const { CONTACT } = viewTypes;

const CREATE = "create";
const UPDATE = "update";

const getType = (contact) => (contact ? UPDATE : CREATE);

const getInitialValues = ({
  contact = {},
  defaults = {},
  organization = {},
}) => {
  return {
    accountId: null,
    vendorId: null,
    email: "",
    firstName: "",
    lastName: "",
    linkedIn: "",
    phone: "",
    summary: "",
    title: "",
    userIds: [],
    ...mapInitialUserDefinedFieldsState(contact, {
      organization,
      type: CONTACT,
    }),
    ...pick(contact, [
      "accountId",
      "vendorId",
      "email",
      "firstName",
      "lastName",
      "linkedIn",
      "phone",
      "summary",
      "title",
      "userIds",
    ]),
    ...defaults,
  };
};

const mapValues = (values) => {
  return {
    // todo: test creating and updating with udfs
    ...mapOutgoingValues(values),
    // todo: test removing vendor
    // todo: test removing account
    accountId: values.accountId || null,
    vendorId: values.vendorId || null,
  };
};

const MAP_VARIABLES = {
  [CREATE]: ({ values }) => mapValues(values),
  [UPDATE]: ({ contact, values }) => ({
    id: contact._id,
    ...mapValues(values),
  }),
};

const OK_TEXT = {
  [CREATE]: "Create",
  [UPDATE]: "Update",
};

const TITLES = {
  [CREATE]: "Create Contact",
  [UPDATE]: "Details",
};

export const ContactDetailsModal = (props) => {
  const {
    defaults = {},
    disableRelationshipSelect,
    close,
    isOpen,
    onCreate,
    contact,
  } = props;

  const organization = useOrganization();

  const createContact = useCreateContact({
    onSuccess: async (result) => {
      close();

      if (onCreate) {
        await onCreate(result.contact);
      }
    },
  });
  const updateContact = useUpdateContact({
    onSuccess: close,
  });

  const type = getType(contact);
  const action = type === CREATE ? createContact : updateContact;

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue(
        getInitialValues({
          defaults,
          contact,
          organization,
        })
      );
    },
    [isOpen, form]
  );

  const onSubmit = (values) => {
    action.mutate(MAP_VARIABLES[type]({ contact, values }));
  };

  return (
    <Modal
      confirmLoading={action.isLoading}
      okText={OK_TEXT[type]}
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title={TITLES[type]}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit}
      >
        <Form.Item label="Email" name="email">
          <Input />
        </Form.Item>
        <Form.Item label="First Name" name="firstName">
          <Input />
        </Form.Item>
        <Form.Item label="Last Name" name="lastName">
          <Input />
        </Form.Item>
        <CreateAccountSelect
          disableAccountSelect={disableRelationshipSelect}
          form={form}
        />
        {organization?.settings?.showPartners && (
          <CreateVendorSelect
            disableVendorSelect={disableRelationshipSelect}
            form={form}
          />
        )}
        <Form.Item label="Title" name="title">
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="LinkedIn" name="linkedIn">
          <Input />
        </Form.Item>
        <Form.Item label="Summary" name="summary">
          <Input.TextArea />
        </Form.Item>
        {type === CREATE ? (
          <UserSelect label="Sales Reps" name="userIds" mode="multiple" />
        ) : null}
        <UserDefinedFieldInputs organization={organization} type={CONTACT} />
      </Form>
    </Modal>
  );
};
