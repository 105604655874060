import isNil from "lodash/isNil";
import { Badge } from "antd";

import { colors } from "@evolved/constants";

const { PRIMARY } = colors;

export const WidgetHeader = ({ count, label }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: "auto" }}>
      {!isNil(count) && (
        <Badge
          count={count}
          overflowCount={999}
          showZero
          style={{ backgroundColor: PRIMARY, marginRight: "8px" }}
        />
      )}
      <h2 style={{ margin: "0px" }}>{label}</h2>
    </div>
  );
};
