import { useEffect, useRef } from "react";

export const useOnChange = (change, onChange, dependencies) => {
  const last = useRef();

  useEffect(() => {
    if (change !== last.current) {
      onChange();
    }

    last.current = change;
  }, dependencies);
};
