import React from "react";

import { EditButton } from "../../components/edit-button";
import { useModal } from "../../hooks/use-modal";
import { UserDetailsModal } from "../organization/modals/user-details";

const style = {
  container: { alignItems: "center", display: "flex", margin: "24px 0px" },
  button: { marginLeft: "24px" },
  header: { margin: "0px" },
};

export const Header = (props) => {
  const { profile } = props;
  const { alias } = profile;

  const { modal, open } = useModal(UserDetailsModal);

  return (
    <>
      {modal}
      <div style={style.container}>
        <h2 style={style.header}>{alias}</h2>
        <EditButton
          onClick={() => open({ user: profile, disableRole: true })}
          style={style.button}
        >
          Update Details
        </EditButton>
      </div>
    </>
  );
};
