import { useQuery, useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities";
import { fetcher } from "./fetcher";
import { root as accountsRoot } from "./use-accounts";
import { root as contactsRoot } from "./use-contacts";
import { root as followupsRoot } from "./use-followups";
import { useMutation } from "./use-mutation";
import { root as opportunitiesRoot } from "./use-opportunities";
import { root as vendorsRoot } from "./use-vendors";

export const root = "/activity";

export const useAllActivities = buildUseEntities([root, "/all"]);

export const useAccountActivities = ({ _id }) => {
  const queryFn = fetcher.get(`/account/${_id}/activities`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/account", `${_id}`],
  });

  return data || [];
};

export const useContactActivities = ({ _id }) => {
  const queryFn = fetcher.get(`/contact/${_id}/activities`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/contact", `${_id}`],
  });

  return data || [];
};

export const useOpportunityActivities = ({ _id }) => {
  const queryFn = fetcher.get(`/opportunity/${_id}/activities`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/opportunity", `${_id}`],
  });

  return data || [];
};

export const useVendorActivities = ({ _id }) => {
  const queryFn = fetcher.get(`/vendor/${_id}/activities`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/vendor", `${_id}`],
  });

  return data || [];
};

export const usePerformAccountActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/account`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
      },
    },
    overrides
  );
};

export const usePerformContactActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/contact`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
      },
    },
    overrides
  );
};

export const usePerformOpportunityActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/opportunity`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [opportunitiesRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
      },
    },
    overrides
  );
};

export const usePerformVendorActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/vendor`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
        await queryClient.invalidateQueries({ queryKey: [followupsRoot] });
      },
    },
    overrides
  );
};

export const useUpdateActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, performedOn, summary }) => {
        return fetcher.patch(`${root}/${id}`)({ performedOn, summary });
      },
      onSuccess: async (data, { variables }) => {
        await queryClient.invalidateQueries({ queryKey: [root] });

        for (const type of variables.types) {
          const queryKey = {
            ACCOUNT: [accountsRoot],
            CONTACT: [contactsRoot],
            OPPORTUNITY: [opportunitiesRoot],
            VENDOR: [vendorsRoot],
          }[type];

          await queryClient.invalidateQueries({ queryKey });
        }
      },
    },
    overrides
  );
};

export const useHighlightActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id }) => {
        return fetcher.put(`${root}/${id}/highlight`)();
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUnhighlightActivity = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id }) => {
        return fetcher.put(`${root}/${id}/unhighlight`)();
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
