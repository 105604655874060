import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import { useDeleteOpportunity } from "data/use-opportunities";
import { getLastCrumb } from "../../../stores/crumbs";
import { withFlag } from "../../../components/with-flag";

export const DeleteOpportunity = withFlag("delete-entities")((props) => {
  const { opportunity } = props;

  const navigate = useNavigate();
  const deleteOpportunity = useDeleteOpportunity({
    onSuccess: async (data, { mutation }) => {
      navigate(getLastCrumb() || "/opportunities");
      // TODO: wrapping this in setTimeout for now to guarantee that
      // navigate has finished, and react has finished rendering.
      // Only then do we invalidate the cache.
      // A better UX would be to navigate the user to a loading screen, without
      // any calls to data that is getting removed. There, we can safely update the cache
      // without any janky experience.
      setTimeout(() => {
        mutation.onSuccess.call();
      });
    },
  });

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() => deleteOpportunity.mutateAsync({ id: opportunity._id })}
      title={
        <div className="max-w-[300px]">
          Are you sure?
          <br />
          <br />
          This will remove the opportunity and any associated entities including
          followups and activities.
          <br />
          <br />
        </div>
      }
    >
      <Button
        icon={<EyeInvisibleOutlined />}
        disabled={deleteOpportunity.isLoading}
      >
        Delete
      </Button>
    </Popconfirm>
  );
});
