import { useQuery, useQueryClient } from "@tanstack/react-query";

import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";

import { root as meRoot } from "./use-me";
import { root as usersRoot } from "./use-users";

export const root = "/organization";

export const useOrganization = (options = {}) => {
  const { data, error, isLoading, refetch } = useQuery({
    ...(options.hasOwnProperty("enabled") ? { enabled: options.enabled } : {}),
    queryKey: [root],
    queryFn: fetcher.get(root, { bypassStatusCodes: [404] }),
    select: (data) => {
      return data?.statusCode === 404 ? null : data;
    },
  });

  if (options.detailed) {
    return { data, error, isLoading, refetch };
  }

  return data;
};

useOrganization.key = [root];

export const usePatchAccessControl = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.patch(`${root}/access-control`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const usePatchOrganizationSettings = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.patch(`${root}/settings`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const usePatchOrganizationSetupState = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.patch(`${root}/setup-state`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
      onFinal: () => {},
    },
    overrides
  );
};

export const usePatchOrganizationLabels = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.patch(`${root}/labels`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetLossReasonEnforcement = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ shouldEnforce }) => {
        return fetcher.put(`${root}/loss-reason-enforcement`)({
          shouldEnforce,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOrganizationSalesProcess = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.put(`${root}/sales-process`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetOrganizationTimezone = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.put(`${root}/timezone`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetSalesProcessEnforcement = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ shouldEnforce }) => {
        return fetcher.put(`${root}/sales-process-enforcement`)({
          shouldEnforce,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useSetUserDefinedFields = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (userDefinedFields) => {
        return fetcher.put(`${root}/user-defined-fields`)(userDefinedFields);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateSubscriptionActiveUsers = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ activeUsers }) => {
        return fetcher.put(`${root}/subscription/active-users`)({
          activeUsers,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [usersRoot] });
        await queryClient.invalidateQueries({ queryKey: [meRoot] });
      },
    },
    overrides
  );
};
