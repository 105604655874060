import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

import { ActivitiesList } from "components/lists/activities";
import { ContactsList } from "components/lists/contacts";
import { Details } from "./details";
import { Emails } from "components/emails";
import { Files } from "../../components/lists/files/files";
import { FollowupsList } from "components/lists/followups";
import { Header } from "./header";
import { OpportunitiesList } from "components/lists/opportunities";
import { SetContacts } from "./modals/set-contacts";
import { Tags } from "./tags";
import { emailToDefaults } from "components/modals/perform-activity/email-to-defaults";
import { useActivityTypes } from "data/use-activity-types";
import { useContacts } from "data/use-contacts";
import { useModal } from "hooks/use-modal";
import { useOpportunities } from "data/use-opportunities";
import { useOrganization } from "data/use-organization";
import { useVendorActivities } from "data/use-activities";
import { useVendorFollowups } from "data/use-followups";
import { useVendors } from "data/use-vendors";
import {
  CREATE_VENDOR_FOLLOWUP_MODAL,
  PERFORM_VENDOR_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { withEntityExists } from "../with-entity-exists";

export const VendorRoute = withEntityExists(useVendors)(() => {
  const id = Number(useParams().id);

  const activities = useVendorActivities({ _id: id });
  const activityTypes = useActivityTypes();
  const contacts = useContacts();
  const followups = useVendorFollowups({ _id: id });
  const opportunities = useOpportunities();
  const organization = useOrganization();
  const vendor = useVendors().byId(id);

  const showPerformActivity = () =>
    useLayoutStore.getState().open(PERFORM_VENDOR_ACTIVITY_MODAL, {
      vendorId: id,
    });

  const showCreateFollowup = () => {
    useLayoutStore.getState().open(CREATE_VENDOR_FOLLOWUP_MODAL, {
      vendorId: id,
    });
  };

  const { modal: setContactsModal, open: openSetContacts } =
    useModal(SetContacts);

  return (
    <div className="w-full">
      {setContactsModal}
      <Header vendor={vendor} />
      <Row gutter={48}>
        <Col xs={24} lg={12} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <Tags vendor={vendor} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Details organization={organization} vendor={vendor} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ContactsList
              contacts={contacts.byIds(vendor.contacts)}
              onUpdate={() => openSetContacts({ vendor })}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <OpportunitiesList
              opportunities={opportunities
                .all()
                .filter(({ vendorId }) => vendorId === vendor._id)}
            />
          </div>
          <Files entityId={id} entityType="VENDOR" />
        </Col>
        <Col xs={24} lg={12} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <FollowupsList
              followups={followups}
              hideVendor
              showCreateFollowup={showCreateFollowup}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ActivitiesList
              activities={activities}
              showPerformActivity={showPerformActivity}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Emails
              contacts={vendor.contacts}
              onLogActivity={(email, userEmails) =>
                showPerformActivity({
                  vendor,
                  defaults: emailToDefaults({
                    activityTypes,
                    contacts: contacts.byIds(vendor.contacts),
                  })(email, userEmails),
                })
              }
              users={vendor.users}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});
