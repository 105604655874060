import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useArchiveVendor } from "data/use-vendors";

export const ArchiveVendor = (props) => {
  const { vendor } = props;

  const archiveVendor = useArchiveVendor({
    onSuccess: async (data, { mutation }) => {
      await mutation.onSuccess.call();
    },
  });

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() => archiveVendor.mutateAsync({ id: vendor._id })}
      title="Are you sure?"
    >
      <Button
        icon={<EyeInvisibleOutlined />}
        disabled={archiveVendor.isLoading}
      >
        Archive
      </Button>
    </Popconfirm>
  );
};
