import { useNavigate, useParams } from "react-router-dom";

export const withEntityExists = (useStore) => (Component) => (props) => {
  const id = Number(useParams().id);

  const entity = useStore().byId(id);

  const navigate = useNavigate();

  if (!entity)
    return (
      <div>
        <div className="mt-20 text-xl">Entity does not exist</div>
        <div
          className="mt-2 cursor-pointer text-lg text-blue-500"
          onClick={() => navigate(-1)}
        >
          Go Back
        </div>
      </div>
    );

  return <Component {...props} />;
};
