import { UpdatePartnersLabel } from "./partners";
import { UpdateSalesFunnelLabel } from "./sales-funnel";

export const Labels = ({ organization }) => {
  return (
    <>
      <div style={{ marginBottom: "32px" }}>
        <strong>Note: </strong>
        Labels can only contain letters with a maximum length of 18 characters.
      </div>
      <UpdateSalesFunnelLabel organization={organization} />
      <div style={{ marginTop: "16px" }}></div>
      <UpdatePartnersLabel organization={organization} />
    </>
  );
};
