import { Card, Col, Tooltip } from "antd";
import isNil from "lodash/isNil";

import { colors, styles } from "@evolved/constants";

const { PRIMARY } = colors;
const { PRIMARY_TEXT } = styles;

export const InfoCard = ({
  colOptions = {},
  content,
  flexDirection = "row",
  Icon,
  label,
  onClick,
  style = {},
  tooltip,
}) => {
  const colStyle = {
    marginBottom: "8px",
    ...style.colStyle,
  };

  const contentStyle = {
    width: "100%",
    ...style.content,
    ...PRIMARY_TEXT,
  };

  const iconStyle = {
    color: PRIMARY,
    fontSize: "14px",
    marginRight: "8px",
    ...style.icon,
  };

  const innerContainerStyle = {
    alignItems: "center",
    display: "flex",
    flexDirection,
  };

  const cardBodyStyle = {
    padding: 12,
  };

  const cardStyle = {
    ...(!!onClick || !!tooltip
      ? {
          borderColor: "rgba(0, 0, 0, 0.12)",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.12)",
          cursor: "pointer",
        }
      : {}),
  };

  const tile = (
    <Col {...colOptions} style={colStyle}>
      <Card
        bodyStyle={cardBodyStyle}
        bordered={false}
        className={onClick ? "md-larger-on-hover transition" : ""}
        hoverable={!isNil(onClick) || !isNil(tooltip)}
        onClick={onClick}
        style={cardStyle}
      >
        {label && (
          <div style={{ color: PRIMARY, fontSize: "12px" }}>{label}</div>
        )}
        <div style={innerContainerStyle}>
          {Icon && <Icon style={iconStyle} />}
          <span style={contentStyle}>{content}</span>
        </div>
      </Card>
    </Col>
  );

  return tooltip ? (
    <Tooltip title={tooltip} placement="top">
      {tile}
    </Tooltip>
  ) : (
    tile
  );
};
