import isEmpty from "lodash/isEmpty";
import without from "lodash/without";

import { FilterOutlined } from "@ant-design/icons";
import { Button, Input, message, Tag, Tooltip } from "antd";
import { useState } from "react";

import { useFocus } from "./use-focus";
import { validateFilter } from "./validate-filter";

export const TextFilter = ({
  config,
  defaultValue = [],
  hide,
  onFilter,
  type,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState(defaultValue);

  const ref = useFocus(config.dataIndex);

  const onSubmit = (value) =>
    validateFilter(type, value)
      ? onFilter({ dataIndex: config.dataIndex, value })
      : message.warning("Filter must have at least one entry.");

  return (
    <>
      <Input
        onChange={(e) => setInputValue(e.target.value)}
        onPressEnter={() => {
          if (!isEmpty(inputValue)) {
            setValue([...value, inputValue]);
            setInputValue("");
          } else onSubmit();
        }}
        placeholder="Filter by..."
        ref={ref}
        size="small"
        style={{
          width: "100%",
          marginBottom: 8,
          marginRight: 0,
          display: "block",
        }}
        value={inputValue}
      />
      <div style={{ width: "100%" }}>
        {value.map((tag) => {
          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag
              key={tag}
              onClose={() => setValue(without(value, tag))}
              closable
              style={{ marginBottom: "8px" }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </Tag>
          );

          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      </div>
      <div style={{ display: "flex" }}>
        <Button
          icon={<FilterOutlined />}
          onClick={() => {
            if (!isEmpty(inputValue)) {
              return onSubmit([...value, inputValue]);
            }

            onSubmit(value);
          }}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1, marginRight: 8 }}
          type="primary"
        >
          Filter
        </Button>
        <Button
          onClick={hide}
          size="small"
          style={{ flexBasis: 0, flexGrow: 1 }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};
