import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useArchiveContact } from "data/use-contacts";

export const ArchiveContact = (props) => {
  const { contact } = props;

  const archiveContact = useArchiveContact({
    onSuccess: async (data, { mutation }) => {
      await mutation.onSuccess.call();
    },
  });

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() => archiveContact.mutateAsync({ id: contact._id })}
      title="Are you sure?"
    >
      <Button
        icon={<EyeInvisibleOutlined />}
        disabled={archiveContact.isLoading}
      >
        Archive
      </Button>
    </Popconfirm>
  );
};
