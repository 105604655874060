import produce from "immer";
import clone from "lodash/cloneDeep";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { diff } from "radash";

import { UploadOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";
import { useState } from "react";

import { viewTypes } from "@evolved/constants";
import { getPartnersLabel } from "@evolved/labels";

import getErrorMessage from "utils/get-error-message";

import { ErrorMessage } from "components/error-message";
import { GetTemplate } from "./get-template";
import { handleFileUpload } from "./handle-file-upload";
import { normalizeText } from "./handle-file-upload/normalize-text";
import { style } from "./style";
import { useAccounts, useCreateAccountBatch } from "data/use-accounts";
import { useContacts } from "../../../data/use-contacts";
import { useCreateContactBatch } from "data/use-contacts";
import { useCreateOpportunityBatch } from "data/use-opportunities";
import { useLossReasons } from "../../../data/use-loss-reasons";
import { useMe } from "data/use-me";
import { useOpportunityStates } from "data/use-opportunity-states";
import { useProducts, useCreateProductBatch } from "data/use-products";
import { useSalesProcesses } from "data/use-sales-processes";
import { useTags } from "../../../data/use-tags";
import { useVendors, useCreateVendorBatch } from "data/use-vendors";
import { useViewStore } from "../../../stores/view";
// import { PreviewTable } from "./preview-table";
import { buildEntityCache } from "./build-entity-cache";
import { useEntityCache } from "./use-entity-cache";
import { parseFieldConfigs } from "./parse-field-configs";
import { parseHeaders } from "./handle-file-upload/parse-headers";
import { getBlacklistedImportFields } from "./get-blacklisted-system-fields";
import { getNewUserDefinedFields } from "./get-new-user-defined-fields";
import {
  useOrganization,
  useSetUserDefinedFields,
} from "data/use-organization";
import { getUnmatchedRelationships } from "./get-unmatched-relationships";

import { FileUpload } from "./file-upload";
import { CreateUdfs } from "./create-udfs";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;
const { Dragger } = Upload;

const getTitle = (organization) => (type) =>
({
  [ACCOUNT]: "Accoun Import",
  [CONTACT]: "Contact Import",
  [OPPORTUNITY]: "Opportunity Import",
  [VENDOR]: `${getPartnersLabel(organization, "singular")} Import`,
}[type]);

const useFileListState = (initialState) => {
  const [value, set] = useState(initialState);

  const setFileList = (name, status) =>
    set([{ name, status: status || "done", uid: "-1" }]);
  const clearFileList = () => set([]);

  return [value, setFileList, clearFileList];
};

// TODO:
// 1) drop file and analyze the headers
//  - new UDFs what shoudl they be?
// CREATE UDFS
// 2) analyze the data and analyze
//  - new things to be created
//    - if id, match on that, if no id, match on name, if no match, new thing
// CREATE RELATIONSHIPS
// 3) analyze the data and analyze 
//  - errors in the data
//  should be able to re-drop after fixing and 1/2 should pass, but they will re-run again
// 4) payload is ready, import

const Wizard = (props) => {
  const { entityCache, entityType, fieldConfigs, organization } = props;

  const [error, setError] = useState();
  const [upload, setUpload] = useState();

  const setUserDefinedFields = useSetUserDefinedFields();

  // we want to filter out System fields, we should let the user know, how?
  // drop file -> confirmation screen that certain fields cannot be updated, those are sytem fields that are not in importable fields

  console.log(fieldConfigs);
  console.log(organization.userDefinedFields);

  const newUserDefinedFields = upload && getNewUserDefinedFields({ entityType, fieldConfigs, headers: upload.headers });

  console.log({
    entityCache,
    fieldConfigs,
    headers: upload?.headers,
    rows: upload?.rows,
  });

  const unmatchedRelationships = upload && isEmpty(newUserDefinedFields) && getUnmatchedRelationships({
    entityCache,
    fieldConfigs,
    headers: upload.headers,
    rows: upload.rows,
  });

  console.log(unmatchedRelationships)

  let step;

  console.log(fieldConfigs);

  if (!upload) {
    step = (
      <FileUpload
        onStart={() => {
          setError();
        }}
        onError={(error) => {
          console.error(error);
          setError(error);
        }}
        onSuccess={(result) => {
          setUpload(result);
        }}
      />
    );
  }
  // else if (getBlacklistedImportFields({
  //   entityType,
  //   fieldConfigs,
  //   headers: upload.headers,
  // }).length > 0) {
  //   // TODO: acknowledge
  //   step = (<div>{getBlacklistedImportFields({
  //     entityType,
  //     fieldConfigs,
  //     headers: upload.headers,
  //   }).join(", ")} will not be imported.</div>)
  //   // TODO: then, go through the udfs that will be created, create, then re-run
  //   // also realized, there's no need to show this, we can show it later
  // } 
  else if (!isEmpty(newUserDefinedFields)) {
    // TODO: what 
    step = (
      <CreateUdfs
        entityType={entityType}
        newUdfHeaders={newUserDefinedFields}
        onBack={() => {
          setUpload();
        }}
        onNext={({ removed, newUdfs } = {}) => {
          upload.headers = diff(upload.headers, removed);

          setUserDefinedFields.mutate([
            ...organization.userDefinedFields ?? [],
            ...newUdfs,
          ]);
        }}
        rows={upload.rows}
      />
    );
  }
  else if (unmatchedRelationships) {
    step = (
      <div>Unmatched Relationships</div>
    )
    // we want to see  
    console.log(fieldConfigs)
    // TODO: this is where we check for any relationships
    // how do we check for relationships?
    // TODO: then we check for any bad data
    // TODO: then we import
  } else {
    // TODO: review table
    step = (
      <div>Done</div>
    );
  }

  return (
    <>
      {step}
      {error && (
        <ErrorMessage
          error={`${getErrorMessage(
            error
          )}. Take any corrective actions and re-upload the file to try again.`}
          style={{ marginTop: "24px" }}
          support={false}
        />
      )}
    </>
  );
};

export const ImportDataModal = (props) => {
  const { isOpen, type } = props;

  const organization = useOrganization();
  const profile = useMe();

  const entityCache = useEntityCache();
  const fieldConfigs = type && parseFieldConfigs(organization, type);

  // const setUserDefinedFields = useSetUserDefinedFields({
  //   onSuccess: (data, { mutation }) => mutation.onFinal.skip(),
  // });
  //
  // const createAccountBatch = useCreateAccountBatch();
  // const createContactBatch = useCreateContactBatch();
  // const createOpportunityBatch = useCreateOpportunityBatch();
  // const createProductBatch = useCreateProductBatch();
  // const createVendorBatch = useCreateVendorBatch();

  // const [loading, setLoading] = useState(false);
  // const [error, setError] = useState();
  // const [fileList, setFileList, clearFileList] = useFileListState([]);
  // const [payload, setPayload] = useState();

  // const IMPORTS = {
  //   [ACCOUNT]: createAccountBatch.mutateAsync,
  //   [CONTACT]: createContactBatch.mutateAsync,
  //   [OPPORTUNITY]: createOpportunityBatch.mutateAsync,
  //   [VENDOR]: createVendorBatch.mutateAsync,
  // };

  const onOk = async () => {
    const temporaryIdIndexMap = {
      accounts: "accountId",
      products: "productId",
      salesProcesses: "salesProcessId",
      vendors: "vendorId",
    };

    setLoading(true);

    // two steps, first, is showing everything in a table + what identifiers will be created
    // then we can iterate on
    // - validating and fixing errors
    // - what the udfs should be
    // - relationships that will be created

    try {
      if (!isEmpty(payload.udfs)) {
        const udfPayload = payload.udfs.reduce((acc, udf) => {
          const existingIndex = acc.findIndex(({ id }) => id === udf.id);

          if (existingIndex > -1) {
            acc[existingIndex] = udf;
          } else {
            acc.push(udf);
          }

          return acc;
        }, clone(organization.userDefinedFields || []));

        await setUserDefinedFields.mutateAsync(udfPayload);

        payload.udfs.forEach((u) =>
          useViewStore.getState().addField(type)(`userDefinedFields.${u.id}`)
        );
      }

      let entitiesPayload = payload.entities;

      if (!isEmpty(payload.relationships)) {
        for (const relationship of payload.relationships) {
          const { type, entities } = relationship;

          // TODO: so what are the things that we want to import?
          // anything that we can create must be created with just a name, right?
          const importType = {
            accounts: createAccountBatch.mutateAsync,
            products: createProductBatch.mutateAsync,
            vendors: createVendorBatch.mutateAsync,
          }[type];
          // TODO: should show the user what entities will be created, could even
          // bring them through the process for products, although for now, we can default
          // that's fine. Just strip those things off and explain it to the user.

          // The other good enough thing for now, is that we stick with not uploading these other things, because it works today, and instead,

          const result = await importType(map(entities, ({ value }) => value));

          entitiesPayload = produce(payload.entities, (draft) => {
            for (const r of result) {
              const relationship = entities[normalizeText(r.name)];

              if (relationship) {
                for (const i of relationship.indexes) {
                  draft[i][temporaryIdIndexMap[type]] = r._id;
                }
              }
            }
          });
        }
      }

      await IMPORTS[type](entitiesPayload);
      setLoading(false);

      close();
    } catch (err) {
      clearFileList();
      setError(err);
      setLoading(false);
    }
  };

  // const userDefinedFields = get(organization, "userDefinedFields", []).filter(
  //   (field) => field.type === type
  // );

  // now that I have the headers, what do they match to?

  // it's a pretty long process tbh... may deserve an entire page

  return (
    <Modal
      destroyOnClose
      footer={null}
      maskClosable={false}
      onCancel={props.close}
      open={isOpen}
      title={getTitle(organization)(type)}
    >
      {fieldConfigs && (
        <Wizard
          entityCache={entityCache}
          entityType={type}
          fieldConfigs={fieldConfigs}
          organization={organization}
        />
      )}
    </Modal>
  )

  return (
    <Modal
      footer={null}
      maskClosable={false}
      open={isOpen}
      title={getTitle(organization)(type)}
      okButtonProps={{ disabled: isEmpty(fileList) || error }}
    >
      {isOpen && fieldConfigs && !payload && (
        <div style={style.container}>
          {!loading ? (
            <>
              <GetTemplate
                profile={profile}
                userDefinedFields={userDefinedFields}
                setError={setError}
                type={type}
              />
              <FileUpload
                onStart={() => {
                  setError();
                }}
                onError={(error) => {
                  console.error(error);
                  setError(error);
                }}
                onSuccess={(result) => {
                  console.log("success");
                  console.log(result);
                }}
              />
            </>
          ) : (
            <div
              style={{
                alignItems: "center",
                flexDirection: "column",
                display: "flex",
                height: "352px",
                justifyContent: "center",
              }}
            >
              <h2>We are working hard to load your data!</h2>
              <h3>
                {payload?.entities?.length} {type.toLowerCase()}s total...
              </h3>
            </div>
          )}
          {error && (
            <ErrorMessage
              error={`${getErrorMessage(
                error
              )}. Take any corrective actions and re-upload the file to try again.`}
              style={{ marginTop: "24px" }}
              support={false}
            />
          )}
        </div>
      )}
      {payload && <PreviewTable payload={payload} />}
    </Modal>
  );
};
