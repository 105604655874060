import { Cell, Label, Pie, PieChart, Tooltip } from "recharts";

import { getActivityColor } from "utils/color/get-activity-color";

import { CHART_SIZE } from "./constants";

export const ActivityCountPie = (props) => {
  const { count, counts, name, style = {} } = props;

  return (
    <div style={style}>
      {name && (
        <div className={"ant-statistic-title"} style={{ marginBottom: "8px" }}>
          {name}
        </div>
      )}
      <PieChart
        style={{ fontSize: "18px" }}
        height={CHART_SIZE}
        width={CHART_SIZE}
      >
        <Pie
          isAnimationActive
          data={counts}
          innerRadius={CHART_SIZE / (count ? 4 : 6)}
          outerRadius={CHART_SIZE / 2}
          dataKey="count"
        >
          {counts.map((count, index) => {
            return <Cell key={index} fill={getActivityColor(count._id)} />;
          })}
          <Label position="center" style={{ fontSize: "26px" }} value={count} />
        </Pie>
        <Tooltip />
      </PieChart>
    </div>
  );
};
