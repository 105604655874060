import { fieldTypes } from "@evolved/constants";

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

const { ACTIVITY_DATE, DATE, FOLLOWUP_DATE, SET, TEXT } = fieldTypes;

export const account = {
  collection: "accounts",
  dataIndex: "accountId",
  idIndex: "accountId",
  isSortable: true,
  title: "Account",
  type: SET,
};

export const createdOn = {
  dataIndex: "createdOn",
  title: "Added On",
  type: DATE,
};

export const email = {
  dataIndex: "email",
  title: "Email",
  type: TEXT,
};

export const lastActedOn = {
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: ACTIVITY_DATE,
};

export const name = {
  dataIndex: "name",
  title: "Name",
  type: TEXT,
  mapUiUrl: (value) => `/contacts/${value}`,
};

export const firstName = {
  dataIndex: "firstName",
  title: "First Name",
  type: TEXT,
};

export const lastName = {
  dataIndex: "lastName",
  title: "Last Name",
  type: TEXT,
};

export const nextFollowup = {
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: FOLLOWUP_DATE,
};

export const phone = {
  dataIndex: "phone",
  title: "Phone",
  type: TEXT,
};

export const tags = {
  collection: "tags",
  dataIndex: "tags",
  title: "Tags",
  type: SET,
};

export const title = {
  dataIndex: "title",
  title: "Title",
  type: TEXT,
};

export const users = {
  collection: "users",
  dataIndex: "users",
  title: "Sales Reps",
  type: SET,
};

export const vendor = {
  collection: "vendors",
  dataIndex: "vendorId",
  idIndex: "vendorId",
  isSortable: true,
  title: "Partner",
  type: SET,
};

export const COLLECTION = [
  account,
  email,
  createdOn,
  lastActedOn,
  name,
  nextFollowup,
  phone,
  tags,
  users,
  title,
  vendor,
];

export const DEFAULT_FIELDS = [
  "title",
  "account.name",
  "email",
  "phone",
  "lastActedOn",
  "nextFollowupOn",
];

export const FIXED_FIELDS = ["name"];

export const MAP = reduceFieldsToMap(COLLECTION);
