import { AssignAccountFollowupModal } from "../components/modals/assign-account-followup-modal";
import { AssignContactFollowupModal } from "../components/modals/assign-contact-followup-modal";
import { AssignOpportunityFollowupModal } from "../components/modals/assign-opportunity-followup-modal";
import { AssignVendorFollowupModal } from "../components/modals/assign-vendor-followup-modal";
import { PerformAccountActivityModal } from "../components/modals/perform-activity/perform-account-activity";
import { PerformContactActivityModal } from "../components/modals/perform-activity/perform-contact-activity";
import { PerformOpportunityActivityModal } from "../components/modals/perform-activity/perform-opportunity-activity";
import { PerformVendorActivityModal } from "../components/modals/perform-activity/perform-vendor-activity";
import { SetOpportunityProducts } from "../components/modals/set-opportunity-products";
import { UpdateFollowupModal } from "../components/modals/update-followup";

export const GlobalModals = () => (
  <>
    <AssignAccountFollowupModal />
    <AssignContactFollowupModal />
    <AssignOpportunityFollowupModal />
    <AssignVendorFollowupModal />
    <PerformAccountActivityModal />
    <PerformContactActivityModal />
    <PerformOpportunityActivityModal />
    <PerformVendorActivityModal />
    <SetOpportunityProducts />
    <UpdateFollowupModal />
  </>
);
