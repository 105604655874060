import trim from "lodash/trim";

import { contactFields } from "@evolved/views";

import {
  usePatchContact,
  useSetContactTags,
  useSetContactUsers,
} from "data/use-contacts";
import { swallow } from "../../../../../utils/swallow";

import { EditableAccountSelect } from "./editable-account-select";
import { EditablePartnerSelect } from "./editable-partner-select";
import { EditableTagsSelect } from "./editable-tags-select";
import { EditableTextCell } from "./editable-text-cell";
import { EditableUserDefinedFieldCell } from "./editable-user-defined-field";
import { EditableUsersSelect } from "./editable-users-select";

const { account, email, name, phone, tags, title, users, vendor } =
  contactFields;

const Account = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (accountId) => {
    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        accountId,
      })
    );
  };

  return <EditableAccountSelect {...rest} onUpdate={onUpdate} />;
};

const Email = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (email) => {
    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        email,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Name = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (name) => {
    const pieces = name.split(" ").map(trim);

    let firstName, lastName;

    if (pieces.length === 0) {
      firstName = "";
      lastName = "";
    } else if (pieces.length === 1) {
      firstName = pieces[0];
      lastName = "";
    } else if (pieces.length === 2) {
      firstName = pieces[0];
      lastName = pieces[1];
    } else {
      lastName = pieces.pop();
      firstName = pieces.join(" ");
    }

    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        firstName,
        lastName,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Partner = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (vendorId) => {
    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        vendorId,
      })
    );
  };

  return <EditablePartnerSelect {...rest} onUpdate={onUpdate} />;
};

const Phone = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (phone) => {
    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        phone,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Tags = (props) => {
  const { row, ...rest } = props;

  const setContactTags = useSetContactTags();

  const onUpdate = (tagIds) => {
    return swallow(
      setContactTags.mutateAsync({
        id: row._id,
        tagIds,
      })
    );
  };

  return <EditableTagsSelect onUpdate={onUpdate} {...rest} />;
};

const Title = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (title) => {
    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        title,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Users = (props) => {
  const { row, ...rest } = props;

  const setUsers = useSetContactUsers();

  const onUpdate = (userIds) => {
    return swallow(
      setUsers.mutateAsync({
        id: row._id,
        userIds,
      })
    );
  };

  return <EditableUsersSelect onUpdate={onUpdate} {...rest} />;
};

const UserDefinedField = (props) => {
  const { row, ...rest } = props;

  const patchContact = usePatchContact();

  const onUpdate = (userDefinedFields) => {
    return swallow(
      patchContact.mutateAsync({
        id: row._id,
        userDefinedFields,
      })
    );
  };

  return (
    <EditableUserDefinedFieldCell
      {...rest}
      onUpdate={onUpdate}
      viewType="CONTACT"
    />
  );
};

export const contactCells = {
  [account.dataIndex]: Account,
  [name.dataIndex]: Name,
  [email.dataIndex]: Email,
  [phone.dataIndex]: Phone,
  [tags.dataIndex]: Tags,
  [title.dataIndex]: Title,
  [users.dataIndex]: Users,
  [vendor.dataIndex]: Partner,
  userDefinedField: UserDefinedField,
};
