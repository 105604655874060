import isEmpty from "lodash/isEmpty";
import { Button, Popover } from "antd";
import { CSS } from "@dnd-kit/utilities";
import { OrderedListOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { useState } from "react";

import { OpportunityState } from "components/opportunity-state";
import { SortableList } from "components/sortable-list";
import { useOpportunityStates, useUpdateOpportunityStateOrder } from "data/use-opportunity-states";

const Field = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div className="item-center flex">
        <div className="grow cursor-move" {...attributes} {...listeners}>
          <OrderedListOutlined />
          <OpportunityState
            showFunnelProgress={false}
            opportunity={{
              stateId: props.id,
            }}
            style={{ marginBottom: "8px" }}
            marginLeft="12px"
          />
        </div>
      </div>
    </div>
  );
};

export const OpportunityStatesOrder = () => {
  const updateOpportunityStateOrder = useUpdateOpportunityStateOrder();

  const [isVisible, setIsVisible] = useState(false);

  const opportunityStates = useOpportunityStates().all({
    includeArchived: false,
  });

  const orderableStates = opportunityStates.filter(({ isSystem }) => {
    return !isSystem;
  });

  const [stateOrder, setStateOrder] = useState(orderableStates.map(({ _id }) => _id));

  if (isEmpty(orderableStates)) {
    return null;
  }

  return (
    <Popover
      content={
        <div style={{ minWidth: "50px" }}>
          <div style={{ marginBottom: "8px" }}>
            Stage Order
          </div>
          <div
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
              marginBottom: "12px",
            }}
          >
            <div>
              <OpportunityState
                showFunnelProgress={false}
                opportunity={{
                  stateId: opportunityStates.find(({ systemKey }) => systemKey === "OPEN")._id,
                }}
                style={{ marginBottom: "8px" }}
                marginLeft="0px"
              />
            </div>
            <SortableList
              items={stateOrder.map((id) => {
                return {
                  id,
                  Component: Field,
                  props: { opportunityState: opportunityStates.find(({ _id }) => _id === id) },
                };
              })}
              setItems={setStateOrder}

            />
            <div>
              <OpportunityState
                showFunnelProgress={false}
                opportunity={{
                  stateId: opportunityStates.find(({ systemKey }) => systemKey === "WON")._id,
                }}
                style={{ marginBottom: "8px" }}
                marginLeft="0px"
              />
            </div>
            <div>
              <OpportunityState
                showFunnelProgress={false}
                opportunity={{
                  stateId: opportunityStates.find(({ systemKey }) => systemKey === "LOST")._id,
                }}
                style={{ marginBottom: "8px" }}
                marginLeft="0px"
              />
            </div>
          </div>
          <Button
            loading={updateOpportunityStateOrder.isLoading}
            onClick={async () => {
              await updateOpportunityStateOrder.mutateAsync({
                order: stateOrder,
              });

              setIsVisible(false);
            }}
            size={"small"}
          >
            Save Changes
          </Button>
        </div>
      }
      onOpenChange={(visible) => {
        setIsVisible(visible);
        setTimeout(() => {
          setStateOrder(orderableStates.map(({ _id }) => _id));
        }, 250);
      }}
      open={isVisible}
      placement="bottom"
      trigger="click"
    >
      <OrderedListOutlined />
    </Popover>
  );
};
