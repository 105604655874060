import { getViewFields } from "@evolved/views";
import { normalizeText } from "./handle-file-upload/normalize-text";

export const getNewUserDefinedFields = ({
  entityType,
  fieldConfigs,
  headers,
}) => {
  return headers.filter((header) => {
    let exists = fieldConfigs.some(
      ({ title }) => normalizeText(header) === normalizeText(title)
    );

    if (exists) {
      return false;
    }

    exists = getViewFields(entityType).COLLECTION.find(
      ({ title }) => normalizeText(header) === normalizeText(title)
    );

    return !exists;
  });
};
