import day from "dayjs";
import filter from "lodash/filter.js";
import find from "lodash/find.js";
import noop from "lodash/noop.js";
import orderBy from "lodash/orderBy";
import {
  GlobalOutlined,
  IdcardOutlined,
  RadarChartOutlined,
  RocketOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Card, DatePicker, Select, Spin, Tooltip } from "antd";
import { saveAs } from "file-saver";
import { useState } from "react";

import {
  styles as commonStyles,
  userRoles,
  viewTypes,
} from "@evolved/constants";
import { getPartnersLabel } from "@evolved/labels";

import { fetcher } from "../../../data/fetcher";
import { useModal } from "hooks/use-modal";
import { useMe } from "data/use-me";
import { useOrganization } from "../../../data/use-organization";
import { long } from "utils/format-date";
import { getNextRunOn } from "./get-next-run-on";
import { ScheduleReportModal } from "./schedule-report-modal";
import { Settings } from "./settings";
import { styles } from "./styles";
import {
  useDeleteScheduledReport,
  useUpdateCannedReportSettings,
} from "../../../data/use-me";
import { swallow } from "../../../utils/swallow";
import { useNotification } from "contexts/notification/use-notification";
import { useUsers } from "data/use-users";
import { useViews } from "data/use-views";
import { hasAccess } from "../../../utils/has-access";

const { CLICKABLE_STYLE } = commonStyles;
const { RangePicker } = DatePicker;
const { ROLES } = userRoles;

const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const icons = ({ organization, type }) =>
({
  [viewTypes.ACCOUNT]: (
    <Tooltip title="Accounts">
      <RadarChartOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  ),
  [viewTypes.ACTIVITY]: (
    <Tooltip title="Activities">
      <ThunderboltOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  ),
  [viewTypes.CONTACT]: (
    <Tooltip title="Contacts">
      <IdcardOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  ),
  [viewTypes.OPPORTUNITY]: (
    <Tooltip title="Opportunities">
      <RocketOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  ),
  [viewTypes.VENDOR]: (
    <Tooltip title={getPartnersLabel(organization)}>
      <GlobalOutlined style={{ fontSize: "20px" }} />
    </Tooltip>
  ),
}[type]);

const ScheduleReport = (props) => {
  const { loading, schedule, type, id, setLoading } = props;

  const { modal, open } = useModal(ScheduleReportModal);

  const deleteScheduledReport = useDeleteScheduledReport();

  if (!schedule) {
    return (
      <>
        {modal}
        <span
          onClick={() => open({ id, schedule, type })}
          style={{ ...CLICKABLE_STYLE }}
        >
          Schedule Report
        </span>
      </>
    );
  }

  const nextRunOn = long(+getNextRunOn(schedule) / 1000);

  const { day, every, period } = schedule;

  const submit = async () => {
    setLoading(true);

    await swallow(deleteScheduledReport.mutateAsync({ id, type }));

    setLoading(false);
  };

  return (
    <>
      {modal}
      <div style={{ color: "grey" }}>
        {period === "monthly" ? (
          <>
            Scheduled to send at the end of every month, next on {nextRunOn} |{" "}
          </>
        ) : (
          <>
            Scheduled to send every {every === 2 ? "2 weeks" : "week"} on{" "}
            {days[day]}, next on {nextRunOn} |{" "}
          </>
        )}
        <span
          onClick={loading ? undefined : () => open({ id, schedule, type })}
          style={{ ...CLICKABLE_STYLE }}
        >
          Edit
        </span>{" "}
        |{" "}
        <span
          onClick={loading ? undefined : submit}
          style={{ ...CLICKABLE_STYLE }}
        >
          Clear
        </span>
      </div>
    </>
  );
};

const UserDefinedFieldsSelect = (props) => {
  const { organization, me } = props;

  const userDefinedFields = (organization.userDefinedFields || []).filter(
    ({ type }) => type === props.type
  );

  const updateCannedReportSettings = useUpdateCannedReportSettings({
    delay: 250,
  });

  const submit = (value) => {
    updateCannedReportSettings.mutate({
      id: props.id,
      userDefinedFields: value,
    });
  };

  if (userDefinedFields.length === 0) {
    return null;
  }

  const value = filter(
    me?.cannedReportSettings?.[props.id]?.userDefinedFields,
    (id) => {
      return userDefinedFields.find((udf) => udf.id === id);
    }
  );

  return (
    <Select
      allowClear
      disabled={updateCannedReportSettings.isLoading}
      loading={updateCannedReportSettings.isLoading}
      mode="multiple"
      onChange={submit}
      options={userDefinedFields.map(({ id, name }) => ({
        value: id,
        label: name,
      }))}
      placeholder="Add Custom Fields to report"
      style={{ width: "100%" }}
      value={value}
    />
  );
};

const serializeParam = (key, value) => {
  if (["before", "after"].includes(key)) {
    return value.valueOf() / 1000;
  }

  return value;
};

const CannedReport = (props) => {
  const { filters = [], route, schedule, organization, me } = props;

  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState(filters.reduce((acc, filter) => {
    return {
      ...acc,
      ...filter.defaults,
    };
  }, {}));
  const notification = useNotification();

  const getReport = async () => {
    const start = +new Date();
    setLoading(true);

    try {
      // TODO: transform any query

      const searchParams = new URLSearchParams();
      for (const [key, value] of Object.entries(query)) {
        searchParams.set(key, serializeParam(key, value));
      }

      const queryString = searchParams.size > 0 ? `?${searchParams}` : "";

      const response = await fetcher.get(`/canned-report/${route}${queryString}`, {
        raw: true,
      })();

      const end = +new Date();

      if (end - start < 1000) {
        await new Promise((resolve) =>
          setTimeout(resolve, 1000 - (end - start))
        );
      }

      if (response.status === 200) {
        const blob = new Blob([await response.text()], {
          type: "text/plain;charset=utf-8",
        });

        saveAs(blob, `${route}-report.csv`);
      } else {
        throw new Error();
      }
    } catch (err) {
      notification.support(err);
    }

    setLoading(false);
  };

  return (
    <Card
      bordered={false}
      className={"sm-larger-on-hover transition"}
      style={styles.buttonCard.container}
    >
      <Spin spinning={loading}>
        <h4>{props.title}</h4>
        {filters?.map((filter, key) => {
          if (filter.id === "date-range") {
            return (
              <div key={key}>
                <RangePicker
                  onChange={([after, before]) => {
                    setQuery({ after: after.startOf("day"), before: before.endOf("day") });
                  }}
                  value={[
                    query.after ?? filter.defaults.after,
                    query.before ?? filter.defaults.before,
                  ]}
                />
              </div>
            )
          }

          return null;
        })}
        {props.description}
        <span
          onClick={!loading ? getReport : noop}
          style={{ ...CLICKABLE_STYLE, marginLeft: "8px" }}
        >
          Download
        </span>
        <div style={{ marginTop: "8px" }} />
        <ScheduleReport
          id={route}
          loading={loading}
          schedule={schedule}
          setLoading={setLoading}
          type="canned"
        />

        {route === "opportunities-funnel" && (
          <div style={{ marginTop: "8px" }}>
            <UserDefinedFieldsSelect
              id={route}
              organization={organization}
              me={me}
              type="OPPORTUNITY"
            />
          </div>
        )}
      </Spin>
    </Card>
  );
};

const ViewReport = (props) => {
  const { isOwner, organization, owner, schedule, view } = props;

  const [loading, setLoading] = useState(false);

  return (
    <Card
      bodyStyle={{ width: "100%" }}
      bordered={false}
      className={"sm-larger-on-hover transition"}
      key={view._id}
      style={{ ...styles.buttonCard.container }}
    >
      <Spin spinning={loading}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <h4 style={{ marginRight: "auto", marginBottom: "0px" }}>
            {view.name}
          </h4>
          {icons({ organization, type: view.type })}
        </div>
        <div style={{ color: "grey" }}>
          {isOwner ? "Your View" : `Owned By ${owner.alias}`}
        </div>
        <div style={{ marginTop: "8px" }} />
        <ScheduleReport
          id={view._id}
          loading={loading}
          schedule={schedule}
          setLoading={setLoading}
          type="view"
        />
      </Spin>
    </Card>
  );
};

export const Reports = () => {
  const profile = useMe();
  const organization = useOrganization();

  const getSchedule = (id) => {
    return find(profile.scheduledReports, ({ report }) => report.id === id);
  };

  let views = useViews();
  const users = useUsers();

  if (!organization?.settings?.showPartners) {
    views = filter(views, ({ type }) => type !== viewTypes.VENDOR);
  }

  views = filter(views, ({ type }) =>
    hasAccess(profile)(organization)(`${type.toLowerCase()}:export`)()
  );

  return (
    <Card
      style={{ marginTop: "16px", marginLeft: "16px", marginRight: "34px" }}
    >
      {(!organization?.settings?.managerOnlyReports ||
        profile.role <= ROLES.MANAGER) && (
          <>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <h3 style={{ marginTop: "16px", marginRight: "auto" }}>
                One-Click Reports
              </h3>
              <Settings />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              <CannedReport
                description="See potential and probable revenue across all open Opportunities"
                route="total-product-opportunities"
                schedule={getSchedule("total-product-opportunities")}
                title="Total Product Opportunities"
                organization={organization}
                me={profile}
              />
              <CannedReport
                description="See funnel progress across all Opportunities"
                route="opportunities-funnel"
                schedule={getSchedule("opportunities-funnel")}
                title="Opportunities Funnel"
                organization={organization}
                me={profile}
              />
              <CannedReport
                description="See Sales Rep Activity counts by Activity Type"
                route="activities-by-sales-rep"
                schedule={getSchedule("activities-by-sales-rep")}
                title="Activities By Sales Rep"
                organization={organization}
                me={profile}
              />
              <CannedReport
                description="See all Activities in reverse chronological order"
                route="all-activities"
                schedule={getSchedule("all-activities")}
                title="All Activities"
                organization={organization}
                me={profile}
              />
              <CannedReport
                description="See all opportunity stage changes during the time period"
                route="opportunity-stage-changes"
                schedule={getSchedule("opportunity-stage-changes")}
                title="Opportunity Stage Changes"
                organization={organization}
                me={profile}
                filters={[{
                  id: "date-range",
                  defaults: {
                    after: day().subtract(1, "month").startOf("day"),
                    before: day(),
                  }
                }]}
              />
              <CannedReport
                description="Count of opportunity stage change transitions during a given time period."
                route="opportunity-stage-change-counts"
                schedule={getSchedule("opportunity-stage-change-counts")}
                title="Opportunity Stage Change Counts"
                organization={organization}
                me={profile}
                filters={[{
                  id: "date-range",
                  defaults: {
                    after: day().subtract(1, "month").startOf("day"),
                    before: day(),
                  }
                }]}
              />
            </div>
          </>
        )}
      <h3 style={{ marginTop: "16px" }}>Views</h3>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {views.length > 0 ? (
          orderBy(views, ["type", "name"]).map((view) => (
            <ViewReport
              key={view._id}
              isOwner={profile._id === view.createdById}
              owner={users.byId(view.createdById)}
              organization={organization}
              schedule={getSchedule(Number(view._id))}
              view={view}
            />
          ))
        ) : (
          <div>
            Create some opportunity, account, contact, or partner views to
            schedule reports on them.
          </div>
        )}
      </div>
    </Card>
  );
};
