import { Switch } from "antd";

import {
  useOrganization,
  useSetLossReasonEnforcement,
} from "data/use-organization";

export const ToggleLossReasonEnforcement = () => {
  const organization = useOrganization();
  const setLossReasonEnforcement = useSetLossReasonEnforcement();

  return (
    <>
      <div style={{ display: "flex" }}>
        <strong>Enforce opportunity loss reason</strong>
        <Switch
          checked={organization.shouldEnforceLossReason}
          onChange={(shouldEnforce) => {
            setLossReasonEnforcement.mutate({ shouldEnforce });
          }}
          style={{ marginLeft: "16px" }}
        />
      </div>
      <div style={{ fontSize: "12px", marginTop: "8px" }}>
        Enabling this will enforce that the reason for losing an opportunity is
        recorded.
      </div>
    </>
  );
};
