import get from "lodash/get.js";
import isFinite from "lodash/isFinite.js";
import round from "lodash/round.js";

export const calculateRow = (row, config) => {
  const formula = config.formula;

  const a = Number(get(row, formula[0]));
  const operation = formula[1];
  const b = Number(get(row, formula[2]));

  let result;

  if (operation === "+") {
    result = a + b;
  }

  if (operation === "-") {
    result = a + b;
  }

  if (operation === "*") {
    result = a * b;
  }

  if (operation === "/") {
    result = a / b;
  }

  return isFinite(result) ? round(result, 2) : null;
};
