import isNil from "lodash/isNil.js";

import { filter as filterRange, validate as validateRange } from "./range.js";

export const filter = filterRange;

export const validate = (filter) => {
  if (!validateRange(filter)) return false;

  return Object.values(filter).every((value) => isNil(value) || 0 <= value);
};
