import { MoreOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";

import { withManagerRole } from "../../components/with-role";
import { useModal } from "../../hooks/use-modal";
import { MergeAccountsModal } from "./merge-accounts-modal";

const Component = () => {
  const { modal: mergeAccountModal, open: openMergeAccountModal } =
    useModal(MergeAccountsModal);

  return (
    <>
      {mergeAccountModal}
      <Popover
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button size="small" onClick={openMergeAccountModal}>
              Merge Accounts
            </Button>
          </div>
        }
        placement={"bottom"}
      >
        <Button
          ghost
          icon={<MoreOutlined />}
          shape="circle"
          style={{ marginRight: "8px" }}
          type="primary"
        />
      </Popover>
    </>
  );
};

export const Extras = withManagerRole(Component);
