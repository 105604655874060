import isBoolean from "lodash/isBoolean";
import isNil from "lodash/isNil";
import startCase from "lodash/startCase";
import React, { useEffect, useState } from "react";
import { Card, Select } from "antd";

import { getPartnersLabel } from "@evolved/labels";
import { permissions, userRoles } from "@evolved/constants";
import { useMe } from "data/use-me";
import { usePatchAccessControl } from "../../data/use-organization";

const { display, ROLES } = userRoles;
const { accessLevels, defaults, options } = permissions;

const roles = [ROLES.VIEWER, ROLES.SALES, ROLES.MANAGER, ROLES.OWNER];

const groups = Object.keys(defaults).reduce((acc, key) => {
  const [entityType, permission, role] = key.split(":");

  if (Number(role) < 0) {
    return acc;
  }

  if (!acc[entityType]) {
    acc[entityType] = new Set();
  }

  acc[entityType].add(permission);

  return acc;
}, {});

const renderAccessControl = (access) => {
  if (isBoolean(access)) {
    return access ? "✅" : "🛑";
  }

  return {
    [accessLevels.ASSIGNED]: "Is Assigned",
    [accessLevels.RESPONSIBLE]: "Is Owner",
  }[access];
};

const permissionMap = {
  "self-assign-followup": "Assign Followup",
};

export const Permissions = (props) => {
  const { organization } = props;

  const profile = useMe();
  const patchAccessControl = usePatchAccessControl();
  const [loadingKey, setLoadingKey] = useState();

  useEffect(() => {
    if (!patchAccessControl.isLoading) {
      setLoadingKey(null);
    }
  }, [patchAccessControl.isLoading]);

  return (
    <div style={{ width: "676px" }}>
      <Card bordered={false}>
        <div style={{ display: "flex", maxWidth: "650px", flexWrap: "wrap" }}>
          <div
            style={{
              borderBottom: "#a9a9a9 1px solid",
              height: "50px",
              width: "120px",
            }}
          ></div>
          {roles.map((role) => (
            <div
              key={role || "empty"}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                borderBottom: "#a9a9a9 1px solid",
                height: "50px",
                width: "125px",
              }}
            >
              <strong>{!isNil(role) ? display(role) : ""}</strong>
            </div>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            maxWidth: "650px",
            flexWrap: "wrap",
            maxHeight: "500px",
            overflowY: "scroll",
          }}
        >
          {Object.keys(groups).map((entityType) => {
            return (
              <React.Fragment key={entityType}>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    height: "50px",
                    width: "125px",
                  }}
                >
                  <strong>
                    {entityType !== "vendor"
                      ? startCase(entityType)
                      : getPartnersLabel(organization, "singular")}
                  </strong>
                </div>
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "flex-start",
                    display: "flex",
                    height: "50px",
                    width: "500px",
                  }}
                ></div>
                {Array.from(groups[entityType]).map((permission) => (
                  <React.Fragment key={permission}>
                    <div
                      style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                        borderBottom: "#a9a9a9 1px solid",
                        height: "50px",
                        width: "120px",
                      }}
                    >
                      {startCase(permissionMap[permission] || permission)}
                    </div>
                    {roles.map((role) => {
                      const key = `${entityType}:${permission}:${role}`;

                      return (
                        <div
                          key={key}
                          style={{
                            alignItems: "center",
                            justifyContent: "center",
                            display: "flex",
                            borderBottom: "#a9a9a9 1px solid",
                            height: "50px",
                            width: "125px",
                          }}
                        >
                          {options[key] && role > profile.role ? (
                            <Select
                              disabled={patchAccessControl.isLoading}
                              loading={
                                patchAccessControl.isLoading &&
                                loadingKey === key
                              }
                              onChange={async (value) => {
                                setLoadingKey(key);
                                patchAccessControl.mutate({ [key]: value });
                              }}
                              options={options[key].map((value) => ({
                                label: renderAccessControl(value),
                                value,
                              }))}
                              value={organization.accessControl[key]}
                              style={{ width: "118px" }}
                            />
                          ) : (
                            renderAccessControl(organization.accessControl[key])
                          )}
                        </div>
                      );
                    })}
                  </React.Fragment>
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </Card>
      <small style={{ display: "flex", marginTop: "24px" }}>
        <div style={{ width: "250px" }}>
          <div style={{ marginBottom: "8px" }}>
            ✅ = Anyone with the given role can perform the action
          </div>
          <div>🛑 = No one with the given role can perform the action</div>
        </div>
        <div style={{ width: "250px", marginLeft: "24px" }}>
          <div style={{ marginBottom: "8px" }}>
            Is Assigned = User must be assigned to perform the action
          </div>
          <div>Is Owner = User must be the owner to perform the action</div>
        </div>
      </small>
    </div>
  );
};
