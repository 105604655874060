import debounce from "lodash/debounce";
import get from "lodash/get";

import { EditOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { fieldTypes } from "@evolved/constants";

import { getEditableComponent } from "./edit";
import { renderView } from "./view";

const ALIGNMENT = {
  [fieldTypes.DOLLAR]: "right",
};

export const Cell = ({ column, row, style, viewType }) => {
  const { dataIndex } = column;

  const navigate = useNavigate();

  const [isInside, setIsInside] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const setIsInsideDebounced = useMemo(
    () => debounce(setIsInside, 50),
    [setIsInside]
  );

  const value = get(row, dataIndex);

  const openButton = column.mapUiUrl && isInside && !isEditing && !value && (
    <Button
      onClick={() => navigate(column.mapUiUrl(row._id))}
      size="small"
      style={{ position: "absolute", top: "4px", right: "4px" }}
    >
      Details
      <RightOutlined />
    </Button>
  );

  const Editable = getEditableComponent({ dataIndex, viewType });

  const wrapperStyle = {
    maxWidth: column.width,
    minWidth: column.width,
    overflowWrap: "anywhere",
    position: "relative",
    textAlign: ALIGNMENT[column.type] || "left",
    ...style,
  };

  const archivedStyle =
    column.mapUiUrl && row.isArchived ? { opacity: 0.5 } : null;

  if (!Editable && !archivedStyle) {
    return (
      <td style={wrapperStyle}>
        {openButton}
        {renderView({ column, row })}
      </td>
    );
  }

  if (!Editable && archivedStyle) {
    return (
      <td style={wrapperStyle}>
        {openButton}
        <span style={archivedStyle}>{renderView({ column, row })}</span>
      </td>
    );
  }

  return (
    <td
      // todo: these are triggered on the entire viewport when an editable modal is
      // open, causing unnecessary re-renders.
      // Make sure we use global modals in tables for this purpose.
      // Global modals should really be used anywhere there's a list.
      onPointerOver={() => setIsInsideDebounced(true)}
      onPointerLeave={() => setIsInsideDebounced(false)}
      style={wrapperStyle}
    >
      <Editable
        column={column}
        dataIndex={dataIndex}
        isEditing={isEditing}
        row={row}
        setIsEditing={setIsEditing}
        value={value}
      >
        {archivedStyle ? (
          <span style={archivedStyle}>{renderView({ column, row })}</span>
        ) : (
          renderView({ column, row })
        )}
      </Editable>
      {openButton}
      {isInside && !isEditing && column.type !== fieldTypes.CALCULATED && (
        <Button
          icon={<EditOutlined />}
          onClick={() => setIsEditing(true)}
          size="small"
          style={{
            position: "absolute",
            top: "4px",
            right: openButton ? "92px" : "4px",
          }}
        />
      )}
    </td>
  );
};
