export const styles = {
  cell: {
    background: "#fff",
    border: "0.5px solid #f0f0f0",
    height: "26.5px",
    overflowWrap: "break-word",
    padding: "4px",
    textAlign: "left",
  },
};
