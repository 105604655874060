import { STATES as ACCOUNT_STATES } from "./account-states.js";

export const INCOMPLETE = "#a9a9a9";
export const PRIMARY = "#874d9a";
export const SECONDARY = "#5DA9E9";
export const SUCCESS = "#3ad48c";

export const URGENT = "#ffa140";
export const EMERGENCY = "#FE6847";

export const WHITE = "#fff";
export const JOYRIDE_SPOTLIGHT = "rgba(255,255,255,0.5)";

export const ACCOUNT_STATE_COLORS = {
  [ACCOUNT_STATES.LEAD]: URGENT,
  [ACCOUNT_STATES.PROSPECT]: PRIMARY,
  [ACCOUNT_STATES.CLIENT]: SUCCESS,
};

export const FORM_ERROR = URGENT;
