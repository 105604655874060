import { SaveOutlined } from "@ant-design/icons";
import { Button, message, Select } from "antd";
import { useEffect, useRef, useState } from "react";

import noop from "lodash/noop";

import { getSelectOptions } from "../../../../../utils/get-select-options";
import { useUsers } from "../../../../../data/use-users";

export const EditableUsersSelect = (props) => {
  const {
    isEditing,
    setIsEditing,
    children,
    onUpdate,
    validate = noop,
  } = props;

  const users = useUsers();

  const [pendingValue, setPendingValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    if (isEditing) {
      setPendingValue(props.value);
      inputRef.current.focus();
    }
  }, [isEditing]);

  const save = async () => {
    const error = validate(pendingValue);

    if (error) {
      message.warning(error);
      return;
    }

    setIsLoading(true);

    const isSuccess = await onUpdate(pendingValue);

    if (isSuccess) {
      setIsEditing(false);
      setPendingValue(null);
    }

    setIsLoading(false);
  };

  return isEditing ? (
    <>
      <div style={{ display: "flex", marginBottom: "8px" }}>
        <Button
          loading={isLoading}
          icon={<SaveOutlined />}
          onClick={save}
          size="small"
          style={{ flexBasis: 0, marginRight: 8 }}
          type="primary"
        >
          Save
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => setIsEditing(false)}
          size="small"
          style={{ flexBasis: 0 }}
        >
          Cancel
        </Button>
      </div>
      <Select
        defaultOpen
        disabled={isLoading}
        filterOption={(input, option) =>
          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
        }
        mode="multiple"
        onChange={setPendingValue}
        options={getSelectOptions({
          store: users,
          initialIds: props.value,
          labelKey: "alias",
        })}
        ref={inputRef}
        style={{ width: "100%" }}
        value={pendingValue || props.value}
      />
    </>
  ) : (
    children
  );
};
