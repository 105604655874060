import isEmpty from "lodash/isEmpty";

import { Button } from "antd";
import { Outlet, useHref } from "react-router-dom";

import { userRoles } from "@evolved/constants";

import ActionsMenu from "./actions-menu";
import NavigationMenu from "./navigation-menu/navigation-menu";
import NotificationsSider from "./notifications-sider/notifications-sider";
import logo from "../assets/navbar-logo-dark.png";

import { ApplicationAccess } from "./organization/application-access";
import { EmptyBox } from "components/image-placeholders";
import { CommandPalette } from "../components/command-palette/command-palette";
import { FullScreenCenter } from "components/full-screen-center";
import { LogOutButton } from "components/log-out-button";
import { useMe } from "data/use-me";
import { useOrganization } from "data/use-organization";
import { CreateOrganizationForm } from "./create-organization-form";
import { CreateUserForm } from "./create-user-form";
import { GlobalModals } from "./global-modals";
import { RedirectToDashboard } from "./redirect-to-dashboard";
import { useLogOut } from "../stores/authentication";
import { WatchCrumbs } from "./watch-crumbs";

const { ROLES } = userRoles;

export const RootRoute = () => {
  const href = useHref();

  const me = useMe({ detailed: true });
  const organization = useOrganization({ detailed: true });
  const logout = useLogOut();

  let setup;

  if (isEmpty(organization.data)) {
    setup = <CreateOrganizationForm />;
  }

  if (!me.data) {
    setup = <CreateUserForm />;
  }

  if (setup) {
    return (
      <>
        <div className="absolute right-8 top-8">
          <LogOutButton />
        </div>
        <FullScreenCenter>{setup}</FullScreenCenter>
      </>
    );
  }

  if (href === "/") {
    return <RedirectToDashboard />;
  }

  if (!organization.data.subscriptionState?.userHasAccess) {
    if ([ROLES.SALES, ROLES.VIEWER].includes(me.data.role)) {
      return (
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            paddingTop: "10%",
          }}
        >
          <img
            alt="logo"
            style={{
              position: "fixed",
              top: "50px",
              left: "25%",
              width: "250px",
            }}
            src={logo}
          />
          <Button
            onClick={logout}
            type="primary"
            style={{ position: "fixed", top: "50px", right: "25%" }}
          >
            Logout
          </Button>
          <EmptyBox />
          <h2 style={{ marginBottom: "24px" }}>
            Sorry, you no longer have access.
          </h2>
          <h4 style={{ marginBottom: "12px", maxWidth: "400px" }}>
            Contact an account owner or manager to activate your seat by
            updating your organization billing settings.
          </h4>
        </div>
      );
    }

    return (
      <div className="grid place-content-center">
        <div className="mb-8 mt-[15%] flex">
          <img
            alt="logo"
            style={{
              width: "250px",
            }}
            src={logo}
          />
          <Button onClick={logout} type="primary" className="ml-auto">
            Logout
          </Button>
        </div>
        <div
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <EmptyBox />
          </div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              marginLeft: "64px",
              width: "50%",
            }}
          >
            <ApplicationAccess organization={organization.data} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <CommandPalette />
      <WatchCrumbs />
      <GlobalModals />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          gridTemplateRows: "64px 1fr",
          height: "100vh",
        }}
      >
        <ActionsMenu />
        <NavigationMenu />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gridColumnStart: 2,
            gridColumnEnd: 3,
            gridRowStart: 2,
            gridRowEnd: 3,
            overflowY: "scroll",
            padding: "24px",
          }}
        >
          <Outlet />
        </div>
        <NotificationsSider />
      </div>
    </>
  );
};
