import { Select, Tag } from "antd";
import { useState } from "react";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";

import difference from "lodash/difference";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import toUpper from "lodash/toUpper";

import { useTags } from "data/use-tags";
import { useCreateTag } from "data/use-tags";

const { CheckableTag } = Tag;

import classes from "./style.module.css";

const renderTags = (props) => {
  const {
    isSelect,
    addTag,
    removeTag,
    selectedTags,
    tags = [],
    setLoadingKey,
    loadingKey,
  } = props;

  const sorted = sortBy(tags, [({ label }) => toUpper(label)]);

  return sorted
    .filter((tag) => !isSelect || selectedTags.includes(tag._id))
    .map((tag) => {
      const isChecked = selectedTags.includes(tag._id);

      return (
        <CheckableTag
          checked={isChecked}
          className="primary-hoverable-text"
          key={tag._id}
          onChange={async (checked) => {
            setLoadingKey(tag._id);

            if (checked) {
              addTag.mutate(tag._id);
            } else {
              removeTag.mutate(tag._id);
            }
          }}
          style={{
            ...(isChecked ? { backgroundColor: "#ffffff" } : {}),
            color: "rgba(0, 0, 0, 0.65)",
            cursor: "pointer",
          }}
        >
          {(removeTag.isLoading || removeTag.isLoading) &&
          loadingKey === tag._id ? (
            <SyncOutlined spin style={{ marginRight: "8px" }} />
          ) : null}
          {tag.label}
        </CheckableTag>
      );
    });
};

export const Tags = (props) => {
  const { addTag, removeTag } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [loadingKey, setLoadingKey] = useState(null);

  const tags = useTags();

  const createTag = useCreateTag({
    onSuccess: async ({ tag }, { mutation }) => {
      await mutation.onSuccess.call();
      mutation.onFinal.skip();

      addTag.mutate(tag._id);
    },
  });

  const onConfirm = (label) => {
    if (!isEmpty(label)) {
      createTag.mutate({ label });
    }
  };

  return (
    <div
      className={classes.container}
      style={{
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {!isEditing && (
        <Tag onClick={() => setIsEditing(true)} style={{ cursor: "pointer" }}>
          <PlusOutlined /> Tag
        </Tag>
      )}
      {isEditing && (
        <Select
          autoFocus
          loading={
            (addTag.isLoading || createTag.isLoading || removeTag.isLoading) &&
            loadingKey === "primary"
          }
          size="small"
          mode="tags"
          style={{ marginRight: "8px", width: 250 }}
          options={tags
            .all()
            .map(({ _id, label }) => ({ value: String(_id), label }))}
          optionFilterProp="label"
          onBlur={() => setIsEditing(false)}
          onChange={async (values) => {
            const [value] = difference(
              values,
              props.selectedTags.map((v) => String(v))
            );

            if (!value) {
              return;
            }

            setLoadingKey("primary");

            const existing = (tags.all() || []).find(
              ({ _id }) => Number(value) === _id
            );

            if (!existing) {
              onConfirm(value);
            } else {
              addTag.mutate(Number(value));
            }
          }}
          onDeselect={async (value) => {
            const tag = tags.all().find(({ _id }) => _id == value);

            if (!tag) {
              return;
            }

            setLoadingKey("primary");

            removeTag.mutate(tag._id);
          }}
          open
          placeholder="Type and enter to add"
          tagRender={() => null}
          value={props.selectedTags.map((v) => String(v))}
        />
      )}
      {renderTags({
        ...props,
        tags: tags.all(),
        loadingKey,
        setLoadingKey,
      })}
    </div>
  );
};
