import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useArchiveOpportunity } from "data/use-opportunities";

export const ArchiveOpportunity = (props) => {
  const { opportunity } = props;

  const archiveOpportunity = useArchiveOpportunity({
    onSuccess: async (data, { mutation }) => {
      await mutation.onSuccess.call();
    },
  });

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() => archiveOpportunity.mutateAsync({ id: opportunity._id })}
      title="Are you sure?"
    >
      <Button
        icon={<EyeInvisibleOutlined />}
        disabled={archiveOpportunity.isLoading}
      >
        Archive
      </Button>
    </Popconfirm>
  );
};
