import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import reject from "lodash/reject";
import { Button, Popover } from "antd";
import { CSS } from "@dnd-kit/utilities";
import { OrderedListOutlined } from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { useState } from "react";

import { SortableList } from "components/sortable-list";
import { useSetUserDefinedFields } from "data/use-organization";

const Field = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div className="item-center flex">
        <div className="grow cursor-move" {...attributes} {...listeners}>
          <OrderedListOutlined className="mr-2" />
          {props.field.name}
        </div>
      </div>
    </div>
  );
};

export const FieldOrder = ({ fields, organization, type }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [localFields, setLocalFields] = useState(fields);

  const setUserDefinedFields = useSetUserDefinedFields();
  const userDefinedFields = get(organization, "userDefinedFields", []);

  if (isEmpty(fields)) {
    return null;
  }

  return (
    <Popover
      content={
        <div style={{ minWidth: "50px" }}>
          <h4>Field Order</h4>
          <div
            style={{
              maxHeight: "500px",
              overflowY: "scroll",
              marginBottom: "12px",
            }}
          >
            <SortableList
              items={localFields.map((field) => {
                return {
                  id: field.id,
                  Component: Field,
                  props: { field },
                };
              })}
              setItems={(items) =>
                setLocalFields(
                  items.map((id) => fields.find((field) => field.id === id))
                )
              }
            />
          </div>
          <Button
            loading={setUserDefinedFields.isLoading}
            onClick={async () => {
              setIsVisible(false);

              const payload = [
                ...localFields,
                ...reject(userDefinedFields, { type }),
              ];

              setUserDefinedFields.mutate(payload);
            }}
            size={"small"}
          >
            Save Changes
          </Button>
        </div>
      }
      onOpenChange={(visible) => {
        setIsVisible(visible);
        setTimeout(() => {
          setLocalFields(fields);
        }, 250);
      }}
      open={isVisible}
      placement="bottom"
      trigger="click"
    >
      <OrderedListOutlined />
    </Popover>
  );
};
