import { Card } from "antd";

import { Schedule } from "components/image-placeholders";

import { styles } from "./styles";

export const ScheduleNextFollowup = (props) => {
  const { showCreateFollowup } = props;

  return (
    <Card
      bordered={false}
      className={"sm-larger-on-hover transition"}
      hoverable
      onClick={showCreateFollowup}
      style={styles.buttonCard.container}
    >
      <Schedule
        containerStyle={styles.buttonCard.svg.container}
        primary={
          "What's your next move? Go ahead and schedule it as a followup."
        }
        style={styles.buttonCard.svg.body}
      />
    </Card>
  );
};
