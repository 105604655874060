import { Badge, Button, Card, List, Popconfirm } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { OpportunityState } from "components/opportunity-state";
import { OpportunityStateModal } from "./modals/opportunity-state";
import { OpportunityStatesOrder } from "./opportunity-states-order";
import { colors } from "@evolved/constants";
import { useModal } from "hooks/use-modal";
import {
  useArchiveOpportunityState,
  useOpportunityStates,
} from "data/use-opportunity-states";

const { PRIMARY } = colors;

const Header = ({ opportunityStates, openOpportunityStateModal }) => {
  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={opportunityStates.length}
        showZero
        style={{ backgroundColor: PRIMARY }}
      />
      <span style={{ marginLeft: "16px" }}>Opportunity Stages</span>
      <div
        style={{ marginLeft: "auto", marginRight: "16px" }}
      >
        <OpportunityStatesOrder />
      </div>
      <Button
        ghost
        onClick={() => openOpportunityStateModal()}
        type="primary"
      >
        <PlusCircleOutlined style={{ margin: "0px" }} />
      </Button>
    </div>
  );
};

export const OpportunityStates = () => {
  const { modal, open } = useModal(OpportunityStateModal);

  const archiveOpportunityState = useArchiveOpportunityState();
  const opportunityStates = useOpportunityStates().all({
    includeArchived: false,
  });

  return (
    <>
      {modal}
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
        <List
          dataSource={opportunityStates}
          header={
            <Header
              {...{ opportunityStates, openOpportunityStateModal: open }}
            />
          }
          itemLayout={"vertical"}
          pagination={{
            pageSize: 5,
          }}
          renderItem={(opportunityState) => {
            const { _id, description } = opportunityState;

            let label = opportunityState.label;

            if (
              opportunityState.isSystem &&
              opportunityState.systemLabel !== label
            ) {
              label = `${label} (${opportunityState.systemLabel})`;
            }

            return (
              <List.Item key={_id}>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div>
                    <OpportunityState
                      showFunnelProgress={false}
                      opportunity={{
                        stateId: _id,
                      }}
                      style={{ marginLeft: "0px", marginBottom: "8px" }}
                    />
                    {opportunityState.isSystem && (
                      <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        System Stage
                      </div>
                    )}
                    {description && (
                      <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {description}
                      </div>
                    )}
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <span
                      className="primary-clickable"
                      onClick={() => open({ opportunityState })}
                    >
                      Update
                    </span>
                  </div>

                  {!opportunityState.isSystem && (
                    <>
                      <div style={{ margin: "0px 8px" }}>|</div>
                      <Popconfirm
                        okText="Yes"
                        onConfirm={() =>
                          archiveOpportunityState.mutateAsync({
                            id: opportunityState._id,
                          })
                        }
                        title="Are you sure?"
                      >
                        <span className="primary-clickable">Archive</span>
                      </Popconfirm>
                    </>
                  )}
                </div>
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
};
