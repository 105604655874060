import {
  BorderOutlined,
  CheckSquareOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import { Button, Card, Progress } from "antd";

import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";

import { colors } from "@evolved/constants";

import {
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { useOpportunitySteps } from "data/use-opportunities";
import { useSalesProcesses } from "data/use-sales-processes";
import { usePermission } from "../../hooks/use-permission";

const { INCOMPLETE, PRIMARY, SUCCESS } = colors;

const renderIcon = ({ isComplete }) =>
  isComplete ? (
    <CheckSquareOutlined style={{ color: SUCCESS, marginRight: "15px" }} />
  ) : (
    <BorderOutlined style={{ color: INCOMPLETE, marginRight: "15px" }} />
  );

export const FunnelState = (props) => {
  const { opportunity, openOverrideSalesProcess } = props;

  const [open, setOpen] = useState(true);

  const steps = useOpportunitySteps(opportunity._id);
  const salesProcess = useSalesProcesses().byId(opportunity.salesProcessId);

  const hasOverrideOpportunity =
    true || usePermission("opportunity:override-sales-process");

  const showPerformActivity = ({ defaults } = {}) => {
    useLayoutStore.getState().open(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, {
      opportunityId: opportunity._id,
      defaults,
    });
  };

  function hasSteps() {
    return !isEmpty(steps);
  }

  function toggleQuestions() {
    if (!hasSteps()) return;

    setOpen(!open);
  }

  function renderProgress() {
    const options = {
      ...(opportunity.funnelProgress < 100
        ? {
            status: "active",
          }
        : {}),
      percent: opportunity.funnelProgress,
      strokeColor: PRIMARY,
      style: {
        marginBottom: "0px",
        marginRight: "16px",
        paddingRight: "4px",
      },
    };

    return (
      <div
        style={{
          alignItems: "center",
          display: "flex",
        }}
        className="items-center"
      >
        {salesProcess && <h4 className="mb-0 mr-4">{salesProcess.name}</h4>}
        <Progress {...options} />
        {openOverrideSalesProcess && hasOverrideOpportunity && (
          <div className="mr-6">
            <Button onClick={openOverrideSalesProcess}>Override</Button>
          </div>
        )}
        {hasSteps() && open && <UpOutlined onClick={toggleQuestions} />}
        {hasSteps() && !open && <DownOutlined onClick={toggleQuestions} />}
      </div>
    );
  }

  function renderSteps() {
    if (!open || !hasSteps()) {
      return null;
    }

    return (
      <div style={{ padding: "0px 16px" }}>
        {steps.map((step) => {
          const onClick = !step.isComplete
            ? () => {
                showPerformActivity({
                  opportunity,
                  defaults: { completedSteps: [step._id] },
                });
              }
            : noop;

          return (
            <div key={step._id}>
              <div style={{ display: "flex", fontSize: "1.2em" }}>
                <div
                  onClick={onClick}
                  style={{
                    flexBasis: "16px",
                    ...(!step.isComplete ? { cursor: "pointer" } : {}),
                  }}
                >
                  {renderIcon(step)}
                </div>
                <div
                  onClick={onClick}
                  style={{
                    ...(!step.isComplete ? { cursor: "pointer" } : {}),
                  }}
                >
                  {step.question}
                </div>
              </div>
              {step?.triggers?.quickbooks?.isEnabled && (
                <div style={{ marginLeft: "32px" }}>
                  Create an estimate in Quickbooks based on when this step was
                  logged.
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  const bodyStyle = {
    ...(!open || !hasSteps()
      ? {
          padding: "0px",
        }
      : {}),
  };

  const style = hasSteps()
    ? {
        borderColor: "rgba(0, 0, 0, 0.12)",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.12)",
      }
    : {};

  return (
    <Card
      bodyStyle={bodyStyle}
      bordered={false}
      className={hasSteps() ? "sm-larger-on-hover transition" : ""}
      style={style}
      title={renderProgress()}
    >
      {renderSteps()}
    </Card>
  );
};
