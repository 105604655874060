import maxBy from "lodash/maxBy";
import { Alert, Button, Form, Input, InputNumber } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { useState } from "react";

import { TemplateSelect } from "./template-select";
import { useCreateSalesProcess } from "data/use-sales-processes";
import { useOnChange } from "hooks/use-on-change";
import { Modal } from "components/modal";

const onSubmit =
  ({ createSalesProcess, setError }) =>
  (values) => {
    setError();

    const { steps } = values;

    if (
      steps.some(({ funnelTrigger: testFunnelTrigger }, testIndex) =>
        steps.find(
          ({ funnelTrigger }, index) =>
            testIndex !== index && testFunnelTrigger === funnelTrigger
        )
      )
    ) {
      throw new Error("No two steps can have the same funnel trigger percent.");
    }

    if (!steps.some(({ funnelTrigger }) => funnelTrigger === 100)) {
      throw new Error("Must have at least one funnel trigger percent at 100%.");
    }

    createSalesProcess.mutate(values);
  };

const getNextFunnelTrigger = (steps) => {
  const maxStep = maxBy(
    steps.filter((step) => step.funnelTrigger !== 100),
    "funnelTrigger"
  ) || {
    funnelTrigger: 0,
  };

  const indexOf = steps.indexOf(maxStep);

  return [
    maxStep.funnelTrigger <= 95 ? maxStep.funnelTrigger + 5 : 100,
    indexOf,
  ];
};

export const CreateSalesProcessModal = (props) => {
  const { close, isOpen, onCreate = () => {} } = props;

  const [error, setError] = useState();

  const createSalesProcess = useCreateSalesProcess({
    onSuccess: async (result, { mutation }) => {
      await mutation.onSuccess.call();

      await onCreate(result);

      close();
    },
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        name: "",
        steps: [
          { funnelTrigger: 5, question: "" },
          { funnelTrigger: 100, question: "" },
        ],
      });
    },
    [isOpen, form]
  );

  return (
    <Modal
      confirmLoading={createSalesProcess.isLoading}
      maskClosable={false}
      okText={"Create"}
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title={"Create Sales Process"}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({ createSalesProcess, setError })}
      >
        <div id="create-sales-process-modal">
          <Form.Item label="Name" name="name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <TemplateSelect form={form} />
        </div>
        <Form.List name="steps">
          {(fields, arrayHelpers) => {
            return (
              <div style={{ margin: "24px 0px 12px 0px" }}>
                <div
                  style={{
                    alignItems: "flex-end",
                    display: "flex",
                    marginBottom: "24px",
                  }}
                >
                  <div>
                    <span
                      className={"primary-clickable"}
                      onClick={() => {
                        form.setFieldValue(
                          "steps",
                          form
                            .getFieldValue("steps")
                            .sort((a, b) => a.funnelTrigger - b.funnelTrigger)
                        );
                      }}
                    >
                      Sort Steps By Progress
                    </span>
                  </div>
                  <Button
                    icon={<PlusOutlined />}
                    onClick={() => {
                      const [funnelTrigger, index] = getNextFunnelTrigger(
                        form.getFieldValue("steps")
                      );

                      arrayHelpers.add(
                        {
                          funnelTrigger,
                          question: "",
                        },
                        index + 1
                      );
                    }}
                    style={{ marginLeft: "auto" }}
                  >
                    Add Step
                  </Button>
                </div>
                {fields.map((field, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        height: "58px",
                      }}
                    >
                      <Form.Item
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        name={[field.name, "funnelTrigger"]}
                        rules={[{ required: true }]}
                        style={{ margin: "0px", width: 104 }}
                      >
                        <InputNumber addonAfter="%" min={1} max={100} />
                      </Form.Item>
                      <Form.Item
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        name={[field.name, "question"]}
                        rules={[{ required: true }]}
                        style={{ margin: "0px 12px", flexGrow: 1 }}
                      >
                        <Input />
                      </Form.Item>
                      <Button
                        icon={<DeleteOutlined />}
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </div>
                  );
                })}
              </div>
            );
          }}
        </Form.List>
      </Form>

      {error && (
        <Alert
          message={error}
          style={{ margin: "-24px 0px 12px 0px" }}
          type="error"
        />
      )}
    </Modal>
  );
};
