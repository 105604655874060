import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

import { ActivitiesList } from "components/lists/activities";
import { ContactDetailsModal } from "components/modals/contact-details-modal";
import { Details } from "./details";
import { Emails } from "components/emails";
import { Files } from "../../components/lists/files/files";
import { FollowupsList } from "components/lists/followups";
import { Header } from "./header";
import { Opportunities } from "./opportunities";
import { Tags } from "./tags";
import { emailToDefaults } from "components/modals/perform-activity/email-to-defaults";
import { useActivityTypes } from "data/use-activity-types";
import { useContactActivities } from "data/use-activities";
import { useContactFollowups } from "data/use-followups";
import { useContacts } from "data/use-contacts";
import { useModal } from "hooks/use-modal";
import { useOrganization } from "data/use-organization";
import {
  CREATE_CONTACT_FOLLOWUP_MODAL,
  PERFORM_CONTACT_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { withEntityExists } from "../with-entity-exists";

export const ContactRoute = withEntityExists(useContacts)(() => {
  const id = Number(useParams().id);

  const activityTypes = useActivityTypes();
  const contact = useContacts().byId(id);
  const organization = useOrganization();
  const activities = useContactActivities({ _id: id });
  const followups = useContactFollowups({ _id: id });

  const showPerformActivity = () =>
    useLayoutStore.getState().open(PERFORM_CONTACT_ACTIVITY_MODAL, {
      contactId: id,
    });

  const showCreateFollowup = () => {
    useLayoutStore.getState().open(CREATE_CONTACT_FOLLOWUP_MODAL, {
      contactId: id,
    });
  };

  const { modal: contactDetailsModal, open: openContactDetails } =
    useModal(ContactDetailsModal);

  return (
    <div className="w-full">
      {contactDetailsModal}
      <Header
        contact={contact}
        openUpdateDetails={() => openContactDetails({ contact })}
      />
      <Row gutter={48}>
        <Col xs={24} lg={12} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <Tags contact={contact} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Details contact={contact} organization={organization} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Opportunities contact={contact} />
          </div>
          <Files entityId={id} entityType="CONTACT" />
        </Col>
        <Col xs={24} lg={12} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <FollowupsList
              followups={followups}
              hideContact
              showCreateFollowup={showCreateFollowup}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ActivitiesList
              activities={activities}
              showPerformActivity={showPerformActivity}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Emails
              contacts={[id]}
              onLogActivity={(email, userEmails) => {
                useLayoutStore.getState().open(PERFORM_CONTACT_ACTIVITY_MODAL, {
                  contactId: id,
                  defaults: emailToDefaults({
                    activityTypes: activityTypes.all(),
                  })(email, userEmails),
                });
              }}
              users={contact.users}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});
