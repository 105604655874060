import get from "lodash/get";
import trim from "lodash/trim";
import { Button, Input } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useState } from "react";

import { format } from "./format";
import { usePatchOrganizationLabels } from "data/use-organization";

export const UpdatePartnersLabel = (props) => {
  const { organization } = props;

  const patchLabels = usePatchOrganizationLabels();

  const current = get(organization, "labels.partners", {
    singular: "Partner",
    plural: "Partners",
  });

  const [singular, setSingular] = useState(current.singular);
  const [plural, setPlural] = useState(current.plural);

  return (
    <>
      <div style={{ width: "90px", marginBottom: "16px" }}>
        <strong>Partners</strong>
      </div>
      <div
        style={{ alignItems: "center", display: "flex", marginBottom: "8px" }}
      >
        <div style={{ width: "90px" }}>Singular*</div>
        <Input
          onChange={(e) => setSingular(format(e.target.value))}
          value={singular}
          style={{ margin: "0px 8px", width: "250px" }}
        />
      </div>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div style={{ width: "90px" }}>Plural*</div>
        <Input
          onChange={(e) => setPlural(format(e.target.value))}
          value={plural}
          style={{ margin: "0px 8px", width: "250px" }}
        />
        <Button
          loading={patchLabels.isLoading}
          disabled={!singular || !plural}
          icon={<SaveOutlined />}
          onClick={() => {
            patchLabels.mutate({
              partners: { singular: trim(singular), plural: trim(plural) },
            });
          }}
        />
      </div>
    </>
  );
};
