import { Button, Popconfirm } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useArchiveSalesProcess } from "data/use-sales-processes";

export const ArchiveSalesProcess = (props) => {
  const { salesProcess } = props;

  const archiveSalesProcess = useArchiveSalesProcess({
    onSuccess: async (data, { mutation }) => {
      await mutation.onSuccess.call();
    },
  });

  return (
    <Popconfirm
      loading={archiveSalesProcess.isLoading}
      cancelText="Cancel"
      okText="Yes"
      onConfirm={() =>
        archiveSalesProcess.mutateAsync({ id: salesProcess._id })
      }
      title="Are you sure?"
    >
      <Button
        disabled={archiveSalesProcess.isLoading}
        icon={<EyeInvisibleOutlined />}
      >
        Archive
      </Button>
    </Popconfirm>
  );
};
