import moment from "moment";

import { long } from "../../../utils/format-date";

export const displayDate = (date) => {
  return long(moment(date).unix());
};

export const DisplayDate = ({ date }) => {
  return <strong>{displayDate(date)}</strong>;
};
