import get from "lodash/get.js";
import isEmpty from "lodash/isEmpty.js";

import { getFilterByType } from "./filters/get-filter-by-type.js";

export const filterData = ({ data, fieldConfigs, filters }) => {
  return data.filter((d) => {
    return filters.every(({ dataIndex, value }) => {
      if (isEmpty(value) || !fieldConfigs[dataIndex]) return true;

      const { type } = fieldConfigs[dataIndex];
      const { filter } = getFilterByType(type);

      return filter(get(d, dataIndex), value, d, fieldConfigs[dataIndex]);
    });
  });
};
