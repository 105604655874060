import { useQuery } from "@tanstack/react-query";

import { fetcher } from "./fetcher";

export const root = "/stream";

export const useOpportunityStream = ({ _id }) => {
  const queryFn = fetcher.get(`/opportunity/${_id}/stream`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/opportunity", `${_id}`],
  });

  return data || [];
};
