import { Form, Input } from "antd";

import { useOnChange } from "hooks/use-on-change";
import { useUpdateSalesProcess } from "data/use-sales-processes";
import { Modal } from "components/modal";

const onSubmit =
  ({ salesProcess, updateSalesProcess }) =>
  (values) => {
    updateSalesProcess.mutate({
      id: salesProcess._id,
      ...values,
    });
  };

export const UpdateSalesProcess = (props) => {
  const { close, isOpen, salesProcess } = props;

  const updateSalesProcess = useUpdateSalesProcess({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({ name: salesProcess.name });
    },
    [isOpen, form, salesProcess]
  );

  return (
    <Modal
      confirmLoading={updateSalesProcess.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      title="Details"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          salesProcess,
          updateSalesProcess,
        })}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
