import isEmpty from "lodash/isEmpty.js";
import isFinite from "lodash/isFinite.js";
import isNil from "lodash/isNil.js";
import isPlainObject from "lodash/isPlainObject.js";

export const filter = (value, filter) => {
  if (!isNil(filter.min) && filter.min > value) return false;

  return isNil(filter.max) || filter.max >= value;
};

const VALID_KEYS = ["max", "min"];

export const validate = (filter) => {
  if (!isPlainObject(filter)) return false;
  if (isEmpty(filter)) return false;
  if (!Object.keys(filter).every((f) => VALID_KEYS.includes(f))) return false;

  const values = Object.values(filter);

  return (
    values.some(isFinite) &&
    values.every((value) => isNil(value) || isFinite(value))
  );
};
