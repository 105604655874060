import { useQueryClient } from "@tanstack/react-query";
import { Form, Input } from "antd";

import { useUpdateName, useViews } from "../../../data/use-views";
import { useOnChange } from "../../../hooks/use-on-change";
import { useViewStore } from "../../../stores/view";
import { Modal } from "components/modal";

export const UpdateViewNameModal = ({ close, isOpen, viewType }) => {
  const queryClient = useQueryClient();
  const updateName = useUpdateName({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      const views = queryClient.getQueryData(useViews.key);
      const view = views.find(
        (view) => view._id === useViewStore.getState()[viewType].selectedId
      );

      form.setFieldsValue({
        name: view.name,
      });
    },
    [isOpen, form]
  );

  const onFinish = async (values) => {
    const { name } = values;

    updateName.mutate({
      id: useViewStore.getState()[viewType].selectedId,
      name,
    });
  };

  return (
    <Modal
      confirmLoading={updateName.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      title="Update View Name"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
