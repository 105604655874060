import { Form, Select } from "antd";

import { useActivityTypes } from "../../data/use-activity-types";
import { filterOption } from "./filter-option";
import { pickFormItemProps, pickSelectProps } from "./pick-props";
import { getSelectOptions } from "../../utils/get-select-options";

export const ActivityTypeSelect = (props) => {
  const activityTypes = useActivityTypes();

  const form = Form.useFormInstance();
  const initialValue = form.getFieldValue(props.name);

  return (
    <Form.Item {...pickFormItemProps(props)}>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        options={getSelectOptions({
          store: activityTypes,
          initialIds: initialValue,
          labelKey: "label",
        })}
        {...pickSelectProps(props)}
      />
    </Form.Item>
  );
};
