import { App } from "antd";
import { useEffectOnce } from "react-use";
import { useSearchParams } from "react-router-dom";

import { ProcoreConfig } from "./procore-config";
import { HubspotConfig } from "./hubspot-config";

const hasIntegrationError = (searchParams) => {
  return searchParams.get("integration_error") === "true";
};

const hasIntegrationSuccess = (searchParams) => {
  return searchParams.get("integration_success") === "true";
};

export const Integrations = () => {
  const { message } = App.useApp();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffectOnce(() => {
    if (hasIntegrationError(searchParams)) {
      message.error(
        "Integration failed, try again or contact customer support"
      );
    }

    if (hasIntegrationSuccess(searchParams)) {
      message.success("Integration Succeeded");
    }

    const next = new URLSearchParams(searchParams);
    next.delete("integration_error");
    next.delete("integration_success");

    setSearchParams(next);
  });

  return (
    <div
      style={{
        background: "#fff",
        borderRadius: "0.75em",
        maxWidth: "500px",
        padding: "12px",
      }}
    >
      <div style={{ marginBottom: "8px" }}>
        <strong>Integrations</strong>
        <ProcoreConfig />
        <HubspotConfig />
      </div>
    </div>
  );
};
