import get from "lodash/get";

import { viewTypes } from "@evolved/constants";

import { accountCells } from "./account-cells";
import { contactCells } from "./contact-cells";
import { opportunityCells } from "./opportunity-cells";
import { vendorCells } from "./vendor-cells";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;
const types = {
  [ACCOUNT]: accountCells,
  [CONTACT]: contactCells,
  [OPPORTUNITY]: opportunityCells,
  [VENDOR]: vendorCells,
};

const mapKey = (dataIndex) => {
  if (dataIndex && dataIndex.startsWith("userDefinedFields")) {
    return "userDefinedField";
  }

  return dataIndex;
};

export const getEditableComponent = ({ dataIndex, viewType }) => {
  return get(types, [viewType, mapKey(dataIndex)]);
};
