import { useEffect, useRef } from "react";

import { AccountSummary } from "../../../private/accounts/summary";
import { ContactSummary } from "../../../private/contacts/summary";
import { OpportunitySummary } from "../../../private/opportunities/opportunity-summary";
import { VendorSummary } from "../../../private/vendors/summary";

const getColumnsLength = (columns) => {
  return columns.fixed.length + columns.scrollable.length + 1;
};

const components = {
  ACCOUNT: AccountSummary,
  CONTACT: ContactSummary,
  OPPORTUNITY: OpportunitySummary,
  VENDOR: VendorSummary,
};

export const Drawer = (props) => {
  const { columns, row, viewType } = props;

  const ref = useRef();

  useEffect(() => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }, []);

  const Component = components[viewType];

  return (
    <tr ref={ref}>
      <td colSpan={getColumnsLength(columns)}>
        <div
          style={{
            position: "sticky",
            left: 1,
            width: 500,
            padding: "24px",
          }}
        >
          <Component _id={row._id} />
        </div>
      </td>
    </tr>
  );
};
