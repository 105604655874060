import { DndContext, useDraggable } from "@dnd-kit/core";
import { restrictToHorizontalAxis } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";

const Draggable = (props) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: "draggable",
  });

  const style = transform
    ? {
        transform: CSS.Translate.toString(transform),
      }
    : undefined;

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {props.children}
    </div>
  );
};

export const DraggableBlock = (props) => {
  const { onWidthChange, width } = props;

  const [isDragging, setIsDragging] = useState(false);

  function onDragStart() {
    setIsDragging(true);
  }

  function onDragEnd(event) {
    let nextWidth = width + event.delta.x;

    if (nextWidth < 25) {
      nextWidth = 25;
    }
    onWidthChange(nextWidth);
    setIsDragging(false);
  }

  return (
    <DndContext
      modifiers={[restrictToHorizontalAxis]}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <Draggable>
        <div
          style={{
            cursor: "col-resize",
            height: "calc(100% + 26px)",
            marginTop: "-13px",
            padding: "0px 4px",
            position: "relative",
            right: "-8px",
            width: "5px",
          }}
        >
          <div
            style={{
              height: "100%",
              ...(isDragging
                ? {
                    backgroundColor: "grey",
                    width: "2px",
                  }
                : {
                    width: "1px",
                  }),
            }}
          />
        </div>
      </Draggable>
    </DndContext>
  );
};
