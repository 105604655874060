import { Button, Popconfirm } from "antd";

import { useArchiveTag } from "data/use-tags";

export const ArchiveTag = (props) => {
  const { tag } = props;

  const archiveTag = useArchiveTag();

  return (
    <Popconfirm
      cancelText="Cancel"
      okText="Delete"
      onConfirm={() => archiveTag.mutateAsync({ id: tag._id })}
      title="Are you sure? Tag will be removed from all tagged items."
    >
      <Button disabled={archiveTag.isLoading} size="small">
        Delete
      </Button>
    </Popconfirm>
  );
};
