import { ActivityCountPie } from "../activity-count-pie";

export const Charts = ({ userActivityCounts = [] }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
      }}
    >
      {userActivityCounts.map((d, i) => {
        return <ActivityCountPie key={d._id} {...d} />;
      })}
    </div>
  );
};
