// todo: In general, this is a terrible idea.

// This only exists for instances where we want to wait for a mutation
// to complete  with react query's mutateAsync. In this case, if the mutation
// has a onError handler, mutateAsync will still throw. In such a case, we are
// handling the error in onError and are intentionally swallowing the error later as
// there is no need to deal with it again.

export const swallow = async (p) => {
  try {
    return (await p) || true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
