import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

const emptyCodes = [202, 204];

const processResponse = async (request, options) => {
  const bypass = isArray(options?.bypassStatusCodes)
    ? options.bypassStatusCodes
    : [];

  const response = await request;

  const payload = emptyCodes.includes(response.status)
    ? null
    : await response.json();

  if (!response.ok && !bypass.includes(payload?.statusCode)) {
    throw payload;
  }

  if (options.map) {
    return options.map(payload);
  }

  return payload;
};

const buildQuery = (query) => {
  if (isEmpty(query)) {
    return "";
  }

  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(query)) {
    params.append(key, value);
  }

  return `?${params}`;
};

const get =
  (url, options = {}) =>
  async () => {
    const token = await window.Clerk.session.getToken();

    const request = fetch(
      `${import.meta.env.VITE_API_URL}${url}${buildQuery(options.query)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (options.raw) return request;

    return await processResponse(request, options);
  };

const patch =
  (url, options = {}) =>
  async (args) => {
    const token = await window.Clerk.session.getToken();

    const request = fetch(`${import.meta.env.VITE_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "PATCH",
      body: JSON.stringify(args),
    });

    return await processResponse(request, options);
  };

const post =
  (url, options = {}) =>
  async (args) => {
    const token = await window.Clerk.session.getToken();

    const request = fetch(`${import.meta.env.VITE_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(args),
    });

    return await processResponse(request, options);
  };

const put =
  (url, options = {}) =>
  async (args) => {
    const token = await window.Clerk.session.getToken();

    const request = fetch(`${import.meta.env.VITE_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        ...(args
          ? {
              "Content-Type": "application/json",
            }
          : {}),
      },
      method: "PUT",
      ...(args
        ? {
            body: JSON.stringify(args),
          }
        : {}),
    });

    return await processResponse(request, options);
  };

const del =
  (url, options = {}) =>
  async () => {
    const token = await window.Clerk.session.getToken();

    const request = fetch(`${import.meta.env.VITE_API_URL}${url}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: "DELETE",
    });

    return await processResponse(request, options);
  };

export const fetcher = {
  del,
  get,
  patch,
  post,
  put,
};
