import { Input, Form } from "antd";

import { useOnChange } from "hooks/use-on-change";
import {
  useCreateLossReason,
  useUpdateLossReason,
} from "data/use-loss-reasons";
import { Modal } from "components/modal";

const onSubmit =
  ({ mutation, lossReason }) =>
  (values) => {
    let variables;

    if (lossReason) {
      variables = {
        id: lossReason._id,
        ...values,
      };
    }

    if (!lossReason) {
      variables = values;
    }

    mutation.mutate(variables);
  };

export const LossReasonModal = ({ close, isOpen, lossReason }) => {
  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      if (lossReason) {
        form.setFieldsValue({
          label: lossReason.label,
          description: lossReason.description,
        });
        if (!lossReason.color) form.setFieldValue("color");
      } else {
        form.setFieldsValue({
          label: "",
          description: "",
        });
        form.setFieldValue("color");
      }
    },
    [isOpen, form, lossReason]
  );

  const updateLossReason = useUpdateLossReason({
    onSuccess: close,
  });

  const createLossReason = useCreateLossReason({
    onSuccess: close,
  });

  const mutation = lossReason ? updateLossReason : createLossReason;

  return (
    <Modal
      confirmLoading={mutation.isLoading}
      okText={lossReason ? "Update" : "Create"}
      onCancel={close}
      onOk={form.submit}
      title={`${lossReason ? "Update" : "Create"} Loss Reason`}
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({ form, mutation, lossReason })}
      >
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};
