import day from "dayjs";
import pick from "lodash/pick";

import { DatePicker, Form, Input, InputNumber } from "antd";
import { useState } from "react";

import { viewTypes } from "@evolved/constants";

import { toUnixDate } from "utils/unix-date";
import { useContacts } from "../../../data/use-contacts";
import { useVendors } from "../../../data/use-vendors";
import { useCreateOpportunity } from "../../../data/use-opportunities";
import { useOrganization } from "../../../data/use-organization";
import { useOnChange } from "../../../hooks/use-on-change";
import { CreateAccountSelect } from "../../selects/create-account-select";
import { CreateVendorSelect } from "../../selects/create-partner-select";
import { OpportunityProductsSelect } from "../../selects/opportunity-products-select";
import { UserSelect } from "../../selects/user-select";
import {
  mapInitialUserDefinedFieldsState,
  mapOutgoingValues,
  UserDefinedFieldInputs,
} from "../../user-defined-field-inputs";
import { ContactsSelect } from "./contacts-select";
import { filterAccountContacts } from "./filter-account-contacts";
import { filterVendorContacts } from "./filter-vendor-contacts";
import { SalesProcessSelect } from "./sales-process-select";
import { Modal } from "components/modal";

const { OPPORTUNITY } = viewTypes;

const onSubmit =
  ({ createOpportunity, isMultipleProducts }) =>
  (values) => {
    let products = [];

    if (isMultipleProducts) {
      products = values.products || [];
    } else if (values.product) {
      products = [{ id: values.product, quantity: 1 }];
    }

    createOpportunity.mutate({
      ...pick(values, [
        "accountId",
        "alias",
        "salesProcessId",
        "summary",
        "value",
      ]),
      userDefinedFields: mapOutgoingValues(values.userDefinedFields),
      ...toUnixDate(values, "startedOn"),
      ...toUnixDate(values, "targetWinDate"),
      ...pick(values, ["contactIds", "userIds", "vendorIds"]),
      products,
    });
  };

const getContacts = ({
  contacts,
  preselectedAccountId,
  preselectedContactId,
  preselectedVendorId,
}) => {
  if (preselectedContactId) {
    return [preselectedContactId];
  }

  if (preselectedAccountId) {
    return filterAccountContacts({
      accountId: preselectedAccountId,
      contacts,
    }).map(({ _id }) => _id);
  }

  if (preselectedVendorId) {
    return filterVendorContacts({
      vendorId: preselectedVendorId,
      contacts,
    }).map(({ _id }) => _id);
  }

  return [];
};

const getDefaultValues = ({
  contacts,
  organization,
  preselectedAccountId,
  preselectedContactId,
}) => {
  const accountId = preselectedAccountId || null;

  return {
    alias: "",
    accountId,
    contactIds: getContacts({
      contacts,
      preselectedAccountId,
      preselectedContactId,
    }),
    products: [],
    salesProcessId: null,
    startedOn: day(),
    summary: null,
    targetWinDate: null,
    userDefinedFields: mapInitialUserDefinedFieldsState(undefined, {
      organization,
      type: OPPORTUNITY,
    }),
    userIds: [],
    value: null,
    vendorIds: [],
  };
};

export const CreateOpportunityModal = (props) => {
  const {
    close,
    isOpen,
    onCreate,
    preselectedAccountId,
    preselectedContactId,
    preselectedVendorId,
  } = props;

  const [isMultipleProducts, setIsMultipleProducts] = useState(false);

  const contacts = useContacts();
  const vendors = useVendors();
  const organization = useOrganization();

  const createOpportunity = useCreateOpportunity({
    onSuccess: async ({ opportunity }, { mutation }) => {
      if (onCreate) {
        await onCreate({ opportunity }, { mutation });
      }

      close();
    },
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      setIsMultipleProducts(false);

      form.setFieldsValue(
        getDefaultValues({
          contacts: contacts.all(),
          organization,
          preselectedAccountId,
          preselectedContactId,
          preselectedVendorId,
        })
      );
    },
    [
      contacts,
      form,
      isOpen,
      organization,
      preselectedAccountId,
      preselectedContactId,
      preselectedVendorId,
      setIsMultipleProducts,
    ]
  );

  const preselectedVendor = vendors.byId(preselectedVendorId);

  return (
    <Modal
      confirmLoading={createOpportunity.isLoading}
      maskClosable={false}
      okText="Create"
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title={"Create Opportunity"}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          createOpportunity,
          isMultipleProducts,
        })}
      >
        <Form.Item label="Started On" name="startedOn">
          <DatePicker allowClear={false} format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item label="Target Win Date" name="targetWinDate">
          <DatePicker allowClear={false} format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item label="Name" name="alias" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <CreateAccountSelect form={form} />
        <ContactsSelect contacts={contacts.all()} form={form} />
        <OpportunityProductsSelect
          isMultiple={isMultipleProducts}
          setIsMultiple={setIsMultipleProducts}
        />
        <SalesProcessSelect form={form} organization={organization} />
        <Form.Item label="Value" name="value">
          <InputNumber
            addonBefore="$"
            max={999999999}
            style={{ width: "133px" }}
          />
        </Form.Item>
        <UserSelect
          label="Sales Reps"
          mode="multiple"
          name="userIds"
          placeholder="Select sales reps"
        />

        {organization?.settings?.showPartners && (
          <CreateVendorSelect
            disableVendorSelect={preselectedVendor}
            form={form}
            multi
          />
        )}

        <Form.Item label="Summary" name="summary">
          <Input.TextArea />
        </Form.Item>
        <UserDefinedFieldInputs
          organization={organization}
          type={OPPORTUNITY}
        />
      </Form>
    </Modal>
  );
};
