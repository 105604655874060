import { Badge, Button, Card, Empty, List } from "antd";

import { colors } from "@evolved/constants";

import {
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";

import { Opportunity } from "./opportunity";

const { PRIMARY } = colors;

const Header = ({ openCreateModal, opportunities }) => {
  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={opportunities.length}
        showZero
        style={{ backgroundColor: PRIMARY, marginRight: "16px" }}
      />
      <h3 style={{ margin: "0px" }}>Opportunities</h3>
      {openCreateModal && (
        <Button
          ghost
          onClick={openCreateModal}
          style={{ marginLeft: "auto" }}
          type="primary"
        >
          Create Opportunity
        </Button>
      )}
    </div>
  );
};

export const OpportunitiesList = (props) => {
  const { openCreateModal, opportunities = [] } = props;

  const showPerformActivity = ({ opportunity } = {}) => {
    useLayoutStore.getState().open(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, {
      opportunityId: opportunity._id,
    });
  };

  return (
    <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
      <List
        dataSource={opportunities}
        header={
          <Header
            openCreateModal={openCreateModal}
            opportunities={opportunities}
          />
        }
        itemLayout={"vertical"}
        locale={{
          emptyText: <Empty description={"No opportunities yet..."} />,
        }}
        pagination={{
          pageSize: 5,
        }}
        renderItem={(opportunity) => (
          <Opportunity
            key={opportunity._id}
            opportunity={opportunity}
            showPerformActivity={showPerformActivity}
          />
        )}
      />
    </Card>
  );
};
