import { select } from "radash";

import { useOpportunities } from "data/use-opportunities";

export const OpportunitiesSet = (props) => {
  const { value } = props;

  const opportunities = useOpportunities();

  return select(
    value,
    (id) => opportunities.byId(id).alias,
    (id) => !!opportunities.byId(id)
  ).join(", ");
};
