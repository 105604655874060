import get from "lodash/get";
import {
  GlobalOutlined,
  InfoCircleOutlined,
  MailOutlined,
  PhoneOutlined,
  RadarChartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";

import { viewTypes } from "@evolved/constants";

import { renderList } from "utils/render-list";
import { useModal } from "hooks/use-modal";
import { useAccounts } from "data/use-accounts";
import { useUsers } from "data/use-users";
import { useVendors } from "data/use-vendors";
import { InfoCard } from "components/info-card";
import { SetUsers } from "./modals/set-users";
import { UserDefinedFields } from "components/user-defined-fields";
import { ScrollableContent } from "../../components/scrollable-content";

const { CONTACT } = viewTypes;

export const Details = (props) => {
  const { contact, organization } = props;

  const users = useUsers();

  const account = useAccounts().byId(contact.accountId);
  const vendor = useVendors().byId(contact.vendorId);

  const { modal: usersModal, open: openUsersModal } = useModal(SetUsers);

  const navigate = useNavigate();

  return (
    <>
      {usersModal}
      <ScrollableContent>
        <Row gutter={16} type="flex">
          {contact.title && <InfoCard content={contact.title} label="Title" />}
          {account && (
            <InfoCard
              content={account.name}
              Icon={RadarChartOutlined}
              label="Account"
              onClick={() => navigate(`/accounts/${account._id}`)}
            />
          )}
          {vendor && organization?.settings?.showPartners && (
            <InfoCard
              content={vendor.name}
              Icon={GlobalOutlined}
              label="Partner"
              onClick={() => navigate(`/partners/${vendor._id}`)}
            />
          )}
          <InfoCard
            content={contact.email || "--"}
            Icon={MailOutlined}
            label="Email"
          />
          <InfoCard
            content={contact.phone || "--"}
            Icon={PhoneOutlined}
            label="Phone Number"
          />
          <InfoCard content={contact.linkedIn || "--"} label="LinkedIn" />
          {get(contact, "summary") && (
            <InfoCard
              content={get(contact, "summary")}
              Icon={InfoCircleOutlined}
              label="Summary"
              style={{
                content: { fontSize: "1em" },
              }}
            />
          )}
          <InfoCard
            content={renderList(
              users.byIds(contact.users),
              "Assign sales reps"
            )}
            Icon={UserOutlined}
            label="Sales Reps"
            onClick={() => openUsersModal({ contact })}
          />
          <UserDefinedFields
            data={contact}
            organization={organization}
            type={CONTACT}
          />
        </Row>
      </ScrollableContent>
    </>
  );
};
