import { useScrollWithShadow } from "../hooks/use-scroll-with-shadow";

import { useEffect, useRef, useState } from "react";

export const ScrollableContent = (props) => {
  const { boxShadow, onScrollHandler } = useScrollWithShadow();

  const ref = useRef();
  const [isOverflowing, setIsOverflowing] = useState();

  useEffect(() => {
    if (!ref.current) return;

    setIsOverflowing(ref.current.clientHeight < ref.current.scrollHeight);
  });

  return (
    <div style={{ ...isOverflowing ? { boxShadow } : {}, padding: "8px 0px" }}>
      <div style={{ maxHeight: props.height || "500px", overflowY: "scroll" }} onScroll={onScrollHandler} ref={ref}>
        {props.children}
      </div>
    </div>
  )
};
