import BarLoader from "react-spinners/BarLoader";

import logo from "../assets/navbar-logo-dark.png";
// todo: remove if we're going with the logo
// import PuffLoader from "react-spinners/PuffLoader";

// export const Loader = (props) => {
//   return (
//     <PuffLoader
//       {...{
//         color: "#8b5cf6",
//         size: 150,
//         ...props,
//       }}
//     />
//   );
// };

export const Loader = () => {
  return (
    <div className="animate-pulse">
      <img
        alt="logo"
        style={{ height: "48px", marginBottom: "8px" }}
        src={logo}
      />
      <BarLoader color={"#8b5cf6"} width={"100%"} />
    </div>
  );
};
