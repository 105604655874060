import noop from "lodash/noop";

import { LoadingOutlined } from "@ant-design/icons";
import { InputNumber, message } from "antd";
import { useEffect, useRef, useState } from "react";

export const EditableNumberCell = (props) => {
  const {
    addonBefore,
    addonAfter,
    isEditing,
    setIsEditing,
    children,
    onUpdate,
    validate = noop,
  } = props;

  const [value, setValue] = useState();
  const [isLoading, setIsLoading] = useState();
  const inputRef = useRef();

  useEffect(() => {
    if (isEditing) {
      setValue(props.value);
    }
  }, [isEditing]);

  useEffect(() => {
    if (isEditing && !isLoading && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing, isLoading]);

  const save = async () => {
    const error = validate(value);

    if (error) {
      message.warning(error);
      return;
    }

    setIsLoading(true);

    const isSuccess = await onUpdate(value);

    if (isSuccess) {
      setIsEditing(false);
    } else {
      inputRef.current.focus();
    }

    setIsLoading(false);
  };

  return isEditing ? (
    <InputNumber
      disabled={isLoading}
      addonBefore={addonBefore}
      addonAfter={isLoading ? <LoadingOutlined /> : addonAfter}
      ref={inputRef}
      onBlur={() => setIsEditing(false)}
      onChange={setValue}
      onPressEnter={save}
      value={value}
    />
  ) : (
    children
  );
};
