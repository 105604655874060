import noop from "lodash/noop";
import without from "lodash/without";

import { Checkbox } from "antd";

import { userRoles } from "@evolved/constants";

import { useMe } from "data/use-me";
import { useUsers } from "data/use-users";

const { ROLES } = userRoles;

export const SelectUsers = (props) => {
  const { checked, onChange = noop } = props;

  const profile = useMe();
  const users = useUsers().all({ includeArchived: false });

  return (
    <>
      <small>*Owner user must be selected</small>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "0px 0px 16px 0px",
          flexWrap: "wrap",
        }}
      >
        {users.map((user) => {
          return (
            <Checkbox
              checked={checked.includes(Number(user._id))}
              disabled={user.role < ROLES.MANAGER || user._id === profile._id}
              key={user._id}
              onChange={(e) => {
                let updated;

                if (e.target.checked) {
                  updated = [...checked, Number(user._id)];
                } else {
                  updated = without(checked, Number(user._id));
                }

                onChange(updated);
              }}
              style={{ margin: "0px 8px 4px 0px" }}
            >
              {user.alias}
            </Checkbox>
          );
        })}
      </div>
    </>
  );
};
