import { Card } from "antd";

import { Followup } from "components/lists/followups/followup";

import { styles } from "./styles";

export const NextFollowup = (props) => {
  const { followup } = props;

  if (!followup) {
    return null;
  }

  return (
    <div style={styles.infoCard.container}>
      <div style={styles.infoCard.title}>
        <strong>Next Followup</strong>
      </div>
      <Card style={styles.infoCard.body}>
        <Followup hideAccount followup={followup} />
      </Card>
    </div>
  );
};
