import { Button, Checkbox, Input, Select } from "antd";
import { useState } from "react";
import { sift, unique } from "radash";
import { fieldTypes } from "@evolved/constants";
import { styles } from "@evolved/constants";

export const CreateUdfs = ({ entityType, onNext, onBack, newUdfHeaders, rows }) => {
  // do you want to bring in each custom field?
  // - [ ] ignore those that you don't want to, remove them from the import
  //
  // give an explanation if it doesn't match to write
  // the names correctly
  //
  // you can also ignore the field, and it will be deleted
  // we also want to select the type
  // and then based on the type, later, we'll point out issues
  // first, list everything out
  // - [ ] date, can we convert your dates? now I need to solve date conversion

  const [state, setState] = useState(
    newUdfHeaders.map((header) => ({
      id: crypto.randomUUID(),
      name: header,
      dataType: "TEXT",
      // TODO: a user can uncheck a box, then the header will be removed from the UX
      create: true,
    }))
  );
  const [previewOptionsId, setPreviewOptionsId] = useState();
  const [previewOptionsSearch, setPreviewOptionsSearch] = useState("");

  const getUdfOptions = (udf) => {
    return unique(sift(udf?.name ? rows.map(row => row[udf.name]) : []));
  };

  const previewUdf = state.find(({ id }) => previewOptionsId === id);
  const udfOptions = previewUdf?.name && rows.map(row => row[previewUdf.name])

  if (previewOptionsId) {
    return (
      <div>
        <div className="mb-2 flex">
          <Button
            className="mr-2"
            onClick={() => {
              setPreviewOptionsId(null);
              setPreviewOptionsSearch("");
            }}
          >
            Back
          </Button>
          <Input
            autoFocus
            onChange={(e) => {
              setPreviewOptionsSearch(e.target.value);
            }}
            value={previewOptionsSearch}
          />
        </div>
        {udfOptions
          .filter(
            (option) =>
              !previewOptionsSearch || option.includes(previewOptionsSearch)
          )
          .map((option) => {
            return <div className="mx-2">{option}</div>;
          })}
      </div>
    );
  }

  return (
    <>
      <div className="mb-4">
        These columns do not match any system fields or existing user defined
        fields. Would you like to create user defined fields for them?
      </div>
      <div className="mb-4 flex font-bold">
        <div>Name</div>
        <div className="ml-auto">Create</div>
      </div>
      {state.map((udf) => {
        return (
          <div className="mb-4 flex">
            <div>{udf.name}</div>
            <div className="ml-auto flex items-start space-x-4">
              <div>
                <Select
                  value={udf.dataType}
                  onChange={(value) => {
                    setState(
                      state.map((item) => {
                        if (item.id !== udf.id) {
                          return item;
                        }

                        return { ...item, dataType: value };
                      })
                    );
                  }}
                  options={[
                    {
                      value: fieldTypes.TEXT,
                      label: "Text",
                    },
                    {
                      value: fieldTypes.SELECT,
                      label: "Select",
                    },
                    {
                      value: fieldTypes.DATE,
                      label: "Date",
                    },
                  ]}
                  style={{ width: "120px" }}
                />
                {udf.dataType === fieldTypes.SELECT && (
                  <div
                    onClick={() => {
                      console.log(udf.id);
                      setPreviewOptionsId(udf.id);
                    }}
                    style={styles.CLICKABLE_STYLE}
                  >
                    Preview Options
                  </div>
                )}
              </div>
              <Checkbox
                checked={udf.create}
                onChange={(e) => {
                  setState(
                    state.map((item) => {
                      if (item.id !== udf.id) {
                        return item;
                      }

                      return { ...item, create: e.target.checked };
                    })
                  );
                }}
              />
            </div>
          </div>
        );
      })}
      <div className="flex justify-end">
        <Button className="mr-2" onClick={onBack}>Back</Button>
        <Button type="primary" onClick={() => {
          onNext({
            removed: state.filter(s => !s.create).map(s => s.name),
            newUdfs: state.filter(s => s.create).map(({ create, ...rest }) => ({
              type: entityType,
              ...(rest.dataType === "SELECT" ? {
                options: getUdfOptions(rest),
              } : {}),
              ...rest,
            }))
          });
        }}>Next</Button>
      </div>
    </>
  );
};
