import { Form } from "antd";

import { LossReasonSelect as BaseSelect } from "../../../selects/loss-reason-select";

export const LossReasonSelect = (props) => {
  const { form, lostOpportunityState, organization } = props;

  const opportunityStateChangeId = Form.useWatch(
    "opportunityStateChangeId",
    form
  );

  if (Number(opportunityStateChangeId) !== Number(lostOpportunityState._id)) {
    return null;
  }

  return (
    <BaseSelect
      label="Reason for Loss"
      name="lossReasonId"
      {...(organization.shouldEnforceLossReason
        ? { rules: [{ required: true }] }
        : {})}
    />
  );
};
