import { Tag } from "antd";

import { accountStates, colors, styles } from "@evolved/constants";

export const AccountState = ({ account = {}, style = {} }) => {
  const { state } = account;

  return (
    <Tag
      {...(colors.ACCOUNT_STATE_COLORS[state]
        ? { color: colors.ACCOUNT_STATE_COLORS[state] }
        : {})}
      style={{ ...styles.STATE_TAG_STYLE, ...style }}
    >
      {accountStates.display(state)}
    </Tag>
  );
};
