import { useQueryClient } from "@tanstack/react-query";
import produce from "immer";
import capitalize from "lodash/capitalize";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import identity from "lodash/identity";
import map from "lodash/map";
import merge from "lodash/merge";
import pick from "lodash/pick";
import toLower from "lodash/toLower";
import { Checkbox, Form, Input, InputNumber, Select, message } from "antd";
import { useState, useRef } from "react";
import { root as opportunitiesKey } from "../../../data/use-opportunities";

import { fieldTypes, viewTypes } from "@evolved/constants";

import { ListItemImport } from "components/list-item-import";
import { useOnChange } from "hooks/use-on-change";
import {
  useOrganization,
  useSetUserDefinedFields,
} from "data/use-organization";
import { Modal } from "components/modal";
import { getCalculatedFieldOptions } from "./get-calculated-fields";

const { CALCULATED, DATE, NUMBER, SELECT, TEXT } = fieldTypes;
const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

const trim = (str) => str.split(" ").filter(identity).join(" ");

const sanitizeLabel = (label) => {
  return trim(label);
};

const TYPE_OPTIONS = {
  [ACCOUNT]: "Account",
  [CONTACT]: "Contact",
  [OPPORTUNITY]: "Opportunity",
  [VENDOR]: "Vendor",
};

const DATA_TYPE_OPTIONS = [
  { label: "Select", value: SELECT },
  { label: "Text", value: TEXT },
  { label: "Number", value: NUMBER },
  { label: "Date", value: DATE },
  { label: "Calculated", value: CALCULATED },
];

const onSubmit =
  ({
    defaultNumberInputRef,
    form,
    formula,
    onCreate,
    type,
    setUserDefinedFields,
    userDefinedField,
    userDefinedFields,
  }) =>
    (values) => {
      const id = userDefinedField ? userDefinedField.id : crypto.randomUUID();

      values = {
        ...values,
        options: form.getFieldValue("options"),
      };

      if (
        values.dataType === CALCULATED ||
        userDefinedField?.dataType === CALCULATED
      ) {
        values.formula = formula;
      }

      if (
        (values.dataType === NUMBER ||
          userDefinedField?.dataType === NUMBER) && isFinite(Number(defaultNumberInputRef.current.value))
      ) {
        values.default = Number(defaultNumberInputRef.current.value);
      }

      const payload = produce(userDefinedFields, (draft) => {
        if (userDefinedField) {
          const index = findIndex(draft, { id });
          draft[index] = merge({}, userDefinedField, values);
        } else {
          draft.push({
            id,
            type,
            ...values,
          });
        }
      });

      setUserDefinedFields.mutate(payload, {
        onSuccess: () => {
          onCreate(`userDefinedFields.${id}`);
        },
      });
    };

export const UserDefinedFieldModal = (props) => {
  const { close, isOpen, onCreate = () => { }, userDefinedField } = props;

  const [form] = Form.useForm();
  const [formula, setFormula] = useState([undefined, "+", undefined]);

  const defaultNumberInputRef = useRef();

  const queryClient = useQueryClient();
  const organization = useOrganization();
  const setUserDefinedFields = useSetUserDefinedFields({
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [opportunitiesKey] });
      close();
    },
  });

  const userDefinedFields = get(organization, "userDefinedFields", []);

  const isUpdate = !!userDefinedField;

  Form.useWatch("options", { form, preserve: true });

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      const initialValues = isUpdate
        ? {
          hideFromDetailsPage: false,
          ...pick(userDefinedField, [
            "dataType",
            "name",
            "description",
            "options",
            "formula",
            "hideFromDetailsPage",
          ]),
        }
        : {
          dataType: TEXT,
          name: "",
          description: "",
          options: undefined,
          hideFromDetailsPage: false,
        };

      form.setFieldsValue(initialValues);
      setFormula([undefined, "+", undefined]);
    },
    [isOpen, isUpdate, form, userDefinedField]
  );

  let title;

  if (isUpdate) {
    title = `Update Custom ${capitalize(props.type)} Type`;
  } else if (props.type) {
    title = `Create Custom ${capitalize(props.type)} Type`;
  } else {
    title = "Create Custom Type";
  }

  let dataType = Form.useWatch("dataType", form);
  if (isUpdate) dataType = userDefinedField.dataType;

  return (
    <Modal
      confirmLoading={setUserDefinedFields.isLoading}
      okText={isUpdate ? "Update" : "Create"}
      onCancel={close}
      onOk={form.submit}
      title={title}
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          form,
          formula,
          onCreate,
          defaultNumberInputRef,
          setUserDefinedFields,
          type: props.type,
          userDefinedField,
          userDefinedFields,
        })}
      >
        {!isUpdate && (
          <Form.Item
            label="Data Type"
            name="dataType"
            rules={[{ required: true }]}
          >
            <Select options={DATA_TYPE_OPTIONS} />
          </Form.Item>
        )}
        {!props.type && (
          <Form.Item label="type" name="type">
            <Select options={TYPE_OPTIONS} />
          </Form.Item>
        )}
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, max: 32 }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          extra="If checked, this will only be available on the table view."
          name="hideFromDetailsPage"
          valuePropName="checked"
          wrapperCol={{ offset: 6, span: 18 }}
        >
          <Checkbox size="small">Hide from details page</Checkbox>
        </Form.Item>
        {dataType === NUMBER && (
          <div className=" flex">
            <div style={{ width: "25%", textAlign: "right", marginRight: "8px" }}>
              Default Value:
            </div>
            <InputNumber
              style={{ maxWidth: "75%" }}
              defaultValue={userDefinedField?.default}
              ref={defaultNumberInputRef}
            />
          </div>
        )}
        {dataType === CALCULATED && (
          <div className="flex">
            <Select
              options={getCalculatedFieldOptions({
                userDefinedFields,
                type: props.type,
              })}
              onChange={(value) => {
                const next = [...formula];
                next[0] = value;
                setFormula(next);
              }}
              value={formula[0]}
              className="w-5/12"
            />
            <Select
              options={[
                { label: "+", value: "+" },
                { label: "-", value: "-" },
                { label: "*", value: "*" },
                { label: "/", value: "/" },
              ]}
              onChange={(value) => {
                const next = [...formula];
                next[1] = value;
                setFormula(next);
              }}
              className="w-5/12"
              value={formula[1]}
            />
            <Select
              options={getCalculatedFieldOptions({
                userDefinedFields,
                type: props.type,
              })}
              onChange={(value) => {
                const next = [...formula];
                next[2] = value;
                setFormula(next);
              }}
              value={formula[2]}
              className="w-5/12"
            />
          </div>
        )}
        {dataType === SELECT && (
          <ListItemImport
            label="Option"
            onCreate={(label) => {
              label = sanitizeLabel(label);

              const existing = find(
                form.getFieldValue("options"),
                (o) => toLower(o.label) === toLower(label)
              );

              if (existing && !existing.isArchived) {
                message.info("Option already exists.");
                return;
              }

              if (existing && existing.isArchived) {
                form.setFieldValue(
                  "options",
                  produce(form.getFieldValue("options"), (draft) => {
                    const updated = find(draft, { id: existing.id });

                    updated.isArchived = false;
                  })
                );

                return;
              }

              form.setFieldValue("options", [
                ...(form.getFieldValue("options") || []),
                // todo: make sure we aren't using uuid/v4 package anywhere
                { id: crypto.randomUUID(), label },
              ]);
            }}
            items={map(
              filter(
                form.getFieldValue("options"),
                ({ isArchived }) => !isArchived
              ),
              (option) => ({
                key: option.id,
                name: option.label,
                onDelete: () => {
                  form.setFieldValue(
                    "options",
                    produce(form.getFieldValue("options"), (draft) => {
                      const existing = find(draft, { id: option.id });
                      existing.isArchived = true;
                    })
                  );
                },
                onClick: (label, { reset = () => { } } = {}) => {
                  label = sanitizeLabel(label);

                  const without = filter(
                    form.getFieldValue("options"),
                    ({ id }) => id !== option.id
                  );

                  const existing = find(
                    without,
                    (o) => toLower(o.label) === toLower(label)
                  );

                  if (!label) {
                    return form.setFieldValue("options", [
                      ...without,
                      {
                        id: option.id,
                        label: option.label,
                        isArchived: true,
                      },
                    ]);
                  }

                  if (existing && existing.isArchived) {
                    const withoutExisting = filter(
                      without,
                      ({ id }) => id !== existing.id
                    );

                    return form.setFieldValue("options", [
                      ...withoutExisting,
                      { ...existing, isArchived: false },
                      {
                        id: option.id,
                        label: option.label,
                        isArchived: true,
                      },
                    ]);
                  }

                  if (existing && !existing.isArchived) {
                    message.info("Option already exists.");
                    return reset();
                  }

                  form.setFieldValue("options", [
                    ...without,
                    { id: option.id, label },
                  ]);
                },
              })
            )}
          />
        )}
      </Form>
    </Modal>
  );
};
