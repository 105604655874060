import { Tag } from "antd";
import { select } from "radash";

import { useTags } from "../../../../../data/use-tags";

export const TagsSet = (props) => {
  const { value } = props;

  const tags = useTags();

  const selected = select(
    value,
    (id) => tags.byId(id),
    (id) => !!tags.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((tag) => {
    return (
      <Tag key={tag._id} style={tag.isArchived ? { opacity: 0.5 } : {}}>
        {tag.label}
      </Tag>
    );
  });
};
