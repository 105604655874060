import get from "lodash/get";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import { List } from "antd";
import { useState } from "react";

import { NavigateName } from "components/navigate-name";

const renderItem = (contact, dataIndex) => {
  const value = get(contact, dataIndex) || "--";

  if (isString(value)) {
    return value;
  }

  if (isArray(value)) {
    if (isEmpty(value)) {
      return "--";
    }

    return value
      .map(({ alias, label, name }) => alias || label || name)
      .join(", ");
  }

  return "--";
};

export const Contact = ({ contact, displaySettings, fieldMap }) => {
  const { name } = contact;

  const [isHover, setIsHover] = useState();

  const [topRight, bottomLeft, bottomRight] = displaySettings;

  return (
    <List.Item
      onPointerOver={() => setIsHover(true)}
      onPointerLeave={() => setIsHover(false)}
      style={{ padding: "8px" }}
    >
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "8px",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            minWidth: "50%",
            overflowWrap: "anywhere",
            padding: "0px 8px 8px 8px",
          }}
        >
          <NavigateName
            name={name}
            link={`/contacts/${contact._id}`}
            showButton={isHover}
          />
        </div>
        {topRight?.dataIndex && (
          <div
            style={{
              flexGrow: 1,
              minWidth: "50%",
              overflowWrap: "anywhere",
              padding: "0px 8px 0px 8px",
              textAlign: "left",
            }}
          >
            <strong style={{ marginRight: "8px" }}>
              {topRight.dataIndex ? fieldMap[topRight.dataIndex]?.title : ""}
            </strong>
            {renderItem(contact, topRight.dataIndex)}
          </div>
        )}
      </div>
      <div
        style={{
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            flexGrow: 1,
            minWidth: "50%",
            overflowWrap: "anywhere",
            padding: "0px 8px 0px 8px",
            textAlign: "left",
          }}
        >
          {bottomLeft?.dataIndex && (
            <>
              <strong style={{ marginRight: "8px" }}>
                {bottomLeft.dataIndex
                  ? fieldMap[bottomLeft.dataIndex]?.title
                  : ""}
              </strong>
              {renderItem(contact, bottomLeft.dataIndex)}
            </>
          )}
        </div>
        {bottomRight?.dataIndex && (
          <div
            style={{
              flexGrow: 1,
              minWidth: "50%",
              overflowWrap: "anywhere",
              padding: "0px 8px 0px 8px",
              textAlign: "left",
            }}
          >
            <strong style={{ marginRight: "8px" }}>
              {bottomRight.dataIndex
                ? fieldMap[bottomRight.dataIndex]?.title
                : ""}
            </strong>
            {renderItem(contact, bottomRight.dataIndex)}
          </div>
        )}
      </div>
    </List.Item>
  );
};
