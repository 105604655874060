import { useEffect } from "react";

import { useMe } from "../data/use-me";
import { useViews } from "../data/use-views";
import { useViewStore } from "../stores/view";

export const useDefaultView = (viewType) => {
  const me = useMe();
  const defaultViewId = me.defaultViews.find(
    (view) => view.type === viewType
  )?._id;

  const views = useViews();
  const selectedId = useViewStore((state) => state[viewType].selectedId);
  const preset = useViewStore((state) => state[viewType].preset);

  useEffect(() => {
    if (selectedId || !defaultViewId || preset) return;

    const view = views.find((view) => view._id === Number(defaultViewId));

    if (!view) {
      return;
    }

    useViewStore.getState().setView(viewType)(view);
  }, [defaultViewId, views, selectedId]);
};
