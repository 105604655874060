import { viewTypes } from "@evolved/constants";
import {
  accountFields as account,
  contactFields as contact,
  opportunityFields as opportunity,
  vendorFields as vendor,
} from "@evolved/views";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

export const fields = {
  [ACCOUNT]: [
    account.name,
    account.address,
    account.phone,
    account.state,
    account.website,
  ],
  [CONTACT]: [
    contact.email,
    contact.firstName,
    contact.lastName,
    contact.phone,
    contact.title,
  ],
  [OPPORTUNITY]: [
    opportunity.alias,
    opportunity.initialFunnelProgress,
    opportunity.startedOn,
    opportunity.targetWinDate,
    opportunity.value,
  ],
  [VENDOR]: [vendor.name, vendor.address, vendor.phone],
};
