import isEmpty from "lodash/isEmpty";
import sumBy from "lodash/sumBy";
import { useNavigate } from "react-router-dom";
import { Fade } from "react-awesome-reveal";

import { styles } from "@evolved/constants";

import { EmptyBox } from "components/image-placeholders";
import { convertRange } from "../convert-range";
import { useUserActivityCounts } from "../../../data/use-reports";
import { RangeSelect } from "../range-select";
import { WidgetHeader } from "../widget-header";
import { WidgetContainer } from "../widget-container";
import { Charts } from "./charts";
import { withSalesRole } from "../../../components/with-role";

const { CLICKABLE_STYLE } = styles;

const OpportunitiesLink = ({ navigate }) => (
  <span onClick={() => navigate("/opportunities")} style={CLICKABLE_STYLE}>
    opportunities
  </span>
);

const Component = (props) => {
  const { widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets } =
    props.dateRange;

  const navigate = useNavigate();
  const userActivityCounts = useUserActivityCounts(convertRange(widgetFilter));
  const total = sumBy(userActivityCounts, "count");

  return (
    <Fade>
      <div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            marginBottom: "24px",
          }}
        >
          <WidgetHeader count={total} label="User Activity" />
          <RangeSelect
            tenses={["past", "all-time"]}
            {...{ widgetFilter, widgetFilterLabel, setWidgetFilter, ...dateFilterOffsets }}
          />
        </div>
        {isEmpty(userActivityCounts) ? (
          <EmptyBox
            primary={"There has been no activity this period"}
            secondary={
              <>
                Head over to your <OpportunitiesLink navigate={navigate} /> and
                log some
              </>
            }
          />
        ) : (
          <Charts
            range={widgetFilter}
            userActivityCounts={userActivityCounts}
          />
        )}
      </div>
    </Fade>
  );
};

const Container = (props) => {
  return (
    <WidgetContainer>
      <Component {...props} />
    </WidgetContainer>
  );
};

export const UserActivities = withSalesRole(Container);
