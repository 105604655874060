import { Tag } from "antd";

export const DisplayArchived = (props) => {
  const { className, entity } = props;

  if (!entity?.isArchived) {
    return null;
  }

  return (
    <div className={className}>
      <Tag>Archived</Tag>
    </div>
  );
};
