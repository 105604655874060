import { useEffect, useRef } from "react";

export const useOnExists = (callback, selector) => {
  const isCalled = useRef(false);

  useEffect(() => {
    if (selector === null) {
      isCalled.current = false;
    }
  }, [selector]);

  useEffect(() => {
    if (isCalled.current || selector === null) {
      return;
    }

    let observer = new MutationObserver(() => {
      const element = document.getElementById(selector);

      if (element) {
        observer.disconnect();

        isCalled.current = true;
        observer = null;

        setTimeout(() => callback(element), 250);
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [isCalled.current, selector]);
};
