import { Card } from "antd";
import { useEffectOnce } from "react-use";
import { useNavigate, useSearchParams } from "react-router-dom";

import find from "lodash/find";

import { userRoles } from "@evolved/constants";

import { useOrganization } from "data/use-organization";
import { useMe } from "data/use-me";
import { useFlag } from "hooks/use-flag";
import { hasAccess } from "utils/has-access";
import { ActivityTypes } from "./activity-types";
import { ApplicationAccess } from "./application-access";
import { Integrations } from "./integrations";
import { Labels } from "./labels";
import { LossReasons } from "./loss-reasons";
import { OpportunityStates } from "./opportunity-states";
import { Permissions } from "./permissions";
import { SelectSalesProcess } from "./select-sales-process";
import { SelectTimezone } from "./select-timezone";
import { Tags } from "./tags";
import { ToggleLossReasonEnforcement } from "./toggle-loss-reason-enforcement";
import { ToggleProductSalesProcessEnforcement } from "./toggle-product-sales-process-enforcement";
import { ToggleShowPartners } from "./toggle-show-partners";
import { UserDefinedFields } from "./user-defined-fields";
import { Users } from "./users";
import { OpportunityReportingSettings } from "./opportunity-reporting-settings";

const { ROLES } = userRoles;

const GeneralSettings = ({ organization }) => {
  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <SelectSalesProcess organization={organization} />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <SelectTimezone organization={organization} />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <ToggleLossReasonEnforcement organization={organization} />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <ToggleProductSalesProcessEnforcement organization={organization} />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <ToggleShowPartners />
      </div>
    </>
  );
};

export const Billing = ({ organization }) => {
  return (
    <>
      <div style={{ marginBottom: "24px", padding: "0px" }}>
        <ApplicationAccess organization={organization} />
      </div>
    </>
  );
};

const ActivityStream = () => {
  return (
    <>
      <div style={{ marginBottom: "24px" }}>
        <OpportunityStates />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <OpportunityReportingSettings />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <ActivityTypes />
      </div>
      <div style={{ marginBottom: "24px" }}>
        <LossReasons />
      </div>
    </>
  );
};

const tabs = [
  {
    hasAccess: () => true,
    label: "General Settings",
    Component: GeneralSettings,
    path: "general",
  },
  {
    hasAccess: () => true,
    label: "Custom Fields",
    Component: UserDefinedFields,
    path: "custom-fields",
  },
  {
    hasAccess: () => true,
    label: "Activity Stream",
    Component: ActivityStream,
    path: "activity-stream",
  },
  {
    hasAccess: () => true,
    label: "Labels",
    Component: Labels,
    path: "labels",
  },
  {
    hasAccess: () => true,
    label: "Tags",
    Component: Tags,
    path: "tags",
  },
  {
    hasAccess: ({ hasProcore }) => !!hasProcore,
    label: "Integrations",
    Component: Integrations,
    path: "integrations",
  },
  {
    hasAccess: ({ profile }) => {
      return profile.role <= ROLES.MANAGER;
    },
    label: "Permissions",
    Component: Permissions,
    path: "permissions",
  },
  {
    hasAccess: ({ organization, profile }) => {
      return hasAccess(profile)(organization)("organization:users")();
    },
    label: "Users",
    Component: Users,
    path: "users",
  },
  {
    hasAccess: ({ organization, profile }) => {
      return hasAccess(profile)(organization)("organization:billing")();
    },
    label: "Billing",
    Component: Billing,
    path: "billing",
  },
];

// todo: test navigating to this page as a viewer user
export const OrganizationRoute = () => {
  const profile = useMe();
  const organization = useOrganization();

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const tab = searchParams.get("tab");
  const hasProcore = useFlag("integrations:procore");

  const permittedTabs = tabs.filter(({ hasAccess }) =>
    hasAccess({ organization, profile, hasProcore })
  );

  const Tab = find(permittedTabs, { path: tab });

  useEffectOnce(() => {
    if (!tab || !Tab) {
      setSearchParams(new URLSearchParams("tab=general"), { replace: true });
    }
  });

  return (
    <div
      style={{
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "auto 1fr",
        width: "880px",
      }}
    >
      <h2 className="mb-4" style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
        Organization
      </h2>
      <Card
        bordered={false}
        bodyStyle={{ padding: "0px", width: "155px" }}
        style={{
          height: `${permittedTabs.length * 46}px`,
          marginRight: "48px",
        }}
      >
        {permittedTabs.map(({ label, path }) => (
          <div
            key={path}
            id={`${path}-settings-tab`}
            onClick={() => {
              navigate(`/organization?tab=${path}`);
            }}
            style={{
              cursor: "pointer",
              padding: "12px",
              ...(tab === path
                ? { borderRight: "2px solid purple", color: "purple" }
                : {}),
            }}
          >
            {label}
          </div>
        ))}
      </Card>
      <div
        style={{ display: "flex", justifyContent: "center", overflowY: "auto" }}
      >
        <div
          style={{
            width: tab === "permissions" ? "" : "500px",
          }}
        >
          {Tab && <Tab.Component organization={organization} />}
        </div>
      </div>
    </div>
  );
};
