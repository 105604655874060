import { accountFields } from "@evolved/views";

import {
  usePatchAccount,
  useSetAccountTags,
  useSetAccountContacts,
  useSetAccountUsers,
} from "data/use-accounts";

import { swallow } from "../../../../../utils/swallow";
import { EditableContactsSelect } from "./editable-contacts-select";
import { EditableTagsSelect } from "./editable-tags-select";
import { EditableTextCell } from "./editable-text-cell";
import { EditableUserDefinedFieldCell } from "./editable-user-defined-field";
import { EditableUsersSelect } from "./editable-users-select";

const { address, contacts, name, phone, tags, users, website } = accountFields;

const Address = (props) => {
  const { row, ...rest } = props;

  const patchAccount = usePatchAccount();

  const onUpdate = (address) => {
    return swallow(
      patchAccount.mutateAsync({
        id: row._id,
        address,
      })
    );
  };

  return <EditableTextCell onUpdate={onUpdate} {...rest} />;
};

const Contacts = (props) => {
  const { row, ...rest } = props;

  const setAccountContacts = useSetAccountContacts();

  const onUpdate = (contactIds) => {
    return swallow(
      setAccountContacts.mutateAsync({
        id: row._id,
        contactIds,
      })
    );
  };

  return <EditableContactsSelect onUpdate={onUpdate} {...rest} />;
};

const Name = (props) => {
  const { row, ...rest } = props;

  const patchAccount = usePatchAccount();

  const onUpdate = (name) => {
    return swallow(
      patchAccount.mutateAsync({
        id: row._id,
        name,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Phone = (props) => {
  const { row, ...rest } = props;

  const patchAccount = usePatchAccount();

  const onUpdate = (phone) => {
    return swallow(
      patchAccount.mutateAsync({
        id: row._id,
        phone,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Tags = (props) => {
  const { row, ...rest } = props;

  const setAccountTags = useSetAccountTags();

  const onUpdate = (tagIds) => {
    return swallow(
      setAccountTags.mutateAsync({
        id: row._id,
        tagIds,
      })
    );
  };

  return <EditableTagsSelect onUpdate={onUpdate} {...rest} />;
};

const Users = (props) => {
  const { row, ...rest } = props;

  const setAccountUsers = useSetAccountUsers();

  const onUpdate = (userIds) => {
    return swallow(
      setAccountUsers.mutateAsync({
        id: row._id,
        userIds,
      })
    );
  };

  return <EditableUsersSelect onUpdate={onUpdate} {...rest} />;
};

const Website = (props) => {
  const { row, ...rest } = props;

  const patchAccount = usePatchAccount();

  const onUpdate = (website) => {
    return swallow(
      patchAccount.mutateAsync({
        id: row._id,
        website,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const UserDefinedField = (props) => {
  const { row, ...rest } = props;

  const patchAccount = usePatchAccount();

  const onUpdate = (userDefinedFields) => {
    return swallow(
      patchAccount.mutateAsync({
        id: row._id,
        userDefinedFields,
      })
    );
  };

  return (
    <EditableUserDefinedFieldCell
      {...rest}
      onUpdate={onUpdate}
      viewType="ACCOUNT"
    />
  );
};

export const accountCells = {
  [address.dataIndex]: Address,
  [contacts.dataIndex]: Contacts,
  [name.dataIndex]: Name,
  [phone.dataIndex]: Phone,
  [tags.dataIndex]: Tags,
  [users.dataIndex]: Users,
  [website.dataIndex]: Website,
  userDefinedField: UserDefinedField,
};
