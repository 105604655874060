import every from "lodash/every";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { useNavigate } from "react-router-dom";

import { List as AntList } from "antd";
import { useEffect, useRef } from "react";

import { styles } from "@evolved/constants";

import { FollowupsComplete } from "components/image-placeholders";
import { Followup } from "components/lists/followups/followup";
import { FUTURE, PAST, TODAY } from "./followups";

const { CLICKABLE_STYLE } = styles;

const linkStyle = {
  ...CLICKABLE_STYLE,
  fontWeight: "700",
};

const AccountsLink = ({ navigate }) => (
  <span onClick={() => navigate("/accounts")} style={linkStyle}>
    accounts
  </span>
);
const OpportunitiesLink = ({ navigate }) => (
  <span onClick={() => navigate("/opportunities")} style={linkStyle}>
    opportunities
  </span>
);

const getPrimaryText = ({ followupGroups, hadFollowups }) => {
  if (every(followupGroups, isEmpty)) {
    return hadFollowups
      ? "Great job, it looks like you are all caught up..."
      : "It looks like you are all caught up...";
  }

  return hadFollowups
    ? "Great job, however, there is still more to do..."
    : "All good here, however, there is still more to do...";
};

const getSecondaryText = ({
  followupGroups,
  hadFollowups,
  navigate,
  selectTab,
}) => {
  const prefix = hadFollowups ? "Take a break and when you are ready... " : "";

  if (!isEmpty(followupGroups[TODAY])) {
    return (
      <>
        {prefix}Let&apos;s shift focus to{" "}
        <span onClick={() => selectTab(TODAY)} style={linkStyle}>
          what is due today
        </span>
      </>
    );
  }

  if (!isEmpty(followupGroups[PAST])) {
    return (
      <>
        {prefix}Let&apos;s shift focus to{" "}
        <span onClick={() => selectTab(PAST)} style={linkStyle}>
          what you missed
        </span>
      </>
    );
  }

  if (!isEmpty(followupGroups[FUTURE])) {
    return (
      <>
        {prefix}Let&apos;s{" "}
        <span onClick={() => selectTab(FUTURE)} style={linkStyle}>
          look ahead
        </span>
      </>
    );
  }

  return (
    <>
      {prefix}Let&apos;s look at your <AccountsLink navigate={navigate} /> and{" "}
      <OpportunitiesLink navigate={navigate} /> for what needs attention
    </>
  );
};

export const List = ({ followupGroups = {}, heightStyle, selectTab, type }) => {
  const followups = followupGroups[type] || [];

  const hadFollowups = useRef(!isEmpty(followups));
  const navigate = useNavigate();

  useEffect(() => {
    if (hadFollowups.current) {
      hadFollowups.current = false;
    }

    if (!hadFollowups.current && !isEmpty(followups)) {
      hadFollowups.current = true;
    }
  });

  if (isEmpty(followups)) {
    return (
      <div>
        <FollowupsComplete
          primary={getPrimaryText({
            followupGroups,
            hadFollowups: hadFollowups.current,
          })}
          secondary={getSecondaryText({
            followupGroups,
            hadFollowups: hadFollowups.current,
            navigate,
            selectTab,
          })}
        />
      </div>
    );
  }

  return (
    <AntList style={{ maxHeight: heightStyle, overflow: "scroll" }}>
      {map(followups, (followup) => {
        return (
          <AntList.Item key={followup._id} style={{ padding: "16px 8px" }}>
            <Followup followup={followup} hideAssignee isCompact />
          </AntList.Item>
        );
      })}
    </AntList>
  );
};
