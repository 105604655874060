import { useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";

const root = "/opportunity-state";

export const useOpportunityStates = buildUseEntities(root);

export const useCreateOpportunityState = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(root)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateOpportunityState = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateOpportunityStateOrder = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ order }) => {
        return fetcher.put(`${root}/order`)({ order });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchiveOpportunityState = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id }) => {
        return fetcher.post(`${root}/${id}/archive`)({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
