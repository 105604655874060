import { CreateSubscription, UpdateSubscription } from "../subscription";
import { DisplayDate } from "../display-date";

export const Trialing = ({ organization, users }) => {
  const {
    cancelAtPeriodEnd,
    hasSubscription,
    subscribedPeriodEnd,
    trialPeriodEnd,
  } = organization.subscriptionState;

  const summary =
    hasSubscription && !cancelAtPeriodEnd ? (
      <>
        You will be charged for the first time following your trial on{" "}
        <DisplayDate date={subscribedPeriodEnd} /> for:
      </>
    ) : (
      <>
        Your trial will end on <DisplayDate date={trialPeriodEnd} />.
      </>
    );
  return (
    <>
      <div style={{ margin: "16px 0px" }}>{summary}</div>
      {hasSubscription ? (
        <UpdateSubscription organization={organization} users={users} />
      ) : (
        <CreateSubscription organization={organization} users={users} />
      )}
    </>
  );
};
