import day from "dayjs";
import { isEmpty } from "radash";

import { DatePicker, Form, Input } from "antd";

import { useUpdateActivity } from "../../../data/use-activities";
import { useOnChange } from "../../../hooks/use-on-change";
import { Modal } from "components/modal";

const getTypes = (activity) => {
  const types = [];

  if (activity.accountId) types.push("ACCOUNT");
  if (!isEmpty(activity.contacts)) types.push("CONTACT");
  if (activity.opportunityId) types.push("OPPORTUNITY");
  if (!isEmpty(activity.vendors)) types.push("VENDOR");

  return types;
};

export const UpdateActivityModal = (props) => {
  const { activity, close, isOpen } = props;

  const updateActivity = useUpdateActivity({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        performedOn: day.unix(activity.performedOn),
        summary: activity.summary,
      });
    },
    [activity, isOpen, form]
  );

  return (
    <Modal
      confirmLoading={updateActivity.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title="Update Activity"
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={(values) => {
          updateActivity.mutate({
            id: activity._id,
            summary: values.summary,
            performedOn: values.performedOn.unix(),
            types: getTypes(activity),
          });
        }}
      >
        <Form.Item
          label="Performed On"
          name="performedOn"
          rules={[{ required: true }]}
        >
          <DatePicker allowClear={false} format={"YYYY-MM-DD HH:mm"} showTime />
        </Form.Item>
        <Form.Item label="Summary" name="summary" rules={[{ required: true }]}>
          <Input.TextArea rows={6} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
