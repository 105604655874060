import { ExclamationCircleOutlined } from "@ant-design/icons";
import { App, Form } from "antd";

import { ContactSelect } from "../../components/selects/contact-select";
import { useMergeContacts } from "../../data/use-contacts";
import { useOnChange } from "../../hooks/use-on-change";
import { Modal } from "components/modal";

export const MergeContactsModal = (props) => {
  const { close, isOpen } = props;

  const { modal } = App.useApp();

  const mergeContacts = useMergeContacts({
    onSuccess: close,
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        childIds: [],
        parentId: null,
      });
    },
    [isOpen, form]
  );

  return (
    <Modal
      confirmLoading={mergeContacts.isLoading}
      okText="Merge"
      onCancel={close}
      onOk={form.submit}
      title="Merge Contacts"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={(values) => {
          modal.confirm({
            title: "Are you sure you want to merge these contacts?",
            icon: <ExclamationCircleOutlined />,
            content: "This cannot be undone!",
            okText: "Yes",
            okType: "danger",
            cancelText: "No",
            onOk: () => mergeContacts.mutate(values),
          });
        }}
      >
        <div style={{ marginBottom: "16px" }}>
          The secondary contact relationships will be reassigned to the primary
          contact. The secondary contacts will then be archived.
        </div>
        <div style={{ marginBottom: "16px" }}>
          <strong>
            If you wish to retain any fields, please update them prior to
            merging.
          </strong>
        </div>
        <ContactSelect
          label="Primary"
          name="parentId"
          rules={[{ required: true }]}
        />
        <ContactSelect
          label="Secondary"
          mode="multiple"
          name="childIds"
          rules={[{ required: true }]}
        />
      </Form>
    </Modal>
  );
};
