import { Pagination } from "antd";
import { debounce, isEmpty } from "radash";
import { useCallback, useEffect, useRef, useState } from "react";

import { fieldTypes } from "@evolved/constants";
import { getViewFields } from "@evolved/views";

import { aggregate } from "./aggregate";
import { Aggregations } from "./aggregations";
import { HeaderCell } from "./header-cell";
import { Row } from "./row";
import { UserDefinedFieldModal } from "../../../private/organization/modals/user-defined-field";
import { calcFillerWidth } from "./calc-filler-width";
import { styles } from "./styles";
import { useFieldConfigs } from "../use-field-configs";
import { useIndexes } from "hooks/use-indexes";
import { useModal } from "../../../hooks/use-modal";
import { usePagination } from "../../../hooks/use-pagination";
import { useViewStore } from "../../../stores/view";
import { useHasManagerRole } from "../../../hooks/use-has-role";
import { useScrollState } from "./use-scroll-state";
import { useFlag } from "hooks/use-flag";

const processColumns =
  (viewType) =>
  ({ aggregations, fields, fieldConfigs, sortOrder, widths }) => {
    const fixed = getViewFields(viewType).FIXED_FIELDS;
    return fields.reduce(
      (accumulator, field) => {
        const config = fieldConfigs[field];

        if (!config) {
          return accumulator;
        }

        const column = {
          aggregation: aggregations[field],
          ...config,
          sortOrder:
            sortOrder.find(({ dataIndex }) => dataIndex === config.dataIndex)
              ?.order || null,
          width:
            widths.find(({ dataIndex }) => dataIndex === config.dataIndex)
              ?.width || fieldTypes.DEFAULT_WIDTHS[config.type],
        };

        accumulator.width = accumulator.width + column.width;

        if (fixed.includes(field)) {
          accumulator.fixed.push(column);
        } else {
          accumulator.scrollable.push(column);
        }

        return accumulator;
      },
      { fixed: [], scrollable: [], width: 48 }
    );
  };

// todo: some way of indicating that there are columns to the left or to the right (shadows)
// todo: row highlighting should be handled at the row level
// todo: border radius
// todo: scrolling horizontally around the app
// todo: scrolling vertically around the app

const Header = (props) => {
  const { columns, fillerWidth, onWidthChange, scrollToEnd, viewType } = props;

  let fixedLeft = 48;

  const { modal, open } = useModal(UserDefinedFieldModal);
  const hasManagerRole = useHasManagerRole();

  return (
    <>
      {modal}
      <thead
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
        }}
      >
        <tr
          style={{
            backgroundColor: "#fafafa",
            color: "#000",
            height: "43.5px",
          }}
        >
          <th
            style={{
              ...styles.cell,
              backgroundColor: "#fafafa",
              left: 0,
              maxWidth: 48,
              minWidth: 48,
              position: "sticky",
              zIndex: 2,
            }}
          />
          {columns.fixed.map((column) => {
            const left = fixedLeft;
            fixedLeft = fixedLeft + column.width;

            // todo: see if we can make sticky columns adjustable
            // do it by passing styles
            return (
              <th
                key={column.dataIndex}
                style={{
                  ...styles.cell,
                  backgroundColor: "#fafafa",
                  left,
                  maxWidth: column.width,
                  minWidth: column.width,
                  position: "sticky",
                  zIndex: 2,
                }}
              >
                {column.title}
              </th>
            );
          })}
          {columns.scrollable.map((column) => {
            return (
              <HeaderCell
                key={column.dataIndex}
                column={column}
                onWidthChange={onWidthChange(column.dataIndex)}
                viewType={viewType}
              />
            );
          })}
          {hasManagerRole ? (
            <th
              className="cursor-pointer font-normal text-purple-700"
              style={{
                ...styles.cell,
                backgroundColor: "#fafafa",
                maxWidth: fillerWidth,
                minWidth: fillerWidth,
                padding: "4px 16px",
              }}
              onClick={() =>
                open({
                  type: viewType,
                  onCreate: (field) => {
                    useViewStore.getState().addField(viewType)(field);
                    setTimeout(() => scrollToEnd());
                  },
                })
              }
            >
              Add Column
            </th>
          ) : (
            <th
              style={{
                ...styles.cell,
                backgroundColor: "#fafafa",
                maxWidth: fillerWidth,
                minWidth: fillerWidth,
              }}
            />
          )}
        </tr>
      </thead>
    </>
  );
};

const useElementWidth = (ref) => {
  const [width, setWidth] = useState([]);

  const debouncedSetWidth = useCallback(debounce({ delay: 100 }, setWidth), [
    setWidth,
  ]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];

      if (!entry) return;

      debouncedSetWidth(entry.contentRect.width);
    });

    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref.current, debouncedSetWidth]);

  return width;
};

export const Table = (props) => {
  const { data, viewType } = props;

  const hasAggregations = useFlag("table:aggregations");

  const aggregations = { value: "sum" };
  const fields = useViewStore((state) => state[viewType].fields);
  const page = useViewStore((state) => state[viewType].page);
  const pageSize = useViewStore((state) => state[viewType].pageSize);
  const sortOrder = useViewStore((state) => state[viewType].sortOrder);
  const widths = useViewStore((state) => state[viewType].widths);

  const fieldConfigs = useFieldConfigs(viewType);

  const onWidthChange = (dataIndex) => (width) => {
    if (width < 75) {
      width = 75;
    }

    useViewStore.getState().setWidth(viewType)({ dataIndex, width });
  };

  const openRows = useIndexes(viewType);

  const pagination = usePagination({
    page,
    pageSize,
  });

  // todo: we can likley improve performance by leaving it up to the individual
  // cells to handle their widths. May be a pre-optimization however.
  const columns = processColumns(viewType)({
    ...(hasAggregations
      ? {
          aggregations: aggregate(data, aggregations),
        }
      : { aggregations: {} }),
    fields,
    fieldConfigs,
    sortOrder,
    widths,
  });

  const containerRef = useRef(null);

  const width = useElementWidth(containerRef);
  const fillerWidth = calcFillerWidth(width, columns);

  useEffect(() => {
    containerRef.current.scrollTo({ top: 0 });
  }, [pagination.start, pagination.end]);

  useScrollState(containerRef, viewType);
  const scrollToEnd = () =>
    containerRef.current.scrollTo({ left: 99999, behavior: "smooth" });

  return (
    <>
      <div
        className="drop-shadow-md"
        id={`${viewType}-view-table`}
        ref={containerRef}
        style={{
          backgroundColor: "white",
          overflowY: "scroll",
          position: "relative",
        }}
      >
        <table cellSpacing={0} style={{ borderCollapse: "separate" }}>
          <Header
            columns={columns}
            fillerWidth={fillerWidth}
            onWidthChange={onWidthChange}
            scrollToEnd={scrollToEnd}
            viewType={viewType}
          />
          <tbody>
            {data.slice(pagination.start, pagination.end + 1).map((row) => {
              return (
                <Row
                  columns={columns}
                  fillerWidth={fillerWidth}
                  isOpen={openRows.isOn(row._id)}
                  key={row._id}
                  row={row}
                  viewType={viewType}
                  toggleOpen={() => openRows.toggle(row._id)}
                />
              );
            })}
            {!isEmpty(aggregations) && hasAggregations ? (
              <Aggregations columns={columns} />
            ) : null}
          </tbody>
        </table>
      </div>
      <div style={{ height: "24px" }} />
      <div
        style={{
          float: "right",
          height: "32px",
          margin: "0px 24px",
        }}
      >
        <Pagination
          current={page}
          onChange={(page, pageSize) => {
            useViewStore.getState().setPagination(viewType)({ page, pageSize });
          }}
          pageSize={pageSize}
          pageSizeOptions={[10, 15, 20, 25]}
          showSizeChanger={true}
          showTotal={(total) => `Total ${total} Items`}
          style={{ float: "right" }}
          total={data.length}
        />
      </div>
    </>
  );
};
