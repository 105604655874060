import { useClerk, useSession } from "@clerk/clerk-react";
import { useEffect } from "react";

import { FullScreenCenter } from "components/full-screen-center";
import { Loader } from "components/loader";
import { useAuthenticationStore } from "./stores/authentication";
import { PrivateRoutes } from "./private";
import { PublicRoutes } from "./public";
import { useMe } from "./data/use-me";
import { useOrganization } from "./data/use-organization";
import { useInitializeCache } from "./use-initialize-cache";

function App() {
  const clerk = useClerk();

  const { isLoaded: isSessionLoaded } = useSession();
  const { session } = useAuthenticationStore();

  const initSessionListener = () => {
    clerk.addListener((resources) => {
      const { session, setSession } = useAuthenticationStore.getState();

      if (resources.session && session?.id !== resources.session.id) {
        setSession(resources.session);
      }

      if (!resources.session && session) {
        setSession(null);
      }
    });
  };

  useEffect(() => {
    clerk.addOnLoaded(() => {
      initSessionListener();
    });
  }, [clerk]);

  const me = useMe({ detailed: true, enabled: isSessionLoaded && !!session });
  const organization = useOrganization({
    detailed: true,
    enabled: isSessionLoaded && !!session,
  });

  const isUserLoaded =
    isSessionLoaded && (!session || (!me.isLoading && !organization.isLoading));

  const hasAccess =
    session &&
    isUserLoaded &&
    organization.data?.subscriptionState?.userHasAccess;

  const isCacheEnabled = isUserLoaded && organization.data;

  const isCacheLoaded = useInitializeCache({
    enabled: isCacheEnabled,
    hasAccess,
  });

  const isLoaded = isUserLoaded && (!isCacheEnabled || isCacheLoaded);

  if (!isSessionLoaded) return null;

  if (!isLoaded) {
    return (
      <FullScreenCenter>
        <Loader />
      </FullScreenCenter>
    );
  }

  if (!session) {
    return <PublicRoutes />;
  }

  return <PrivateRoutes />;
}

export default App;
