import omit from "lodash/omit";
import pick from "lodash/pick";

const baseProps = ["filter"];
const formItemProps = [
  "extra",
  "help",
  "label",
  "name",
  "noStyle",
  "required",
  "rules",
];

export const pickFormItemProps = (props) => {
  return pick(props, formItemProps);
};

export const pickSelectProps = (props) => {
  return omit(props, [...formItemProps, ...baseProps]);
};
