import { compareValues } from "./compare-value";

export const buildEntityCache = ({
  accounts = [],
  contacts = [],
  opportunityStates = [],
  products = [],
  salesProcesses = [],
  vendors = [],
}) => ({
  accounts: {
    find: (value) => {
      return accounts.filter((e) => {
        return compareValues(e.name, value);
      });
    },
  },
  contacts: {
    find: (value) => {
      return contacts.filter((e) => {
        return compareValues(e.name, value) || compareValues(e.email, value);
      });
    },
  },
  opportunityStates: {
    find: (value) => {
      return opportunityStates.filter((e) => {
        return compareValues(e.label, value);
      });
    },
  },
  products: {
    find: (value) => {
      return products.filter((e) => {
        return compareValues(e.name, value);
      });
    },
  },
  salesProcesses: {
    find: (value) => {
      return salesProcesses.filter((e) => {
        return compareValues(e.name, value);
      });
    },
  },
  vendors: {
    find: (value) => {
      return vendors.filter((e) => {
        return compareValues(e.name, value);
      });
    },
  },
});
