export const smartTrim = (str) => {
  return str.replace(/\s+/g, " ").trim();
};

export const normalizeText = (str) => {
  if (str === null || str === undefined) {
    return "";
  }

  return smartTrim(str).toLowerCase();
};
