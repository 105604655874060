import { useNavigate } from "react-router-dom";

import { useEffectOnce } from "hooks/use-effect-once";
import { useAuthenticationStore } from "stores/authentication";

export const RedirectToDashboard = () => {
  const navigate = useNavigate();

  useEffectOnce(() => {
    const authentication = useAuthenticationStore.getState();

    let redirect = "/dashboard";

    if (authentication.redirect) {
      redirect = authentication.redirect;
      authentication.clearRedirect();
    }

    navigate(redirect);
  });

  return null;
};
