import dayjs from "dayjs";

import { DatePicker, Select } from "antd";
import { useEffect, useState } from "react";

import { weekday } from "utils/format-date";
import { getNextRunOn } from "./get-next-run-on";
import { useUpsertScheduledReport } from "../../../data/use-me";
import { Modal } from "components/modal";

const { Option } = Select;

const everyMap = {
  "every-week": {
    period: "weekly",
    every: 1,
  },
  "every-two-weeks": {
    period: "weekly",
    every: 2,
  },
  "every-month": {
    period: "monthly",
  },
};

export const ScheduleReportModal = (props) => {
  const { close, id, schedule, type, isOpen } = props;

  const [every, setEvery] = useState("every-week");
  const [day, setDay] = useState(1);
  const [startOn, setStartOn] = useState(dayjs().add(1, "day"));

  const upsertScheduledReport = useUpsertScheduledReport({ onSuccess: close });

  useEffect(() => {
    if (schedule) {
      if (schedule.period === "weekly") {
        setDay(schedule.day);
        setEvery(schedule.every === 1 ? "every-week" : "every-two-weeks");
      } else {
        setDay(1);
        setEvery("every-month");
      }

      setStartOn(dayjs(schedule.startOn));
    } else {
      setEvery("every-week");
      setDay(1);
      setStartOn(dayjs().add(1, "day"));
    }
  }, [schedule]);

  const update = {
    ...everyMap[every],
    ...((every || "").includes("week") ? { day } : {}),
    startOn: startOn.clone().hour(12).startOf("hour").toISOString(),
  };

  const submit = () => {
    upsertScheduledReport.mutate({ id, type, ...update });
  };

  return (
    <Modal
      confirmLoading={upsertScheduledReport.isLoading}
      okText="Schedule"
      onCancel={() => {
        close();
      }}
      onOk={submit}
      title={"Schedule Report"}
      open={isOpen}
    >
      <div style={{ display: "flex" }}>
        <strong style={{ width: "150px" }}>Every</strong>
        <Select onChange={setEvery} value={every} style={{ width: 150 }}>
          <Option value="every-week">Week</Option>
          <Option value="every-two-weeks">Two Weeks</Option>
          <Option value="every-month">End of Month</Option>
        </Select>
      </div>
      <div style={{ display: "flex", marginTop: "16px" }}>
        <strong style={{ width: "150px" }}>Day of the week</strong>
        <Select
          disabled={every === "every-month"}
          onChange={setDay}
          value={day}
          style={{ width: 150 }}
        >
          <Option value={0}>Sunday</Option>
          <Option value={1}>Monday</Option>
          <Option value={2}>Tuesday</Option>
          <Option value={3}>Wednesday</Option>
          <Option value={4}>Thursday</Option>
          <Option value={5}>Friday</Option>
          <Option value={6}>Saturday</Option>
        </Select>
      </div>
      <div style={{ display: "flex", marginTop: "16px" }}>
        <strong style={{ width: "150px" }}>Start On</strong>
        <DatePicker
          allowClear={false}
          onChange={setStartOn}
          value={startOn}
          style={{ width: 150 }}
        />
      </div>
      <div>
        <div style={{ margin: "16px 0px" }}>Will next run on:</div>
        <strong>{weekday(getNextRunOn(update).valueOf() / 1000)}</strong>
        <br />
        <strong>
          {weekday(
            getNextRunOn({
              ...update,
              lastRanOn: getNextRunOn(update),
            }).valueOf() / 1000
          )}
        </strong>
      </div>
    </Modal>
  );
};
