import map from "lodash/map";

import { Alert } from "antd";

import getErrorMessage from "utils/get-error-message";

import { styles } from "@evolved/constants";

const { CLICKABLE_STYLE } = styles;

const getDefaults = ({ message }) => {
  const defaults = {
    body: message,
    subject: "CRM - Please Fix Me",
  };

  return map(defaults, (value, key) => {
    return `${key}=${encodeURIComponent(value)}`;
  }).join("&");
};

const MAX_LENGTH = 300;

export const ErrorMessage = ({
  action,
  error,
  refresh,
  support = true,
  style,
}) => {
  let message = getErrorMessage(error);

  if (message.length > MAX_LENGTH) {
    message = `${message.slice(0, MAX_LENGTH)}...`;
  }

  return (
    <Alert
      action={action}
      message={message}
      description={
        support && (
          <span>
            <strong>
              <a
                href={`mailto:support@evolvedmetrics.com?${getDefaults({
                  message,
                })}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Click here
              </a>
            </strong>
            <> </>to email us this error, any extra details you may have to help
            us find the issue, and we will look into it right away! Thank you.
            {refresh && (
              <>
                <br />
                <br />
                Next,{" "}
                <strong onClick={refresh} style={CLICKABLE_STYLE}>
                  try refreshing and see if the problem resolved itself.
                </strong>
              </>
            )}
          </span>
        )
      }
      showIcon
      style={style}
      type="error"
    />
  );
};
