import { Tag } from "antd";
import { select } from "radash";

import { useOpportunityStates } from "../../../../../data/use-opportunity-states";

export const OpportunityStatesSet = (props) => {
  const { value } = props;

  const opportunityStates = useOpportunityStates();

  const selected = select(
    value,
    (id) => opportunityStates.byId(id),
    (id) => !!opportunityStates.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((opportunityState) => {
    return (
      <Tag
        color={opportunityState.color}
        key={opportunityState._id}
        style={opportunityState.isArchived ? { opacity: 0.5 } : {}}
      >
        {opportunityState.label}
      </Tag>
    );
  });
};
