import { useQueryClient } from "@tanstack/react-query";

import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation";

import { root as meRoot } from "./use-me";
import { root as organizationRoot } from "./use-organization";

export const usePatchIsNylasEmailPublic = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ isEmailPublic }) => {
        return fetcher.patch("/integrations/nylas/settings")({ isEmailPublic });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [meRoot] });
      },
    },
    overrides
  );
};

export const useNylasLogout = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: () => {
        return fetcher.post("/integrations/nylas/logout")({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [meRoot] });
      },
      onError: async () => {
        await queryClient.invalidateQueries({ queryKey: [meRoot] });
      },
    },
    overrides
  );
};

export const useProcoreLogout = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: () => {
        return fetcher.post("/integrations/procore/logout")({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [organizationRoot] });
      },
      onError: async () => {
        await queryClient.invalidateQueries({ queryKey: [organizationRoot] });
      },
    },
    overrides
  );
};

export const useHubSpotLogout = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: () => {
        return fetcher.post("/integrations/hubspot/logout")({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [organizationRoot] });
      },
      onError: async () => {
        await queryClient.invalidateQueries({ queryKey: [organizationRoot] });
      },
    },
    overrides
  );
};
