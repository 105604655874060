import { fields as generalFields } from "./fields";
import { relationships } from "./relationships";

export const parseGeneralFields = (type) =>
  generalFields[type].map((field) => ({
    ...field,
    fieldType: "GENERAL",
  }));

export const parseOrganizationUserDefinedFields = (organization, type) =>
  (organization.userDefinedFields || [])
    .filter((field) => field.type === type)
    .map(({ id, name, options, dataType }) => ({
      dataIndex: `userDefinedFields.${id}`,
      fieldType: "UDF",
      title: name,
      type: dataType,
      ...(options ? { options: options.filter((o) => !o.isArchived) } : {}),
    }));

export const parseRelationshipsFields = (type) =>
  relationships[type].map((relationship) => ({
    ...relationship,
    fieldType: "RELATIONSHIP",
  }));

export const parseFieldConfigs = (organization, type) => {
  return [
    ...parseGeneralFields(type),
    ...parseRelationshipsFields(type),
    ...parseOrganizationUserDefinedFields(organization, type),
  ];
};
