import { Button } from "antd";

import { usePatchOrganizationSetupState } from "../../data/use-organization";

export const SetupWizard = () => {
  const patchSetupState = usePatchOrganizationSetupState();

  return (
    <Button
      loading={patchSetupState.isLoading}
      onClick={() => {
        patchSetupState.mutate({
          isComplete: false,
          isGreeted: false,
          isIntegrationsStepComplete: false,
          isOrganizationStepComplete: false,
          isSalesProcessesStepComplete: false,
          isProductsStepComplete: false,
          isAccountsStepComplete: false,
          isContactsStepComplete: false,
          isOpportunitiesStepComplete: false,
          isVendorsStepComplete: false,
        });
      }}
    >
      Reset Setup Wizard State
    </Button>
  );
};
