import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { useOnChange } from "./hooks/use-on-change";
import { useNotification } from "contexts/notification/use-notification";

import { useAccounts } from "./data/use-accounts";
import { useActivityTypes } from "./data/use-activity-types";
import { useContacts } from "./data/use-contacts";
import { useLossReasons } from "./data/use-loss-reasons";
import { useOpportunities } from "./data/use-opportunities";
import { useOpportunityStates } from "./data/use-opportunity-states";
import { useProducts } from "./data/use-products";
import { useSalesProcesses } from "./data/use-sales-processes";
import { useTags } from "./data/use-tags";
import { useUsers } from "./data/use-users";
import { useVendors } from "./data/use-vendors";
import { useViews } from "./data/use-views";

export const useInitializeCache = ({ enabled, hasAccess }) => {
  const queryClient = useQueryClient();
  const notification = useNotification();

  const [done, setDone] = useState(false);

  useOnChange(enabled, () => {
    if (!enabled) {
      setDone(false);
      return;
    }

    const initialize = async () => {
      try {
        const start = +new Date();

        await Promise.all([
          // users are initialized if no access as they are needed to update the subscription
          queryClient.fetchQuery({
            queryKey: useUsers.key,
            queryFn: useUsers.queryFn,
          }),
          ...(hasAccess
            ? [
                queryClient.fetchQuery({
                  queryKey: useAccounts.key,
                  queryFn: useAccounts.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useActivityTypes.key,
                  queryFn: useActivityTypes.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useContacts.key,
                  queryFn: useContacts.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useLossReasons.key,
                  queryFn: useLossReasons.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useOpportunities.key,
                  queryFn: useOpportunities.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useOpportunityStates.key,
                  queryFn: useOpportunityStates.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useSalesProcesses.key,
                  queryFn: useSalesProcesses.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useTags.key,
                  queryFn: useTags.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useVendors.key,
                  queryFn: useVendors.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useProducts.key,
                  queryFn: useProducts.queryFn,
                }),

                queryClient.fetchQuery({
                  queryKey: useViews.key,
                  queryFn: useViews.queryFn,
                }),
              ]
            : []),
        ]);

        // todo: move this out into a useDelay hook
        // which returns true after x amount of time
        const end = +new Date();

        if (end - start < 1000) {
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 - (end - start))
          );
        }
      } catch (error) {
        // todo: what if this fails?
        notification.support(error);
      }

      setDone(true);
    };

    initialize();
  });

  return done;
};
