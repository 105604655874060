import get from "lodash/get";
import { Row } from "antd";
import { useNavigate } from "react-router-dom";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FunnelPlotOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import { InfoCard } from "components/info-card";

export const Details = (props) => {
  const { product } = props;

  const { canCrossSell, canPartialSell, canSellQuantities, canUpsell } =
    product;

  const navigate = useNavigate();

  return (
    <Row gutter={16} type="flex">
      <InfoCard
        content={`${get(product, "salesProcess.name", "--")}`}
        Icon={FunnelPlotOutlined}
        label="Sales Process"
        {...(get(product, "salesProcess._id")
          ? {
              onClick: () =>
                navigate(
                  `/sales-processes/${get(product, "salesProcess._id")}`
                ),
            }
          : {})}
      />
      <InfoCard
        content={product.description || "--"}
        Icon={InfoCircleOutlined}
        label="Description"
        style={{
          content: { fontSize: "1em" },
        }}
      />
      <InfoCard
        content={canCrossSell ? "Yes" : "No"}
        Icon={canCrossSell ? CheckCircleOutlined : CloseCircleOutlined}
        label="Can be cross sold"
        style={{
          content: { fontSize: "1em" },
        }}
      />
      <InfoCard
        content={canPartialSell ? "Yes" : "No"}
        Icon={canPartialSell ? CheckCircleOutlined : CloseCircleOutlined}
        label="Can be partially sold"
        style={{
          content: { fontSize: "1em" },
        }}
      />
      <InfoCard
        content={canSellQuantities ? "Yes" : "No"}
        Icon={canSellQuantities ? CheckCircleOutlined : CloseCircleOutlined}
        label="Can be sold in quantities"
        style={{
          content: { fontSize: "1em" },
        }}
      />
      <InfoCard
        content={canUpsell ? "Yes" : "No"}
        Icon={canUpsell ? CheckCircleOutlined : CloseCircleOutlined}
        label="Can be up sold"
        style={{
          content: { fontSize: "1em" },
        }}
      />
    </Row>
  );
};
