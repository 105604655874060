import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

import { useOpportunities } from "data/use-opportunities";
import { useProducts } from "data/use-products";
import { OpportunitiesList } from "components/lists/opportunities";
import { withEntityExists } from "../with-entity-exists";

import { Details } from "./details";
import { Header } from "./header";
import { Tags } from "./tags";

export const ProductRoute = withEntityExists(useProducts)(() => {
  const id = Number(useParams().id);

  const product = useProducts().byId(id);
  const opportunities = useOpportunities();

  // todo: add the ability to create opportunity pre-filled with product
  return (
    <div className="w-full">
      <Row gutter={48} justify={"center"}>
        <Col xs={24} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <Header product={product} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Tags product={product} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Details product={product} />
          </div>
          <OpportunitiesList
            opportunities={opportunities
              .all()
              .filter(({ products }) => products.find(({ _id }) => _id === id))}
          />
        </Col>
      </Row>
    </div>
  );
});
