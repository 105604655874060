import Parse from "papaparse";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";

const useFileList = (initialState) => {
  const [state, setState] = useState(initialState);

  const set = ({ name, status = "done" }) => {
    setState([{ name, status: status, uid: "-1" }]);
  };

  const clear = () => {
    setState([]);
  };

  return {
    state,
    set,
    clear,
  };
};

export const FileUpload = (props) => {
  const {
    onStart = () => { },
    onSuccess = () => { },
    onError = () => { },
  } = props;

  const fileList = useFileList([]);

  const onDrop = async (file) => {
    onStart();
    fileList.set({ name: file.name, status: "uploading" });

    try {
      const parsed = Parse.parse(await file.text(), {
        header: true,
        skipEmptyLines: true,
      });

      const headers = parsed.meta.fields;
      const rows = parsed.data;

      fileList.set({ name: file.name, status: "done" });
      onSuccess({ headers, rows });
    } catch (error) {
      fileList.clear();
      onError(error);
    }
  };

  return (
    <Upload.Dragger
      accept=".csv"
      customRequest={async ({ file, onSuccess }) => {
        await onDrop(file);
        onSuccess("ok");
      }}
      fileList={fileList.state}
      onRemove={fileList.clear}
    >
      <p className="ant-upload-drag-icon">
        <UploadOutlined />
      </p>
      <p className="ant-upload-text" style={{ margin: "4px 24px" }}>
        Drop your .csv file here.
      </p>
    </Upload.Dragger>
  )
};
