import { Tags as TagsList } from "components/lists/tags";

import { useTagAccount, useUntagAccount } from "data/use-accounts";

export const Tags = (props) => {
  const { account } = props;
  const { tags } = account;

  const tagAccount = useTagAccount();
  const untagAccount = useUntagAccount();

  const addTag = {
    isLoading: tagAccount.isLoading,
    mutate: (tagId) => tagAccount.mutate({ id: account._id, tagId }),
  };

  const removeTag = {
    isLoading: untagAccount.isLoading,
    mutate: (tagId) => untagAccount.mutate({ id: account._id, tagId }),
  };

  return (
    <TagsList
      isSelect
      addTag={addTag}
      removeTag={removeTag}
      selectedTags={tags}
    />
  );
};
