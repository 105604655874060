import { message } from "antd";

import { timezones } from "@evolved/constants";

import { useUpdateTimezone } from "../../data/use-me";
import { SearchSelect } from "components/selects/search-select";

export const SelectTimezone = (props) => {
  const { organization, profile } = props;

  const updateUserTimezone = useUpdateTimezone();

  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <strong style={{ fontSize: "16px" }}>Timezone</strong>
      </div>
      <SearchSelect
        allowClear
        onChange={(timezone) => {
          updateUserTimezone.mutate({ timezone });
        }}
        options={timezones.map((timezone) => ({
          label: timezone,
          value: timezone,
        }))}
        placeholder={`Use Organization Setting: ${
          organization.timezone || "America/Edmonton"
        }`}
        style={{ marginBottom: "8px", display: "block" }}
        value={profile.timezone}
      />
      <div>
        <strong>Note: </strong>this timezone is only used for generated reports.
        The app will continue to display info in your local timezone.
      </div>
    </>
  );
};
