import { Form, Input } from "antd";
import pick from "lodash/pick";

import { viewTypes } from "@evolved/constants";

import {
  mapInitialUserDefinedFieldsState,
  mapOutgoingValues,
  UserDefinedFieldInputs,
} from "components/user-defined-field-inputs";
import { useOrganization } from "data/use-organization";
import { useUpdateVendor } from "data/use-vendors";
import { useOnChange } from "hooks/use-on-change";
import { Modal } from "../../../components/modal";

const onSubmit =
  ({ updateVendor, vendor }) =>
  async (values) => {
    await updateVendor.mutate(
      mapOutgoingValues({
        id: vendor._id,
        ...values,
      })
    );
  };

export const UpdateVendorDetailsModal = (props) => {
  const { close, isOpen, vendor } = props;

  const organization = useOrganization();

  const [form] = Form.useForm();

  const updateVendor = useUpdateVendor({
    onSuccess: close,
  });

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        ...mapInitialUserDefinedFieldsState(vendor, {
          organization,
          type: viewTypes.VENDOR,
        }),
        ...pick(vendor, ["address", "name", "phone", "website"]),
      });
    },
    [isOpen, form, vendor, organization]
  );

  return (
    <Modal
      confirmLoading={updateVendor.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      title="Details"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          vendor,
          updateVendor,
        })}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input />
        </Form.Item>
        <UserDefinedFieldInputs
          organization={organization}
          type={viewTypes.VENDOR}
        />
      </Form>
    </Modal>
  );
};
