import { useState } from "react";

import { EyeOutlined } from "@ant-design/icons";

export const DefaultPreviewBackground = ({ file, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div
      className="width relative flex h-16 w-[250px] items-center justify-center bg-slate-400 font-bold text-white"
      onClick={onClick}
      onMouseOver={() => setIsHovering(true)}
      onMouseOut={() => setIsHovering(false)}
    >
      {isHovering && (
        <div className="absolute top-0 bottom-0 left-0 right-0 flex cursor-pointer items-center justify-center bg-black bg-opacity-50 font-normal text-white">
          <div>
            <EyeOutlined /> Preview
          </div>
        </div>
      )}
      {file.fileType}
    </div>
  );
};
