import { Button } from "antd";
import { loadStripe } from "@stripe/stripe-js";
import { useState } from "react";
import day from "dayjs";

import { fetcher } from "data/fetcher";
import { getDefaultActiveUsers } from "./get-default-active-users";
import { Price } from "./price";
import { SelectUsers } from "./select-users";
import { useNotification } from "contexts/notification/use-notification";

const onClick =
  ({ activeUsers, organization, notification, setLoading }) =>
  async () => {
    setLoading(true);

    try {
      const { sessionId } = await fetcher.post(
        "/integrations/stripe/checkout-session"
      )({
        activeUsers,
        redirectTo: {
          success: "/organization?tab=billing",
          cancel: organization?.subscriptionState?.userHasAccess
            ? "/organization?tab=billing"
            : "",
        },
      });

      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);
      const { error } = await stripe.redirectToCheckout({ sessionId });
      throw error;
    } catch (err) {
      notification.support(err);
    }

    setLoading(false);
  };

const isMoreThanTwoDays = (date) => {
  return day.utc().add(2, "days").add(1, "minute").isBefore(date);
};

export const CreateSubscription = (props) => {
  const { organization, users } = props;

  const [loading, setLoading] = useState(false);
  const [activeUsers, setActiveUsers] = useState(
    getDefaultActiveUsers({ organization, users })
  );

  const notification = useNotification();

  return (
    <>
      <SelectUsers checked={activeUsers} onChange={setActiveUsers} />
      <Price
        users={users.filter(({ _id }) => activeUsers.includes(Number(_id)))}
      />
      {organization?.subscriptionState?.status === "trialing" &&
        !isMoreThanTwoDays(organization.subscriptionState.trialPeriodEnd) && (
          <div>
            <strong>
              *Subscribing now will end your trial and bill you today
            </strong>
          </div>
        )}
      <Button
        ghost
        loading={loading}
        onClick={onClick({
          activeUsers,
          organization,
          notification,
          setLoading,
        })}
        style={{ marginTop: "8px" }}
        type="primary"
      >
        Subscribe
      </Button>
    </>
  );
};
