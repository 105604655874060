import { Card, Col, Row, Table as AntTable } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { AddButton } from "components/add-button";
import { ProductDetailsModal } from "components/modals/product-details-modal";
import { useModal } from "hooks/use-modal";
import { useProducts } from "data/use-products";
import { withManagerRole } from "components/with-role";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      if (record.isArchived) {
        return <span style={{ opacity: 0.5 }}>{text}</span>;
      }

      return text;
    },
    sorter: (a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;

      return 0;
    },
  },
];

const CreateProduct = withManagerRole((props) => {
  const { open } = props;

  return (
    <AddButton
      id="create-product-button"
      onClick={open}
      style={{ marginLeft: "auto" }}
    >
      Product
    </AddButton>
  );
});

const onCreate = (navigate) => (product) => {
  navigate(`/products/${product._id}`);
};

export const ProductsRoute = () => {
  const [includeArchived, setIncludeArchived] = useState(false);

  const navigate = useNavigate();

  const products = useProducts().all({ includeArchived });

  const { modal, open } = useModal(ProductDetailsModal);

  return (
    <div className="w-full">
      {modal}
      <Row justify={"center"} type={"flex"}>
        <Col xs={24} xl={12}>
          <div className="mb-2 flex">
            <h2>Products</h2>
            <div className="ml-auto flex flex-col items-end">
              <CreateProduct
                open={() =>
                  open({
                    onDone: onCreate(navigate),
                  })
                }
              />
              <div
                className="mt-2 cursor-pointer text-purple-700"
                onClick={() => setIncludeArchived(!includeArchived)}
              >
                {includeArchived ? "Hide" : "Show"} Archived
              </div>
            </div>
          </div>
          <Card
            bodyStyle={{ padding: 0 }}
            bordered={false}
            id="PRODUCT-view-table"
          >
            <AntTable
              columns={columns}
              dataSource={products}
              locale={{
                emptyText: "You have no products, go ahead and create some!",
              }}
              onRow={(record) => ({
                onClick: () => navigate(`/products/${record._id}`),
              })}
              rowClassName="clickable"
              rowKey="_id"
              size="middle"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
