import { Button } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useUnarchiveProduct } from "data/use-products";

export const UnarchiveProduct = (props) => {
  const { product } = props;

  const unarchiveProduct = useUnarchiveProduct();

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      disabled={unarchiveProduct.isLoading}
      onClick={() => unarchiveProduct.mutate({ id: product._id })}
    >
      Unarchive
    </Button>
  );
};
