export const styles = {
  buttonCard: {
    container: {
      alignItems: "center",
      borderColor: "rgba(0, 0, 0, 0.12)",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.12)",
      display: "flex",
      justifyContent: "center",
      width: "500px",
    },
    svg: {
      container: {
        padding: "0px",
      },
      body: {
        width: 100,
        height: 80,
      },
    },
  },
  infoCard: {
    body: {
      borderColor: "rgba(0, 0, 0, 0.08)",
    },
    container: {
      width: "500px",
    },
    title: {
      color: "rgba(0, 0, 0, 0.5)",
      marginBottom: "8px",
    },
  },
};
