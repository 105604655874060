import { useQuery, useQueryClient } from "@tanstack/react-query";

import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation";

import { root as accountsRoot } from "./use-accounts";
import { root as contactsRoot } from "./use-contacts";
import { root as opportunitiesRoot } from "./use-opportunities";
import { root as vendorsRoot } from "./use-vendors";

export const root = "/followup";

export const useAssignedFollowups = () => {
  const queryFn = fetcher.get("/me/assigned-followups");

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/me/assigned-followups"],
  });

  return data || [];
};

export const useAccountFollowups = ({ _id }) => {
  const queryFn = fetcher.get(`/account/${_id}/followups`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/account", `${_id}`],
  });

  return data || [];
};

export const useContactFollowups = ({ _id }) => {
  const queryFn = fetcher.get(`/contact/${_id}/followups`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/contact", `${_id}`],
  });

  return data || [];
};

export const useOpportunityFollowups = ({ _id }) => {
  const queryFn = fetcher.get(`/opportunity/${_id}/followups`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/opportunity", `${_id}`],
  });

  return data || [];
};

export const useVendorFollowups = ({ _id }) => {
  const queryFn = fetcher.get(`/vendor/${_id}/followups`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, "/vendor", `${_id}`],
  });

  return data || [];
};

export const useScheduleAccountFollowup = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/account`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
      },
    },
    overrides
  );
};

export const useScheduleContactFollowup = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/contact`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [contactsRoot] });
      },
    },
    overrides
  );
};

export const useScheduleOpportunityFollowup = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/opportunity`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [opportunitiesRoot] });
      },
    },
    overrides
  );
};

export const useScheduleVendorFollowup = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (body) => {
        return fetcher.post(`${root}/vendor`)(body);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await queryClient.invalidateQueries({ queryKey: [vendorsRoot] });
      },
    },
    overrides
  );
};

const invalidateQueryByTypes = (queryClient) => async (types) => {
  for (const type of types) {
    const queryKey = {
      ACCOUNT: [accountsRoot],
      CONTACT: [contactsRoot],
      OPPORTUNITY: [opportunitiesRoot],
      VENDOR: [vendorsRoot],
    }[type];

    await queryClient.invalidateQueries({ queryKey });
  }
};

export const useUpdateFollowup = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (args) => {
        const { id, dueOn, assigneeId, task } = args;

        return fetcher.patch(`${root}/${id}`)({ dueOn, assigneeId, task });
      },
      onSuccess: async (data, { variables }) => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await invalidateQueryByTypes(queryClient)(variables.types);
      },
    },
    overrides
  );
};

export const useArchiveFollowup = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id }) => {
        return fetcher.put(`${root}/${id}/archive`)();
      },
      onSuccess: async (data, { variables }) => {
        await queryClient.invalidateQueries({ queryKey: [root] });
        await invalidateQueryByTypes(queryClient)(variables.types);
      },
    },
    overrides
  );
};
