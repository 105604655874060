import { ArchiveVendor } from "./archive";
import { BackButton } from "components/back-button";
import { DisplayArchived } from "components/display-archived";
import { EditButton } from "components/edit-button";
import { UnarchiveVendor } from "./unarchive";
import { UpdateVendorDetailsModal } from "../modals/update-details";
import { useModal } from "hooks/use-modal";

const style = {
  container: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "16px",
  },
  button: { marginLeft: "auto" },
  header: { margin: "0px 16px 0px 0px" },
};

export const Header = (props) => {
  const { vendor } = props;

  const { modal, open } = useModal(UpdateVendorDetailsModal);

  return (
    <>
      {modal}
      <div className="space-y-2" style={style.container}>
        <div className="mr-auto flex flex-wrap items-center">
          <BackButton default={"/partners"} />
          <h2 style={style.header}>{vendor.name}</h2>
          <DisplayArchived entity={vendor} />
        </div>
        <div className="flex flex-wrap items-center">
          <EditButton
            onClick={() => open({ vendor })}
            style={{ marginRight: "8px" }}
          />
          {!vendor.isArchived ? (
            <ArchiveVendor vendor={vendor} />
          ) : (
            <UnarchiveVendor vendor={vendor} />
          )}
        </div>
      </div>
    </>
  );
};
