import { Form, Input } from "antd";

import { useOnChange } from "hooks/use-on-change";
import { useCreateTag, useUpdateTag } from "data/use-tags";
import { Modal } from "components/modal";

const onSubmit =
  ({ mutation, tag }) =>
  (values) => {
    if (tag) {
      mutation.mutate({
        id: tag._id,
        ...values,
      });
    }

    if (!tag) {
      mutation.mutate(values);
    }
  };

export const TagDetailsModal = (props) => {
  const { close, isOpen, tag } = props;

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        label: tag ? tag.label : "",
      });
    },
    [isOpen, form, tag]
  );

  const createTag = useCreateTag({ onSuccess: close });
  const updateTag = useUpdateTag({ onSuccess: close });

  const mutation = tag ? updateTag : createTag;

  return (
    <Modal
      confirmLoading={mutation.isLoading}
      maskClosable={false}
      okText={tag ? "Update" : "Create"}
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title={`${tag ? "Update" : "Create"} Tag`}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        onFinish={onSubmit({ mutation, tag })}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item label="Label" name="label" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
