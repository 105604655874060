import { Button } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const BackButton = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      icon={<LeftOutlined />}
      onClick={() => {
        if (props.default) {
          navigate(props.default);
        } else {
          navigate("/");
        }
      }}
      style={{ marginRight: "16px" }}
    >
      Overview
    </Button>
  );
};
