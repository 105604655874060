import produce from "immer";
import without from "lodash/without";
import create from "zustand";

import { replaceOrAppend, unique } from "radash";

import { getViewFields } from "@evolved/views";

import { useSearchStore } from "./search";
import { useViews } from "../data/use-views";

const ensureFixedIndexes = (viewType) => (fields) => {
  const fixed = getViewFields(viewType).FIXED_FIELDS;

  return [...fixed, ...fields.filter((field) => !fixed.includes(field))];
};

const getDefaults = (viewType) => {
  return {
    selectedId: null,
    includeArchived: false,
    fields: ensureFixedIndexes(viewType)(
      getViewFields(viewType).DEFAULT_FIELDS
    ),
    filters: [],
    sortOrder: [],
    widths: [],
    pageSize: 25,
    page: 1,
    preset: false,
  };
};

export const useResetView = (viewType) => {
  const views = useViews();

  return () => {
    const { resetView, setView, ...viewState } = useViewStore.getState();
    const { selectedId } = viewState[viewType];

    const view = selectedId && views.find(({ _id }) => _id === selectedId);

    const searchState = useSearchStore.getState();
    searchState.reset(viewType)();

    if (!view) {
      resetView(viewType)();
      return;
    }

    setView(viewType)(view);
  };
};

export const useViewStore = create((set) => ({
  ACCOUNT: getDefaults("ACCOUNT"),
  ACTIVITY: getDefaults("ACTIVITY"),
  CONTACT: getDefaults("CONTACT"),
  OPPORTUNITY: getDefaults("OPPORTUNITY"),
  VENDOR: getDefaults("VENDOR"),
  setView: (viewType) => (view) =>
    set(
      produce((state) => {
        const defaults = getDefaults(viewType);

        state[viewType].selectedId = view._id || defaults.selectedId;
        state[viewType].fields = view.fields
          ? ensureFixedIndexes(viewType)(view.fields)
          : defaults.fields;
        state[viewType].filters = view.filters || defaults.filters;
        state[viewType].includeArchived = !!view.includeArchived;
        state[viewType].sortOrder = view.sortOrder || defaults.sortOrder;
        state[viewType].widths = view.widths || defaults.widths;
        state[viewType].pageSize = view.pageSize || defaults.pageSize;
        state[viewType].preset = view.preset || defaults.preset;

        state[viewType].page = 1;
      })
    ),
  clearSelectedId: (viewType) => () =>
    set(
      produce((state) => {
        state[viewType].selectedId = null;
      })
    ),
  resetView: (viewType) => () => {
    set(
      produce((state) => {
        state[viewType] = getDefaults(viewType);
      })
    );
  },
  addField: (viewType) => (field) =>
    set(
      produce((state) => {
        state[viewType].fields = ensureFixedIndexes(viewType)(
          unique([...state[viewType].fields, field])
        );
        state[viewType].preset = false;
      })
    ),
  setFields: (viewType) => (fields) =>
    set(
      produce((state) => {
        state[viewType].fields = ensureFixedIndexes(viewType)(fields);
        state[viewType].preset = false;
      })
    ),
  setIncludeArchived: (viewType) => (includeArchived) =>
    set(
      produce((state) => {
        state[viewType].includeArchived = includeArchived;
        state[viewType].page = 1;
      })
    ),
  removeField: (viewType) => (field) =>
    set(
      produce((state) => {
        state[viewType].fields = ensureFixedIndexes(viewType)(
          without(state[viewType].fields, field)
        );
        state[viewType].preset = false;
      })
    ),
  setFilter:
    (viewType) =>
    ({ dataIndex, value }) =>
      set(
        produce((state) => {
          state[viewType].filters = replaceOrAppend(
            state[viewType].filters,
            { dataIndex, value },
            (filter) => filter.dataIndex === dataIndex
          );
          state[viewType].page = 1;
          state[viewType].preset = false;
        })
      ),
  removeFilter: (viewType) => (dataIndex) =>
    set(
      produce((state) => {
        state[viewType].filters = state[viewType].filters.filter(
          (filter) => filter.dataIndex !== dataIndex
        );
        state[viewType].page = 1;
        state[viewType].preset = false;
      })
    ),
  toggleSortOrder: (viewType) => (dataIndex) =>
    set(
      produce((state) => {
        const index = state[viewType].sortOrder.findIndex(
          (sortOrder) => sortOrder.dataIndex === dataIndex
        );

        if (index < 0) {
          return;
        }

        state[viewType].sortOrder[index].order =
          state[viewType].sortOrder[index].order === "ascend"
            ? "descend"
            : "ascend";
        state[viewType].page = 1;
        state[viewType].preset = false;
      })
    ),
  removeSortOrder: (viewType) => (dataIndex) =>
    set(
      produce((state) => {
        state[viewType].sortOrder = state[viewType].sortOrder.filter(
          (sortOrder) => sortOrder.dataIndex !== dataIndex
        );
        state[viewType].page = 1;
        state[viewType].preset = false;
      })
    ),
  setSortOrder: (viewType) => (sortOrder) =>
    set(
      produce((state) => {
        state[viewType].sortOrder = sortOrder;
        state[viewType].page = 1;
        state[viewType].preset = false;
      })
    ),
  setWidth:
    (viewType) =>
    ({ dataIndex, width }) =>
      set(
        produce((state) => {
          state[viewType].widths = replaceOrAppend(
            state[viewType].widths,
            { dataIndex, width },
            (width) => width.dataIndex === dataIndex
          );
          state[viewType].preset = false;
        })
      ),
  setPagination:
    (viewType) =>
    ({ pageSize, page }) =>
      set(
        produce((state) => {
          if (pageSize && pageSize !== state[viewType].pageSize) {
            state[viewType].pageSize = pageSize;
            state[viewType].page = 1;
          } else {
            state[viewType].page = page;
          }

          state[viewType].preset = false;
        })
      ),
}));
