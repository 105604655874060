export const FUTURE_15_DAYS = "FUTURE_15_DAYS";
export const FUTURE_1_MONTH = "FUTURE_1_MONTH";
export const FUTURE_3_MONTHS = "FUTURE_3_MONTHS";
export const FUTURE_1_YEAR = "FUTURE_1_YEAR";
export const PAST_15_DAYS = "PAST_15_DAYS";
export const PAST_1_MONTH = "PAST_1_MONTH";
export const PAST_3_MONTHS = "PAST_3_MONTHS";
export const PAST_1_YEAR = "PAST_1_YEAR";

export const OPTIONS = {
  [FUTURE_15_DAYS]: "Next 15 Days",
  [FUTURE_1_MONTH]: "Next Month",
  [FUTURE_3_MONTHS]: "Next 3 Months",
  [FUTURE_1_YEAR]: "Next Year",
  [PAST_15_DAYS]: "Last 15 Days",
  [PAST_1_MONTH]: "Last Month",
  [PAST_3_MONTHS]: "Last 3 Months",
  [PAST_1_YEAR]: "Last Year",
};
