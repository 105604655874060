import {
  CloseOutlined,
  PlusCircleOutlined,
  OrderedListOutlined,
} from "@ant-design/icons";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Popover } from "antd";

import { getViewFields } from "@evolved/views";

import { useViewStore } from "../../stores/view";
import { SortableList } from "../sortable-list";
import { useFieldConfigs } from "./use-field-configs";
import { ScrollableContent } from "../scrollable-content";

const Field = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  return (
    <div
      ref={setNodeRef}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <div className="item-center flex">
        <div className="grow cursor-move" {...attributes} {...listeners}>
          <OrderedListOutlined className="mr-2" />
          {props.config.title}
        </div>
        <CloseOutlined
          className="cursor-pointer"
          onClick={() =>
            useViewStore.getState().removeField(props.viewType)(
              props.config.dataIndex
            )
          }
        />
      </div>
    </div>
  );
};

export const FieldSelect = (props) => {
  const fields = useViewStore((state) => state[props.viewType].fields);
  const fieldConfigs = useFieldConfigs(props.viewType);

  return (
    <Popover
      content={
        <>
          <ScrollableContent height="250px">
            <SortableList
              items={fields.reduce((accumulator, field) => {
                const config = fieldConfigs[field];

                if (
                  !config ||
                  getViewFields(props.viewType).FIXED_FIELDS.includes(
                    config.dataIndex
                  )
                ) {
                  return accumulator;
                }

                accumulator.push({
                  id: field,
                  Component: Field,
                  props: { config, viewType: props.viewType },
                });

                return accumulator;
              }, [])}
              setItems={useViewStore.getState().setFields(props.viewType)}
            />
          </ScrollableContent>
          <div
            style={{
              marginTop: "8px",
            }}
          >
            <ScrollableContent height="250px">
              {Object.values(fieldConfigs).reduce((accumulator, config) => {
                if (fields.includes(config.dataIndex)) {
                  return accumulator;
                }

                accumulator.push(
                  <div
                    key={config.dataIndex}
                    onClick={() =>
                      useViewStore.getState().setFields(props.viewType)([
                        ...fields,
                        config.dataIndex,
                      ])
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <PlusCircleOutlined style={{ marginRight: "4px" }} />
                    {config.title}
                  </div>
                );

                return accumulator;
              }, [])}
            </ScrollableContent>
          </div>
        </>
      }
      placement="bottom"
      trigger="hover"
    >
      <Button
        ghost
        icon={<OrderedListOutlined />}
        shape="circle"
        style={{ marginRight: "8px" }}
        type="primary"
      />
    </Popover>
  );
};
