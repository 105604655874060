import { Button } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useUnarchiveSalesProcess } from "data/use-sales-processes";

export const UnarchiveSalesProcess = (props) => {
  const { salesProcess } = props;

  const unarchiveSalesProcess = useUnarchiveSalesProcess();

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      disabled={unarchiveSalesProcess.isLoading}
      onClick={() => unarchiveSalesProcess.mutate({ id: salesProcess._id })}
    >
      Unarchive
    </Button>
  );
};
