import { Button } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useUnarchiveOpportunity } from "data/use-opportunities";

export const UnarchiveOpportunity = (props) => {
  const { opportunity } = props;

  const unarchiveOpportunity = useUnarchiveOpportunity();

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      disabled={unarchiveOpportunity.isLoading}
      onClick={() => unarchiveOpportunity.mutate({ id: opportunity._id })}
    >
      Unarchive
    </Button>
  );
};
