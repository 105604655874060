import { useQueryClient } from "@tanstack/react-query";

import { CreateOpportunityModal } from "components/modals/create-opportunity-modal";
import { OpportunitiesList } from "components/lists/opportunities";
import { root as accountsRoot } from "data/use-accounts";
import { useModal } from "hooks/use-modal";
import { useOpportunities } from "data/use-opportunities";

export const Opportunities = (props) => {
  const { account } = props;

  const queryClient = useQueryClient();
  const opportunities = useOpportunities();

  const { modal, open } = useModal(CreateOpportunityModal);

  return (
    <>
      {modal}
      <OpportunitiesList
        opportunities={opportunities
          .all()
          .filter(({ accountId }) => accountId === account._id)}
        openCreateModal={() =>
          open({
            onCreate: async () => {
              await queryClient.invalidateQueries({ queryKey: [accountsRoot] });
            },
            preselectedAccountId: account._id,
          })
        }
      />
    </>
  );
};
