import { fieldTypes } from "@evolved/constants";

import * as accountState from "./account-state.js";
import * as calculated from "./calculated.js";
import * as date from "./date.js";
import * as dollar from "./dollar.js";
import * as percent from "./percent.js";
import * as range from "./range.js";
import * as select from "./select.js";
import * as set from "./set.js";
import * as text from "./text.js";

const {
  ACCOUNT_STATE,
  ACTIVITY_DATE,
  CALCULATED,
  DATE,
  DOLLAR,
  FOLLOWUP_DATE,
  LINK,
  NUMBER,
  PERCENT,
  RANGE,
  SELECT,
  SET,
  TEXT,
} = fieldTypes;

const COLUMN_FILTERS = {
  [ACCOUNT_STATE]: accountState,
  [ACTIVITY_DATE]: date,
  [CALCULATED]: calculated,
  [DATE]: date,
  [DOLLAR]: dollar,
  [FOLLOWUP_DATE]: date,
  [LINK]: text,
  [NUMBER]: range,
  [PERCENT]: percent,
  [RANGE]: range,
  [SELECT]: select,
  [SET]: set,
  [TEXT]: text,
};

export const getFilterByType = (type) => COLUMN_FILTERS[type];
