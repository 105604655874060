import { Tag } from "antd";
import { select } from "radash";

import { useContacts } from "../../../../../data/use-contacts";
import { Link } from "../../../../link";

export const ContactsSet = (props) => {
  const { value } = props;

  const contacts = useContacts();

  const selected = select(
    value,
    (id) => contacts.byId(id),
    (id) => !!contacts.byId(id)
  );

  if (selected.length === 0) {
    return "-";
  }

  return selected.map((contact) => {
    return (
      <Link link={`/contacts/${contact._id}`} key={contact._id}>
        <Tag style={contact.isArchived ? { opacity: 0.5 } : {}}>
          {contact.alias || `Unidentified Contact (${contact._id})`}
        </Tag>
      </Link>
    );
  });
};
