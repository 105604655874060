import { Button, Col, Row } from "antd";
import { useParams } from "react-router-dom";

import { ActivitiesList } from "components/lists/activities";
import { ContactsList } from "components/lists/contacts";
import { Details } from "./details";
import { Emails } from "components/emails";
import { Files } from "../../components/lists/files/files";
import { FollowupsList } from "components/lists/followups";
import { FunnelState } from "./funnel-state";
import { Header } from "./header";
import { SetContacts } from "./modals/set-contacts";
import { OverrideSalesProcess } from "./modals/override-sales-process";
import { Stream } from "./stream";
import { Tags } from "./tags";
import { UpdateOpportunityDetails } from "./modals/update-details";
import { emailToDefaults } from "components/modals/perform-activity/email-to-defaults";
import { useActivityTypes } from "data/use-activity-types";
import { useContacts } from "data/use-contacts";
import { useModal } from "hooks/use-modal";
import { useOpportunities } from "data/use-opportunities";
import { useOpportunityActivities } from "data/use-activities";
import { useOpportunityFollowups } from "data/use-followups";
import { useOrganization } from "data/use-organization";
import {
  CREATE_OPPORTUNITY_FOLLOWUP_MODAL,
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { withEntityExists } from "../with-entity-exists";

export const OpportunityRoute = withEntityExists(useOpportunities)(() => {
  const id = Number(useParams().id);

  const activityTypes = useActivityTypes();
  const activities = useOpportunityActivities({ _id: id });
  const followups = useOpportunityFollowups({ _id: id });
  const opportunity = useOpportunities().byId(id);
  const organization = useOrganization();
  const contacts = useContacts();

  const showPerformActivity = () =>
    useLayoutStore.getState().open(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, {
      opportunityId: id,
    });

  const showCreateFollowup = () => {
    useLayoutStore.getState().open(CREATE_OPPORTUNITY_FOLLOWUP_MODAL, {
      opportunityId: id,
    });
  };

  const { modal: updateDetailsModal, open: openUpdateDetails } = useModal(
    UpdateOpportunityDetails
  );

  const { modal: setContactsModal, open: openSetContacts } =
    useModal(SetContacts);

  const {
    modal: overrideSalesProcessModal,
    open: openOverrideSalesProcessModal,
  } = useModal(OverrideSalesProcess, ({ opportunity }) => !!opportunity);

  return (
    <div className="w-full">
      {overrideSalesProcessModal}
      {setContactsModal}
      {updateDetailsModal}
      <Header
        openUpdateDetails={() => openUpdateDetails({ opportunity })}
        opportunity={opportunity}
      />
      <Row gutter={48}>
        <Col xs={24} lg={12} xl={12} style={{ marginBottom: "24px" }}>
          <div style={{ marginBottom: "24px" }}>
            <Tags opportunity={opportunity} />
          </div>
          <div style={{ marginBottom: "4px" }}>
            <Details opportunity={opportunity} organization={organization} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <FunnelState
              opportunity={opportunity}
              openOverrideSalesProcess={() =>
                openOverrideSalesProcessModal({ opportunity })
              }
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ContactsList
              contacts={contacts.byIds(opportunity.contacts)}
              onUpdate={() => openSetContacts({ opportunity })}
            />
          </div>
          <Files entityId={id} entityType="OPPORTUNITY" />
          <Stream opportunity={opportunity} />
        </Col>
        <Col xs={24} lg={12} xl={12} style={{ marginBottom: "24px" }}>
          <div style={{ marginBottom: "24px" }}>
            <FollowupsList
              followups={followups}
              hideAccount
              hideOpportunity
              showCreateFollowup={showCreateFollowup}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ActivitiesList
              activities={activities}
              showPerformActivity={showPerformActivity}
            />
          </div>
          <Emails
            contacts={opportunity.contacts}
            onLogActivity={(email, userEmails) =>
              useLayoutStore
                .getState()
                .open(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, {
                  opportunityId: id,
                  defaults: emailToDefaults({
                    activityTypes: activityTypes.all(),
                    contacts: contacts.byIds(opportunity.contacts),
                  })(email, userEmails),
                })
            }
            users={opportunity.users}
          />
        </Col>
      </Row>
    </div>
  );
});
