import { Button } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { styles } from "@evolved/constants";

const { CLICKABLE_STYLE } = styles;

export const NavigateName = (props) => {
  const { name, link, showButton } = props;

  const navigate = useNavigate();

  return (
    <div style={{ display: "flex" }}>
      {name ? (
        <Link style={CLICKABLE_STYLE} to={link}>
          {name}
        </Link>
      ) : (
        <span>--</span>
      )}
      <Button
        onClick={() => navigate(link)}
        size="small"
        style={{ marginLeft: "auto", opacity: showButton ? 1 : 0 }}
      >
        Open
      </Button>
    </div>
  );
};
