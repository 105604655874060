import { message, Select } from "antd";
import { useEffect, useRef } from "react";

import noop from "lodash/noop";

import { getSelectOptions } from "../../../../../utils/get-select-options";
import { useVendors } from "../../../../../data/use-vendors";

export const EditablePartnerSelect = (props) => {
  const {
    isEditing,
    setIsEditing,
    children,
    onUpdate,
    validate = noop,
  } = props;

  const vendors = useVendors();

  const inputRef = useRef();

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const save = async (value) => {
    const error = validate(value);

    if (!error) {
      await onUpdate(value);
      setIsEditing(false);
    } else {
      message.warning(error);
    }
  };

  return isEditing ? (
    <Select
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      onChange={save}
      onBlur={() => {
        setIsEditing(false);
      }}
      open
      optionFilterProp="children"
      options={getSelectOptions({
        store: vendors,
        initialIds: props.value,
      })}
      showSearch
      style={{ width: "100%" }}
      ref={inputRef}
      value={props.value}
    />
  ) : (
    children
  );
};
