import { Button } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useUnarchiveAccount } from "data/use-accounts";

export const UnarchiveAccount = (props) => {
  const { account } = props;

  const unarchiveAccount = useUnarchiveAccount();

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      disabled={unarchiveAccount.isLoading}
      onClick={() => unarchiveAccount.mutate({ id: account._id })}
    >
      Unarchive
    </Button>
  );
};
