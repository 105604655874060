import { Document, Page } from "react-pdf";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal, Pagination } from "antd";
import { useState } from "react";

import { DefaultPreviewBackground } from "./default-preview-background";
import { DelayRender } from "../../delay-render";
import { LoadingPreviewBackground } from "./loading-preview-background";
import { useSignedUrl } from "./use-signed-url";

export const PreviewPdf = ({ file }) => {
  const [open, setOpen] = useState(false);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState(null);

  const isLoading = renderedPageNumber !== pageNumber;

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
  };

  const signedUrl = useSignedUrl(file);

  if (!signedUrl) {
    return <LoadingPreviewBackground />;
  }

  // todo:
  // - [ ] add download button here as well

  return (
    <>
      <Modal
        footer={null}
        open={open}
        onCancel={() => setOpen(false)}
        wrapClassName="flex"
        styles={{
          body: {
            height: "calc(100vh - 96px)",
            minWidth: "812px",
            overflowY: "scroll",
          },
        }}
        style={{ top: 28, height: "calc(100vh - 56px)" }}
        width={"auto"}
      >
        <Document
          file={signedUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading={
            <div className="flex h-full w-full items-center justify-center">
              <DelayRender>
                <LoadingOutlined />
              </DelayRender>
            </div>
          }
        >
          {isLoading && renderedPageNumber ? (
            <Page
              key={renderedPageNumber}
              pageNumber={renderedPageNumber}
              scale={1.5}
            />
          ) : null}
          <Page
            className={`${isLoading ? "hidden" : ""}`}
            key={pageNumber}
            pageNumber={pageNumber}
            onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
            scale={1.5}
          />
        </Document>
        <div
          className="fixed flex justify-center"
          style={{ bottom: "80px", left: 0, right: 0, zIndex: 9999 }}
        >
          <Pagination
            total={numPages}
            pageSize={1}
            current={pageNumber}
            onChange={setPageNumber}
          />
        </div>
      </Modal>
      <DefaultPreviewBackground file={file} onClick={() => setOpen(true)} />
    </>
  );
};
