import { fieldTypes } from "@evolved/constants";

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

const { ACCOUNT_STATE, ACTIVITY_DATE, DATE, FOLLOWUP_DATE, LINK, SET, TEXT } =
  fieldTypes;

export const address = {
  dataIndex: "address",
  title: "Address",
  type: TEXT,
};

export const contacts = {
  collection: "contacts",
  dataIndex: "contacts",
  title: "Contacts",
  type: SET,
};

export const createdOn = {
  dataIndex: "createdOn",
  title: "Added On",
  type: DATE,
};

export const lastActedOn = {
  dataIndex: "lastActedOn",
  title: "Last Acted On",
  type: ACTIVITY_DATE,
};

export const name = {
  dataIndex: "name",
  title: "Name",
  type: TEXT,
  mapUiUrl: (value) => `/accounts/${value}`,
};

export const nextFollowup = {
  dataIndex: "nextFollowupOn",
  title: "Next Followup",
  type: FOLLOWUP_DATE,
};

export const phone = {
  dataIndex: "phone",
  title: "Phone",
  type: TEXT,
};

export const state = {
  dataIndex: "state",
  title: "Status",
  type: ACCOUNT_STATE,
};

export const tags = {
  collection: "tags",
  dataIndex: "tags",
  title: "Tags",
  type: SET,
};

export const users = {
  collection: "users",
  dataIndex: "users",
  title: "Sales Reps",
  type: SET,
};

export const website = {
  dataIndex: "website",
  title: "Website",
  type: LINK,
};

export const COLLECTION = [
  name,
  createdOn,
  nextFollowup,
  lastActedOn,
  address,
  phone,
  contacts,
  state,
  tags,
  users,
  website,
];

export const DEFAULT_FIELDS = [
  "state",
  "contacts",
  "lastActedOn",
  "nextFollowupOn",
  "website",
  "phone",
  "address",
];

export const FIXED_FIELDS = ["name"];

export const MAP = reduceFieldsToMap(COLLECTION);
