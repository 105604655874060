import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import day from "dayjs";
import { useState } from "react";
import { useLocalStorage } from "react-use";

import { dateFilters } from "@evolved/constants";

import { useOrganization } from "../../data/use-organization";

export const THIS_MONTH = "THIS_MONTH";
export const ONE_MONTH = "ONE_MONTH";
export const TWO_MONTHS = "TWO_MONTHS";
export const SIX_MONTHS = "SIX_MONTHS";
export const CALENDAR_YEAR = "CALENDAR_YEAR";

export const COMPANY_TOTALS = "COMPANY_TOTALS";
export const ORGANIZATION_WINS = "ORGANIZATION_WINS";
export const ORGANIZATION_ACTIVITIES = "ORGANIZATION_ACTIVITIES";
export const USER_ACTIVITIES = "USER_ACTIVITIES";
export const PRODUCT_OPPORTUNITIES = "PRODUCT_OPPORTUNITIES";

const {
  FUTURE_15_DAYS,
  FUTURE_1_MONTH,
  FUTURE_3_MONTHS,
  FUTURE_6_MONTHS,
  FUTURE_1_YEAR,
} = dateFilters;

const ranges = {
  [THIS_MONTH]: () => [day().startOf("month"), day()],
  [ONE_MONTH]: (offset) => [
    day()
      .subtract(1 * offset, "month")
      .startOf("month"),
    day()
      .subtract(1 * offset, "month")
      .endOf("month"),
  ],
  [TWO_MONTHS]: (offset) => [
    day()
      .subtract(2 * offset, "month")
      .startOf("month"),
    day()
      .subtract(2 * offset - 1, "month")
      .endOf("month"),
  ],
  [SIX_MONTHS]: (offset) => [
    day()
      .subtract(6 * offset, "month")
      .startOf("month"),
    day()
      .subtract(6 * offset - 5, "month")
      .endOf("month"),
  ],
  [CALENDAR_YEAR]: (offset) => [
    day()
      .subtract(offset - 1, "year")
      .startOf("year"),
    offset === 1
      ? day()
      : day()
          .subtract(1 + (offset - 2), "year")
          .endOf("year"),
  ],
  [FUTURE_15_DAYS]: () => [day(), day().add(15, "days")],
  [FUTURE_1_MONTH]: () => [day(), day().add(1, "month")],
  [FUTURE_3_MONTHS]: () => [day(), day().add(3, "month")],
  [FUTURE_6_MONTHS]: () => [day(), day().add(6, "month")],
  [FUTURE_1_YEAR]: () => [day(), day().add(1, "year")],
};

const supportsOffset = [ONE_MONTH, TWO_MONTHS, SIX_MONTHS, CALENDAR_YEAR];

const FORMATTED_DATES = {
  [THIS_MONTH]: "This Month",
  [ONE_MONTH]: "Last Month",
  [TWO_MONTHS]: "Last 2 Months",
  [SIX_MONTHS]: "Last 6 Months",
  [CALENDAR_YEAR]: "Calendar Year",
  [FUTURE_15_DAYS]: "Next 15 Days",
  [FUTURE_1_MONTH]: "Next Month",
  [FUTURE_3_MONTHS]: "Next 3 Months",
  [FUTURE_6_MONTHS]: "Next 6 Months",
  [FUTURE_1_YEAR]: "Next Year",
};

const getFormattedDate = (range) => {
  if (isEmpty(range)) {
    return "All Time";
  }

  // todo: update to utils/format-date once that is migrated properly to dayjs
  return `${day(range[0]).format("MMM DD YYYY")} - ${day(range[1]).format(
    "MMM DD YYYY"
  )}`;
};

export const getTimeRange = (range, offset) => {
  if (isEmpty(range)) {
    return range;
  }

  let days;

  if (isArray(range)) {
    days = [day(range[0]).startOf("day"), day(range[1]).endOf("day")];
  } else {
    days = (ranges[range] || ranges[ONE_MONTH])(offset);
  }

  return [days[0].startOf("day"), days[1].endOf("day")];
};

export const useDashboardDateFilters = (widget) => {
  const organization = useOrganization();

  const [state, setState] = useLocalStorage(
    `${organization._id}.LAYOUT_STATE.DASHBOARD.DATE_FILTERS`,
    {
      [ORGANIZATION_ACTIVITIES]: ONE_MONTH,
      [ORGANIZATION_WINS]: [],
      [USER_ACTIVITIES]: ONE_MONTH,
      [PRODUCT_OPPORTUNITIES]: [],
    }
  );

  const [offset, setOffset] = useState(1);

  const widgetFilter = getTimeRange(state[widget], offset);

  return {
    widgetFilter,
    widgetFilterLabel: getFormattedDate(widgetFilter),
    widgetFilterState: state[widget],
    supportsOffset: supportsOffset.includes(state[widget]),
    back:
      supportsOffset.includes(state[widget]) &&
      (() => {
        setOffset(offset + 1);
      }),
    forward:
      supportsOffset.includes(state[widget]) &&
      offset > 1 &&
      (() => {
        setOffset(offset - 1);
      }),
    setWidgetFilter: (filter) => {
      setOffset(1);
      setState({
        ...state,
        [widget]: filter,
      });
    },
  };
};
