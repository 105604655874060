import { Button } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";

import { useUnarchiveVendor } from "data/use-vendors";

export const UnarchiveVendor = (props) => {
  const { vendor } = props;

  const unarchiveVendor = useUnarchiveVendor();

  return (
    <Button
      icon={<EyeInvisibleOutlined />}
      disabled={unarchiveVendor.isLoading}
      onClick={() => unarchiveVendor.mutate({ id: vendor._id })}
    >
      Unarchive
    </Button>
  );
};
