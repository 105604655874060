import { Form, Input } from "antd";

import { viewTypes } from "@evolved/constants";

import { useCreateAccount } from "../../data/use-accounts";
import { useOrganization } from "../../data/use-organization";
import { useModal } from "../../hooks/use-modal";
import { useOnChange } from "../../hooks/use-on-change";
import { ContactSelect as ContactSelectBase } from "../selects/contact-select";
import { UserSelect } from "../selects/user-select";
import {
  mapInitialUserDefinedFieldsState,
  mapOutgoingValues,
  UserDefinedFieldInputs,
} from "../user-defined-field-inputs";
import { ContactDetailsModal } from "./contact-details-modal";
import { Modal } from "components/modal";

const { ACCOUNT } = viewTypes;

const ContactSelect = (props) => {
  const { modal, open } = useModal(ContactDetailsModal);

  return (
    <>
      {modal}
      <ContactSelectBase
        mode="multiple"
        label="Contacts"
        name="contactIds"
        extra={
          <div style={{ marginTop: "8px" }}>
            <a
              onClick={() =>
                open({
                  disableRelationshipSelect: true,
                  onCreate: (contact) => {
                    const current = props.form.getFieldValue("contactIds");

                    props.form.setFieldValue("contactIds", [
                      ...current,
                      contact._id,
                    ]);
                  },
                })
              }
            >
              Create
            </a>{" "}
            a new contact
          </div>
        }
      />
    </>
  );
};

export const CreateAccountModal = (props) => {
  const { close, isOpen, onCreate } = props;

  const organization = useOrganization();

  const createAccount = useCreateAccount({
    onSuccess: async (result, { mutation }) => {
      await mutation.onSuccess.call();

      close();

      if (onCreate) {
        await onCreate(result.account);
      }
    },
  });

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        name: "",
        phone: "",
        address: "",
        website: "",
        userIds: [],
        contactIds: [],
        ...mapInitialUserDefinedFieldsState(
          {},
          {
            organization,
            type: ACCOUNT,
          }
        ),
      });
    },
    [isOpen, form]
  );

  return (
    <Modal
      confirmLoading={createAccount.isLoading}
      okText="Create"
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title="Create Account"
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={(values) => {
          createAccount.mutate(mapOutgoingValues(values));
        }}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input />
        </Form.Item>
        <UserSelect label="Sales Reps" name="userIds" mode="multiple" />
        <ContactSelect form={form} />
        <UserDefinedFieldInputs type={ACCOUNT} />
      </Form>
    </Modal>
  );
};
