import get from "lodash/get";

import { useEffect } from "react";
import { usePrevious } from "react-use";

export const useOnItemsCreated = (callback, items) => {
  const latest = get(items, "length", 0);
  const previous = usePrevious(latest);

  useEffect(() => {
    if (previous < latest) {
      return callback();
    }
  }, [latest, previous]);
};
