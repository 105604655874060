import { useState } from "react";

import { useEffectOnce } from "../hooks/use-effect-once";

export const DelayRender = ({ children, delay = 500 }) => {
  const [visible, setVisible] = useState(false);

  useEffectOnce(() => {
    setTimeout(() => {
      setVisible(true);
    }, delay);
  });

  if (!visible) {
    return null;
  }

  return children;
};
