import { accounts } from "./accounts";
import { contacts } from "./contacts";
import { integrations } from "./integrations";
import { opportunities } from "./opportunities";
import { organization } from "./organization";
import { products } from "./products";
import { salesProcesses } from "./sales-processes";
import { vendors } from "./vendors";

export const steps = [
  salesProcesses,
  products,
  accounts,
  contacts,
  opportunities,
  vendors,
  integrations,
  organization,
];
