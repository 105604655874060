import pick from "lodash/pick";
import { Form, Input } from "antd";

import { viewTypes } from "@evolved/constants";
import { Modal } from "components/modal";

import {
  mapInitialUserDefinedFieldsState,
  mapOutgoingValues,
  UserDefinedFieldInputs,
} from "components/user-defined-field-inputs";
import { useOrganization } from "data/use-organization";
import { useUpdateAccount } from "data/use-accounts";
import { useOnChange } from "hooks/use-on-change";

const mapIncomingAccount = (account, organization) => {
  return {
    ...mapInitialUserDefinedFieldsState(account, {
      organization,
      type: viewTypes.ACCOUNT,
    }),
    ...pick(account, ["name", "phone", "website"]),
  };
};

const onSubmit =
  ({ account, updateAccount }) =>
  (values) => {
    updateAccount.mutate(
      mapOutgoingValues({
        id: account._id,
        ...values,
      })
    );
  };

export const UpdateAccountDetails = (props) => {
  const { account, close, isOpen } = props;

  const organization = useOrganization();

  const [form] = Form.useForm();

  const updateAccount = useUpdateAccount({
    onSuccess: close,
  });

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue(mapIncomingAccount(account, organization));
    },
    [isOpen, form, account, organization]
  );

  return (
    <Modal
      confirmLoading={updateAccount.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      open={isOpen}
      title="Details"
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          account,
          updateAccount,
        })}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item label="Address" name="address">
          <Input />
        </Form.Item>
        <Form.Item label="Website" name="website">
          <Input />
        </Form.Item>
        <UserDefinedFieldInputs
          organization={organization}
          type={viewTypes.ACCOUNT}
        />
      </Form>
    </Modal>
  );
};
