import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import striptags from "striptags";
import { intersects } from "radash";

function decodeHTMLEntities(text) {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}

export const emailToDefaults =
  ({ activityTypes = [], contacts = [] }) =>
  (email, userEmails) => {
    const { bcc, cc, created, from, body, subject, to } = email;

    let typeId;

    if (intersects(from || [], userEmails || [])) {
      typeId = find(activityTypes, { label: "Email Sent" })?._id;
    } else {
      typeId = find(activityTypes, { label: "Email Received" })?._id;
    }

    const emails = [
      ...(bcc || []),
      ...(cc || []),
      ...(to || []),
      ...(from || []),
    ];

    const filtered = contacts.filter((contact) =>
      emails.includes(contact.email)
    );

    let summary = body.replace(/<\/div>|<br>|<\/br>/g, "\n\n");

    summary = striptags(summary);

    summary = `Subject: ${subject}\n\n${summary}`
      .replace(/&nbsp;/g, "\n\n")
      .replace(/\n\s*/g, "\n\n")
      .replace(/\n*$/g, "")
      .replace(/&lt;|&gt;/g, "");

    summary = decodeHTMLEntities(summary);

    return {
      ...(!isEmpty(filtered)
        ? { contactIds: filtered.map(({ _id }) => _id) }
        : {}),
      performedOn: moment(created),
      summary,
      ...(typeId ? { typeId } : {}),
    };
  };
