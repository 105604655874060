import { MailOutlined } from "@ant-design/icons";
import { Checkbox, Divider, Row, Select } from "antd";
import get from "lodash/get";

import { userRoles } from "@evolved/constants";
import { InfoCard } from "components/info-card";
import { usePatchScheduledEmails } from "../../data/use-me";

const { ROLES } = userRoles;
const { Option } = Select;

const scheduleValueMap = {
  week: ["every-week", "every-two-weeks"],
  month: ["every-month"],
};

const getScheduleValue = (schedule) => {
  if (!get(scheduleValueMap, [schedule?.period, schedule?.every - 1])) {
    return "every-week";
  }

  return scheduleValueMap[schedule.period][schedule.every - 1];
};

const everyMap = {
  "every-week": {
    period: "week",
    every: 1,
  },
  "every-two-weeks": {
    period: "week",
    every: 2,
  },
  "every-month": {
    period: "month",
    every: 1,
  },
};

export const ScheduledEmails = (props) => {
  const { profile } = props;

  const patchScheduledEmails = usePatchScheduledEmails();

  return (
    <Row gutter={16} type="flex">
      <InfoCard
        content={
          <>
            <div style={{ paddingTop: "24px" }}>
              <strong>Activity Digest</strong>
              <p>
                This weekly email will be sent every Sunday night to greet you
                Monday morning and will include an overview of how you are
                progressing with your sales. Let us know what you think!
              </p>
              <Checkbox
                checked={profile?.scheduledEmails?.digest?.enabled}
                onChange={(e) => {
                  patchScheduledEmails.mutate({
                    scheduledEmails: {
                      digest: { enabled: e.target.checked },
                    },
                  });
                }}
              >
                Send me this email please!
              </Checkbox>
              {profile.role <= ROLES.MANAGER && (
                <>
                  <br />
                  <Checkbox
                    checked={profile?.scheduledEmails?.digest?.sendSalesDigest}
                    onChange={async (e) => {
                      patchScheduledEmails.mutate({
                        scheduledEmails: {
                          digest: { sendSalesDigest: e.target.checked },
                        },
                      });
                    }}
                  >
                    Send me the sales digest in addition to the managers digest
                  </Checkbox>
                </>
              )}
            </div>
            <div style={{ paddingTop: "24px" }}>
              <strong>Followups Reminder</strong>
              <p>
                This email will send you a report every day with followups due
                over the next day and followups that were assigned to you over
                the previous day.
              </p>
              <Checkbox
                checked={profile?.scheduledEmails?.followupsReminder?.enabled}
                onChange={async (e) => {
                  patchScheduledEmails.mutate({
                    scheduledEmails: {
                      followupsReminder: { enabled: e.target.checked },
                    },
                  });
                }}
              >
                Send me this email please!
              </Checkbox>
            </div>
            <div style={{ paddingTop: "24px" }}>
              <strong>Poke</strong>
            </div>
            <div className="mt-2" style={{ display: "flex", flexWrap: "wrap" }}>
              This email will remind you every selected period of inactivity.
            </div>
            <div className="mt-2">
              <Select
                onChange={async (value) => {
                  patchScheduledEmails.mutate({
                    scheduledEmails: {
                      poke: { schedule: everyMap[value] },
                    },
                  });
                }}
                value={getScheduleValue(
                  profile?.scheduledEmails?.poke?.schedule
                )}
                style={{ width: 200 }}
              >
                <Option value="every-week">Weekly</Option>
                <Option value="every-two-weeks">Every Two Weeks</Option>
                <Option value="every-month">Monthly</Option>
              </Select>{" "}
            </div>
            <div className="mt-2">
              <Checkbox
                checked={profile?.scheduledEmails?.poke?.enabled}
                onChange={async (e) => {
                  patchScheduledEmails.mutate({
                    scheduledEmails: {
                      poke: { enabled: e.target.checked },
                    },
                  });
                }}
              >
                Send me this email please!
              </Checkbox>
            </div>
            <Divider />
            <Checkbox
              checked={profile?.scheduledEmails?.doNotSend}
              onChange={async (e) => {
                patchScheduledEmails.mutate({
                  scheduledEmails: {
                    doNotSend: e.target.checked,
                  },
                });
              }}
            >
              Turn off all scheduled CRM email communication
            </Checkbox>
          </>
        }
        label={
          <div
            style={{
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <MailOutlined style={{ marginRight: "8px" }} />
            <span style={{ fontSize: "16px" }}>Emails</span>
          </div>
        }
      />
    </Row>
  );
};
