import produce from "immer";
import create from "zustand";

const getDefaults = () => {
  return {
    query: "",
    result: null,
  };
};

export const useSearchStore = create((set) => ({
  ACCOUNT: getDefaults(),
  ACTIVITY: getDefaults(),
  CONTACT: getDefaults(),
  OPPORTUNITY: getDefaults(),
  VENDOR: getDefaults(),

  setQuery: (viewType) => (query) =>
    set(
      produce((state) => {
        state[viewType].query = query;
      })
    ),

  setResult: (viewType) => (result) =>
    set(
      produce((state) => {
        state[viewType].result = result;
      })
    ),

  reset: (viewType) => () =>
    set(
      produce((state) => {
        state[viewType] = getDefaults();
      })
    ),
}));
