import { notification } from "antd";
import { useContext, useMemo } from "react";
import { v4 as uuid } from "uuid";

import { parseError } from "../../components/support-message";
import { SupportMessage } from "../../components/support-message";
import { NotificationContext } from "./context";

const buildNotification = (api) => {
  const support = (error, options = {}) => {
    if (!error) {
      return;
    }

    const key = uuid();

    api.error({
      duration: 5,
      key,
      description: (
        <SupportMessage
          onClick={() => api.destroy(key)}
          error={error}
          retry={options.retry}
          silenceEmail={!!options.silenceEmail}
        />
      ),
    });
  };

  const error = (err) => {
    const key = uuid();

    api.error({
      duration: 5,
      key,
      message: parseError(err),
    });
  };

  return {
    ...api,
    error,
    support,
  };
};

// NOTE: This should only be rendered once, at a top level
export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  const value = useMemo(() => buildNotification(api), [api]);

  return (
    <NotificationContext.Provider value={value}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
