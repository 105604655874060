import { ClockCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import day from "dayjs";

import { colors } from "@evolved/constants";

import { long } from "utils/format-date";

const { EMERGENCY, INCOMPLETE, SECONDARY, SUCCESS, URGENT, WHITE } = colors;

const getActivityColor = (date) => {
  const test = day.unix(date);
  const today = day();
  const yesterday = day().subtract(1, "day");
  const oneWeekBeforeNow = day().subtract(1, "week");
  const oneMonthBeforeNow = day().subtract(1, "month");
  const sixMonthsAgo = day().subtract(6, "month");

  if (test.isAfter(today, "day")) return INCOMPLETE;
  if (test.isSame(today, "day")) return SUCCESS;
  if (test.isBetween(oneWeekBeforeNow, yesterday, "day", "[]"))
    return SECONDARY;
  if (test.isBetween(oneMonthBeforeNow, oneWeekBeforeNow, "day", "(]"))
    return URGENT;
  if (test.isBefore(sixMonthsAgo, "day")) return INCOMPLETE;

  return EMERGENCY;
};

export const ActivityDate = (props) => {
  const { date, hasColor = true } = props;

  return (
    <Tag
      style={{
        ...(hasColor
          ? {
              backgroundColor: getActivityColor(date),
              color: WHITE,
              border: "none",
            }
          : {}),
        fontWeight: 700,
      }}
    >
      <ClockCircleOutlined style={{ marginRight: "5px" }} />
      {long(date)}
    </Tag>
  );
};
