import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import last from "lodash/last";
import reduce from "lodash/reduce";
import sortBy from "lodash/sortBy";
import { useNavigate } from "react-router-dom";

import { BorderOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Statistic } from "antd";

import { colors, styles } from "@evolved/constants";

import { ActivityCountPie } from "components/activity-count-pie";
import { InitialActivity } from "./initial-activity";
import { LatestActivity } from "./latest-activity";
import { NextFollowup } from "./next-followup";
import { ScheduleNextFollowup } from "./schedule-next-followup";

import { useOpportunityActivities } from "../../../data/use-activities";
import { useActivityTypes } from "../../../data/use-activity-types";
import { useOpportunityFollowups } from "../../../data/use-followups";
import { useOpportunitySteps } from "../../../data/use-opportunities";
import {
  CREATE_OPPORTUNITY_FOLLOWUP_MODAL,
  PERFORM_OPPORTUNITY_ACTIVITY_MODAL,
  useLayoutStore,
} from "../../../stores/layout";

const { INCOMPLETE } = colors;
const { CLICKABLE_STYLE } = styles;

export const OpportunitySummary = (props) => {
  const { _id } = props;

  const navigate = useNavigate();

  const activities = useOpportunityActivities({ _id });
  const followups = useOpportunityFollowups({ _id });
  const activityTypes = useActivityTypes();

  const steps = useOpportunitySteps(_id);

  const showPerformActivity = ({ defaults } = {}) => {
    useLayoutStore.getState().open(PERFORM_OPPORTUNITY_ACTIVITY_MODAL, {
      opportunityId: _id,
      defaults,
    });
  };

  const showCreateFollowup = () => {
    useLayoutStore.getState().open(CREATE_OPPORTUNITY_FOLLOWUP_MODAL, {
      opportunityId: _id,
    });
  };

  const activityTypeGroups = reduce(
    activities,
    (accumulator, activity) => {
      if (!activity?.typeId) {
        return accumulator;
      }

      if (!accumulator[activity.typeId]) {
        const type = activityTypes.byId(activity.typeId);

        if (!type) {
          return accumulator;
        }

        accumulator[activity.typeId] = {
          _id: type._id,
          name: type.label,
          count: 0,
        };
      }

      accumulator[activity.typeId].count =
        accumulator[activity.typeId].count + 1;

      return accumulator;
    },
    {}
  );

  const nextStep = isEmpty(steps)
    ? null
    : reduce(
        steps,
        (accumulator, step) => {
          if (step.isComplete) {
            return null;
          }

          return accumulator || step;
        },
        null
      );

  const activity = last(sortBy(activities, "performedOn"));
  const followup = first(sortBy(followups, "dueOn"));

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 16px",
          marginRight: "24px",
        }}
      >
        <div style={{ marginBottom: "24px" }}>
          {!followup && (
            <ScheduleNextFollowup showCreateFollowup={showCreateFollowup} />
          )}
          {followup && <NextFollowup followup={followup} />}
          <Button
            onClick={showCreateFollowup}
            ghost
            type="primary"
            style={{ width: "100%", height: "36px", marginTop: "16px" }}
          >
            Schedule Followup
          </Button>
        </div>
        <div>
          {activity && <LatestActivity activity={activity} />}
          {!activity && (
            <InitialActivity showPerformActivity={showPerformActivity} />
          )}
          <Button
            ghost
            onClick={showPerformActivity}
            style={{ width: "100%", height: "36px", marginTop: "16px" }}
            type="primary"
          >
            Log Activity
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "8px 18px 18px 18px",
        }}
      >
        <Button
          onClick={() => navigate(`/opportunities/${_id}`)}
          style={{ marginBottom: "16px", width: "100%", height: "36px" }}
        >
          Details
          <RightOutlined />
        </Button>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Statistic
            title="Logged Activities"
            value={activities.length}
            style={{ marginBottom: "16px" }}
          />
        </div>
        {nextStep && (
          <>
            <div style={{ marginBottom: "8px" }}>
              <strong>Next Step</strong>
            </div>
            <div
              onClick={() =>
                showPerformActivity({
                  defaults: {
                    completedSteps: [nextStep._id],
                  },
                })
              }
              style={{
                ...CLICKABLE_STYLE,
                marginBottom: "16px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <BorderOutlined
                style={{ color: INCOMPLETE, marginRight: "8px" }}
              />
              <div>{nextStep.question}</div>
            </div>
          </>
        )}
        <div style={{ padding: "18px" }}>
          <ActivityCountPie counts={Object.values(activityTypeGroups)} />
        </div>
      </div>
    </div>
  );
};
