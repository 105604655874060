import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import reverse from "lodash/reverse";
import moment from "moment";

import { Badge, Collapse } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

import { colors } from "@evolved/constants";

import { useAssignedFollowups } from "../../../data/use-followups";
import { List } from "./list";

const { INCOMPLETE, SECONDARY } = colors;

export const FUTURE = "FUTURE";
export const PAST = "PAST";
export const TODAY = "TODAY";

const getDateGroup = (date) => {
  const today = moment();

  if (date.isBefore(today, "day")) return PAST;
  if (date.isSame(today, "day")) return TODAY;
  if (date.isAfter(today, "day")) return FUTURE;
};

const getFollowupGroup = (followup) => {
  const { dueOn } = followup;

  return getDateGroup(moment.unix(dueOn));
};

const groupFollowups = (followups) => {
  followups = orderBy(followups, "dueOn");

  const grouped = groupBy(followups, getFollowupGroup);

  return {
    ...grouped,
    [PAST]: reverse(grouped[PAST]),
  };
};

const renderHeader = (followups = [], title, backgroundColor) => {
  return (
    <>
      {title}
      <Badge
        count={followups.length}
        overflowCount={999}
        style={{ backgroundColor, marginLeft: "8px" }}
      />
    </>
  );
};

// todo: clean up onClose cases if we always want to show the button -
// pending testing from Chris and Andrew
export const Followups = ({ onClose, isOpen }) => {
  const assignedFollowups = useAssignedFollowups();
  const grouped = groupFollowups(assignedFollowups);

  const [tab, selectTab] = useState([TODAY]);

  const [heightStyle, setHeightStyle] = useState(
    `calc((100vh - ${54 * 3}px) / ${tab.length})`
  );

  useEffect(() => {
    // 54 = estimated tab title height
    setHeightStyle(
      onClose
        ? `calc((100vh - ${54 * 3}px - 64px) / ${tab.length})`
        : `calc((100vh - ${54 * 3}px) / ${tab.length})`
    );
  }, [onClose, tab]);

  return (
    <div id="notifications-sider-followups">
      {onClose && isOpen && (
        <div
          onClick={onClose}
          style={{
            backgroundColor: "#002140",
            height: "64px",
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          <Badge
            count={assignedFollowups.length}
            overflowCount={999}
            style={{
              backgroundColor: colors.PRIMARY,
              marginRight: "8px",
              boxShadow: "none",
            }}
          />
          <div style={{ marginRight: "8px" }}>Assigned Followups</div>
          <RightOutlined />
        </div>
      )}
      <Collapse
        activeKey={tab}
        bordered={false}
        onChange={selectTab}
        style={{
          height: onClose ? "calc(100vh - 64px)" : "100vh",
          overflow: "scroll",
        }}
        items={[
          {
            key: PAST,
            label: renderHeader(grouped[PAST], "Past", INCOMPLETE),
            children: (
              <List
                followupGroups={grouped}
                heightStyle={heightStyle}
                selectTab={(tab) => selectTab([tab])}
                type={PAST}
              />
            ),
          },
          {
            key: TODAY,
            label: renderHeader(grouped[TODAY], "Today", SECONDARY),
            children: (
              <List
                followupGroups={grouped}
                heightStyle={heightStyle}
                selectTab={(tab) => selectTab([tab])}
                type={TODAY}
              />
            ),
          },
          {
            key: FUTURE,
            label: renderHeader(grouped[FUTURE], "Future", INCOMPLETE),
            children: (
              <List
                followupGroups={grouped}
                heightStyle={heightStyle}
                selectTab={(tab) => selectTab([tab])}
                type={FUTURE}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
