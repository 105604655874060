import { ArchiveContact } from "./archive";
import { BackButton } from "components/back-button";
import { DisplayArchived } from "components/display-archived";
import { EditButton } from "components/edit-button";
import { UnarchiveContact } from "./unarchive";

const style = {
  container: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "16px",
  },
  header: { margin: "0px 16px 0px 0px" },
};

export const Header = (props) => {
  const { contact, openUpdateDetails } = props;
  const { alias } = contact;

  return (
    <>
      <div className="space-y-2" style={style.container}>
        <div className="mr-auto flex flex-wrap items-center">
          <BackButton default={"/contacts"} />
          <h2 style={style.header}>{alias || "--"}</h2>
          <DisplayArchived entity={contact} />
        </div>
        <div className="flex flex-wrap items-center">
          <EditButton
            onClick={openUpdateDetails}
            style={{ marginRight: "8px" }}
          />
          {!contact.isArchived ? (
            <ArchiveContact contact={contact} />
          ) : (
            <UnarchiveContact contact={contact} />
          )}
        </div>
      </div>
    </>
  );
};
