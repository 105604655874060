import { styles } from "@evolved/constants";

const { CLICKABLE_STYLE } = styles;

export const Metadata = ({ Icon, isLink, style, value }) => {
  return (
    <div
      style={{
        color: "rgba(0, 0, 0, 0.5)",
        fontSize: "12px",
        marginRight: "16px",
        ...(isLink ? CLICKABLE_STYLE : {}),
        ...style,
      }}
    >
      {Icon && <Icon style={{ marginRight: "4px" }} />}
      {value}
    </div>
  );
};
