import { Card } from "antd";

export const WidgetContainer = (props) => {
  const { children } = props;

  return (
    <div className="flex justify-center">
      <Card style={{ breakInside: "avoid", flexGrow: 1, width: "100%" }}>
        {children}
      </Card>
    </div>
  );
};
