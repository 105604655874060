import { LoadingOutlined } from "@ant-design/icons";
import { DatePicker } from "antd";
import day from "dayjs";
import { useState } from "react";

export const EditableDateCell = (props) => {
  const { isEditing, setIsEditing, children, onUpdate } = props;

  const [pendingValue, setPendingValue] = useState(null);

  const save = async (value) => {
    setPendingValue(value);

    const isSuccess = await onUpdate(value.unix());

    if (isSuccess) {
      setIsEditing(false);
    }

    setPendingValue(null);
  };

  return isEditing ? (
    <DatePicker
      defaultValue={day.unix(props.value)}
      defaultPickerValue={day.unix(props.value)}
      disabled={!!pendingValue}
      onChange={save}
      onOpenChange={(isOpen) => {
        if (!pendingValue) {
          setIsEditing(isOpen);
        }
      }}
      open={isEditing}
      {...(pendingValue
        ? { suffixIcon: <LoadingOutlined />, value: pendingValue }
        : {})}
    />
  ) : (
    children
  );
};
