import isArray from "lodash/isArray.js";
import isEmpty from "lodash/isEmpty.js";
import isString from "lodash/isString.js";

const prepare = (string) => string.toString().toLowerCase();

export const filter = (value, filter) =>
  filter.some((f) => prepare(value || "").includes(prepare(f)));

export const validate = (filter) => {
  if (!isArray) return false;

  return !isEmpty(filter) && filter.every(isString);
};
