import c from "classnames";

import Logo from "../assets/logo.png";

export const LogoLarge = ({ className }) => {
  return (
    <div className={c(className, "flex items-center justify-center")}>
      <img className="w-1/5" src={Logo} alt="CRM Logo" />
      <p className=" ml-4 mb-0 text-left text-3xl font-semibold dark:text-white">
        Evolved Metrics CRM
      </p>
    </div>
  );
};
