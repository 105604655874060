import { Select } from "antd";

import { filterOption } from "./filter-option";

export const SearchSelect = (props) => {
  return (
    <Select
      showSearch
      optionFilterProp="children"
      filterOption={filterOption}
      {...props}
    />
  );
};
