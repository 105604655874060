import { select } from "radash";

import { useAccounts } from "data/use-accounts";

export const AccountsSet = (props) => {
  const { value } = props;

  const accounts = useAccounts();

  return select(
    value,
    (id) => accounts.byId(id).name,
    (id) => !!accounts.byId(id)
  ).join(", ");
};
