import { useQuery, useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";

export const root = "/files";

export const useAllActivities = buildUseEntities([root, "/all"]);

export const useFiles = ({ entityType, entityId }) => {
  const queryFn = fetcher.get(`${root}/${entityType}/${entityId}`);

  const { data } = useQuery({
    queryFn,
    queryKey: [root, entityType, entityId],
  });

  return data || [];
};

export const useUpdateFileName = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        const { entityType, entityId, bucketKey, name } = data;

        return fetcher.patch(`${root}/${entityType}/${entityId}/${bucketKey}`)({
          name,
        });
      },
      onSuccess: async (data, { variables }) => {
        const { entityType, entityId } = variables;

        await queryClient.invalidateQueries({
          queryKey: [root, entityType, entityId],
        });
      },
    },
    overrides
  );
};

export const useDeleteFile = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        const { entityType, entityId, bucketKey } = data;

        return fetcher.del(`${root}/${entityType}/${entityId}/${bucketKey}`)();
      },
      onSuccess: async (data, { variables }) => {
        const { entityType, entityId } = variables;

        await queryClient.invalidateQueries({
          queryKey: [root, entityType, entityId],
        });
      },
    },
    overrides
  );
};
