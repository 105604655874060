import { message, Select } from "antd";
import { useEffect, useRef, useState } from "react";

import noop from "lodash/noop";

import { getSelectOptions } from "../../../../../utils/get-select-options";
import { useAccounts } from "../../../../../data/use-accounts";

export const EditableAccountSelect = (props) => {
  const {
    isEditing,
    setIsEditing,
    children,
    onUpdate,
    validate = noop,
  } = props;

  const [pendingValue, setPendingValue] = useState(null);

  const accounts = useAccounts();
  const inputRef = useRef();

  useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const save = async (value) => {
    const error = validate(value);

    if (!error) {
      setPendingValue(value);

      const isSuccess = await onUpdate(value);

      if (isSuccess) {
        setIsEditing(false);
      }

      setPendingValue(null);
    } else {
      message.warning(error);
    }
  };

  return isEditing ? (
    <Select
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      loading={!!pendingValue}
      onChange={save}
      onBlur={() => {
        setIsEditing(false);
      }}
      open={!pendingValue}
      optionFilterProp="children"
      options={getSelectOptions({
        store: accounts,
        initialIds: props.value,
      })}
      ref={inputRef}
      showSearch={!pendingValue}
      style={{ width: "100%" }}
      value={pendingValue || props.value}
    />
  ) : (
    children
  );
};
