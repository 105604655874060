import { ExpandAltOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { memo } from "react";

import { Cell } from "./cell";
import { Drawer } from "./drawer";
import { styles } from "./styles";

export const Row = memo((props) => {
  const { columns, fillerWidth, isOpen, row, toggleOpen, viewType } = props;

  let fixedLeft = 48;

  const fixed = columns.fixed.map((column) => {
    const left = fixedLeft;
    fixedLeft = fixedLeft + column.width;

    return (
      <Cell
        column={column}
        key={column.dataIndex}
        row={row}
        style={{
          ...styles.cell,
          left,
          maxWidth: column.width,
          minWidth: column.width,
          position: "sticky",
          zIndex: 1,
        }}
        viewType={viewType}
      />
    );
  });

  const scrollable = columns.scrollable.map((column) => {
    return (
      <Cell
        column={column}
        key={column.dataIndex}
        row={row}
        style={{
          ...styles.cell,
          maxWidth: column.width,
          minWidth: column.width,
          position: "relative",
        }}
        viewType={viewType}
      />
    );
  });

  return (
    <>
      <tr>
        <td
          style={{
            ...styles.cell,
            left: 0,
            maxWidth: 48,
            minWidth: 48,
            position: "sticky",
            zIndex: 1,
            textAlign: "center",
          }}
        >
          <Button
            icon={<ExpandAltOutlined />}
            onClick={toggleOpen}
            size="small"
          />
        </td>
        {fixed}
        {scrollable}
        <td
          style={{
            ...styles.cell,
            maxWidth: fillerWidth,
            minWidth: fillerWidth,
            position: "relative",
          }}
        />
      </tr>
      {isOpen ? (
        <Drawer columns={columns} row={row} viewType={viewType} />
      ) : null}
    </>
  );
});
