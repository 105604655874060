import filter from "lodash/filter";

export const filterVendorContacts = ({ vendorId, contacts }) => {
  if (!vendorId) {
    return [];
  }

  return filter(contacts, (contact) => {
    return contact.vendorId === vendorId;
  });
};
