import find from "lodash/find";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  CheckCircleOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, List, Popconfirm, Popover, Tooltip } from "antd";

import { useModal } from "hooks/use-modal";
import { MergeUsersModal } from "./modals/merge-users-modal";
import { ReassignOwnershipModal } from "./modals/reassign-ownership-modal";
import { UserDetailsModal } from "./modals/user-details";

import { hasAccess } from "utils/has-access";
import { useArchiveUser, useUsers } from "data/use-users";
import { useMe } from "data/use-me";
import { withPermission } from "components/with-permission";

import { colors, userRoles } from "@evolved/constants";

const { display, ROLES } = userRoles;
const { INCOMPLETE, PRIMARY } = colors;

const Extras = ({ profile }) => {
  const { modal: mergeUserModal, open: openMergeUserModal } =
    useModal(MergeUsersModal);

  const { modal: reassignOwnershipModal, open: openReassignOwnershipModal } =
    useModal(ReassignOwnershipModal);

  const [open, setOpen] = useState(false);

  return (
    <>
      {mergeUserModal}
      {reassignOwnershipModal}
      <Popover
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button
              size="small"
              onClick={() => {
                setOpen(false);
                openMergeUserModal();
              }}
              style={{ marginBottom: "8px" }}
            >
              Merge Users
            </Button>
            <Tooltip
              title={
                profile.role > ROLES.OWNER
                  ? "Only the owner can reassign this role."
                  : null
              }
            >
              <Button
                disabled={profile.role > ROLES.OWNER}
                size="small"
                onClick={() => {
                  setOpen(false);
                  openReassignOwnershipModal({ disableSpinner: true });
                }}
              >
                Reassign Ownership
              </Button>
            </Tooltip>
          </div>
        }
        open={open}
        onOpenChange={setOpen}
        placement={"bottom"}
      >
        <Button
          icon={<MoreOutlined />}
          style={{ marginLeft: "auto", marginRight: "8px" }}
        />
      </Popover>
    </>
  );
};

const Header = (props) => {
  const { openUserDetailsModal, organization, profile, users } = props;

  const canAdd = hasAccess(profile)(organization)([
    "organization:billing",
    "organization:users",
  ])();

  return (
    <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
      <Badge
        count={users.length}
        showZero
        style={{ backgroundColor: PRIMARY }}
      />
      <span style={{ marginLeft: "16px" }}>Users</span>
      <Extras profile={profile} />
      <Tooltip
        title={
          !canAdd &&
          "Organization Billing permissions required to add sales reps."
        }
      >
        <Button
          disabled={!canAdd}
          ghost
          onClick={openUserDetailsModal}
          type="primary"
        >
          <PlusCircleOutlined style={{ margin: "0px" }} />
        </Button>
      </Tooltip>
    </div>
  );
};

export const Users = withPermission("organization:users")((props) => {
  const { organization } = props;

  const navigate = useNavigate();

  const { open, modal } = useModal(UserDetailsModal);

  const archiveUser = useArchiveUser();
  const profile = useMe();
  const users = useUsers().all({ includeArchived: false });

  const isLicensed = ({ _id }) =>
    find(
      organization?.subscriptionState?.activeUsers,
      (v) => v === Number(_id)
    );

  return (
    <>
      {modal}
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }}>
        <List
          dataSource={users}
          header={
            <Header
              openUserDetailsModal={open}
              organization={organization}
              profile={profile}
              users={users}
            />
          }
          itemLayout={"vertical"}
          pagination={{ pageSize: 5 }}
          renderItem={(user) => {
            const { _id, alias, email } = user;

            return (
              <List.Item key={_id}>
                <div style={{ alignItems: "center", display: "flex" }}>
                  <div>
                    <div>
                      {alias}
                      <span style={{ margin: "0px 4px" }}>|</span>
                      {display(user.role)}
                    </div>
                    {alias !== email && (
                      <div style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        {email}
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginTop: "4px",
                        marginRight: "16px",
                      }}
                    >
                      {organization?.subscriptionState.status === "trialing" ||
                      isLicensed({ _id }) ? (
                        <>
                          <CheckCircleOutlined
                            style={{
                              color: PRIMARY,
                              fontSize: "18px",
                              marginRight: "8px",
                            }}
                          />
                          <small>Has Access</small>
                        </>
                      ) : (
                        <>
                          <StopOutlined
                            style={{
                              color: INCOMPLETE,
                              fontSize: "18px",
                              marginRight: "8px",
                            }}
                          />
                          <a
                            onClick={() =>
                              navigate("/organization?tab=billing")
                            }
                          >
                            <small>No Access</small>
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                  <div style={{ marginLeft: "auto" }}>
                    <span
                      className="primary-clickable"
                      onClick={() => open({ user })}
                    >
                      Update
                    </span>
                    {user.role > profile.role && +user._id !== +profile._id ? (
                      <>
                        <span style={{ margin: "0px 4px" }}>|</span>
                        <Popconfirm
                          okText="Yes"
                          onConfirm={() => archiveUser.mutate(user._id)}
                          title="Are you sure?"
                        >
                          <span className="primary-clickable">Archive</span>
                        </Popconfirm>
                      </>
                    ) : (
                      <>
                        <span style={{ margin: "0px 4px" }}>|</span>
                        <Tooltip
                          title={
                            user.role < profile.role
                              ? "Cannot archive user with greater role than you."
                              : "Cannot archive yourself."
                          }
                        >
                          <span style={{ color: "grey" }}>Archive</span>
                        </Tooltip>
                      </>
                    )}
                  </div>
                </div>
              </List.Item>
            );
          }}
        />
      </Card>
    </>
  );
});
