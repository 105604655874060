import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { forwardRef } from "react";
import RocketOutlined from "@ant-design/icons/RocketOutlined";

import { Box } from "../box";
import { BodyHeader } from "../body-header";
import { BodyIcon } from "../body-icon";
import { BodyText } from "../body-text";
import { Spotlight } from "../../spotlight";
import { Step } from "../guide";
import { useOnClick } from "../use-on-click";
import { useOnExists } from "../use-on-exists";
import { useOnItemsCreated } from "../use-on-items-created";

const Guide = (props) => {
  const { guide, isComplete, opportunities = [], patchSetupState } = props;

  const steps = [
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onClose={guide.stop}>
            This is how you get to your opportunities, click here to go there
            now.
          </Step>
        ),
        placement: "right",
      },
      elementId: "opportunities-menu-item",
    },
    {
      tooltip: {
        content: (
          <Step onNext={guide.forward} onClose={guide.stop}>
            Here is where your opportunities will show up after you create them.
          </Step>
        ),
        placement: "top",
      },
      elementId: "OPPORTUNITY-view-table",
    },
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onBack={guide.back} onNext={guide.forward} onClose={guide.stop}>
            This is the primary way to create opportunities, click to add one
            now, or continue to use the import tool.
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "create-opportunity-button",
    },
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step
            onBack={guide.back}
            onNext={() => {
              if (!isComplete) {
                patchSetupState.mutate({
                  isOpportunitiesStepComplete: true,
                });
              }

              guide.go(steps.length);
            }}
            nextText={"Got it"}
            onClose={guide.stop}
          >
            Click here to import your opportunity data if you are coming from
            another system.
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "import-OPPORTUNITY-button",
    },
  ];

  useOnExists(
    guide.forward,
    guide.index === 0 ? "OPPORTUNITY-view-table" : null
  );

  useOnClick(
    () => guide.go(steps.length),
    guide.index === 2 ? "create-opportunity-button" : null
  );

  useOnClick(
    () => guide.go(steps.length),
    guide.index === 3 ? "import-OPPORTUNITY-button" : null
  );

  useOnItemsCreated(guide.complete, opportunities);

  const step = steps[guide.index];

  if (!step) {
    return null;
  }

  return (
    <Spotlight
      allowClicks={step.allowClicks}
      elementId={step.elementId}
      tooltip={step.tooltip}
    />
  );
};

const HeroButton = (props, ref) => {
  const { isComplete, isCurrent, onClick } = props;

  const bodyText = (
    <>
      Opportunities are the primary tracking mechanism for your progress,
      guiding you on the journey to winning sales.
    </>
  );

  return (
    <Box
      isComplete={isComplete}
      isCurrent={isCurrent}
      onClick={onClick}
      ref={ref}
    >
      <BodyIcon
        Icon={RocketOutlined}
        isComplete={isComplete}
        isCurrent={isCurrent}
      />
      <BodyHeader
        isComplete={isComplete}
        isCurrent={isCurrent}
        title={"Opportunities"}
      />
      <BodyText isComplete={isComplete} isCurrent={isCurrent}>
        {bodyText}
      </BodyText>
    </Box>
  );
};

const getStartIndex = () => {
  if ((window.location.href || "").endsWith("/opportunities")) {
    return 1;
  }

  return 0;
};

export const opportunities = {
  Guide,
  HeroButton: forwardRef(HeroButton),

  getStartIndex,
  isComplete: ({ organization, opportunities }) =>
    !isEmpty(opportunities) ||
    get(organization, "setupState.isOpportunitiesStepComplete"),
  mapData: ({ opportunities }) => ({ opportunities }),
  type: "opportunity",
};
