import random from "lodash/random";
import React, { useRef } from "react";

import { styles } from "@evolved/constants";

import { ReactComponent as BuildingBlocksSvg } from "./building-blocks.svg";
import { ReactComponent as CelebrationSvg } from "./celebration.svg";
import { ReactComponent as EmptyBoxSvg } from "./empty-box.svg";
import { ReactComponent as FollowupsCompleteFemaleSvg } from "./followups-complete-female.svg";
import { ReactComponent as FollowupsCompleteMaleSvg } from "./followups-complete-male.svg";
import { ReactComponent as NoDataSvg } from "./no-data.svg";
import { ReactComponent as NotFoundSvg } from "./not-found.svg";
import { ReactComponent as ScheduleFemaleSvg } from "./schedule-female.svg";
import { ReactComponent as ScheduleMaleSvg } from "./schedule-male.svg";
import { ReactComponent as SetupWizardSvg } from "./setup-wizard.svg";
import { ReactComponent as VoidSvg } from "./void.svg";
import { ReactComponent as WelcomeSvg } from "./welcome.svg";

const { PRIMARY_TEXT } = styles;

const Container = ({
  children,
  className,
  containerStyle = {},
  onClick,
  primary,
  secondary,
}) => {
  return (
    <div
      className={className}
      onClick={onClick}
      style={{
        padding: "24px",
        textAlign: "center",
        ...containerStyle,
        ...(onClick ? { cursor: "pointer" } : {}),
      }}
    >
      {children}
      {primary && (
        <p style={{ ...PRIMARY_TEXT, marginTop: "16px", marginBottom: "0px" }}>
          {primary}
        </p>
      )}
      {secondary && (
        <p style={{ ...(primary ? { marginTop: "16px" } : {}) }}>{secondary}</p>
      )}
    </div>
  );
};

export const BuildingBlocks = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <BuildingBlocksSvg style={style} />
    </Container>
  );
};

export const Celebration = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <CelebrationSvg style={style} />
    </Container>
  );
};

export const EmptyBox = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <EmptyBoxSvg style={style} />
    </Container>
  );
};

export const FollowupsComplete = (props) => {
  const { style } = props;

  const svg = useRef(random());

  const FollowupsCompleteSvg = [
    FollowupsCompleteMaleSvg,
    FollowupsCompleteFemaleSvg,
  ][svg.current];

  return (
    <Container {...props}>
      <FollowupsCompleteSvg style={style} />
    </Container>
  );
};

export const NoData = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <NoDataSvg style={style} />
    </Container>
  );
};

export const NotFound = (props) => {
  return (
    <Container {...props}>
      <NotFoundSvg />
    </Container>
  );
};

export const Schedule = React.memo((props) => {
  const { style } = props;

  const ScheduleSvg = [ScheduleMaleSvg, ScheduleFemaleSvg][random()];

  return (
    <Container {...props}>
      <ScheduleSvg style={style} />
    </Container>
  );
});

export const SetupWizard = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <SetupWizardSvg style={style} />
    </Container>
  );
};

export const Void = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <VoidSvg style={style} />
    </Container>
  );
};

export const Welcome = (props) => {
  const { style } = props;

  return (
    <Container {...props}>
      <WelcomeSvg style={style} />
    </Container>
  );
};
