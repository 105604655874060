import { Tags as TagsList } from "components/lists/tags";

import { useTagVendor, useUntagVendor } from "data/use-vendors";

export const Tags = (props) => {
  const { vendor } = props;
  const { tags } = vendor;

  const tagVendor = useTagVendor();
  const untagVendor = useUntagVendor();

  const addTag = {
    isLoading: tagVendor.isLoading,
    mutate: (tagId) => tagVendor.mutate({ id: vendor._id, tagId }),
  };

  const removeTag = {
    isLoading: untagVendor.isLoading,
    mutate: (tagId) => untagVendor.mutate({ id: vendor._id, tagId }),
  };

  return (
    <TagsList
      isSelect
      addTag={addTag}
      removeTag={removeTag}
      selectedTags={tags}
    />
  );
};
