import FilterOutlined from "@ant-design/icons/FilterOutlined";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { forwardRef } from "react";

import { Box } from "../box";
import { BodyHeader } from "../body-header";
import { BodyIcon } from "../body-icon";
import { BodyText } from "../body-text";
import { Spotlight } from "../../spotlight";
import { Step } from "../guide";
import { useOnExists } from "../use-on-exists";
import { useOnItemsCreated } from "../use-on-items-created";

const Guide = (props) => {
  const { guide, isComplete, salesProcesses = [], patchSetupState } = props;

  const steps = [
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onClose={guide.stop}>
            This is how you get to your sales processes, click here to go there
            now.
          </Step>
        ),
        placement: "right",
      },
      elementId: "sales-processes-menu-item",
    },
    {
      tooltip: {
        content: (
          <Step onNext={guide.forward} onClose={guide.stop}>
            Here is where you will create and maintain your sales processes.
          </Step>
        ),
        placement: "top",
      },
      elementId: "sales-process-table",
    },
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onBack={guide.back} onClose={guide.stop}>
            This is the primary way to create sales processes,
            {salesProcesses.length === 0
              ? " click to add one now and go to the next step."
              : " it looks like you already have some. Create some more based on how you sell your product(s)."}
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "create-sales-process-button",
    },
    {
      tooltip: {
        allowClicks: true,
        content: (
          <Step
            onNext={async () => {
              if (!isComplete) {
                patchSetupState.mutate({
                  isSalesProcessesStepComplete: true,
                });
              }

              guide.go(steps.length);
            }}
            nextText={"Got it"}
            onClose={guide.stop}
          >
            You can start from scratch, or customize a template to make it your
            own
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "create-sales-process-modal",
    },
  ];

  useOnExists(guide.forward, guide.index === 0 ? "sales-process-table" : null);

  useOnExists(
    guide.forward,
    guide.index === 2 ? "create-sales-process-modal" : null
  );

  useOnItemsCreated(guide.complete, salesProcesses);

  const step = steps[guide.index];

  if (!step) {
    return null;
  }

  return (
    <Spotlight
      allowClicks={step.allowClicks}
      elementId={step.elementId}
      tooltip={step.tooltip}
    />
  );
};

const HeroButton = (props, ref) => {
  const { isComplete, isCurrent, onClick } = props;

  const bodyText = (
    <>
      Utilize sales processes based on how you sell your product(s). You can
      have one for each product or one process for your whole company.
    </>
  );

  return (
    <Box
      isComplete={isComplete}
      isCurrent={isCurrent}
      onClick={onClick}
      ref={ref}
    >
      <BodyIcon
        Icon={FilterOutlined}
        isComplete={isComplete}
        isCurrent={isCurrent}
      />
      <BodyHeader
        isComplete={isComplete}
        isCurrent={isCurrent}
        title={"Sales Processes"}
      />
      <BodyText isComplete={isComplete} isCurrent={isCurrent}>
        {bodyText}
      </BodyText>
    </Box>
  );
};

const getStartIndex = () => {
  if ((window.location.href || "").endsWith("/sales-processes")) {
    return 1;
  }

  return 0;
};

export const salesProcesses = {
  Guide,
  HeroButton: forwardRef(HeroButton),

  getStartIndex,
  isComplete: ({ organization, salesProcesses }) =>
    !isEmpty(salesProcesses) ||
    get(organization, "setupState.isSalesProcessesStepComplete"),
  mapData: ({ salesProcesses }) => ({ salesProcesses }),
  type: "salesProcess",
};
