import UserOutlined from "@ant-design/icons/UserOutlined";
import get from "lodash/get";
import { forwardRef } from "react";
import { sleep } from "radash";

import { BodyHeader } from "../body-header";
import { BodyIcon } from "../body-icon";
import { BodyText } from "../body-text";
import { Box } from "../box";
import { Spotlight } from "../../spotlight";
import { Step } from "../guide";
import { useOnClick } from "../use-on-click";
import { useOnExists } from "../use-on-exists";

const Guide = (props) => {
  const { guide, isComplete, patchSetupState } = props;

  const steps = [
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onClose={guide.stop}>
            Click here to see your user options menu and select
            &quot;Profile&quot;.
          </Step>
        ),
        placement: "right",
      },
      elementId: "actions-menu-user-dropdown-button",
    },
    null,
    // {
    //   allowClicks: true,
    //   tooltip: {
    //     content: null,
    //     placement: "right",
    //   },
    //   elementId: "actions-menu-user-profile-link",
    // },
    // {
    //   allowClicks: true,
    //   tooltip: {
    //     content: (
    //       <Step
    //         onNext={async () => {
    //           if (!isComplete) {
    //             patchSetupState.mutate({
    //               isIntegrationsStepComplete: true,
    //             });
    //           }

    //           guide.complete();
    //         }}
    //         nextText={"Got it"}
    //         onClose={guide.stop}
    //       >
    //         Click here to connect your email account.
    //       </Step>
    //     ),
    //     placement: "top",
    //   },
    //   elementId: "nylas-config-connect-button",
    // },
    // {
    //   allowClicks: false,
    //   tooltip: {
    //     content: (
    //       <Step
    //         onNext={async () => {
    //           if (!isComplete) {
    //             patchSetupState.mutate({
    //               isIntegrationsStepComplete: true,
    //             });
    //           }

    //           guide.complete();
    //         }}
    //         nextText={"Got it"}
    //         onClose={guide.stop}
    //       >
    //         Looks like you have already integrated your email account. This is
    //         where you can manage it in the future.
    //       </Step>
    //     ),
    //     placement: "top",
    //   },
    //   elementId: "nylas-config-card",
    // },
    {
      allowClicks: false,
      tooltip: {
        content: (
          <Step
            onNext={() => {
              if (!isComplete) {
                patchSetupState.mutate({
                  isIntegrationsStepComplete: true,
                });
              }

              guide.complete();
            }}
            nextText={"Got it"}
            onClose={guide.stop}
          >
            Here is where you integrate your email account. Go ahead and give it
            a try!
          </Step>
        ),
        placement: "top",
      },
      elementId: "nylas-config-card",
    },
  ];

  useOnExists(
    guide.forward,
    guide.index === 0 ? "actions-menu-user-profile-link" : null
  );

  // useOnExists(
  //   guide.forward,
  //   guide.index === 1 ? "nylas-config-connect-button" : null
  // );

  // useOnExists(
  //   () => guide.go(3),
  //   guide.index === 1 ? "nylas-disconnect-button" : null
  // );

  useOnExists(
    async (el) => {
      el.scrollIntoView({ behavior: "smooth" });
      await sleep(500);
      guide.forward();
    },
    guide.index === 1 ? "nylas-config-card" : null
  );

  useOnClick(
    async () => {
      if (!isComplete) {
        patchSetupState.mutate({
          isIntegrationsStepComplete: true,
        });
      }

      guide.complete();
    },
    guide.index === 2 ? "nylas-config-connect-button" : null
  );

  const step = steps[guide.index];

  if (!step) {
    return null;
  }

  return (
    <Spotlight
      allowClicks={step.allowClicks}
      elementId={step.elementId}
      tooltip={step.tooltip}
    />
  );
};

const HeroButton = (props, ref) => {
  const { isComplete, isCurrent, onClick } = props;

  const bodyText = (
    <>
      Learn how to integrate your email. This way you can see all of your
      communication in one place.
    </>
  );

  return (
    <Box
      isComplete={isComplete}
      isCurrent={isCurrent}
      onClick={onClick}
      ref={ref}
    >
      <BodyIcon
        Icon={UserOutlined}
        isComplete={isComplete}
        isCurrent={isCurrent}
      />
      <BodyHeader
        isComplete={isComplete}
        isCurrent={isCurrent}
        title={"Integrations"}
      />
      <BodyText isComplete={isComplete} isCurrent={isCurrent}>
        {bodyText}
      </BodyText>
    </Box>
  );
};

const getStartIndex = () => {
  if ((window.location.href || "").endsWith("/profile")) {
    return 2;
  }

  return 0;
};

export const integrations = {
  Guide,
  HeroButton: forwardRef(HeroButton),

  getStartIndex,
  isComplete: ({ organization }) =>
    get(organization, "setupState.isIntegrationsStepComplete"),
  type: "integrations",
};
