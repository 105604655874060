import { Col, Row } from "antd";
import { useParams } from "react-router-dom";

import { ActivitiesList } from "components/lists/activities";
import { ContactsList } from "components/lists/contacts";
import { Details } from "./details";
import { Emails } from "components/emails";
import { Files } from "../../components/lists/files/files";
import { FollowupsList } from "components/lists/followups";
import { Header } from "./header";
import { Opportunities } from "./opportunities";
import { SetContacts } from "./modals/set-contacts";
import { Tags } from "./tags";
import { emailToDefaults } from "components/modals/perform-activity/email-to-defaults";
import { useAccountActivities } from "data/use-activities";
import { useAccountFollowups } from "data/use-followups";
import { useAccounts } from "data/use-accounts";
import { useActivityTypes } from "data/use-activity-types";
import { useContacts } from "data/use-contacts";
import { useModal } from "hooks/use-modal";
import { useOrganization } from "data/use-organization";
import {
  CREATE_ACCOUNT_FOLLOWUP_MODAL,
  PERFORM_ACCOUNT_ACTIVITY_MODAL,
  useLayoutStore,
} from "stores/layout";
import { withEntityExists } from "../with-entity-exists";

export const AccountRoute = withEntityExists(useAccounts)(() => {
  const id = Number(useParams().id);

  const account = useAccounts().byId(id);
  const activityTypes = useActivityTypes();
  const contacts = useContacts();
  const organization = useOrganization();
  const activities = useAccountActivities({ _id: id });
  const followups = useAccountFollowups({ _id: id });

  const showPerformActivity = () =>
    useLayoutStore.getState().open(PERFORM_ACCOUNT_ACTIVITY_MODAL, {
      accountId: id,
    });

  const showCreateFollowup = () => {
    useLayoutStore.getState().open(CREATE_ACCOUNT_FOLLOWUP_MODAL, {
      accountId: id,
    });
  };

  const { modal: setContactsModal, open: openSetContacts } =
    useModal(SetContacts);

  return (
    <div className="w-full">
      {setContactsModal}
      <Header account={account} />
      <Row gutter={48}>
        <Col xs={24} lg={12} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <Tags account={account} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Details account={account} organization={organization} />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ContactsList
              contacts={contacts.byIds(account.contacts)}
              onUpdate={() => openSetContacts({ account })}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Opportunities account={account} />
          </div>
          <Files entityId={id} entityType="ACCOUNT" />
        </Col>
        <Col xs={24} lg={12} xl={12}>
          <div style={{ marginBottom: "24px" }}>
            <FollowupsList
              followups={followups}
              hideAccount
              showCreateFollowup={showCreateFollowup}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <ActivitiesList
              activities={activities}
              showPerformActivity={showPerformActivity}
            />
          </div>
          <div style={{ marginBottom: "24px" }}>
            <Emails
              contacts={account.contacts}
              onLogActivity={(email, userEmails) =>
                useLayoutStore.getState().open(PERFORM_ACCOUNT_ACTIVITY_MODAL, {
                  accountId: id,
                  defaults: emailToDefaults({
                    activityTypes: activityTypes.all(),
                    contacts: contacts.byIds(account.contacts),
                  })(email, userEmails),
                })
              }
              users={account.users}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
});
