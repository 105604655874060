import { get } from "lodash";

const defaults = [
  { dataIndex: "email" },
  { dataIndex: "title" },
  { dataIndex: "phone" },
];

export const getDisplaySettings = (profile, fieldMap) => {
  const slots = get(profile, "settings.display.contactPreview.slots", defaults);

  return slots.map((slot) => {
    if (fieldMap[slot?.dataIndex]) {
      return slot;
    }

    return { dataIndex: null };
  });
};
