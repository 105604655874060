import moment from "moment";

import { ClockCircleOutlined } from "@ant-design/icons";
import { Tag } from "antd";

import { colors } from "@evolved/constants";

import { detailed, shortWithTime } from "utils/format-date";

const { INCOMPLETE, SECONDARY, SUCCESS, URGENT, WHITE } = colors;

const getFollowupColor = (date) => {
  const test = moment.unix(date);
  const today = moment();
  const tomorrow = moment().add(1, "day");
  const oneWeekFromNow = moment().add(1, "week");

  if (test.isBefore(today, "day")) return URGENT;
  if (test.isSame(today, "day")) return SUCCESS;
  if (test.isBetween(tomorrow, oneWeekFromNow, "day", "[]")) return SECONDARY;
  if (test.isAfter(oneWeekFromNow, "day")) return INCOMPLETE;
};

export const FollowupDate = (props) => {
  const { date } = props;

  return (
    <Tag
      style={{
        backgroundColor: getFollowupColor(date),
        border: "none",
        color: WHITE,
        fontWeight: 700,
      }}
    >
      <ClockCircleOutlined style={{ marginRight: "5px" }} />
      {moment().isSame(moment.unix(date), "year")
        ? shortWithTime(date)
        : detailed(date)}
    </Tag>
  );
};
