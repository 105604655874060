import dayjs from "dayjs";

import { DatePicker as DatePickerBase } from "antd";

import { roundPerformedOn } from "./modals/perform-activity/round-performed-on";

export const DatePicker = (props) => {
  return (
    <DatePickerBase
      allowClear={false}
      format={"YYYY-MM-DD HH:mm"}
      showTime={{
        defaultValue: roundPerformedOn(dayjs()),
        minuteStep: 15,
      }}
      {...props}
    />
  );
};
