import memoize from "lodash/memoize";

import { getColorGenerator } from "./color-generator";

const colors = [
  "#82519A",
  "#F4EB55",
  "#5DA9E9",
  "#3ad48c",
  "#494B99",
  "#F6C658",
  "#E27F66",
];

const getColor = getColorGenerator(colors);

export const getActivityColor = memoize(() => {
  return getColor.next().value;
});
