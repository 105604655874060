import { MoreOutlined } from "@ant-design/icons";
import { Button, Popover } from "antd";

import { withManagerRole } from "../../components/with-role";
import { useModal } from "../../hooks/use-modal";
import { MergeOpportunitiesModal } from "./merge-opportunities-modal";

const Component = () => {
  const { modal: mergeModal, open: openMergeModal } = useModal(
    MergeOpportunitiesModal
  );

  return (
    <>
      {mergeModal}
      <Popover
        content={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Button size="small" onClick={openMergeModal}>
              Merge Opportunities
            </Button>
          </div>
        }
        placement={"bottom"}
      >
        <Button
          ghost
          icon={<MoreOutlined />}
          shape="circle"
          style={{ marginRight: "8px" }}
          type="primary"
        />
      </Popover>
    </>
  );
};

export const Extras = withManagerRole(Component);
