import { Tag } from "antd";

import { styles } from "@evolved/constants";

import { useLossReasons } from "../data/use-loss-reasons";
import { useOpportunityStates } from "../data/use-opportunity-states";

const { STATE_TAG_STYLE } = styles;

const style = {
  lossReason: {
    label: { fontSize: "10px", lineHeight: "10px" },
  },
};

const LossReason = (props) => {
  const { lossReason } = props;

  if (!lossReason) {
    return null;
  }

  return (
    <div {...(props.lossReasonLeft ? { className: "text-right" } : {})}>
      <div style={style.lossReason.label}>Reason</div>
      <div>{lossReason.label}</div>
    </div>
  );
};

export const OpportunityState = ({
  opportunity = {},
  showFunnelProgress,
  style = {},
  lossReasonLeft = false,
  hideLossReason = false,
  marginLeft = "15px",
}) => {
  const { funnelProgress, lossReasonId, stateId } = opportunity;

  const lossReason = useLossReasons().byId(lossReasonId);
  const state = useOpportunityStates().byId(stateId);

  // todo: this should never be the case
  // run a script to fix all the bad data
  if (!state) return null;

  return (
    <>
      {!hideLossReason && lossReasonLeft && (
        <LossReason lossReason={lossReason} lossReasonLeft={lossReasonLeft} />
      )}
      <Tag
        {...(state.color ? { color: state.color } : {})}
        style={{ ...STATE_TAG_STYLE, ...style, marginLeft }}
      >
        {state.label}
        {showFunnelProgress && (
          <span style={{ marginLeft: "5px" }}>{`(${funnelProgress}%)`}</span>
        )}
      </Tag>
      {!hideLossReason && !lossReasonLeft && (
        <LossReason lossReason={lossReason} />
      )}
    </>
  );
};
