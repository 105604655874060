import { Image } from "antd";

import { LoadingPreviewBackground } from "./loading-preview-background";
import { useSignedUrl } from "./use-signed-url";

export const PreviewImage = ({ file }) => {
  const signedUrl = useSignedUrl(file);

  if (!signedUrl) {
    return <LoadingPreviewBackground />;
  }

  return <Image src={signedUrl} width={250} />;
};
