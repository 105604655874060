import { CreateSubscription } from "../subscription";

export const None = ({ organization, users }) => {
  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        You do not have an active subscription.
      </div>
      <CreateSubscription organization={organization} users={users} />
    </>
  );
};
