import { useQuery, useQueryClient } from "@tanstack/react-query";
import filter from "lodash/filter";
import identity from "lodash/identity";

import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation";

const root = "/view";
const queryFn = fetcher.get(root);

export const useViews = (options = {}) => {
  const { data } = useQuery({
    queryFn,
    queryKey: [root],
  });

  const map = options.filter
    ? (data) => filter(data, options.filter)
    : identity;

  return map(data || []);
};

useViews.queryFn = queryFn;
useViews.key = [root];

export const useCreateView = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({
        fields,
        filters,
        includeArchived,
        name,
        pageSize,
        sortOrder,
        type,
        widths,
      }) => {
        return fetcher.post(root)({
          fields,
          filters,
          includeArchived,
          name,
          pageSize,
          sortOrder,
          type,
          widths,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateView = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({
        id,
        fields,
        filters,
        includeArchived,
        sortOrder,
        pageSize,
        widths,
      }) => {
        return fetcher.put(`${root}/${id}/filters`)({
          fields,
          filters,
          includeArchived,
          sortOrder,
          pageSize,
          widths,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateName = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, name }) => {
        return fetcher.put(`${root}/${id}/name`)({ name });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useTogglePublic = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id }) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return fetcher.post(`${root}/${id}/toggle-public`)({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchive = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id }) => {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        return fetcher.put(`${root}/${id}/archive`)({});
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
