import { AccountState } from "components/account-state";
import { ArchiveAccount } from "./archive";
import { BackButton } from "components/back-button";
import { DeleteAccount } from "./delete";
import { DisplayArchived } from "components/display-archived";
import { EditButton } from "components/edit-button";
import { UpdateAccountDetails } from "../modals/update-details";
import { useModal } from "hooks/use-modal";
import { UnarchiveAccount } from "./unarchive";

const style = {
  container: {
    alignItems: "center",
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "16px",
  },
  button: { marginLeft: "auto" },
  header: { margin: "0px 16px 0px 0px" },
};

export const Header = (props) => {
  const { account } = props;
  const { name } = account;

  const { modal, open } = useModal(UpdateAccountDetails);

  return (
    <>
      {modal}
      <div className="space-y-2" style={style.container}>
        <div className="mr-auto flex flex-wrap items-center">
          <BackButton default={"/accounts"} />
          <h2 style={style.header}>{name}</h2>
          <AccountState
            account={account}
            style={{ marginLeft: "0px", marginRight: "8px" }}
          />
          <DisplayArchived entity={account} />
        </div>
        <div className="flex flex-wrap items-center">
          <EditButton
            onClick={() => open({ account })}
            style={{ marginRight: "8px" }}
          />
          {!account.isArchived ? (
            <ArchiveAccount account={account} />
          ) : (
            <>
              <div style={{ marginRight: "8px" }}>
                <UnarchiveAccount account={account} />
              </div>
              <DeleteAccount account={account} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
