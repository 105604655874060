import { useQuery, useQueryClient } from "@tanstack/react-query";

import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation";

export const root = "/me";

export const useMe = (options = {}) => {
  const { data, error, isLoading, refetch } = useQuery({
    ...(options.hasOwnProperty("enabled") ? { enabled: options.enabled } : {}),
    queryKey: [root],
    queryFn: fetcher.get(root, { bypassStatusCodes: [404] }),
    select: (data) => {
      return data?.statusCode === 404 ? null : data;
    },
  });

  if (options.detailed) {
    return { data, error, isLoading, refetch };
  }

  return data;
};

useMe.key = [root];

export const useRequestChangeEmail = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ email }) => {
        return fetcher.post(`${root}/request-change-email`)({ email });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateDefaultView = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ _id, viewType }) => {
        return fetcher.put(`${root}/default-view/${viewType}`)({ _id });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateTimezone = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ timezone }) => {
        return fetcher.put(`${root}/timezone`)({ timezone });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const usePatchUserSettings = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (data) => {
        return fetcher.patch(`${root}/settings`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const usePatchScheduledEmails = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async (data) => {
        return fetcher.patch(`${root}/scheduled-emails`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpsertScheduledReport = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, type, ...data }) => {
        return fetcher.put(`${root}/scheduled-reports/${type}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useDeleteScheduledReport = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, type }) => {
        return fetcher.del(`${root}/scheduled-reports/${type}/${id}`)();
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateCannedReportSettings = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: async ({ id, userDefinedFields }) => {
        return fetcher.put(`${root}/scheduled-reports/canned/${id}/settings`)({
          userDefinedFields,
        });
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
