import { Card } from "antd";

import { Activity } from "components/lists/activities/list";

import { styles } from "./styles";

export const LatestActivity = (props) => {
  const { activity } = props;

  if (!activity) {
    return null;
  }

  return (
    <div style={styles.infoCard.container}>
      <div style={styles.infoCard.title}>
        <strong>Latest Activity</strong>
      </div>
      <Card style={styles.infoCard.body}>
        <Activity activity={activity} />
      </Card>
    </div>
  );
};
