import filter from "lodash/filter";
import moment from "moment";
import { createBreakpoint } from "react-use";
import { useNavigate } from "react-router-dom";

import {
  IssuesCloseOutlined,
  QuestionCircleOutlined,
  ScheduleOutlined,
  ThunderboltOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge, Button, Popover } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";

import { colors, userRoles } from "@evolved/constants";

import logo from "../assets/navbar-logo-light.png";
import smallLogo from "../assets/logo.png";

import { useAssignedFollowups } from "../data/use-followups";
import { useMe } from "../data/use-me";
import { useOrganization } from "../data/use-organization";
import { useInternal } from "../hooks/use-internal";
import { useLogOut } from "../stores/authentication";
import { NOTIFICATIONS_SIDER, useLayoutStore } from "../stores/layout";
import { SetupWizard } from "../components/setup-wizard";

const filterImportantFollowups = (followups) => {
  return filter(followups, (followup) => {
    return moment.unix(followup.dueOn).isSameOrBefore(moment(), "day");
  });
};

const NotificationsButton = () => {
  const organization = useOrganization();
  const assignedFollowups = useAssignedFollowups();
  const isOpen = useLayoutStore((state) => state[NOTIFICATIONS_SIDER].isOpen);

  const trialReminder =
    organization?.subscriptionState?.status === "trialing" &&
    moment(organization?.subscriptionState?.trialPeriodEnd).isSameOrBefore(
      moment().add(1, "day")
    );

  const count =
    filterImportantFollowups(assignedFollowups).length + Number(trialReminder);

  let icon = <ScheduleOutlined />;

  if (count > 0) icon = <IssuesCloseOutlined />;

  return (
    <span onClick={() => useLayoutStore.getState().toggle(NOTIFICATIONS_SIDER)}>
      <Badge
        count={isOpen ? 0 : count}
        style={{ backgroundColor: colors.PRIMARY }}
      >
        <Button
          type="default"
          shape="circle"
          icon={icon}
          ghost
          style={{ marginLeft: "24px" }}
        />
      </Badge>
    </span>
  );
};

const useBreakpoint = createBreakpoint({ sm: 0, md: 612 });

const ActionsMenu = () => {
  const profile = useMe();

  const [showPopover, toggleShowPopover] = useState(false);

  const isInternal = useInternal();
  const logOut = useLogOut();

  const navigate = useNavigate();
  const breakpoint = useBreakpoint();

  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: "#000000",
        display: "flex",
        gridColumnStart: 1,
        gridColumnEnd: 3,
        padding: "0px 24px",
        position: "sticky",
      }}
    >
      <Link to="/dashboard">
        {breakpoint === "md" ? (
          <img
            alt="logo"
            className="cursor-pointer"
            style={{ height: "32px" }}
            src={logo}
          />
        ) : (
          <img
            alt="logo"
            className="cursor-pointer"
            style={{ height: "32px" }}
            src={smallLogo}
          />
        )}
      </Link>
      <div className="ml-auto">
        <SetupWizard />
        <Link to={"/activity-feed"}>
          <Button
            icon={<ThunderboltOutlined />}
            ghost
            style={{
              borderColor: "#ffc53b",
              color: "#ffc53b",
            }}
          >
            Activity Feed
          </Button>
        </Link>
        <NotificationsButton />
        <Popover
          arrow
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div style={{ display: "flex" }}>
                <a
                  className={"primary-clickable"}
                  href="https://evolvedmetrics.com/faq/"
                  rel="noreferrer"
                  target="_blank"
                  style={{ flexGrow: "1", textAlign: "center" }}
                >
                  User Guide
                </a>
                <div>|</div>
                <a
                  className={"primary-clickable"}
                  href="https://www.youtube.com/channel/UC7gblyuosT1dJJLU4zl8oUg"
                  rel="noreferrer"
                  target="_blank"
                  style={{ flexGrow: "1", textAlign: "center" }}
                >
                  YouTube
                </a>
              </div>
            </div>
          }
          overlayStyle={{ width: "250px" }}
          placement="bottomRight"
          trigger="click"
        >
          <Button
            type="default"
            shape="circle"
            icon={<QuestionCircleOutlined />}
            ghost
            style={{ marginLeft: "16px" }}
          />
        </Popover>
        <Popover
          arrow
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <strong style={{ textAlign: "center" }}>{profile.alias}</strong>
              <div style={{ marginBottom: "16px", textAlign: "center" }}>
                {userRoles.display(profile.role)}
              </div>
              {isInternal && (
                <strong
                  className="primary-clickable"
                  onClick={() => navigate("/internal-tools")}
                  style={{ marginBottom: "16px", textAlign: "center" }}
                >
                  Internal Tools
                </strong>
              )}
              <div style={{ display: "flex" }}>
                <Link
                  className={"primary-clickable"}
                  id="actions-menu-user-profile-link"
                  onClick={() => {
                    toggleShowPopover(false);
                  }}
                  to="/profile"
                  style={{ flexGrow: "1", textAlign: "center" }}
                >
                  Profile
                </Link>
                <div>|</div>
                <span
                  className="primary-clickable"
                  onClick={logOut}
                  style={{ flexGrow: "1", textAlign: "center" }}
                >
                  Logout
                </span>
              </div>
            </div>
          }
          destroyTooltipOnHide
          overlayStyle={{ minWidth: "250px" }}
          placement="bottomRight"
          onOpenChange={toggleShowPopover}
          trigger="click"
          open={showPopover}
        >
          <Button
            id="actions-menu-user-dropdown-button"
            type="default"
            shape="circle"
            icon={<UserOutlined />}
            ghost
            style={{ marginLeft: "16px" }}
          />
        </Popover>
      </div>
    </div>
  );
};

export default ActionsMenu;
