import reduce from "lodash/reduce.js";

import { getPartnersLabel } from "@evolved/labels";

import { getViewFields } from "./get-view-fields.js";

export const reduceFieldConfigs = ({ organization, viewType }) => {
  let typeFields = getViewFields(viewType).COLLECTION;

  if (!organization?.settings?.showPartners) {
    typeFields = typeFields.filter(
      ({ dataIndex }) => !dataIndex.startsWith("vendor")
    );
  }

  const userDefinedFields = organization.userDefinedFields;

  return {
    ...reduce(
      userDefinedFields,
      (accumulator, field) => {
        const { id, dataType, name, type, ...rest } = field;

        if (type !== viewType) return accumulator;

        const dataIndex = `userDefinedFields.${id}`;

        accumulator[dataIndex] = {
          ...rest,
          dataIndex,
          title: name,
          type: dataType,
        };

        return accumulator;
      },
      {}
    ),
    ...reduce(
      typeFields,
      (accumulator, field) => {
        const { dataIndex, ...rest } = field;

        let title = field.title;

        if (dataIndex === "vendor.name") {
          title = getPartnersLabel(organization, "singular");
        }

        if (dataIndex === "vendors") {
          title = getPartnersLabel(organization);
        }

        accumulator[dataIndex] = {
          ...rest,
          dataIndex,
          title,
        };

        return accumulator;
      },
      {}
    ),
  };
};
