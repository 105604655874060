import produce from "immer";
import { Select } from "antd";
import { useEffect, useState } from "react";

import { reduceFieldConfigs } from "@evolved/views";

import { getDisplaySettings } from "./get-display-settings";
import { useOrganization } from "data/use-organization";
import { useMe, usePatchUserSettings } from "data/use-me";
import { Modal } from "components/modal";

export const DisplayPreviewModal = (props) => {
  const { close, isOpen } = props;

  const organization = useOrganization();
  const profile = useMe();

  const fieldMap = reduceFieldConfigs({
    organization,
    viewType: "CONTACT",
  });

  const patchUserSettings = usePatchUserSettings({
    onSuccess: close,
  });

  const [fields, setFields] = useState(getDisplaySettings(profile, fieldMap));

  useEffect(() => {
    if (profile) {
      setFields(getDisplaySettings(profile, fieldMap));
    }
  }, [profile]);

  const options = Object.values(fieldMap).map(({ dataIndex, title }) => ({
    label: title,
    value: dataIndex,
  }));

  return (
    <Modal
      confirmLoading={patchUserSettings.isLoading}
      okText="Update"
      onCancel={close}
      open={isOpen}
      title="Contact Display Settings"
      onOk={() =>
        patchUserSettings.mutate({
          display: {
            contactPreview: {
              slots: fields,
            },
          },
        })
      }
    >
      <div style={{ marginBottom: "16px", padding: "8px" }}>
        Select which fields you would like to display in the contact preview.
      </div>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div
          style={{
            width: "40%",
            marginRight: "16px",
            marginBottom: "16px",
            padding: "8px",
          }}
        >
          <strong>Name</strong>
        </div>
        {fields.map((field, key) => (
          <Select
            allowClear
            key={key}
            onChange={(dataIndex) => {
              setFields(
                produce(fields, (draft) => {
                  draft[key] = { dataIndex: dataIndex || null };
                })
              );
            }}
            options={options}
            placeholder={"--"}
            style={{ width: "40%", marginRight: "16px", marginBottom: "16px" }}
            value={field?.dataIndex}
          />
        ))}
      </div>
    </Modal>
  );
};
