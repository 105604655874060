import { ConfigProvider, theme } from "antd";
import { useContext, useMemo } from "react";
import useLocalStorage from "react-use/esm/useLocalStorage";
import useMedia from "react-use/esm/useMedia";

import { ThemeContext } from "./context";
import { validateMessages } from "./validate-messages";

const { darkAlgorithm, defaultAlgorithm } = theme;

const localKey = "layout.theme.preference";

export const themePreferences = {
  dark: "dark",
  light: "light",
  system: "system",
};

const useSystemTheme = () => {
  const isSystemDark = useMedia("(prefers-color-scheme: dark)");

  return isSystemDark ? themePreferences.dark : themePreferences.light;
};

// NOTE: This should only be rendered once, at a top level.
export const ThemeProvider = ({ children }) => {
  const systemTheme = useSystemTheme();

  const [preference, setPreference] = useLocalStorage(
    localKey,
    themePreferences.light
  );

  const theme =
    preference === themePreferences.system ? systemTheme : preference;

  const value = useMemo(
    () => ({ preference, setPreference, theme }),
    [preference, setPreference, theme]
  );

  const themeOptions = {
    algorithm:
      theme === themePreferences.dark ? darkAlgorithm : defaultAlgorithm,
    token: {
      borderRadius: 8,
      colorError: "#fb7185",
      colorLink: "#3482F6",
      colorLinkHover: "#60a5fa",
      colorPrimary: "#6b21a8",
      colorBgLayout: theme === themePreferences.dark ? "#0f172a" : "#f8fafc",
      fontFamily: "Montserrat",
    },
    components: {
      Statistic: {
        contentFontSize: 32,
        titleFontSize: 16,
        lineHeight: 1.3,
      },
    },
  };

  const formOptions = {
    validateMessages,
  };

  return (
    <ConfigProvider theme={themeOptions} form={formOptions}>
      <ThemeContext.Provider value={value}>
        <div className={theme === themePreferences.dark ? "dark" : ""}>
          {children}
        </div>
      </ThemeContext.Provider>
    </ConfigProvider>
  );
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
