import { App, Button, Card, Input, Popconfirm, Popover, Upload } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import { useEffectOnce } from "react-use";
import { useQueryClient } from "@tanstack/react-query";
import { useRef, useState } from "react";

import { Preview } from "./preview";
import { fetchSignedUrl } from "./fetch-signed-url";
import { fetcher } from "../../../data/fetcher";
import { long } from "../../../utils/format-date";
import { root, useFiles, useUpdateFileName } from "../../../data/use-files";
import { useDeleteFile } from "../../../data/use-files";
import { useNotification } from "../../../contexts/notification/use-notification";

// todo:
// - [ ] add auto focus to filename input
// - [ ] add ability to hit enter to submit
// - [ ] add nice fade in animation to files, and a proper title

const allowed = [
  "application/msword",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
  "application/vnd.ms-word.document.macroEnabled.12",
  "application/vnd.ms-word.template.macroEnabled.12",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
  "application/vnd.ms-excel.sheet.macroEnabled.12",
  "application/vnd.ms-excel.template.macroEnabled.12",
  "application/vnd.ms-excel.addin.macroEnabled.12",
  "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-powerpoint",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/vnd.openxmlformats-officedocument.presentationml.template",
  "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
  "application/vnd.ms-powerpoint.addin.macroEnabled.12",
  "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
  "application/vnd.ms-powerpoint.template.macroEnabled.12",
  "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",
  "application/vnd.ms-access",
  "application/pdf",
];

const File = (props) => {
  const { file } = props;

  const notification = useNotification();

  const [updateFileNameOpen, setUpdateFileNameOpen] = useState(false);
  const fileNameInputRef = useRef();

  const deleteFile = useDeleteFile();
  const updateFileName = useUpdateFileName({
    onSuccess: () => {
      setUpdateFileNameOpen(false);
    },
  });

  const onClick = async () => {
    try {
      const url = await fetchSignedUrl(file);

      const response = await fetch(url);
      const blob = await response.blob();

      saveAs(new Blob([blob], { type: file.fileType }), file.name);
    } catch (err) {
      notification.error(err);
    }
  };

  return (
    <div className="flex">
      <Preview file={file} />
      <div className="ml-4">
        <div
          className="cursor-pointer font-bold text-slate-400"
          onClick={onClick}
        >
          {file.name}
        </div>
        <div className=" flex text-slate-900">
          <div>Added {long(file.createdOn)}</div>
          <Popconfirm
            cancelText="Cancel"
            okText="Yes"
            onConfirm={() => deleteFile.mutateAsync(file)}
            title="Are you sure?"
          >
            <DeleteOutlined className="ml-2 cursor-pointer" />
          </Popconfirm>
          <Popover
            open={updateFileNameOpen}
            content={
              <div className="space-y-2">
                <Input defaultValue={file.name} ref={fileNameInputRef} />
                <div className="flex justify-end space-x-1">
                  <Button
                    size="small"
                    onClick={() => setUpdateFileNameOpen(false)}
                    disabled={updateFileName.isLoading}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="small"
                    type="primary"
                    loading={updateFileName.isLoading}
                    onClick={() => {
                      updateFileName.mutate({
                        ...file,
                        name: fileNameInputRef.current.input.value,
                      });
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            }
            destroyTooltipOnHide
          >
            <EditOutlined
              className="ml-2 cursor-pointer"
              onClick={() => setUpdateFileNameOpen(true)}
            />
          </Popover>
        </div>
      </div>
    </div>
  );
};

export const Files = ({ entityId, entityType }) => {
  const files = useFiles({ entityType, entityId });

  const queryClient = useQueryClient();

  const [isDropping, setIsDropping] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const fileCount = useRef(0);
  const { message } = App.useApp();

  const notification = useNotification();

  useEffectOnce(() => {
    const onDragEnd = () => {
      setIsDropping(0);
    };

    window.addEventListener("drop", onDragEnd);

    return () => {
      window.removeEventListener("drop", onDragEnd);
    };
  });

  const completeUpload = async ({ onSuccess }) => {
    onSuccess("ok");
    fileCount.current = fileCount.current - 1;

    if (fileCount.current) return;

    await queryClient.invalidateQueries({
      queryKey: [root, entityType, entityId],
    });

    setIsLoading(false);
  };

  const uploadProps = {
    customRequest: async ({ file, onSuccess }) => {
      if (
        !allowed.includes(file.type) &&
        !file.type.startsWith("image/") &&
        !file.type.startsWith("text/")
      ) {
        message.info(
          `File type ${file.type} not supported for upload. Please reach out to customer support to request adding support.`
        );

        return await completeUpload({ onSuccess });
      }

      try {
        const { bucketKey, url } = await fetcher.post("/files/upload")({
          entityId,
          entityType,
          name: file.name,
          fileType: file.type,
          size: file.size,
        });

        const response = await fetch(url, {
          method: "PUT",
          body: await file.arrayBuffer(),
        });

        if (!response.ok) throw new Error("Upload failed. Please Try again.");

        await fetcher.patch(`/files/${entityType}/${entityId}/${bucketKey}`)({
          status: "uploaded",
        });
      } catch (err) {
        notification.error(err);
      }

      return await completeUpload({ onSuccess });
    },
    beforeUpload: (file, files) => {
      if (fileCount.current) return;

      setIsLoading(true);
      fileCount.current = files.length;
    },
    fileList: [],
    method: "PUT",
    multiple: true,
  };

  return (
    <div
      onDragEnter={(e) => {
        e.preventDefault();
        setIsDropping((v) => v + 1);
      }}
      onDragLeave={(e) => {
        e.preventDefault();
        setIsDropping((v) => (v > 0 ? v - 1 : 0));
      }}
      className="relative"
      style={{ marginBottom: "24px" }}
    >
      {isDropping ? (
        <Upload.Dragger
          className="absolute z-50 h-full w-full rounded-[10px] bg-white"
          {...uploadProps}
        >
          <p className="ant-upload-text">Drop to upload</p>
        </Upload.Dragger>
      ) : null}
      <Card
        bordered={false}
        bodyStyle={{ maxHeight: "500px", overflowY: "scroll" }}
      >
        <div className="absolute right-8 top-0 flex justify-end py-4">
          {isLoading && <LoadingOutlined className="mr-4 text-lg" size="" />}
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
        </div>
        {files.length > 0 ? (
          files.map((file) => (
            <div
              key={`${file.entityType}-${file.entityId}-${file.bucketKey}`}
              className="p-1"
            >
              <File file={file} />
            </div>
          ))
        ) : (
          <div className="text-center">
            <div className="mb-2 text-4xl">
              <InboxOutlined style={{ color: "grey" }} />
            </div>
            <p className="ant-upload-text">
              Drag image or PDF files to this area to upload
            </p>
          </div>
        )}
      </Card>
    </div>
  );
};
