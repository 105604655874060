import { Button, Card, Col, Divider, Row, Table as AntTable } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { ArchiveTag } from "./archive";
import { TagDetailsModal } from "./tag-details-modal";
import { useModal } from "hooks/use-modal";
import { useTags } from "data/use-tags";

const columns = ({ openTagDetails }) => [
  {
    title: "Label",
    dataIndex: "label",
    key: "label",
    sorter: (a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;

      return 0;
    },
  },
  {
    title: "Action",
    key: "action",
    render: (text, tag) => (
      <div style={{ alignItems: "center", display: "flex" }}>
        <div
          className="primary-clickable "
          onClick={() => openTagDetails({ tag })}
        >
          Update Label
        </div>
        <Divider type="vertical" />
        <ArchiveTag tag={tag} />
      </div>
    ),
    width: 200,
  },
];

const style = {
  tableCard: {
    padding: 0,
  },
};

const CreateTag = ({ openTagDetails }) => (
  <Button ghost icon={<PlusOutlined />} onClick={openTagDetails} type="primary">
    Tag
  </Button>
);

export const Tags = () => {
  const tags = useTags().all();

  const { modal: tagDetailsModal, open: openTagDetails } =
    useModal(TagDetailsModal);

  return (
    <div>
      {tagDetailsModal}
      <div className="mb-2 flex">
        <CreateTag openTagDetails={openTagDetails} />
      </div>
      <Card bodyStyle={style.tableCard} bordered={false}>
        <AntTable
          columns={columns({ openTagDetails })}
          dataSource={tags}
          locale={{
            emptyText: "You have no tags, go ahead and create some!",
          }}
          rowKey="_id"
          size="middle"
        />
      </Card>
    </div>
  );
};
