import { Tags as TagsList } from "components/lists/tags";

import { useTagOpportunity, useUntagOpportunity } from "data/use-opportunities";

export const Tags = (props) => {
  const { opportunity } = props;
  const { tags } = opportunity;

  const tagOpportunity = useTagOpportunity();
  const untagOpportunity = useUntagOpportunity();

  const addTag = {
    isLoading: tagOpportunity.isLoading,
    mutate: (tagId) => tagOpportunity.mutate({ id: opportunity._id, tagId }),
  };

  const removeTag = {
    isLoading: untagOpportunity.isLoading,
    mutate: (tagId) => untagOpportunity.mutate({ id: opportunity._id, tagId }),
  };

  return (
    <TagsList
      isSelect
      addTag={addTag}
      removeTag={removeTag}
      selectedTags={tags}
    />
  );
};
