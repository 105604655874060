import create from "zustand";
import produce from "immer";

export const useCommandPalette = create((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) =>
    set(
      produce((state) => {
        state.isOpen = isOpen;
      })
    ),
  toggle: () =>
    set(
      produce((state) => {
        state.isOpen = !state.isOpen;
      })
    ),
}));
