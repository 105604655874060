import { select } from "radash";

import { useVendors } from "data/use-vendors";

export const VendorsSet = (props) => {
  const { value } = props;

  const vendors = useVendors();

  return select(
    value,
    (id) => vendors.byId(id).name,
    (id) => !!vendors.byId(id)
  ).join(", ");
};
