import { SearchSelect } from "components/selects/search-select";
import { useSalesProcesses } from "data/use-sales-processes";
import {
  useOrganization,
  useSetOrganizationSalesProcess,
} from "data/use-organization";

export const SelectSalesProcess = () => {
  const salesProcesses = useSalesProcesses();
  const organization = useOrganization();

  const setSalesProcess = useSetOrganizationSalesProcess();

  return (
    <>
      <div style={{ marginBottom: "8px" }}>
        <strong>Default Sales Process</strong>
      </div>
      <SearchSelect
        allowClear
        disabled={setSalesProcess.isLoading}
        loading={setSalesProcess.isLoading}
        onChange={async (id) => {
          await setSalesProcess.mutate({
            id: id || null,
          });
        }}
        options={salesProcesses.all().map(({ _id, name }) => ({
          label: name,
          value: _id,
        }))}
        placeholder={"Select a default sales process for opportunities"}
        style={{ display: "block" }}
        value={organization.defaultSalesProcessId}
      />
    </>
  );
};
