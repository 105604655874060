export const BodyText = (props) => {
  const { children, isCurrent } = props;

  return (
    <div
      style={{
        ...(!isCurrent ? { color: "rgb(192,192,192)" } : {}),
        fontSize: "18px",
        margin: "8px 0px",
      }}
    >
      {children}
    </div>
  );
};
