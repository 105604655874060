import GlobalOutlined from "@ant-design/icons/GlobalOutlined";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { forwardRef } from "react";

import { getPartnersLabel } from "@evolved/labels";

import { Spotlight } from "../../spotlight";
import { BodyHeader } from "../body-header";
import { BodyIcon } from "../body-icon";
import { BodyText } from "../body-text";
import { Box } from "../box";
import { Step } from "../guide";
import { useOnClick } from "../use-on-click";
import { useOnExists } from "../use-on-exists";
import { useOnItemsCreated } from "../use-on-items-created";

const Guide = (props) => {
  const {
    guide,
    organization,
    isComplete,
    vendors = [],
    patchSetupState,
  } = props;

  const steps = [
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onClose={guide.stop}>
            This is how you get to your {getPartnersLabel(organization)}, click
            here to go there now.
          </Step>
        ),
        placement: "right",
      },
      elementId: "partners-menu-item",
    },
    {
      tooltip: {
        content: (
          <Step onNext={guide.forward} onClose={guide.stop}>
            Here is where your {getPartnersLabel(organization)} will show up
            after you create them.
          </Step>
        ),
        placement: "top",
      },
      elementId: "VENDOR-view-table",
    },
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step onBack={guide.back} onNext={guide.forward} onClose={guide.stop}>
            This is the primary way to create {getPartnersLabel(organization)},
            click to add one now, or continue to use the import tool.
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "create-vendor-button",
    },
    {
      allowClicks: true,
      tooltip: {
        content: (
          <Step
            onBack={guide.back}
            onNext={() => {
              if (!isComplete) {
                patchSetupState.mutate({
                  isVendorsStepComplete: true,
                });
              }

              guide.go(steps.length);
            }}
            nextText={"Got it"}
            onClose={guide.stop}
          >
            Click here to import your{" "}
            {getPartnersLabel(organization, "singular")} data if you are coming
            from another system.
          </Step>
        ),
        placement: "bottom",
      },
      elementId: "import-VENDOR-button",
    },
  ];

  useOnExists(guide.forward, guide.index === 0 ? "VENDOR-view-table" : null);

  useOnClick(
    () => guide.go(steps.length),
    guide.index === 2 ? "create-vendor-button" : null
  );

  useOnClick(
    () => guide.go(steps.length),
    guide.index === 3 ? "import-VENDOR-button" : null
  );

  useOnItemsCreated(guide.complete, vendors);

  const step = steps[guide.index];

  if (!step) {
    return null;
  }

  return (
    <Spotlight
      allowClicks={step.allowClicks}
      elementId={step.elementId}
      tooltip={step.tooltip}
    />
  );
};

const HeroButton = (props, ref) => {
  const { isComplete, isCurrent, onClick, organization } = props;

  const bodyText = (
    <>
      {getPartnersLabel(organization)} are companies that you work with to make
      your sales happen.
    </>
  );

  return (
    <Box
      isComplete={isComplete}
      isCurrent={isCurrent}
      onClick={onClick}
      ref={ref}
    >
      <BodyIcon
        Icon={GlobalOutlined}
        isComplete={isComplete}
        isCurrent={isCurrent}
      />
      <BodyHeader
        isComplete={isComplete}
        isCurrent={isCurrent}
        title={getPartnersLabel(organization)}
      />
      <BodyText isComplete={isComplete} isCurrent={isCurrent}>
        {bodyText}
      </BodyText>
    </Box>
  );
};

const getStartIndex = () => {
  if ((window.location.href || "").endsWith("/partners")) {
    return 1;
  }

  return 0;
};

export const vendors = {
  Guide,
  HeroButton: forwardRef(HeroButton),

  getStartIndex,
  isComplete: ({ organization, vendors }) =>
    !isEmpty(vendors) || get(organization, "setupState.isVendorsStepComplete"),
  mapData: ({ vendors }) => ({ vendors }),
  type: "vendor",
};
