import { MoreOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";

import { withManagerRole } from "components/with-role";

import { ToggleManagerOnly } from "./toggle-manager-only";

const items = [
  {
    key: "1",
    label: <ToggleManagerOnly />,
  },
];

export const Settings = withManagerRole(() => {
  return (
    <Dropdown menu={{ items }}>
      <MoreOutlined style={{ cursor: "pointer", fontSize: "24px" }} />
    </Dropdown>
  );
});
