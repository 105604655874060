import { viewTypes } from "@evolved/constants";
import {
  contactFields as contact,
  opportunityFields as opportunity,
} from "@evolved/views";

const { ACCOUNT, CONTACT, OPPORTUNITY, VENDOR } = viewTypes;

// canCreate false means it will fail if the thing does not exist and prompt the user to go create it first
export const relationships = {
  [ACCOUNT]: [],
  [CONTACT]: [
    { ...contact.account, canCreate: true },
    { ...contact.vendor, canCreate: true },
  ],
  [OPPORTUNITY]: [
    { ...opportunity.account, canCreate: true },
    { ...opportunity.salesProcess, canCreate: false },
    { ...opportunity.initialState, canCreate: false },
  ],
  [VENDOR]: [],
};
