import { Card, Divider, Select, Switch } from "antd";

import {
  useOpportunityStates,
} from "data/use-opportunity-states";

import { useOrganization, usePatchOrganizationSettings } from "data/use-organization";

const Header = () => {
  return (
    <>
      <div style={{ height: "32px", alignItems: "center", display: "flex", width: "100%", marginTop: "8px" }}>
        Opportunity Reporting Settings
      </div>
      <Divider style={{ margin: "8px 0px" }} />
    </>
  );

};

export const filterOption = (input, option) =>
  (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const OpportunityReportingSettings = () => {
  const patchOrganizationSettings = usePatchOrganizationSettings();

  const opportunityStates = useOpportunityStates().all({
    includeArchived: false,
  });

  const organization = useOrganization();

  const stateOptions = opportunityStates.filter(({ systemKey }) => {
    return !["LOST", "WON"].includes(systemKey);
  });

  const selectedStateIds = organization.settings?.winRateOpportunityStateIds ?? stateOptions.map(({ _id }) => _id);

  return (
    <>
      <Card bordered={false} bodyStyle={{ paddingTop: "0px" }} >
        <Header />
        <div>
          Select opportunity stages for the "Total Opportunities" of the Opportunity Win Rate calculation.
        </div>
        <div style={{ margin: "16px 0px 8px 0px" }}>
          <strong>Opportunity Win Rate</strong> = Total Won / Total Opportunities
        </div>
        <div style={{ margin: "0px 0px 16px 0px" }}>
          Note: Lost and Won stages are always part of "Total Opportunities".
        </div>
        <Select
          filterOption={filterOption}
          mode="multiple"
          notFoundContent={"No match"}
          placeholder="Select at least one stage"
          optionFilterProp="children"
          showSearch
          options={stateOptions.map((option) => ({
            value: option._id,
            label: option.label,
          }))}
          value={selectedStateIds}
          loading={patchOrganizationSettings.isLoading}
          onChange={(value) => {
            patchOrganizationSettings.mutate({
              winRateOpportunityStateIds: value,
            });
          }}
          size="small"
          style={{ width: "100%" }}
        />
        <div style={{ display: "flex", marginTop: "24px" }}>
          <strong>Include archived closed opportunities in calculations</strong>
          <Switch
            checked={!!organization?.settings?.opportunityReportingShouldIncludeArchivedClosed}
            loading={patchOrganizationSettings.isLoading}
            onChange={(value) => {
              patchOrganizationSettings.mutate({
                opportunityReportingShouldIncludeArchivedClosed: value,
              });
            }}
            style={{ marginLeft: "16px" }}
          />
        </div>
        <div style={{ margin: "0px 0px 16px 0px" }}>
          A closed opportunity is a Won or Lost opportunity.
        </div>
      </Card>
    </>
  );
};
