import get from "lodash/get";

import { userRoles } from "@evolved/constants";

import { useMe } from "../data/use-me";

const { ROLES } = userRoles;

export const withRole = (role) => (Component) => (props) => {
  const profile = useMe();

  if (get(profile, "role", ROLES.VIEWER) > role) {
    return null;
  }

  return <Component {...props} />;
};

export const withOwnerRole = withRole(ROLES.OWNER);
export const withManagerRole = withRole(ROLES.MANAGER);
export const withSalesRole = withRole(ROLES.SALES);
