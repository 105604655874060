import { useQueryClient } from "@tanstack/react-query";

import { buildUseEntities } from "./build-use-entities.js";
import { fetcher } from "./fetcher";
import { useMutation } from "./use-mutation.js";

export const root = "/product";

export const useProducts = buildUseEntities(root);

export const useCreateProduct = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(root)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useCreateProductBatch = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: (data) => {
        return fetcher.post(`${root}/import`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUpdateProduct = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.put(`${root}/${id}`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useTagProduct = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/tag`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUntagProduct = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/untag`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useArchiveProduct = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/archive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};

export const useUnarchiveProduct = (overrides) => {
  const queryClient = useQueryClient();

  return useMutation(
    {
      mutationFn: ({ id, ...data }) => {
        return fetcher.post(`${root}/${id}/unarchive`)(data);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: [root] });
      },
    },
    overrides
  );
};
