import { styles } from "@evolved/constants";

import {
  useOrganization,
  usePatchOrganizationSettings,
} from "../../../data/use-organization";

const { CLICKABLE_STYLE } = styles;

export const ToggleManagerOnly = () => {
  const organization = useOrganization();

  const patchOrganizationSettings = usePatchOrganizationSettings();

  return (
    <div
      onClick={
        !patchOrganizationSettings.isLoading
          ? () => {
              patchOrganizationSettings.mutate({
                managerOnlyReports: !organization?.settings?.managerOnlyReports,
              });
            }
          : () => {}
      }
      style={CLICKABLE_STYLE}
    >
      {organization?.settings?.managerOnlyReports
        ? "Show One-Click Reports To Everyone"
        : "Show One-Click Reports Only To Managers"}
    </div>
  );
};
