import { DatePicker, Form, Input, InputNumber } from "antd";
import { useWindowSize } from "react-use";
import pick from "lodash/pick";

import { viewTypes } from "@evolved/constants";

import { CreateAccountSelect } from "components/selects/create-account-select";
import {
  mapInitialUserDefinedFieldsState,
  mapOutgoingValues,
  UserDefinedFieldInputs,
} from "components/user-defined-field-inputs";
import { SalesProcessSelect } from "components/selects/sales-process-select";
import { fromUnixDate, toUnixDate } from "utils/unix-date";
import { useOnChange } from "hooks/use-on-change";
import { useOrganization } from "data/use-organization";
import { useUpdateOpportunity } from "data/use-opportunities";
import { Modal } from "components/modal";

const mapIncomingOpportunity = (opportunity, organization) => {
  return {
    accountId: opportunity.accountId,
    salesProcessId: opportunity.salesProcessId,
    ...pick(opportunity, ["alias", "summary", "value"]),
    ...mapInitialUserDefinedFieldsState(opportunity, {
      organization,
      type: viewTypes.OPPORTUNITY,
    }),
    ...fromUnixDate(opportunity, "startedOn"),
    ...fromUnixDate(opportunity, "targetWinDate"),
  };
};

const onSubmit =
  ({ opportunity, updateOpportunity }) =>
  (values) => {
    updateOpportunity.mutate(
      mapOutgoingValues({
        id: opportunity._id,
        ...pick(values, ["alias", "summary", "userDefinedFields", "value"]),
        accountId: values.accountId || null,
        salesProcessId: values.salesProcessId || null,
        ...(values.startedOn
          ? toUnixDate(values, "startedOn")
          : { startedOn: null }),
        ...(values.targetWinDate
          ? toUnixDate(values, "targetWinDate")
          : { targetWinDate: null }),
      })
    );
  };

export const UpdateOpportunityDetails = (props) => {
  const { close, isOpen, opportunity } = props;

  const organization = useOrganization();

  const [form] = Form.useForm();

  // todo: also trigger refreshing use opportunity state
  const updateOpportunity = useUpdateOpportunity({
    onSuccess: close,
  });

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue(mapIncomingOpportunity(opportunity, organization));
    },
    [isOpen, form, opportunity, organization]
  );

  const { height } = useWindowSize();

  return (
    <Modal
      confirmLoading={updateOpportunity.isLoading}
      okText="Update"
      onCancel={close}
      onOk={form.submit}
      title="Details"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({
          opportunity,
          updateOpportunity,
        })}
      >
        <Form.Item label="Name" name="alias" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item label="Started On" name="startedOn">
          <DatePicker allowClear={false} format={"YYYY-MM-DD"} />
        </Form.Item>
        <Form.Item label="Target Win Date" name="targetWinDate">
          <DatePicker allowClear={false} format={"YYYY-MM-DD"} />
        </Form.Item>
        <CreateAccountSelect form={form} />
        <SalesProcessSelect name="salesProcessId" label="Sales Process" />
        <Form.Item label="Value" name="value">
          <InputNumber
            addonBefore="$"
            max={999999999}
            style={{ width: "133px" }}
          />
        </Form.Item>
        <Form.Item label="Summary" name="summary">
          <Input.TextArea />
        </Form.Item>
        <UserDefinedFieldInputs
          organization={organization}
          type={viewTypes.OPPORTUNITY}
        />
      </Form>
    </Modal>
  );
};
