import { vendorFields } from "@evolved/views";

import {
  usePatchVendor,
  useSetVendorTags,
  useSetVendorContacts,
  useSetVendorUsers,
} from "data/use-vendors";

import { swallow } from "../../../../../utils/swallow";
import { EditableContactsSelect } from "./editable-contacts-select";
import { EditableTagsSelect } from "./editable-tags-select";
import { EditableTextCell } from "./editable-text-cell";
import { EditableUserDefinedFieldCell } from "./editable-user-defined-field";
import { EditableUsersSelect } from "./editable-users-select";

const { address, contacts, name, phone, tags, users } = vendorFields;

const Address = (props) => {
  const { row, ...rest } = props;

  const patchVendor = usePatchVendor();

  const onUpdate = (address) => {
    return swallow(
      patchVendor.mutateAsync({
        id: row._id,
        address,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Contacts = (props) => {
  const { row, ...rest } = props;

  const setVendorContacts = useSetVendorContacts();

  const onUpdate = (contactIds) => {
    return swallow(
      setVendorContacts.mutateAsync({
        id: row._id,
        contactIds,
      })
    );
  };

  return <EditableContactsSelect onUpdate={onUpdate} {...rest} />;
};

const Name = (props) => {
  const { row, ...rest } = props;

  const patchVendor = usePatchVendor();

  const onUpdate = (name) => {
    return swallow(
      patchVendor.mutateAsync({
        id: row._id,
        name,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Phone = (props) => {
  const { row, ...rest } = props;

  const patchVendor = usePatchVendor();

  const onUpdate = (phone) => {
    return swallow(
      patchVendor.mutateAsync({
        id: row._id,
        phone,
      })
    );
  };

  return <EditableTextCell {...rest} onUpdate={onUpdate} />;
};

const Tags = (props) => {
  const { row, ...rest } = props;

  const setVendorTags = useSetVendorTags();

  const onUpdate = (tagIds) => {
    return swallow(
      setVendorTags.mutateAsync({
        id: row._id,
        tagIds,
      })
    );
  };

  return <EditableTagsSelect onUpdate={onUpdate} {...rest} />;
};

const Users = (props) => {
  const { row, ...rest } = props;

  const setVendorUsers = useSetVendorUsers();

  const onUpdate = (userIds) => {
    return swallow(
      setVendorUsers.mutateAsync({
        id: row._id,
        userIds,
      })
    );
  };

  return <EditableUsersSelect onUpdate={onUpdate} {...rest} />;
};

const UserDefinedField = (props) => {
  const { row, ...rest } = props;

  const patchVendor = usePatchVendor();

  const onUpdate = (userDefinedFields) => {
    return swallow(
      patchVendor.mutateAsync({
        id: row._id,
        userDefinedFields,
      })
    );
  };

  return (
    <EditableUserDefinedFieldCell
      {...rest}
      onUpdate={onUpdate}
      viewType="VENDOR"
    />
  );
};

export const vendorCells = {
  [address.dataIndex]: Address,
  [contacts.dataIndex]: Contacts,
  [name.dataIndex]: Name,
  [phone.dataIndex]: Phone,
  [tags.dataIndex]: Tags,
  [users.dataIndex]: Users,
  userDefinedField: UserDefinedField,
};
