import { Form, Input, Select } from "antd";
import get from "lodash/get";
import map from "lodash/map";
import omit from "lodash/omit";
import pick from "lodash/pick";

import { userRoles } from "@evolved/constants";

import { useCreateUser, useUpdateUser } from "data/use-users";
import { useOnChange } from "hooks/use-on-change";
import { useOrganization } from "data/use-organization";
import { Modal } from "components/modal";

const { display: displayRole, ROLES } = userRoles;

const CREATE = "create";
const UPDATE = "update";

const getType = (user) => (user ? UPDATE : CREATE);

const pickIfDifferent = (user, update, key) => {
  if (update[key] === user[key]) {
    return {};
  }

  return { [key]: update[key] };
};

const MAP_VARIABLES = {
  [CREATE]: ({ values }) => ({ ...values, role: ROLES[values.role] }),
  [UPDATE]: ({ user, values }) => {
    values = {
      ...values,
      role: ROLES[values.role],
    };

    return {
      id: user._id,
      ...pickIfDifferent(user, values, "firstName"),
      ...pickIfDifferent(user, values, "lastName"),
      ...pickIfDifferent(user, values, "role"),
    };
  },
};

const OK_TEXT = {
  [CREATE]: "Create",
  [UPDATE]: "Update",
};

const TITLES = {
  [CREATE]: "Create User",
  [UPDATE]: "Details",
};

const onSubmit =
  ({ mutation, user, type }) =>
  (values) => {
    mutation.mutate(MAP_VARIABLES[type]({ user, values }));
  };

const getInitialValues = ({ user }) => {
  if (!user) {
    return {
      email: "",
      firstName: "",
      lastName: "",
      role: "SALES",
    };
  }

  return {
    ...pick(user, ["email", "firstName", "lastName"]),
    role: Object.keys(ROLES)[user.role],
  };
};

const getCost = (role) => {
  if (role === "VIEWER") {
    return 5;
  }

  return 25;
};

export const UserDetailsModal = (props) => {
  const { close, disableRole = false, isOpen, user } = props;

  const type = getType(user);

  const organization = useOrganization();

  const createUser = useCreateUser({
    onSuccess: close,
  });

  const updateUser = useUpdateUser({
    onSuccess: close,
  });

  const mutation = type === UPDATE ? updateUser : createUser;

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue(getInitialValues({ organization, user }));
    },
    [isOpen, form, organization, user]
  );

  const role = Form.useWatch("role", form);

  return (
    <Modal
      confirmLoading={mutation.isLoading}
      maskClosable={false}
      okText={OK_TEXT[type]}
      onCancel={close}
      onOk={form.submit}
      title={TITLES[type]}
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onSubmit({ close, mutation, type, user })}
      >
        {type === CREATE && (
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, type: "email" }]}
          >
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

        {!disableRole && get(user, "role") !== ROLES.OWNER && (
          <Form.Item label="Role" name="role" rules={[{ required: true }]}>
            <Select
              options={map(omit(ROLES, "OWNER"), (role, key) => ({
                label: displayRole(role),
                value: key,
              }))}
            />
          </Form.Item>
        )}

        {!user && organization?.subscriptionState?.hasSubscription && (
          <div style={{ marginBottom: "24px", textAlign: "right" }}>
            <strong>
              *This will increase your active subscription by ${getCost(role)}{" "}
              CAD/month (prorated)
            </strong>
          </div>
        )}
      </Form>
    </Modal>
  );
};
