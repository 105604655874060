import { reduceFieldConfigs } from "@evolved/views";

import { useOrganization } from "../../data/use-organization";

export const useFieldConfigs = (viewType) => {
  const organization = useOrganization();

  // todo: time this and see how much it is called
  return reduceFieldConfigs({ organization, viewType });
};
