export const BodyIcon = (props) => {
  const { Icon, isComplete, isCurrent } = props;

  let style = {
    color: "rgba(220, 220, 220, 0.25)",
  };

  if (isComplete) {
    style = {
      color: "rgba(143, 75, 230, 0.25)",
    };
  }

  if (isCurrent) {
    style = {
      color: "rgb(143, 75, 230)",
    };
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "8px 0px",
      }}
    >
      <Icon
        style={{
          ...style,
          fontSize: "64px",
        }}
      />
    </div>
  );
};
