import Fuse from "fuse.js";
import classNames from "classnames";
import { Input, Modal } from "antd";
import { isNumber } from "radash";
import { useDebounce } from "react-use";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ClusterOutlined,
  GlobalOutlined,
  HomeOutlined,
  IdcardOutlined,
  RadarChartOutlined,
  RocketOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { PERFORM_ACCOUNT_ACTIVITY_MODAL, useLayoutStore } from "stores/layout";

import { AccountState } from "components/account-state";
import { OpportunityState } from "components/opportunity-state";
import { getPartnersLabel } from "@evolved/labels";
import { useAccounts } from "data/use-accounts";
import { useCommandPalette } from "stores/command-palette";
import { useCommandPaletteListener } from "./use-command-palette-listener";
import { useContacts } from "data/use-contacts";
import { useFocus } from "../view/filters/use-focus";
import { useOpportunities } from "data/use-opportunities";
import { useOrganization } from "data/use-organization";
import { useVendors } from "data/use-vendors";

// TODO:
// - [ ] show empty state
// - [ ] finish acting ability to act for opportunity, contact, and vendors
// - [ ] add ability to followup
// - [ ] what if something has a : in the name?

export const CommandPalette = () => {
  useCommandPaletteListener();

  const navigate = useNavigate();

  const isOpen = useCommandPalette(({ isOpen }) => isOpen);

  const accounts = useAccounts();
  const contacts = useContacts();
  const opportunities = useOpportunities();
  const organization = useOrganization();
  const vendors = useVendors();

  const [search, setSearch] = useState("");
  const [includeArchived, setIncludeArchived] = useState(false);
  const [isActing, setIsActing] = useState(false);

  const [listFocused, setListFocused] = useState(false);

  const execute = (callback) => {
    useCommandPalette.getState().setIsOpen(false);
    callback();
    setSearch("");
  };

  const getDefaultEntities = () => {
    return [
      ...[
        {
          id: "dashboard",
          type: "NAVIGATION",
        },
        {
          id: "opportunities",
          type: "NAVIGATION",
        },
        {
          id: "accounts",
          type: "NAVIGATION",
        },
        {
          id: "contacts",
          type: "NAVIGATION",
        },
        ...(organization?.settings?.showPartners
          ? [
              {
                id: "partners",
                type: "NAVIGATION",
              },
            ]
          : []),
        {
          id: "organization-settings",
          type: "NAVIGATION",
        },
      ],
      ...accounts.all({ includeArchived }).slice(0, 1),
      ...contacts.all({ includeArchived }).slice(0, 1),
      ...opportunities.all({ includeArchived }).slice(0, 1),
      ...vendors.all({ includeArchived }).slice(0, 1),
    ];
  };

  const [filteredEntities, setFilteredEntities] = useState(
    getDefaultEntities()
  );

  const [finishedOpen, setFinishedOpen] = useState(false);
  const inputFocus = useFocus(finishedOpen);

  useEffect(() => {
    setIncludeArchived(search.startsWith("archived:"));
    setIsActing(search.startsWith("act:"));
  }, [search]);

  const renderItem = (entity) => {
    if (entity.type === "ACCOUNT") {
      return {
        id: `entity-account-${entity._id}`,

        children: (
          <div className="flex w-full items-center">
            <div className="mr-auto dark:text-white">{entity.name}</div>
            <AccountState account={entity} />
          </div>
        ),

        icon: () => <RadarChartOutlined className="text-xs dark:text-white" />,
        onClick: () => {
          if (isActing) {
            useLayoutStore
              .getState()
              .open(PERFORM_ACCOUNT_ACTIVITY_MODAL, { accountId: entity._id });
          } else {
            navigate(`/accounts/${entity._id}`);
          }
        },
      };
    }

    if (entity.type === "CONTACT") {
      return {
        id: `entity-contact-${entity._id}`,

        children: (
          <div className="flex w-full items-center">
            <div className="mr-auto dark:text-white">{entity.alias}</div>
          </div>
        ),

        icon: () => <IdcardOutlined className="text-xs dark:text-white" />,
        onClick: () => navigate(`/contacts/${entity._id}`),
      };
    }

    if (entity.type === "OPPORTUNITY") {
      return {
        id: `entity-opportunity-${entity._id}`,
        children: (
          <div className="flex w-full items-center">
            <div className="mr-auto dark:text-white">{entity.alias}</div>
            {isNumber(entity.funnelProgress) ? (
              <div className="mr-1 dark:text-white">
                {entity.funnelProgress}%
              </div>
            ) : null}
            <OpportunityState hideLossReason opportunity={entity} />
          </div>
        ),

        icon: () => <RocketOutlined className="text-xs dark:text-white" />,
        onClick: () => navigate(`/opportunities/${entity._id}`),
      };
    }

    if (entity.type === "VENDOR") {
      return {
        id: `entity-vendor-${entity._id}`,

        children: (
          <div className="flex w-full items-center">
            <div className="mr-auto dark:text-white">{entity.name}</div>
          </div>
        ),

        icon: () => <GlobalOutlined className="text-xs dark:text-white" />,
        onClick: () => navigate(`/partners/${entity._id}`),
      };
    }

    if (entity.type === "NAVIGATION" && entity.id === "dashboard") {
      return {
        id: "dashboard",
        children: "Dashboard",
        icon: () => <HomeOutlined className="dark:text-white" />,
        onClick: () => {
          navigate("/dashboard");
        },
      };
    }

    if (entity.type === "NAVIGATION" && entity.id === "opportunities") {
      return {
        id: "opportunities",
        children: "Opportunities",
        icon: () => <RocketOutlined className="dark:text-white" />,
        onClick: () => {
          navigate("/opportunities");
        },
      };
    }

    if (entity.type === "NAVIGATION" && entity.id === "accounts") {
      return {
        id: "accounts",
        children: "Accounts",
        icon: () => <RadarChartOutlined className="dark:text-white" />,
        onClick: () => {
          navigate("/accounts");
        },
      };
    }
    if (entity.type === "NAVIGATION" && entity.id === "contacts") {
      return {
        id: "contacts",
        children: "Contacts",
        icon: () => <IdcardOutlined className="dark:text-white" />,
        onClick: () => {
          navigate("/contacts");
        },
      };
    }
    if (entity.type === "NAVIGATION" && entity.id === "partners") {
      return {
        id: "partners",
        children: getPartnersLabel(organization),
        icon: () => <GlobalOutlined className="dark:text-white" />,
        onClick: () => {
          navigate("/partners");
        },
      };
    }
    if (entity.type === "NAVIGATION" && entity.id === "organization-settings") {
      return {
        id: "organization-settings",
        children: "Organization Settings",
        icon: () => <ClusterOutlined className="dark:text-white" />,
        onClick: () => {
          navigate("/organization?tab=general");
        },
      };
    }

    return null;
  };

  useDebounce(
    () => {
      const result = new Fuse(
        [
          ...accounts.all({ includeArchived }),
          ...contacts.all({ includeArchived }),
          ...opportunities.all({ includeArchived }),
          ...vendors.all({ includeArchived }),
          ...[
            {
              id: "dashboard",
              type: "NAVIGATION",
            },
            {
              id: "opportunities",
              type: "NAVIGATION",
            },
            {
              id: "accounts",
              type: "NAVIGATION",
            },
            {
              id: "contacts",
              type: "NAVIGATION",
            },
            ...(organization?.settings?.showPartners
              ? [
                  {
                    id: "partners",
                    type: "NAVIGATION",
                  },
                ]
              : []),
            {
              id: "organization-settings",
              type: "NAVIGATION",
            },
          ],
        ],
        {
          shouldSort: true,
          threshold: 0.1,
          keys: ["id", "alias", "name"],
        }
      ).search(search.split(":").length > 1 ? search.split(":")[1] : search);

      setFilteredEntities(
        search
          ? result.map(({ item }) => item).slice(0, 10)
          : getDefaultEntities()
      );
    },
    50,
    [search]
  );

  return (
    <Modal
      afterOpenChange={(open) => setFinishedOpen(open)}
      centered
      closeIcon={null}
      footer={null}
      forceRender
      onCancel={() => useCommandPalette.getState().setIsOpen(false)}
      open={isOpen}
      styles={{ body: { height: 418 }, content: { padding: "1px" } }}
      width={576}
      zIndex={99999999}
    >
      <div className="flex h-full flex-col">
        <Input
          onKeyUp={(e) => {
            if (e.key === "Enter" && filteredEntities[0]) {
              execute(renderItem(filteredEntities[0]).onClick);
            }
          }}
          onChange={(e) => setSearch(e.target.value)}
          prefix={<SearchOutlined />}
          ref={inputFocus}
          size="large"
          value={search}
        />
        <div
          className="overflow-y-scroll p-1"
          onFocus={() => {
            setListFocused(true);
          }}
          onBlur={(e) => {
            const currentTarget = e.currentTarget;

            requestAnimationFrame(() => {});
            if (!currentTarget.contains(document.activeElement)) {
              setListFocused(false);
            }
          }}
        >
          {filteredEntities.map((entity, index) => {
            const { children, icon, id, onClick } = renderItem(entity);

            return (
              <div
                className={classNames(
                  "flex h-10 w-full cursor-pointer items-center rounded-sm border-none  p-1 hover:bg-purple-400 hover:text-white focus:bg-purple-400 focus:text-white focus:outline-none",
                  {
                    "bg-purple-300": !listFocused && index === 0,
                    "text-white": !listFocused && index === 0,
                  }
                )}
                key={id}
                onClick={onClick}
                onKeyUp={(e) => {
                  // TODO:
                  // - [ ] do not scroll on Spacebar

                  if (e.key === "Tab") return;

                  if (e.key === "Enter") {
                    execute(onClick);
                    return;
                  }

                  if (
                    e.key.length !== 1 ||
                    !/^[a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\-=/\\| ]$/.test(
                      e.key
                    )
                  )
                    return;

                  if (e.key === "Backspace") {
                    setSearch(search.slice(0, search.length - 1));
                    inputFocus.current.focus();
                    return;
                  }

                  setSearch(`${search}${e.key}`);
                  inputFocus.current.focus();
                }}
                tabIndex={0}
              >
                <div className="mr-2 flex-grow-0">{icon()}</div>
                <div className="flex-grow">{children}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};
