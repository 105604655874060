import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const AddButton = (props) => {
  const { children, ...rest } = props;

  return (
    <Button ghost icon={<PlusOutlined />} type="primary" {...rest}>
      {children}
    </Button>
  );
};
