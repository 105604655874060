import {
  RouterProvider,
  createBrowserRouter,
  useHref,
  useNavigate,
} from "react-router-dom";

import { LoginRoute } from "./login-route";
import { useAuthenticationStore } from "../stores/authentication";
import { useEffectOnce } from "../hooks/use-effect-once";

// This Component will always be rendered on logout, hence why the
// logout logic is here.

// This is because no private links are public links, therefore a redirect will
// always take place when a private link is attempted and the user session has expired
// or doesn't exist.

const RedirectToLogin = () => {
  const href = useHref();
  const navigate = useNavigate();

  useEffectOnce(() => {
    const authentication = useAuthenticationStore.getState();

    // Authentication is in progress only if a user is logging out from a private route.
    // In this case we do not want to store a redirect.
    if (!authentication.inProgress) {
      authentication.setRedirect(href);
    }

    // If logging out, end the authentication flow here.
    if (authentication.inProgress) {
      authentication.stop();
    }

    navigate("/login");
  });

  return null;
};

const router = () =>
  createBrowserRouter([
    {
      path: "/login",
      element: <LoginRoute />,
    },
    {
      path: "/*",
      element: <RedirectToLogin />,
    },
  ]);

export const PublicRoutes = () => {
  return <RouterProvider router={router()} />;
};
