import { Switch } from "antd";

import { getPartnersLabel } from "@evolved/labels";

import {
  useOrganization,
  usePatchOrganizationSettings,
} from "data/use-organization";

const toggle =
  ({ organization, patchSettings }) =>
  () => {
    patchSettings.mutate({
      showPartners: !organization?.settings.showPartners,
    });
  };

export const ToggleShowPartners = () => {
  const organization = useOrganization();
  const patchSettings = usePatchOrganizationSettings();

  return (
    <>
      <div style={{ display: "flex" }}>
        <strong>{getPartnersLabel(organization)} Module</strong>
        <Switch
          checked={organization?.settings?.showPartners}
          loading={patchSettings.loading}
          onChange={toggle({ patchSettings, organization })}
          style={{ marginLeft: "16px" }}
        />
      </div>
      <div style={{ fontSize: "12px", marginTop: "8px" }}>
        Enabling this will turn on the {getPartnersLabel(organization)} module.
      </div>
    </>
  );
};
