import find from "lodash/find.js";
import get from "lodash/get.js";
import isArray from "lodash/isArray.js";
import isEmpty from "lodash/isEmpty.js";
import isNil from "lodash/isNil.js";
import reduce from "lodash/reduce.js";
import toLower from "lodash/toLower.js";
import { alphabetical, isObject } from "radash";

import { accountStates, fieldTypes } from "@evolved/constants";
import { calculateRow } from "@evolved/views";

const {
  ACCOUNT_STATE,
  ACTIVITY_DATE,
  CALCULATED,
  DATE,
  DOLLAR,
  FOLLOWUP_DATE,
  NUMBER,
  PERCENT,
  SELECT,
  SET,
  TEXT,
} = fieldTypes;

const { CLIENT, LEAD, PROSPECT } = accountStates;

const commonSorter =
  (sorter) =>
  ({ dataIndex, cache, config, order }) =>
  (a, b) => {
    a = get(a, dataIndex);
    b = get(b, dataIndex);

    if (isNil(a) || a === "") {
      return 1;
    }

    if (isNil(b) || b === "") {
      return -1;
    }

    if (order === "descend") {
      const temp = b;
      b = a;
      a = temp;
    }

    return sorter(a, b, config, cache);
  };

const standard = commonSorter((a, b) => a - b);

const SORTERS = {
  [ACTIVITY_DATE]: standard,
  [ACCOUNT_STATE]: commonSorter((a, b) => {
    const ranking = [LEAD, PROSPECT, CLIENT];

    return ranking.indexOf(a) - ranking.indexOf(b);
  }),
  [CALCULATED]:
    ({ config, order }) =>
    (a, b) => {
      a = calculateRow(a, config);
      b = calculateRow(b, config);

      if (a === null) {
        return 1;
      }

      if (b === null) {
        return -1;
      }

      if (order === "descend") {
        const temp = b;
        b = a;
        a = temp;
      }

      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    },
  [DATE]: standard,
  [DOLLAR]: standard,
  [FOLLOWUP_DATE]: standard,
  [NUMBER]: standard,
  [PERCENT]: standard,
  [SELECT]: commonSorter((a, b, config) => {
    const options = (config.options || []).filter((o) => !o.isArchived);

    a = find(options, { id: a });
    a = a ? toLower(a.label) : "";

    b = find(options, { id: b });
    b = b ? toLower(b.label) : "";

    if (a < b) return -1;
    if (a > b) return 1;

    return 0;
  }),
  [SET]:
    ({ dataIndex, cache, config, order }) =>
    (a, b) => {
      // opting to not sort vs error, should log a message
      if (!cache[config.collection]) {
        return 0;
      }

      const { entities, key } = cache[config.collection];

      const getFirst = (arr) => {
        const found = alphabetical(arr ?? [], (a) => {
          const id = isObject(a) ? a._id : a;
          const entity = entities[id];
          return entity?.[key];
        })[0];

        if (isObject(found)) {
          return found._id;
        }

        return found;
      };

      if (isArray(get(a, dataIndex)) || isArray(get(b, dataIndex))) {
        a = entities[getFirst(get(a, dataIndex))];
        b = entities[getFirst(get(b, dataIndex))];
      } else {
        a = entities[get(a, dataIndex)];
        b = entities[get(b, dataIndex)];
      }

      if (isNil(a) || a === "") {
        return 1;
      }

      if (isNil(b) || b === "") {
        return -1;
      }

      if (order === "descend") {
        const temp = b;
        b = a;
        a = temp;
      }

      a = toLower(a[key]);
      b = toLower(b[key]);

      if (a < b) return -1;
      if (a > b) return 1;

      return 0;
    },
  [TEXT]: commonSorter((a, b) => {
    a = toLower(a);
    b = toLower(b);

    if (a < b) return -1;
    if (a > b) return 1;

    return 0;
  }),
};

export const sortData = ({ data, cache = {}, fieldConfigs, sortOrder }) => {
  const sorters = reduce(
    // todo: should be able to remove this
    isArray(sortOrder) ? sortOrder : [sortOrder],
    (accumulator, sortOrder) => {
      // todo: should be able to remove this
      if (!get(sortOrder, "dataIndex")) return accumulator;

      const { dataIndex, order } = sortOrder;

      if (!fieldConfigs[sortOrder.dataIndex]) return accumulator;

      const config = fieldConfigs[sortOrder.dataIndex];
      const { isSortable, type } = config;

      if (!SORTERS[type] || (type === SET && !isSortable)) {
        return accumulator;
      }

      const sorter = SORTERS[type]({ dataIndex, cache, config, order });

      accumulator.push(sorter);
      return accumulator;
    },
    []
  );

  if (isEmpty(sorters)) {
    return data;
  }

  return [...data].sort((a, b) => {
    for (const sorter of sorters) {
      const result = sorter(a, b);

      if (result === 0) {
        continue;
      }

      return result;
    }

    return 0;
  });
};
