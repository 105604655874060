import isArray from "lodash/isArray.js";
import isEmpty from "lodash/isEmpty.js";

import { accountStates } from "@evolved/constants";

export const filter = (value, filter) => filter.includes(value);

export const validate = (filter) => {
  if (!isArray(filter)) return false;

  return !isEmpty(filter) && filter.every(accountStates.isValid);
};
