import omit from "lodash/omit";

import { App } from "antd";
import { useMutation as baseUseMutation } from "@tanstack/react-query";

import { useNotification } from "contexts/notification/use-notification";

export const useMutation = (options = {}, overrides = {}) => {
  const { message } = App.useApp();
  const notification = useNotification();

  return baseUseMutation({
    onSuccess: async (data, variables, context) => {
      const called = {
        onFinal: false,
        onSuccess: false,
      };

      const onSuccess = async () => {
        if (called.onSuccess) return;

        if (options.onSuccess) {
          await options.onSuccess(data, { variables, context });
        }

        called.onSuccess = true;
      };

      const onFinal = async () => {
        if (called.onFinal) return;

        if (options.onFinal) {
          await options.onFinal(data, { variables, context });
        } else {
          message.success("Success!");
        }

        called.onFinal = true;
      };

      if (overrides.onSuccess) {
        await overrides.onSuccess(data, {
          context,
          variables,
          mutation: {
            onSuccess: {
              call: onSuccess,
              skip: () => (called.onSuccess = true),
            },
            onFinal: {
              call: onFinal,
              skip: () => (called.onFinal = true),
            },
          },
        });
      }

      await onSuccess();
      await onFinal();
    },
    onError: async (error, ...rest) => {
      if (options.onError) {
        await options.onError(error, ...rest);
      }

      if (overrides.onError) {
        return await overrides.onError(error, ...rest);
      }

      notification.support(error, { silenceEmail: !!options.silenceEmail });
    },
    mutationFn: async (...args) => {
      const start = +new Date();

      const result = await options.mutationFn(...args);

      const end = +new Date();

      if (overrides.delay && end - start < overrides.delay) {
        await new Promise((resolve) =>
          setTimeout(resolve, overrides.delay - (end - start))
        );
      }

      return result;
    },
    ...omit(options, ["mutationFn", "onSuccess", "onError"]),
  });
};
