import noop from "lodash/noop";

import { useQueryClient } from "@tanstack/react-query";
import { Checkbox, Form, Input, message } from "antd";

import { useUpdateDefaultView } from "../../../data/use-me";
import { useCreateView, useViews } from "../../../data/use-views";
import { useOnChange } from "../../../hooks/use-on-change";
import { useViewStore } from "../../../stores/view";
import { swallow } from "../../../utils/swallow";
import { Modal } from "components/modal";

export const CreateViewModal = ({ close, isOpen, viewType }) => {
  const createView = useCreateView({
    onSuccess: (_, { mutation }) => mutation.onFinal.skip(),
  });
  const updateDefaultView = useUpdateDefaultView({
    onSuccess: (_, { mutation }) => mutation.onFinal.skip(),
  });
  const queryClient = useQueryClient();

  const [form] = Form.useForm();

  useOnChange(
    isOpen,
    () => {
      if (!isOpen) {
        return;
      }

      form.setFieldsValue({
        name: "",
        isDefault: false,
      });
    },
    [isOpen, form]
  );

  const onFinish = async (values) => {
    const { isDefault, name } = values;

    const state = useViewStore.getState()[viewType];

    await swallow(
      (async () => {
        const result = await createView.mutateAsync({
          fields: state.fields,
          filters: state.filters,
          includeArchived: state.includeArchived,
          name,
          pageSize: state.pageSize,
          sortOrder: state.sortOrder,
          type: viewType,
          widths: state.widths,
        });

        if (isDefault) {
          await updateDefaultView.mutateAsync({
            _id: result.view._id,
            viewType,
          });
        }

        message.success("Success!");

        const views = queryClient.getQueryData(useViews.key);

        useViewStore.getState().setView(viewType)(
          views.find((view) => view._id === result.view._id)
        );

        close();
      })()
    );
  };

  return (
    <Modal
      confirmLoading={createView.isLoading || updateDefaultView.isLoading}
      okText="Create"
      onCancel={close}
      onOk={form.submit}
      title="Create View"
      open={isOpen}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        onFinish={onFinish}
      >
        <Form.Item label="Name" name="name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item
          name="isDefault"
          wrapperCol={{ offset: 6, span: 18 }}
          valuePropName="checked"
        >
          <Checkbox>Set as default view</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};
