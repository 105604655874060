import { preparePayload } from "./prepare-payload";

export const onSubmit = (options) => {
  const {
    account,
    close,
    closedFollowup,
    contact,
    opportunity,
    performActivity,
    showCreateFollowup,
    steps,
    vendor,
  } = options;

  return async (values) => {
    const payload = preparePayload({
      account,
      closedFollowup,
      contact,
      opportunity,
      steps,
      values,
      vendor,
    });

    performActivity(payload, {
      // This always happens after all other onSuccess handlers
      onSuccess: (result) => {
        close(() => {
          if (values.shouldFollowUp) {
            showCreateFollowup({
              openingActivityId: result.activity._id,
            });
          }
        });
      },
    });
  };
};
