import c from "classnames";

export const Card = ({ children, className }) => {
  return (
    <div
      className={c(
        className,
        "rounded-xl bg-white p-12 drop-shadow-md dark:bg-slate-800"
      )}
    >
      {children}
    </div>
  );
};
