import { useFlag } from "../hooks/use-flag";

export const withFlag = (flag) => (Component) => (props) => {
  const hasFlag = useFlag(flag);

  if (!hasFlag) {
    return null;
  }

  return <Component {...props} />;
};
