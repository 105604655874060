import { filter as filterRange, validate as validateRange } from "./range.js";
import { calculateRow } from "../calculate-row.js";

export const filter = (_value, filter, row, config) => {
  const value = calculateRow(row, config);

  if (value === null) {
    return false;
  }

  return filterRange(value, filter);
};

export const validate = validateRange;
