import { Card, Col, Row, Table as AntTable } from "antd";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

import { AddButton } from "components/add-button";
import { CreateSalesProcessModal } from "./create-sales-process-modal";
import { useModal } from "hooks/use-modal";
import { useSalesProcesses } from "data/use-sales-processes";
import { withManagerRole } from "components/with-role";

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    render: (text, record) => {
      if (record.isArchived) {
        return <span style={{ opacity: 0.5 }}>{text}</span>;
      }

      return text;
    },
    sorter: (a, b) => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;

      return 0;
    },
  },
];

const CreateSalesProcessButton = withManagerRole(() => {
  const navigate = useNavigate();

  const { modal, open } = useModal(CreateSalesProcessModal);

  return (
    <>
      {modal}
      <AddButton
        id="create-sales-process-button"
        onClick={() =>
          open({
            onCreate: (salesProcess) =>
              navigate(`/sales-processes/${salesProcess._id}`),
          })
        }
        style={{ marginLeft: "auto" }}
      >
        Sales Process
      </AddButton>
    </>
  );
});

export const SalesProcessesRoute = () => {
  const [includeArchived, setIncludeArchived] = useState(false);

  const navigate = useNavigate();

  const salesProcesses = useSalesProcesses().all({ includeArchived });

  return (
    <div className="w-full">
      <Row justify={"center"} type={"flex"}>
        <Col xs={24} xl={12}>
          <div className="mb-2 flex">
            <h2>Sales Processes</h2>
            <div className="ml-auto flex flex-col items-end">
              <CreateSalesProcessButton />
              <div
                className="mt-2 cursor-pointer text-purple-700"
                onClick={() => setIncludeArchived(!includeArchived)}
              >
                {includeArchived ? "Hide" : "Show"} Archived
              </div>
            </div>
          </div>
          <Card bodyStyle={{ padding: 0 }} bordered={false}>
            <AntTable
              columns={columns}
              dataSource={salesProcesses}
              locale={{
                emptyText:
                  "You have no sales processes, go ahead and create some!",
              }}
              id="sales-process-table"
              onRow={(record) => ({
                onClick: () => navigate(`/sales-processes/${record._id}`),
              })}
              rowClassName="clickable"
              rowKey="_id"
              size="middle"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
};
