import isArray from "lodash/isArray";

// input: [{id, ...}]
// output: {ids: [], entities: {[id]: entity}}

export const normalize = (data) => {
  data = isArray(data) ? data : [data];

  return {
    ...data.reduce(
      (accumulator, item) => {
        accumulator.ids.push(item._id);
        accumulator.entities[item._id] = item;
        return accumulator;
      },
      { ids: [], entities: {} }
    ),
    rows: data,
  };
};

export const findByIds =
  (ids = []) =>
  (data) => {
    return ids.reduce((accumulator, id) => {
      if (data.entities[id]) {
        accumulator.push(data.entities[id]);
      }

      return accumulator;
    }, []);
  };

export const findAvailableByIds =
  (ids = []) =>
  (data) => {
    return ids.reduce((accumulator, id) => {
      if (data.entities[id] && !data.entities[id].isArchived) {
        accumulator.push(data.entities[id]);
      }

      return accumulator;
    }, []);
  };
