import get from "lodash/get";
import trim from "lodash/trim";
import { Button, Input } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useState } from "react";

import { format } from "./format";
import { usePatchOrganizationLabels } from "data/use-organization";

export const UpdateSalesFunnelLabel = (props) => {
  const { organization } = props;

  const current = get(organization, "labels.salesFunnel", "");

  const patchLabels = usePatchOrganizationLabels();

  const [value, setValue] = useState(current);

  return (
    <>
      <div style={{ alignItems: "center", display: "flex" }}>
        <div style={{ width: "90px" }}>
          <strong>Sales Funnel</strong>
        </div>
        <Input
          onChange={(e) => setValue(format(e.target.value))}
          placeholder={"Sales Funnel"}
          value={value}
          style={{ margin: "0px 8px", width: "250px" }}
        />
        <Button
          loading={patchLabels.isLoading}
          icon={<SaveOutlined />}
          onClick={() => {
            patchLabels.mutate({ salesFunnel: trim(value) });
          }}
        />
      </div>
    </>
  );
};
