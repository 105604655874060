import { UpdateSubscription } from "../subscription";

export const PastDue = ({ organization, users }) => {
  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        Your subscription payment failed to go through. Please update your
        payment details to continue access.
      </div>
      <UpdateSubscription organization={organization} users={users} />
    </>
  );
};
