import { fieldTypes } from "@evolved/constants";

import { reduceFieldsToMap } from "./reduce-fields-to-map.js";

const { ACTIVITY_DATE, SET, TEXT } = fieldTypes;

export const performedOn = {
  dataIndex: "performedOn",
  title: "Performed On",
  type: ACTIVITY_DATE,
};

export const summary = {
  dataIndex: "summary",
  title: "Summary",
  type: TEXT,
};

export const account = {
  collection: "accounts",
  dataIndex: "accountId",
  idIndex: "accountId",
  isSortable: true,
  title: "Account",
  type: SET,
};

export const contacts = {
  collection: "contacts",
  dataIndex: "contacts",
  title: "Contacts",
  type: SET,
};

export const opportunity = {
  collection: "opportunities",
  dataIndex: "opportunityId",
  idIndex: "opportunityId",
  title: "Opportunity",
  type: SET,
};

export const type = {
  collection: "activityTypes",
  dataIndex: "typeId",
  isSortable: true,
  title: "Type",
  type: SET,
};

export const users = {
  collection: "users",
  dataIndex: "users",
  title: "Sales Reps",
  type: SET,
};

export const vendors = {
  collection: "vendors",
  dataIndex: "vendors",
  title: "Partners",
  type: SET,
};

export const COLLECTION = [
  performedOn,
  summary,
  account,
  contacts,
  opportunity,
  type,
  users,
  vendors,
];

export const DEFAULT_FIELDS = [
  "performedOn",
  "summary",
  "accountId",
  "contacts",
  "opportunityId",
  "typeId",
  "users",
  "vendors",
];
export const FIXED_FIELDS = [];

export const MAP = reduceFieldsToMap(COLLECTION);
