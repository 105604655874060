const typeTemplate = "is not a valid ${type}";

export const validateMessages = {
  default: "Validation error on field '${name}'",
  required: "is required",
  enum: "must be one of [${enum}]",
  whitespace: "cannot be empty",
  date: {
    format: "is invalid for format date",
    parse: "could not be parsed as date",
    invalid: "is invalid date",
  },
  types: {
    string: typeTemplate,
    method: typeTemplate,
    array: typeTemplate,
    object: typeTemplate,
    number: typeTemplate,
    date: typeTemplate,
    boolean: typeTemplate,
    integer: typeTemplate,
    float: typeTemplate,
    regexp: typeTemplate,
    email: typeTemplate,
    url: typeTemplate,
    hex: typeTemplate,
  },
  string: {
    len: "must be exactly ${len} characters",
    min: "must be at least ${min} characters",
    max: "cannot be longer than ${max} characters",
    range: "must be between ${min} and ${max} characters",
  },
  number: {
    len: "must equal ${len}",
    min: "cannot be less than ${min}",
    max: "cannot be greater than ${max}",
    range: "must be between ${min} and ${max}",
  },
  array: {
    len: "must be exactly ${len} in length",
    min: "cannot be less than ${min} in length",
    max: "cannot be greater than ${max} in length",
    range: "must be between ${min} and ${max} in length",
  },
  pattern: {
    mismatch: "does not match pattern ${pattern}",
  },
};
