import get from "lodash/get";
import { Button, Checkbox, Popconfirm, Select } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";

import { DownloadCSV } from "../../components/view/download-csv";
import { getCalculatedFieldOptions } from "./modals/get-calculated-fields";
import {
  useOpportunities,
  root as opportunitiesKey,
} from "../../data/use-opportunities";
import { useOrganization } from "data/use-organization";
import { usePatchOrganizationSettings } from "../../data/use-organization";
import { withFlag } from "../../components/with-flag";

export const CalculatedValue = withFlag("opportunities:calculated-value")(
  () => {
    const queryClient = useQueryClient();
    const opportunities = useOpportunities();
    const organization = useOrganization();

    const userDefinedFields = get(organization, "userDefinedFields", []);

    const patchSettings = usePatchOrganizationSettings({
      onSuccess: () => {
        return queryClient.invalidateQueries({ queryKey: [opportunitiesKey] });
      },
    });

    const [enabled, setEnabled] = useState(
      !!organization?.settings?.opportunityValueCalculation?.enabled
    );
    const [forceUpdate, setForceUpdate] = useState(false);
    const [formula, setFormula] = useState(
      organization?.settings?.opportunityValueCalculation?.formula || [
        undefined,
        "+",
        undefined,
      ]
    );

    const onSave = () => {
      patchSettings.mutate({
        opportunityValueCalculation: {
          formula,
          enabled,
        },
        forceUpdateOpportunityValueCalculation: forceUpdate,
      });
    };

    return (
      <>
        <div style={{ marginBottom: "8px" }}>
          <strong>Calculate Opportunity Value By</strong>
          <Checkbox
            onChange={(e) => {
              setEnabled(e.target.checked);
            }}
            checked={enabled}
            style={{ marginLeft: "8px" }}
          />
        </div>
        <div className="flex space-x-2" style={enabled ? {} : { opacity: 0.3 }}>
          <Select
            options={getCalculatedFieldOptions({
              userDefinedFields,
              type: "OPPORTUNITY",
              excludeSystemFields: true,
            })}
            onChange={(value) => {
              const next = [...formula];
              next[0] = value;
              setFormula(next);
            }}
            value={formula[0]}
            className="w-5/12"
          />
          <Select
            options={[
              { label: "+", value: "+" },
              { label: "-", value: "-" },
              { label: "*", value: "*" },
              { label: "/", value: "/" },
            ]}
            onChange={(value) => {
              const next = [...formula];
              next[1] = value;
              setFormula(next);
            }}
            className="w-5/12"
            value={formula[1]}
          />
          <Select
            options={getCalculatedFieldOptions({
              userDefinedFields,
              type: "OPPORTUNITY",
              excludeSystemFields: true,
            })}
            onChange={(value) => {
              const next = [...formula];
              next[2] = value;
              setFormula(next);
            }}
            value={formula[2]}
            className="w-5/12"
          />
        </div>
        <div
          style={{
            display: "flex",
            margin: "8px 0px",
            justifyContent: "right",
          }}
        >
          <div style={{ display: "flex", marginRight: "auto" }}>
            <div>Force update all existing opportunities</div>
            <Checkbox
              onChange={(e) => {
                setForceUpdate(e.target.checked);
              }}
              checked={forceUpdate}
              style={{ marginLeft: "8px" }}
            />
          </div>
          <Popconfirm
            cancelText="Cancel"
            okText="Yes"
            onConfirm={onSave}
            title={
              <div className="mb-4 w-64 space-y-2">
                <div>
                  {forceUpdate
                    ? "Are you sure? This will overwrite all of your current opportunity values based on this calculation."
                    : "Are you sure? This will overwrite all opportunity values that are empty, based on this calculation."}
                </div>
                <div>
                  We recommend you{" "}
                  <DownloadCSV
                    data={opportunities.all({ includeArchived: false })}
                    fieldsOverride={["alias", "value"]}
                    withId
                    viewType={"OPPORTUNITY"}
                    asText={"Backup Your Opportunity Value Data"}
                  />{" "}
                  first.
                </div>
              </div>
            }
          >
            <Button
              icon={<SaveOutlined />}
              loading={patchSettings.isLoading}
              size="small"
              style={{ flexBasis: 0, marginRight: 8 }}
              type="primary"
            >
              Save
            </Button>
          </Popconfirm>
        </div>
        <div></div>
      </>
    );
  }
);
